import React, { useCallback, useEffect, useState } from 'react'
import {
    Button,
    ExpansionPanelActions,
    ExpansionPanelDetails,
    TextField,
    Typography,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import times from 'lodash/times'
import pick from 'lodash/pick'
import set from 'lodash/set'
import noop from 'lodash/noop'
import debounce from 'debounce-promise'
import numeral from 'numeral'
import { InlineDatePicker } from 'material-ui-pickers'
import Select from 'react-select/async'
import PanoramaIcon from '@material-ui/icons/Panorama'
import { Img } from 'react-image'

import Loader from '../../shared/components/Loader'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import Access from '../../shared/components/Access'

const Container = styled.div`
    flex-grow: 1;
`

const selectStyles = {
    input: base => ({
        ...base,
        height: 60,
        display: 'flex',
        alignItems: 'center',
    }),
}

const createOptions = products =>
    products.map(({ name, cuid, price, imageUrl, slug }) => ({
        cuid,
        name,
        price,
        imageUrl,
        slug,
        value: cuid,
    }))

const getOptionLabel = ({ name, imageUrl, price }) => (
    <Grid spacing={16} container alignItems="center" wrap="nowrap">
        <Grid item>
            <Img
                style={{ maxWidth: 60 }}
                src={imageUrl}
                alt={name}
                unloader={<PanoramaIcon fontSize="large" />}
                loader={<Loader size={30} />}
            />
        </Grid>
        <Grid item style={{ flex: 1 }}>
            {name}
        </Grid>
        <Grid item>
            <Grid container alignItems="center" wrap="nowrap">
                <AttachMoneyIcon fontSize="small" />
                {price}
            </Grid>
        </Grid>
    </Grid>
)

const Giveaway = ({
    giveaway,
    products,
    save,
    cancelAdd,
    search,
    isSearching,
}) => {
    const [changed, setChanged] = useState(new Set())
    const [isSaving, setIsSaving] = useState(false)

    const [errors, setErrors] = useState({})
    const [entity, setEntity] = useState(giveaway)

    const handleChange = useCallback(
        e => {
            if (!e.target || !e.target.name) {
                return
            }
            const { checked, value, type, name } = e.target

            setChanged(new Set([...changed, name]))
            const newEntity = set(
                { ...entity, prizes: [...entity.prizes] },
                name,
                type === 'checkbox'
                    ? checked
                    : type === 'number'
                    ? parseFloat(value)
                    : value
            )
            setEntity(newEntity)
        },
        [entity, setEntity, changed]
    )

    const reset = useCallback(() => {
        setEntity(giveaway)
        setChanged(new Set())
        setIsSaving(false)
    }, [giveaway, setEntity, setChanged])

    useEffect(() => {
        reset()
    }, [giveaway, reset])

    const handleSave = useCallback(async () => {
        const validationErrors = {}
        if (!entity.participants || entity.participants < 3) {
            validationErrors.participants = 'Min amount is 3'
        }

        if (entity.participants) {
            times(entity.participants, i => {
                if (!entity.prizes?.[i]) {
                    set(validationErrors, `prizes.${i}`, 'Select prize')
                }
            })
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors)
            return
        }
        setIsSaving(true)
        await save(pick(entity, [...changed, 'period']), entity)
        setErrors({})
    }, [save, entity, changed, setErrors, setIsSaving])

    const handleCancelAdd = useCallback(() => {
        cancelAdd({ period: entity.period })
    }, [cancelAdd, entity])

    const handleSearch = useCallback(
        debounce(async value => {
            const products = await search({
                searchText: value,
            })

            return createOptions(products)
        }, 500),
        [search]
    )

    const handleProductSelect = useCallback(
        place => value => {
            handleChange({
                target: {
                    name: `prizes[${place}]`,
                    value,
                },
            })
        },
        [handleChange]
    )

    const canEdit = giveaway.isNew || new Date(giveaway.startAt) > new Date()

    return (
        <>
            <ExpansionPanelDetails>
                <Container>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={6} lg={4}>
                            <InlineDatePicker
                                fullWidth
                                label="Period"
                                value={entity.startAt}
                                format="MMM yyyy"
                                disabled
                                onChange={noop}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Access roles="giveawayManager">
                                {({ hasAccess }) => (
                                    <TextField
                                        fullWidth
                                        label="Participants amount"
                                        name="participants"
                                        type="number"
                                        inputProps={{
                                            min: 3,
                                            max: 100,
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={handleChange}
                                        value={entity.participants}
                                        error={Boolean(errors.participants)}
                                        helperText={errors.participants}
                                        disabled={!canEdit || !hasAccess}
                                    />
                                )}
                            </Access>
                        </Grid>
                    </Grid>
                    <Grid container spacing={16}>
                        <Grid item>
                            <Typography variant="caption" color="textSecondary">
                                Prizes
                            </Typography>
                        </Grid>
                    </Grid>
                    {times(entity.participants, place => (
                        <Grid
                            container
                            spacing={16}
                            alignItems="center"
                            key={place}
                        >
                            <Grid item>
                                {numeral(place + 1).format('Oo')} place
                            </Grid>
                            <Grid item xs={12} md={6} lg={4}>
                                <Access roles="giveawayManager">
                                    {({ hasAccess }) => (
                                        <Select
                                            styles={{
                                                ...selectStyles,
                                                container: base => ({
                                                    ...base,
                                                    ...(errors.prizes?.[
                                                        place
                                                    ] && {
                                                        '&:focus': {
                                                            outline: 'none',
                                                        },
                                                    }),
                                                }),
                                                control: base => ({
                                                    ...base,
                                                    height: 70,
                                                    ...(errors.prizes?.[
                                                        place
                                                    ] && {
                                                        borderColor: '#f44336',
                                                        borderWidth: 2,
                                                        '&:hover': {
                                                            borderColor:
                                                                'rgba(255, 0, 0, .7)',
                                                        },
                                                    }),
                                                }),
                                            }}
                                            value={entity.prizes[place]}
                                            label="Prize"
                                            defaultOptions={createOptions(
                                                products
                                            )}
                                            loadOptions={handleSearch}
                                            placeholder="Select prize..."
                                            isLoading={isSearching}
                                            getOptionLabel={getOptionLabel}
                                            onChange={handleProductSelect(
                                                place
                                            )}
                                            isDisabled={!hasAccess || !canEdit}
                                        />
                                    )}
                                </Access>
                            </Grid>
                        </Grid>
                    ))}
                </Container>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
                <Button
                    color="secondary"
                    variant="contained"
                    disabled={!changed.size}
                    onClick={entity.isNew ? handleCancelAdd : reset}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={!changed.size || isSaving}
                    onClick={handleSave}
                >
                    Save
                    {isSaving && <Loader />}
                </Button>
            </ExpansionPanelActions>
        </>
    )
}

Giveaway.propTypes = {
    save: PropTypes.func.isRequired,
    cancelAdd: PropTypes.func.isRequired,
    giveaway: PropTypes.shape({
        period: PropTypes.string.isRequired,
        startAt: PropTypes.string.isRequired,
        finishAt: PropTypes.string.isRequired,
        prizes: PropTypes.arrayOf(
            PropTypes.shape({
                cuid: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired,
                price: PropTypes.number.isRequired,
                imageUrl: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    products: PropTypes.arrayOf(
        PropTypes.shape({
            cuid: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
            imageUrl: PropTypes.string.isRequired,
        })
    ).isRequired,
    search: PropTypes.func.isRequired,
    isSearching: PropTypes.bool.isRequired,
}

export default Giveaway
