/**
 * @flow
 * @relayHash 030fbdfdef6d506ce1ca7697d1fdbee9
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankingManagementAddPaymentStatsInput = {|
  stats: $ReadOnlyArray<?BankingManagementAddPaymentStatsInputStats>,
  clientMutationId?: ?string,
|};
export type BankingManagementAddPaymentStatsInputStats = {|
  type: string,
  date: any,
  amount: number,
  paymentServiceName: string,
|};
export type PaymentsContainerAddPaymentStatsMutationVariables = {|
  input: BankingManagementAddPaymentStatsInput
|};
export type PaymentsContainerAddPaymentStatsMutationResponse = {|
  +bankingManagementAddPaymentStats: ?{|
    +success: ?boolean,
    +errors: ?$ReadOnlyArray<?{|
      +statusCode: ?number,
      +message: ?string,
      +meta: ?any,
      +errorCode: ?string,
      +code: ?number,
    |}>,
  |}
|};
export type PaymentsContainerAddPaymentStatsMutation = {|
  variables: PaymentsContainerAddPaymentStatsMutationVariables,
  response: PaymentsContainerAddPaymentStatsMutationResponse,
|};
*/

/*
mutation PaymentsContainerAddPaymentStatsMutation(
  $input: BankingManagementAddPaymentStatsInput!
) {
  bankingManagementAddPaymentStats(input: $input) {
    success
    errors {
      statusCode
      message
      meta
      errorCode
      code
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'input',
                type: 'BankingManagementAddPaymentStatsInput!',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'LinkedField',
                alias: null,
                name: 'bankingManagementAddPaymentStats',
                storageKey: null,
                args: [
                    {
                        kind: 'Variable',
                        name: 'input',
                        variableName: 'input',
                    },
                ],
                concreteType: 'BankingManagementAddPaymentStatsPayload',
                plural: false,
                selections: [
                    {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'success',
                        args: null,
                        storageKey: null,
                    },
                    {
                        kind: 'LinkedField',
                        alias: null,
                        name: 'errors',
                        storageKey: null,
                        args: null,
                        concreteType: 'BankingManagementError',
                        plural: true,
                        selections: [
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'statusCode',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'message',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'meta',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'errorCode',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'code',
                                args: null,
                                storageKey: null,
                            },
                        ],
                    },
                ],
            },
        ]
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'PaymentsContainerAddPaymentStatsMutation',
            type: 'Mutation',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: (v1 /*: any*/),
        },
        operation: {
            kind: 'Operation',
            name: 'PaymentsContainerAddPaymentStatsMutation',
            argumentDefinitions: (v0 /*: any*/),
            selections: (v1 /*: any*/),
        },
        params: {
            operationKind: 'mutation',
            name: 'PaymentsContainerAddPaymentStatsMutation',
            id: null,
            text:
                'mutation PaymentsContainerAddPaymentStatsMutation(\n  $input: BankingManagementAddPaymentStatsInput!\n) {\n  bankingManagementAddPaymentStats(input: $input) {\n    success\n    errors {\n      statusCode\n      message\n      meta\n      errorCode\n      code\n    }\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '66a49adeee294c118502bf9546837241';
module.exports = node
