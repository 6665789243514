/**
 * @flow
 * @relayHash da3ae3242dffb953d431ff5d6c501bf2
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdminManagementRequireAddressKycInput = {|
  userCuid: string,
  addressCuid: string,
  clientMutationId?: ?string,
|};
export type RequireAddressKycMutationVariables = {|
  input: AdminManagementRequireAddressKycInput
|};
export type RequireAddressKycMutationResponse = {|
  +adminManagementRequireAddressKyc: ?{|
    +address: ?{|
      +cuid: ?string,
      +isKycRequired: ?boolean,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +statusCode: ?number,
      +message: ?string,
    |}>,
  |}
|};
export type RequireAddressKycMutation = {|
  variables: RequireAddressKycMutationVariables,
  response: RequireAddressKycMutationResponse,
|};
*/

/*
mutation RequireAddressKycMutation(
  $input: AdminManagementRequireAddressKycInput!
) {
  adminManagementRequireAddressKyc(input: $input) {
    address {
      cuid
      isKycRequired
      id
    }
    errors {
      statusCode
      message
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'input',
                type: 'AdminManagementRequireAddressKycInput!',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'Variable',
                name: 'input',
                variableName: 'input',
            },
        ],
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'cuid',
            args: null,
            storageKey: null,
        },
        v3 = {
            kind: 'ScalarField',
            alias: null,
            name: 'isKycRequired',
            args: null,
            storageKey: null,
        },
        v4 = {
            kind: 'LinkedField',
            alias: null,
            name: 'errors',
            storageKey: null,
            args: null,
            concreteType: 'AdminManagementError',
            plural: true,
            selections: [
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'statusCode',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'message',
                    args: null,
                    storageKey: null,
                },
            ],
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'RequireAddressKycMutation',
            type: 'Mutation',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagementRequireAddressKyc',
                    storageKey: null,
                    args: (v1 /*: any*/),
                    concreteType: 'AdminManagementRequireAddressKycPayload',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'address',
                            storageKey: null,
                            args: null,
                            concreteType: 'AdminManagementDeliveryInfo',
                            plural: false,
                            selections: [(v2 /*: any*/), (v3 /*: any*/)],
                        },
                        (v4 /*: any*/),
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'RequireAddressKycMutation',
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagementRequireAddressKyc',
                    storageKey: null,
                    args: (v1 /*: any*/),
                    concreteType: 'AdminManagementRequireAddressKycPayload',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'address',
                            storageKey: null,
                            args: null,
                            concreteType: 'AdminManagementDeliveryInfo',
                            plural: false,
                            selections: [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'id',
                                    args: null,
                                    storageKey: null,
                                },
                            ],
                        },
                        (v4 /*: any*/),
                    ],
                },
            ],
        },
        params: {
            operationKind: 'mutation',
            name: 'RequireAddressKycMutation',
            id: null,
            text:
                'mutation RequireAddressKycMutation(\n  $input: AdminManagementRequireAddressKycInput!\n) {\n  adminManagementRequireAddressKyc(input: $input) {\n    address {\n      cuid\n      isKycRequired\n      id\n    }\n    errors {\n      statusCode\n      message\n    }\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '0a51c9a9a51d457ccc5553720a46886f';
module.exports = node
