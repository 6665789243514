import ContainerError from '../../components/ContainerError'
import Website from './Website'
import Loader from '../../components/Loader'
import React, { useCallback } from 'react'
import { useQuery } from 'relay-hooks'
import graphql from 'babel-plugin-relay/macro'
import { useRelayUtils } from '../../shared/hooks/RelayUtils'

const __DEV__ = process.env.NODE_ENV === 'development'

const WebsiteContainerQuery = graphql`
    query WebsiteContainerQuery {
        appManagement {
            id
            themes {
                ...Website_themes
            }
        }
    }
`

const CreateThemeMutation = graphql`
    mutation WebsiteContainerCreateThemeMutation(
        $input: AppManagementCreateThemeInput!
    ) {
        appManagementCreateTheme(input: $input) {
            success
            theme {
                id
                name
                createdAt
                createdByUserCuid
                startAt
                finishAt
            }
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const DeleteThemeMutation = graphql`
    mutation WebsiteContainerDeleteThemeMutation(
        $input: AppManagementDeleteThemeInput!
    ) {
        appManagementDeleteTheme(input: $input) {
            success
            theme {
                id
                name
                createdAt
                createdByUserCuid
                startAt
                finishAt
            }
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const WebsiteContainer = () => {
    const { props, error } = useQuery({
        query: WebsiteContainerQuery,
    })
    const { commitMutation } = useRelayUtils()

    const appManagementId = props?.appManagement.id
    const createTheme = useCallback(
        input =>
            commitMutation({
                mutation: CreateThemeMutation,
                variables: {
                    input,
                },
                updater: store => {
                    const payload = store.getRootField(
                        'appManagementCreateTheme'
                    )
                    if (!payload) {
                        return
                    }

                    const newTheme = payload.getLinkedRecord('theme')
                    if (!newTheme) {
                        return
                    }
                    const appManagement = store.get(appManagementId)
                    if (!appManagement) {
                        return
                    }
                    const themes = appManagement.getLinkedRecords('themes')

                    if (!themes) {
                        return
                    }
                    appManagement.setLinkedRecords(
                        [...themes, newTheme],
                        'themes'
                    )
                },
            }),
        [appManagementId, commitMutation]
    )

    const deleteTheme = useCallback(
        ({ id }) =>
            commitMutation({
                mutation: DeleteThemeMutation,
                variables: {
                    input: { id },
                },
                updater: store => {
                    const appManagement = store.get(appManagementId)

                    if (!appManagement) {
                        return
                    }

                    const themes = appManagement.getLinkedRecords('themes')

                    if (!themes) {
                        return
                    }

                    appManagement.setLinkedRecords(
                        themes.filter(themes => themes.getValue('id') !== id),
                        'themes'
                    )

                    store.delete(id)
                },
            }),
        [appManagementId, commitMutation]
    )

    if (error) {
        console.error(error)

        if (__DEV__) {
            return <div>{error.message}</div>
        }

        return <ContainerError />
    }

    if (props) {
        return (
            <Website
                themes={props.appManagement.themes}
                createTheme={createTheme}
                deleteTheme={deleteTheme}
            />
        )
    }

    return <Loader />
}

export default WebsiteContainer
