import React, { useCallback } from 'react'
import { useQuery } from 'relay-hooks'
import graphql from 'babel-plugin-relay/macro'
import get from 'lodash/get'

import ContainerError from '../../../components/ContainerError'
import Loader from '../../../components/Loader'
import ChatConfig from './ChatConfig'
import { useRelayUtils } from '../../../shared/hooks/RelayUtils'

const __DEV__ = process.env.NODE_ENV === 'development'

const ChatConfigContainerQuery = graphql`
    query ChatConfigContainerQuery {
        appManagement {
            id
            blacklistedPhrases {
                text
                ...ChatConfig_blacklistedPhrases
            }
        }
        userManagement {
            currentUser {
                role
                roles
            }
        }
    }
`

const CreateBlacklistedPhrasesMutation = graphql`
    mutation ChatConfigContainerCreateBlacklistedPhrasesMutation(
        $input: AppManagementCreateBlacklistedPhraseInput!
    ) {
        appManagementCreateBlacklistedPhrases(input: $input) {
            success
            phrases {
                text
                createdAt
                createdBy
                id
            }
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const DeleteBlacklistedPhraseMutation = graphql`
    mutation ChatConfigContainerDeleteBlacklistedPhraseMutation(
        $input: AppManagementDeleteBlacklistedPhraseInput!
    ) {
        appManagementDeleteBlacklistedPhrase(input: $input) {
            success
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const ChatConfigContainer = () => {
    const { props, error } = useQuery({
        query: ChatConfigContainerQuery,
    })
    const { commitMutation } = useRelayUtils()
    const appManagementId = get(props, 'appManagement.id')

    const createBlacklistedPhrases = useCallback(
        ({ phrases }) =>
            commitMutation({
                mutation: CreateBlacklistedPhrasesMutation,
                variables: {
                    input: { phrases },
                },
                updater: store => {
                    const payload = store.getRootField(
                        'appManagementCreateBlacklistedPhrases'
                    )
                    if (!payload) {
                        return
                    }

                    const phrases = payload.getLinkedRecords('phrases')

                    if (!phrases) {
                        return
                    }

                    const appManagement = store.get(appManagementId)

                    if (!appManagement) {
                        return
                    }

                    const storedPhrases = appManagement.getLinkedRecords(
                        'blacklistedPhrases'
                    )

                    if (!storedPhrases) {
                        return
                    }

                    appManagement.setLinkedRecords(
                        [...phrases, ...storedPhrases],
                        'blacklistedPhrases'
                    )
                },
            }),
        [commitMutation, appManagementId]
    )

    const deleteBlacklistedPhrase = useCallback(
        ({ id }) =>
            commitMutation({
                mutation: DeleteBlacklistedPhraseMutation,
                variables: {
                    input: { id },
                },
                updater: store => {
                    const appManagement = store.get(appManagementId)

                    if (!appManagement) {
                        return
                    }

                    const storedPhrases = appManagement.getLinkedRecords(
                        'blacklistedPhrases'
                    )

                    if (!storedPhrases) {
                        return
                    }

                    appManagement.setLinkedRecords(
                        storedPhrases.filter(
                            phrase => phrase.getValue('id') !== id
                        ),
                        'blacklistedPhrases'
                    )

                    store.delete(id)
                },
            }),
        [appManagementId, commitMutation]
    )

    if (error) {
        console.error(error)

        if (__DEV__) {
            return <div>{error.message}</div>
        }

        return <ContainerError />
    }

    if (props) {
        const blacklistedPhrases = get(
            props,
            'appManagement.blacklistedPhrases',
            []
        )
        const currentUser = get(props, 'userManagement.currentUser', null)

        return (
            <ChatConfig
                currentUser={currentUser}
                blacklistedPhrases={blacklistedPhrases}
                createBlacklistedPhrases={createBlacklistedPhrases}
                deleteBlacklistedPhrase={deleteBlacklistedPhrase}
            />
        )
    }

    return <Loader />
}

export default ChatConfigContainer
