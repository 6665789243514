import { QueryRenderer } from 'react-relay'
import React, { PureComponent, Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import environment from '../../../Environment'
import Loader from '../../../components/Loader'
import ContainerError from '../../../components/ContainerError'
import UserMiniPageQuery from './UserMiniPageQuery'
import UserMiniPage from './UserMiniPage'

import Paper from '@material-ui/core/Paper'

const __DEV__ = process.env.NODE_ENV === 'development'

const Container = styled.div`
    position: relative;
    z-index: 10;
`

export default class UserMiniPageContainer extends PureComponent {
    state = {
        isOpen: false,
    }

    renderPage = ({ error, props }) => {
        if (error) {
            console.error(error)

            if (__DEV__) {
                return <div>{error.message}</div>
            }

            return <ContainerError />
        }

        return (
            <Paper
                style={{
                    position: 'absolute',
                    padding: 15,
                    zIndex: 99999999999999,
                }}
            >
                {props ? (
                    <UserMiniPage user={props.adminManagement.user} />
                ) : (
                    <Loader size={30} />
                )}
            </Paper>
        )
    }

    toggleOpen = () => {
        this.setState(state => ({
            isOpen: !state.isOpen,
        }))
    }

    componentWillUnmount() {
        this.setState({
            isOpen: false,
        })
    }

    render() {
        return (
            <Fragment>
                <Link
                    to={this.props.linkTo}
                    onMouseEnter={this.toggleOpen}
                    onMouseLeave={this.toggleOpen}
                >
                    {this.props.userId}
                </Link>
                <Container>
                    {this.state.isOpen && (
                        <QueryRenderer
                            environment={environment}
                            variables={{
                                userId: this.props.userId,
                            }}
                            query={UserMiniPageQuery}
                            render={this.renderPage}
                        />
                    )}
                </Container>
            </Fragment>
        )
    }
}

UserMiniPageContainer.defaultProps = {
    enterDelay: 2000,
    leaveDelay: 200,
}
