/**
 * @flow
 */

/* eslint-disable */

'use strict'

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Promotions_promotion$ref: FragmentReference;
declare export opaque type Promotions_promotion$fragmentType: Promotions_promotion$ref;
export type Promotions_promotion = $ReadOnlyArray<{|
  +id: string,
  +name: string,
  +slug: string,
  +title: string,
  +description: string,
  +SEOTitle: string,
  +SEODescription: string,
  +ctaLabel: ?string,
  +ctaUrl: ?string,
  +imageUrl: string,
  +hasBanner: ?boolean,
  +hasPage: ?boolean,
  +pageUrl: ?string,
  +createdAt: ?any,
  +createdByCuid: ?string,
  +updatedAt: ?any,
  +updatedByCuid: ?string,
  +order: ?number,
  +isEnabled: ?boolean,
  +isNew: ?boolean,
  +$refType: Promotions_promotion$ref,
|}>;
export type Promotions_promotion$data = Promotions_promotion;
export type Promotions_promotion$key = $ReadOnlyArray<{
  +$data?: Promotions_promotion$data,
  +$fragmentRefs: Promotions_promotion$ref,
}>;
*/

const node /*: ReaderFragment*/ = {
    kind: 'Fragment',
    name: 'Promotions_promotion',
    type: 'RewardsPromotion',
    metadata: {
        plural: true,
    },
    argumentDefinitions: [],
    selections: [
        {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'name',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'slug',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'title',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'description',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'SEOTitle',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'SEODescription',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'ctaLabel',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'ctaUrl',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'imageUrl',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'hasBanner',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'hasPage',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'pageUrl',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'createdAt',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'createdByCuid',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'updatedAt',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'updatedByCuid',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'order',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'isEnabled',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ClientExtension',
            selections: [
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'isNew',
                    args: null,
                    storageKey: null,
                },
            ],
        },
    ],
}
// prettier-ignore
;(node/*: any*/).hash = 'fe0a667fdd28a1636b124af5c109200d';
module.exports = node
