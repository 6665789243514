import graphql from 'babel-plugin-relay/macro'

export default graphql`
    query UserInventoryQuery($userId: String!) {
        adminManagement {
            user(userId: $userId) {
                ...UserInventory_data
            }
        }
    }
`
