import React, { useCallback, useContext, useState } from 'react'
import PromoIcon from '@material-ui/icons/VolumeUp'
import noop from 'lodash/noop'
import {
    Button,
    ExpansionPanel,
    ExpansionPanelSummary,
    Tooltip,
    Typography,
} from '@material-ui/core'
import {
    Add as AddIcon,
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { fetchQuery, ReactRelayContext } from 'react-relay'
import styled from 'styled-components'
import format from 'date-fns/format'
import { useFragment } from 'relay-hooks'
import graphql from 'babel-plugin-relay/macro'
import pick from 'lodash/pick'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import BaseSection from '../../shared/components/BaseSection'
import Giveaway from './Giveaway'
import map from 'lodash/map'
import { useModals } from '../../shared/modals'
import { useRelayUtils } from '../../shared/hooks/RelayUtils'
import Access from '../../shared/components/Access'

const Toolbar = ({ onCreate = noop }) => (
    <div>
        <Button variant="contained" color="primary" onClick={onCreate}>
            Add new
            <AddIcon />
        </Button>
    </div>
)

const SummaryContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`

const RefetchQuery = graphql`
    query GiveawaysRefetchQuery($searchText: String) {
        caseManagement {
            products(
                first: 30
                searchText: $searchText
                orderBy: ["price"]
                orderDirections: [DESC]
            ) @connection(key: "Giveaways_products") {
                edges {
                    node {
                        cuid
                        name
                        slug
                        price
                        imgUrl {
                            x2 {
                                default
                            }
                        }
                    }
                }
            }
        }
    }
`

const RewardsFragment = graphql`
    fragment Giveaways_rewards on Rewards {
        id
        giveaways {
            id
            isNew
            period
            startAt
            finishAt
            participants
            prizes {
                cuid
                name
                slug
                imageUrl
                price
            }
        }
    }
`

const CaseManagementFragment = graphql`
    fragment Giveaways_caseManagement on CaseManagement
        @argumentDefinitions(
            searchText: { type: "String", defaultValue: "" } # Optional argument
        ) {
        products(
            first: 30
            searchText: $searchText
            orderBy: ["price"]
            orderDirections: [DESC]
        ) @connection(key: "Giveaways_products") {
            edges {
                node {
                    cuid
                    name
                    slug
                    price
                    imgUrl {
                        x2 {
                            default
                        }
                    }
                }
            }
        }
    }
`

const CreateGiveawayMutation = graphql`
    mutation GiveawaysCreateGiveawayMutation(
        $input: RewardsCreateGiveawayInput!
    ) {
        rewardsCreateGiveaway(input: $input) {
            success
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
            giveaway {
                period
                startAt
                finishAt
                participants
                prizes {
                    cuid
                    name
                    slug
                    imageUrl
                    price
                }
            }
        }
    }
`

const UpdateGiveawayMutation = graphql`
    mutation GiveawaysUpdateGiveawayMutation(
        $input: RewardsUpdateGiveawayInput!
    ) {
        rewardsUpdateGiveaway(input: $input) {
            success
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
            giveaway {
                period
                startAt
                finishAt
                participants
                prizes {
                    cuid
                    name
                    slug
                    imageUrl
                    price
                }
            }
        }
    }
`

const DeleteGiveawayMutation = graphql`
    mutation GiveawaysDeleteGiveawayMutation(
        $input: RewardsDeleteGiveawayInput!
    ) {
        rewardsDeleteGiveaway(input: $input) {
            success
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const Giveaways = ({ reload, rewards, ...props }) => {
    const { params } = useRouteMatch()
    const history = useHistory()
    const [isSearching, setIsSearching] = useState(false)

    const { environment } = useContext(ReactRelayContext)
    const modals = useModals()
    const { commitMutation, commitLocalUpdate } = useRelayUtils()

    const { giveaways, id: rewardsId } = useFragment(RewardsFragment, rewards)

    const caseManagement = useFragment(
        CaseManagementFragment,
        props.caseManagement
    )

    const products = caseManagement?.products?.edges?.map(
        ({ node: product }) => ({
            ...product,
            imageUrl: product?.imgUrl?.x2?.default,
        })
    )

    const [expandedPanel, setExpandedPanel] = useState(
        get(params, 'giveaway', null)
    )
    const handleExpanded = useCallback(
        panel => (_, expanded) => {
            setExpandedPanel(expanded ? panel : null)
            history.replace(
                expanded ? `/rewards/giveaways/${panel}` : '/rewards/giveaways'
            )
        },
        [setExpandedPanel, history]
    )

    const search = useCallback(
        async params => {
            setIsSearching(true)
            const data = await fetchQuery(environment, RefetchQuery, params)

            return data.caseManagement?.products?.edges?.map(
                ({ node: product }) => ({
                    ...product,
                    imageUrl: product?.imgUrl?.x2?.default,
                })
            )
        },
        [environment, setIsSearching]
    )

    const handleAdd = useCallback(
        ({ period, startAt, finishAt }) => {
            commitLocalUpdate(store => {
                const id = btoa(`RewardsGiveaway:${period}`)

                const giveaway = store.create(id, 'RewardsGiveaway')

                giveaway.setValue(id, 'id')
                giveaway.setValue(period, 'period')
                giveaway.setValue(startAt, 'startAt')
                giveaway.setValue(finishAt, 'finishAt')
                giveaway.setValue(true, 'isNew')
                giveaway.setValue(3, 'participants')
                giveaway.setLinkedRecords([], 'prizes')

                const rewards = store.get(rewardsId)

                if (!rewards) {
                    return
                }

                const giveaways = rewards.getLinkedRecords('giveaways')

                if (!giveaways) {
                    return
                }

                rewards.setLinkedRecords([giveaway, ...giveaways], 'giveaways')
            })
        },
        [commitLocalUpdate, rewardsId]
    )

    const add = useCallback(() => {
        modals.openAddGiveawayModal({
            onCreate: handleAdd,
            existedPeriods: map(giveaways, 'period'),
        })
    }, [handleAdd, modals, giveaways])

    const cancelAdd = useCallback(
        ({ period }) => {
            commitLocalUpdate(store => {
                const id = btoa(`RewardsGiveaway:${period}`)
                const giveaway = store.get(id)

                if (!giveaway || !giveaway.getValue('isNew')) {
                    return
                }

                const rewards = store.get(rewardsId)

                if (!rewards) {
                    return
                }

                const giveaways = rewards.getLinkedRecords('giveaways')

                if (!giveaways) {
                    return
                }

                rewards.setLinkedRecords(
                    giveaways.filter(item => item.getDataID() !== id),
                    'giveaways'
                )
                store.delete(id)
            })
        },
        [commitLocalUpdate, rewardsId]
    )

    const save = useCallback(
        (update, entity) => {
            const updater = store => {
                const giveaway = store.get(entity.id)

                if (!giveaway) {
                    return
                }

                giveaway.setValue(false, 'isNew')
            }

            const variables = pick(entity, [
                'period',
                'startAt',
                'finishAt',
                'participants',
                'prizes',
            ])

            variables.prizes = (variables.prizes || []).map(
                ({ value, cuid }) => ({ cuid: cuid || value })
            )

            return commitMutation({
                mutation: entity.isNew
                    ? CreateGiveawayMutation
                    : UpdateGiveawayMutation,
                variables: {
                    input: entity.isNew
                        ? variables
                        : {
                              ...update,
                              ...(update.participants ||
                                  (update.prizes && {
                                      prizes: entity.prizes
                                          .map(({ value, cuid }) => ({
                                              cuid: cuid || value,
                                          }))
                                          .slice(0, entity.participants),
                                  })),
                          },
                },
                optimisticResponse: {
                    [entity.isNew
                        ? 'rewardsCreateGiveaway'
                        : 'rewardsUpdateGiveaway']: {
                        success: true,
                        errors: null,
                        giveaway: {
                            id: entity.id,
                            ...(entity.isNew ? variables : update),
                            prizes: entity.prizes,
                        },
                    },
                },
                ...(entity.isNew && {
                    updater,
                    optimisticUpdater: updater,
                }),
            })
        },
        [commitMutation]
    )

    const remove = useCallback(
        ({ id, period }) => {
            modals.openConfirmationModal({
                title: `You want to delete giveaway ${period}`,
                onConfirm: () => {
                    const updater = store => {
                        const rewards = store.get(rewardsId)

                        if (!rewards) {
                            return
                        }

                        const giveaways = rewards.getLinkedRecords('giveaways')

                        if (!giveaways) {
                            return
                        }

                        rewards.setLinkedRecords(
                            giveaways.filter(item => item.getDataID() !== id),
                            'giveaways'
                        )
                        store.delete(id)
                    }
                    return commitMutation({
                        mutation: DeleteGiveawayMutation,
                        variables: {
                            input: { period },
                        },
                        optimisticResponse: {
                            rewardsDeleteGiveaway: {
                                success: true,
                                errors: null,
                            },
                        },
                        updater,
                        optimisticUpdater: updater,
                    })
                },
            })
        },
        [modals, commitMutation, rewardsId]
    )

    return (
        <BaseSection
            title="Giveaways"
            count={giveaways.length}
            icon={<PromoIcon />}
            reload={reload}
            toolbar={<Toolbar onCreate={add} />}
        >
            {giveaways.map(giveaway => (
                <ExpansionPanel
                    key={giveaway.period}
                    expanded={expandedPanel === giveaway.period}
                    {...(giveaway.isNew && { expanded: true })}
                    onChange={handleExpanded(giveaway.period)}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <SummaryContainer>
                            <Typography variant="subtitle1">
                                {giveaway.period}
                            </Typography>
                            <Typography variant="subtitle1">
                                {format(
                                    new Date(giveaway.startAt),
                                    'dd MMM yyyy'
                                )}{' '}
                                -{' '}
                                {format(
                                    new Date(giveaway.finishAt),
                                    'dd MMM yyyy'
                                )}
                            </Typography>
                            {new Date(giveaway.startAt) > new Date() ? (
                                <Tooltip title="Delete">
                                    <Access roles="giveawayManager">
                                        {({ hasAccess }) => (
                                            <IconButton
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    remove({
                                                        id: giveaway.id,
                                                        period: giveaway.period,
                                                    })
                                                }}
                                                disabled={
                                                    !hasAccess || giveaway.isNew
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </Access>
                                </Tooltip>
                            ) : new Date(giveaway.finishAt) <= new Date() ? (
                                'Finished'
                            ) : (
                                'Current'
                            )}
                        </SummaryContainer>
                    </ExpansionPanelSummary>
                    <Giveaway
                        giveaway={giveaway}
                        products={products}
                        search={search}
                        isSearching={isSearching}
                        cancelAdd={cancelAdd}
                        save={save}
                    />
                </ExpansionPanel>
            ))}
        </BaseSection>
    )
}

Giveaways.propTypes = {
    reload: PropTypes.func.isRequired,
    rewards: PropTypes.shape({
        id: PropTypes.string,
        giveaways: PropTypes.array,
    }),
    products: PropTypes.shape({
        products: PropTypes.array,
    }),
    count: PropTypes.number,
}

Giveaways.defaultProps = {
    count: 0,
    rewards: {
        id: '',
        giveaways: [],
    },
    caseManagement: {
        products: [],
    },
}

export default Giveaways
