/**
 * @flow
 * @relayHash 58a22af7e4dfe4b735d8cb5c1b9f3bc2
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserPage_user$ref = any;
export type AdminManagementAccountType = "gaming" | "sponsored" | "test" | "%future added value";
export type AdminManagementUpdateUserInput = {|
  cuid: string,
  notes?: ?string,
  accountType?: ?AdminManagementAccountType,
  isBlogger?: ?boolean,
  isBlocked?: ?boolean,
  isKycRequired?: ?boolean,
  chancesCounter?: ?number,
  balance?: ?number,
  balanceNotes?: ?string,
  manualBalanceChangeReasonCuid?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  dateOfBirth?: ?any,
  winItemMaxPrice?: ?number,
  winEpicItemMinPrice?: ?number,
  phone?: ?string,
  countryCode?: ?string,
  aml?: ?AdminManagementinputAML,
  clientMutationId?: ?string,
|};
export type AdminManagementinputAML = {|
  amlRequired?: ?boolean,
  amlStatus?: ?string,
|};
export type UpdateUserMutationVariables = {|
  input: AdminManagementUpdateUserInput,
  userId: string,
|};
export type UpdateUserMutationResponse = {|
  +adminManagementUpdateUser: ?{|
    +success: ?boolean,
    +adminManagement: ?{|
      +user: ?{|
        +$fragmentRefs: UserPage_user$ref
      |}
    |},
  |}
|};
export type UpdateUserMutation = {|
  variables: UpdateUserMutationVariables,
  response: UpdateUserMutationResponse,
|};
*/

/*
mutation UpdateUserMutation(
  $input: AdminManagementUpdateUserInput!
  $userId: String!
) {
  adminManagementUpdateUser(input: $input) {
    success
    adminManagement {
      user(userId: $userId) {
        ...UserPage_user
        id
      }
      id
    }
  }
}

fragment UserPage_user on AdminManagementUser {
  id
  cuid
  balance
  chancesCounter
  name
  email
  facebookId
  role
  isBlogger
  isBlocked
  blockedExpireAt
  blockedReason
  accountType
  notes
  createdAt
  socialEmail
  deliveryInfo {
    ...UserDeliveryInfo_deliveryInfo
  }
  logs {
    ...UserLog_logs
  }
  ...UserTransactions_data
  phone
  dateOfBirth
  firstName
  lastName
  kycStatus
  isKycRequired
  isVerified
  winItemMaxPrice
  winEpicItemMinPrice
  premium {
    type
    expirationDate
  }
  countryCode
  aml {
    amlRequired
    amlStatus
  }
  isDeleted
  deletedAt
  deletedBy
  kycVerificationId
  kycDeclineReasonCode
  kycDeclineReason
  kycDocument
  kycPerson
}

fragment UserDeliveryInfo_deliveryInfo on AdminManagementDeliveryInfoConnection {
  edges {
    node {
      id
      cuid
      default
      apartment
      building
      city
      country
      region
      phone
      street
      zipCode
      firstName
      lastName
      CPF
      kycStatus
      isKycRequired
    }
  }
}

fragment UserLog_logs on AdminManagementLogConnection {
  count
  edges {
    node {
      id
      column
      value
      modifiedByUserCuid
      createdAt
      notes
    }
  }
}

fragment UserTransactions_data on AdminManagementUser {
  rows: transactions(orderBy: "createdAt", orderDirection: DESC, first: 100, skip: 0, types: []) {
    count
    sum
    depositsSum
    winProductsPricesSum
    edges {
      node {
        id
        cuid
        createdAt
        actionType
        details
        amount
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'input',
                type: 'AdminManagementUpdateUserInput!',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'userId',
                type: 'String!',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'Variable',
                name: 'input',
                variableName: 'input',
            },
        ],
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'success',
            args: null,
            storageKey: null,
        },
        v3 = [
            {
                kind: 'Variable',
                name: 'userId',
                variableName: 'userId',
            },
        ],
        v4 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        },
        v5 = {
            kind: 'ScalarField',
            alias: null,
            name: 'cuid',
            args: null,
            storageKey: null,
        },
        v6 = {
            kind: 'ScalarField',
            alias: null,
            name: 'notes',
            args: null,
            storageKey: null,
        },
        v7 = {
            kind: 'ScalarField',
            alias: null,
            name: 'createdAt',
            args: null,
            storageKey: null,
        },
        v8 = {
            kind: 'ScalarField',
            alias: null,
            name: 'phone',
            args: null,
            storageKey: null,
        },
        v9 = {
            kind: 'ScalarField',
            alias: null,
            name: 'firstName',
            args: null,
            storageKey: null,
        },
        v10 = {
            kind: 'ScalarField',
            alias: null,
            name: 'lastName',
            args: null,
            storageKey: null,
        },
        v11 = {
            kind: 'ScalarField',
            alias: null,
            name: 'kycStatus',
            args: null,
            storageKey: null,
        },
        v12 = {
            kind: 'ScalarField',
            alias: null,
            name: 'isKycRequired',
            args: null,
            storageKey: null,
        },
        v13 = {
            kind: 'ScalarField',
            alias: null,
            name: 'count',
            args: null,
            storageKey: null,
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'UpdateUserMutation',
            type: 'Mutation',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagementUpdateUser',
                    storageKey: null,
                    args: (v1 /*: any*/),
                    concreteType: 'AdminManagementUpdateUserPayload',
                    plural: false,
                    selections: [
                        (v2 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'adminManagement',
                            storageKey: null,
                            args: null,
                            concreteType: 'AdminManagement',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'user',
                                    storageKey: null,
                                    args: (v3 /*: any*/),
                                    concreteType: 'AdminManagementUser',
                                    plural: false,
                                    selections: [
                                        {
                                            kind: 'FragmentSpread',
                                            name: 'UserPage_user',
                                            args: null,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'UpdateUserMutation',
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagementUpdateUser',
                    storageKey: null,
                    args: (v1 /*: any*/),
                    concreteType: 'AdminManagementUpdateUserPayload',
                    plural: false,
                    selections: [
                        (v2 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'adminManagement',
                            storageKey: null,
                            args: null,
                            concreteType: 'AdminManagement',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'user',
                                    storageKey: null,
                                    args: (v3 /*: any*/),
                                    concreteType: 'AdminManagementUser',
                                    plural: false,
                                    selections: [
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'balance',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'chancesCounter',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'name',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'email',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'facebookId',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'role',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'isBlogger',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'isBlocked',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'blockedExpireAt',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'blockedReason',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'accountType',
                                            args: null,
                                            storageKey: null,
                                        },
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'socialEmail',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'deliveryInfo',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'AdminManagementDeliveryInfoConnection',
                                            plural: false,
                                            selections: [
                                                {
                                                    kind: 'LinkedField',
                                                    alias: null,
                                                    name: 'edges',
                                                    storageKey: null,
                                                    args: null,
                                                    concreteType:
                                                        'AdminManagementDeliveryInfoEdge',
                                                    plural: true,
                                                    selections: [
                                                        {
                                                            kind: 'LinkedField',
                                                            alias: null,
                                                            name: 'node',
                                                            storageKey: null,
                                                            args: null,
                                                            concreteType:
                                                                'AdminManagementDeliveryInfo',
                                                            plural: false,
                                                            selections: [
                                                                (v4 /*: any*/),
                                                                (v5 /*: any*/),
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'default',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'apartment',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'building',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'city',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'country',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'region',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                (v8 /*: any*/),
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'street',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'zipCode',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                (v9 /*: any*/),
                                                                (v10 /*: any*/),
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name: 'CPF',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                (v11 /*: any*/),
                                                                (v12 /*: any*/),
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'logs',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'AdminManagementLogConnection',
                                            plural: false,
                                            selections: [
                                                (v13 /*: any*/),
                                                {
                                                    kind: 'LinkedField',
                                                    alias: null,
                                                    name: 'edges',
                                                    storageKey: null,
                                                    args: null,
                                                    concreteType:
                                                        'AdminManagementLogEdge',
                                                    plural: true,
                                                    selections: [
                                                        {
                                                            kind: 'LinkedField',
                                                            alias: null,
                                                            name: 'node',
                                                            storageKey: null,
                                                            args: null,
                                                            concreteType:
                                                                'AdminManagementLog',
                                                            plural: false,
                                                            selections: [
                                                                (v4 /*: any*/),
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'column',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'value',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'modifiedByUserCuid',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                (v7 /*: any*/),
                                                                (v6 /*: any*/),
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'LinkedField',
                                            alias: 'rows',
                                            name: 'transactions',
                                            storageKey:
                                                'transactions(first:100,orderBy:"createdAt",orderDirection:"DESC",skip:0,types:[])',
                                            args: [
                                                {
                                                    kind: 'Literal',
                                                    name: 'first',
                                                    value: 100,
                                                },
                                                {
                                                    kind: 'Literal',
                                                    name: 'orderBy',
                                                    value: 'createdAt',
                                                },
                                                {
                                                    kind: 'Literal',
                                                    name: 'orderDirection',
                                                    value: 'DESC',
                                                },
                                                {
                                                    kind: 'Literal',
                                                    name: 'skip',
                                                    value: 0,
                                                },
                                                {
                                                    kind: 'Literal',
                                                    name: 'types',
                                                    value: [],
                                                },
                                            ],
                                            concreteType:
                                                'AdminManagementTransactionConnection',
                                            plural: false,
                                            selections: [
                                                (v13 /*: any*/),
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'sum',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'depositsSum',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name:
                                                        'winProductsPricesSum',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'LinkedField',
                                                    alias: null,
                                                    name: 'edges',
                                                    storageKey: null,
                                                    args: null,
                                                    concreteType:
                                                        'AdminManagementTransactionEdge',
                                                    plural: true,
                                                    selections: [
                                                        {
                                                            kind: 'LinkedField',
                                                            alias: null,
                                                            name: 'node',
                                                            storageKey: null,
                                                            args: null,
                                                            concreteType:
                                                                'AdminManagementTransaction',
                                                            plural: false,
                                                            selections: [
                                                                (v4 /*: any*/),
                                                                (v5 /*: any*/),
                                                                (v7 /*: any*/),
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'actionType',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'details',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'amount',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        (v8 /*: any*/),
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'dateOfBirth',
                                            args: null,
                                            storageKey: null,
                                        },
                                        (v9 /*: any*/),
                                        (v10 /*: any*/),
                                        (v11 /*: any*/),
                                        (v12 /*: any*/),
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'isVerified',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'winItemMaxPrice',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'winEpicItemMinPrice',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'premium',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'AdminManagementpremium',
                                            plural: false,
                                            selections: [
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'type',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'expirationDate',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'countryCode',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'aml',
                                            storageKey: null,
                                            args: null,
                                            concreteType: 'AdminManagementAML',
                                            plural: false,
                                            selections: [
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'amlRequired',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'amlStatus',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'isDeleted',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'deletedAt',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'deletedBy',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'kycVerificationId',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'kycDeclineReasonCode',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'kycDeclineReason',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'kycDocument',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'kycPerson',
                                            args: null,
                                            storageKey: null,
                                        },
                                    ],
                                },
                                (v4 /*: any*/),
                            ],
                        },
                    ],
                },
            ],
        },
        params: {
            operationKind: 'mutation',
            name: 'UpdateUserMutation',
            id: null,
            text:
                'mutation UpdateUserMutation(\n  $input: AdminManagementUpdateUserInput!\n  $userId: String!\n) {\n  adminManagementUpdateUser(input: $input) {\n    success\n    adminManagement {\n      user(userId: $userId) {\n        ...UserPage_user\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment UserPage_user on AdminManagementUser {\n  id\n  cuid\n  balance\n  chancesCounter\n  name\n  email\n  facebookId\n  role\n  isBlogger\n  isBlocked\n  blockedExpireAt\n  blockedReason\n  accountType\n  notes\n  createdAt\n  socialEmail\n  deliveryInfo {\n    ...UserDeliveryInfo_deliveryInfo\n  }\n  logs {\n    ...UserLog_logs\n  }\n  ...UserTransactions_data\n  phone\n  dateOfBirth\n  firstName\n  lastName\n  kycStatus\n  isKycRequired\n  isVerified\n  winItemMaxPrice\n  winEpicItemMinPrice\n  premium {\n    type\n    expirationDate\n  }\n  countryCode\n  aml {\n    amlRequired\n    amlStatus\n  }\n  isDeleted\n  deletedAt\n  deletedBy\n  kycVerificationId\n  kycDeclineReasonCode\n  kycDeclineReason\n  kycDocument\n  kycPerson\n}\n\nfragment UserDeliveryInfo_deliveryInfo on AdminManagementDeliveryInfoConnection {\n  edges {\n    node {\n      id\n      cuid\n      default\n      apartment\n      building\n      city\n      country\n      region\n      phone\n      street\n      zipCode\n      firstName\n      lastName\n      CPF\n      kycStatus\n      isKycRequired\n    }\n  }\n}\n\nfragment UserLog_logs on AdminManagementLogConnection {\n  count\n  edges {\n    node {\n      id\n      column\n      value\n      modifiedByUserCuid\n      createdAt\n      notes\n    }\n  }\n}\n\nfragment UserTransactions_data on AdminManagementUser {\n  rows: transactions(orderBy: "createdAt", orderDirection: DESC, first: 100, skip: 0, types: []) {\n    count\n    sum\n    depositsSum\n    winProductsPricesSum\n    edges {\n      node {\n        id\n        cuid\n        createdAt\n        actionType\n        details\n        amount\n      }\n    }\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = 'd507e51cfcbfde3d0ad120167e33eb03';
module.exports = node
