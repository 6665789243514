/**
 * @flow
 * @relayHash a04bec98db333f7430ff40633cc992a4
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GiveawaysRefetchQueryVariables = {|
  searchText?: ?string
|};
export type GiveawaysRefetchQueryResponse = {|
  +caseManagement: ?{|
    +products: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +cuid: ?string,
          +name: ?string,
          +slug: ?string,
          +price: ?number,
          +imgUrl: ?{|
            +x2: ?{|
              +default: ?string
            |}
          |},
        |}
      |}>
    |}
  |}
|};
export type GiveawaysRefetchQuery = {|
  variables: GiveawaysRefetchQueryVariables,
  response: GiveawaysRefetchQueryResponse,
|};
*/

/*
query GiveawaysRefetchQuery(
  $searchText: String
) {
  caseManagement {
    products(first: 30, searchText: $searchText, orderBy: ["price"], orderDirections: [DESC]) {
      edges {
        node {
          cuid
          name
          slug
          price
          imgUrl {
            x2 {
              default
              id
            }
            id
          }
          id
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'searchText',
                type: 'String',
                defaultValue: null,
            },
        ],
        v1 = {
            kind: 'Literal',
            name: 'orderBy',
            value: ['price'],
        },
        v2 = {
            kind: 'Literal',
            name: 'orderDirections',
            value: ['DESC'],
        },
        v3 = {
            kind: 'Variable',
            name: 'searchText',
            variableName: 'searchText',
        },
        v4 = {
            kind: 'ScalarField',
            alias: null,
            name: 'cuid',
            args: null,
            storageKey: null,
        },
        v5 = {
            kind: 'ScalarField',
            alias: null,
            name: 'name',
            args: null,
            storageKey: null,
        },
        v6 = {
            kind: 'ScalarField',
            alias: null,
            name: 'slug',
            args: null,
            storageKey: null,
        },
        v7 = {
            kind: 'ScalarField',
            alias: null,
            name: 'price',
            args: null,
            storageKey: null,
        },
        v8 = {
            kind: 'ScalarField',
            alias: null,
            name: 'default',
            args: null,
            storageKey: null,
        },
        v9 = {
            kind: 'ScalarField',
            alias: null,
            name: '__typename',
            args: null,
            storageKey: null,
        },
        v10 = {
            kind: 'ScalarField',
            alias: null,
            name: 'cursor',
            args: null,
            storageKey: null,
        },
        v11 = {
            kind: 'LinkedField',
            alias: null,
            name: 'pageInfo',
            storageKey: null,
            args: null,
            concreteType: 'PageInfo',
            plural: false,
            selections: [
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'endCursor',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'hasNextPage',
                    args: null,
                    storageKey: null,
                },
            ],
        },
        v12 = [
            {
                kind: 'Literal',
                name: 'first',
                value: 30,
            },
            (v1 /*: any*/),
            (v2 /*: any*/),
            (v3 /*: any*/),
        ],
        v13 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'GiveawaysRefetchQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'caseManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'CaseManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: 'products',
                            name: '__Giveaways_products_connection',
                            storageKey: null,
                            args: [
                                (v1 /*: any*/),
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                            ],
                            concreteType: 'CaseManagementProductConnection',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'edges',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'CaseManagementProductEdge',
                                    plural: true,
                                    selections: [
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'node',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'CaseManagementProduct',
                                            plural: false,
                                            selections: [
                                                (v4 /*: any*/),
                                                (v5 /*: any*/),
                                                (v6 /*: any*/),
                                                (v7 /*: any*/),
                                                {
                                                    kind: 'LinkedField',
                                                    alias: null,
                                                    name: 'imgUrl',
                                                    storageKey: null,
                                                    args: null,
                                                    concreteType:
                                                        'CaseManagementImageURL',
                                                    plural: false,
                                                    selections: [
                                                        {
                                                            kind: 'LinkedField',
                                                            alias: null,
                                                            name: 'x2',
                                                            storageKey: null,
                                                            args: null,
                                                            concreteType:
                                                                'CaseManagementImage',
                                                            plural: false,
                                                            selections: [
                                                                (v8 /*: any*/),
                                                            ],
                                                        },
                                                    ],
                                                },
                                                (v9 /*: any*/),
                                            ],
                                        },
                                        (v10 /*: any*/),
                                    ],
                                },
                                (v11 /*: any*/),
                            ],
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'GiveawaysRefetchQuery',
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'caseManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'CaseManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'products',
                            storageKey: null,
                            args: (v12 /*: any*/),
                            concreteType: 'CaseManagementProductConnection',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'edges',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'CaseManagementProductEdge',
                                    plural: true,
                                    selections: [
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'node',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'CaseManagementProduct',
                                            plural: false,
                                            selections: [
                                                (v4 /*: any*/),
                                                (v5 /*: any*/),
                                                (v6 /*: any*/),
                                                (v7 /*: any*/),
                                                {
                                                    kind: 'LinkedField',
                                                    alias: null,
                                                    name: 'imgUrl',
                                                    storageKey: null,
                                                    args: null,
                                                    concreteType:
                                                        'CaseManagementImageURL',
                                                    plural: false,
                                                    selections: [
                                                        {
                                                            kind: 'LinkedField',
                                                            alias: null,
                                                            name: 'x2',
                                                            storageKey: null,
                                                            args: null,
                                                            concreteType:
                                                                'CaseManagementImage',
                                                            plural: false,
                                                            selections: [
                                                                (v8 /*: any*/),
                                                                (v13 /*: any*/),
                                                            ],
                                                        },
                                                        (v13 /*: any*/),
                                                    ],
                                                },
                                                (v13 /*: any*/),
                                                (v9 /*: any*/),
                                            ],
                                        },
                                        (v10 /*: any*/),
                                    ],
                                },
                                (v11 /*: any*/),
                            ],
                        },
                        {
                            kind: 'LinkedHandle',
                            alias: null,
                            name: 'products',
                            args: (v12 /*: any*/),
                            handle: 'connection',
                            key: 'Giveaways_products',
                            filters: [
                                'searchText',
                                'orderBy',
                                'orderDirections',
                            ],
                        },
                        (v13 /*: any*/),
                    ],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'GiveawaysRefetchQuery',
            id: null,
            text:
                'query GiveawaysRefetchQuery(\n  $searchText: String\n) {\n  caseManagement {\n    products(first: 30, searchText: $searchText, orderBy: ["price"], orderDirections: [DESC]) {\n      edges {\n        node {\n          cuid\n          name\n          slug\n          price\n          imgUrl {\n            x2 {\n              default\n              id\n            }\n            id\n          }\n          id\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n',
            metadata: {
                connection: [
                    {
                        count: null,
                        cursor: null,
                        direction: 'forward',
                        path: ['caseManagement', 'products'],
                    },
                ],
            },
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '2cab403a6a905bd22541d057e4764299';
module.exports = node
