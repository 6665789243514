/**
 * @flow
 * @relayHash bd8f25dff3bf377b09335635d76d2ba2
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdminManagementAddContactsSupportInput = {|
  data: $ReadOnlyArray<?AdminManagementAddContactSupportData>,
  clientMutationId?: ?string,
|};
export type AdminManagementAddContactSupportData = {|
  userCuid?: ?string,
  date?: ?any,
|};
export type UsersContainerMutationVariables = {|
  input: AdminManagementAddContactsSupportInput
|};
export type UsersContainerMutationResponse = {|
  +adminManagementAddContactsSupport: ?{|
    +success: ?boolean,
    +count: number,
    +whoMadeDepositsCount: number,
    +whoMadeDeposits: $ReadOnlyArray<?string>,
    +depositsSum: number,
    +errors: ?$ReadOnlyArray<?{|
      +code: ?number,
      +errorCode: ?string,
      +statusCode: ?number,
      +message: ?string,
      +meta: ?any,
    |}>,
  |}
|};
export type UsersContainerMutation = {|
  variables: UsersContainerMutationVariables,
  response: UsersContainerMutationResponse,
|};
*/

/*
mutation UsersContainerMutation(
  $input: AdminManagementAddContactsSupportInput!
) {
  adminManagementAddContactsSupport(input: $input) {
    success
    count
    whoMadeDepositsCount
    whoMadeDeposits
    depositsSum
    errors {
      code
      errorCode
      statusCode
      message
      meta
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'input',
                type: 'AdminManagementAddContactsSupportInput!',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'LinkedField',
                alias: null,
                name: 'adminManagementAddContactsSupport',
                storageKey: null,
                args: [
                    {
                        kind: 'Variable',
                        name: 'input',
                        variableName: 'input',
                    },
                ],
                concreteType: 'AdminManagementAddContactsSupportPayload',
                plural: false,
                selections: [
                    {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'success',
                        args: null,
                        storageKey: null,
                    },
                    {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'count',
                        args: null,
                        storageKey: null,
                    },
                    {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'whoMadeDepositsCount',
                        args: null,
                        storageKey: null,
                    },
                    {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'whoMadeDeposits',
                        args: null,
                        storageKey: null,
                    },
                    {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'depositsSum',
                        args: null,
                        storageKey: null,
                    },
                    {
                        kind: 'LinkedField',
                        alias: null,
                        name: 'errors',
                        storageKey: null,
                        args: null,
                        concreteType: 'AdminManagementError',
                        plural: true,
                        selections: [
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'code',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'errorCode',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'statusCode',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'message',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'meta',
                                args: null,
                                storageKey: null,
                            },
                        ],
                    },
                ],
            },
        ]
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'UsersContainerMutation',
            type: 'Mutation',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: (v1 /*: any*/),
        },
        operation: {
            kind: 'Operation',
            name: 'UsersContainerMutation',
            argumentDefinitions: (v0 /*: any*/),
            selections: (v1 /*: any*/),
        },
        params: {
            operationKind: 'mutation',
            name: 'UsersContainerMutation',
            id: null,
            text:
                'mutation UsersContainerMutation(\n  $input: AdminManagementAddContactsSupportInput!\n) {\n  adminManagementAddContactsSupport(input: $input) {\n    success\n    count\n    whoMadeDepositsCount\n    whoMadeDeposits\n    depositsSum\n    errors {\n      code\n      errorCode\n      statusCode\n      message\n      meta\n    }\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '5642b4cc636607b8755c85591397556c';
module.exports = node
