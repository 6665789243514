import React, { Fragment, useState, useCallback } from 'react'
import {
    DialogActions,
    DialogTitle,
    Button,
    CircularProgress,
    DialogContent,
} from '@material-ui/core'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { InlineDatePicker } from 'material-ui-pickers'
import format from 'date-fns/format'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'

const LoaderContainer = styled.div`
    margin-right: 5px;
    align-items: center;
    display: flex;
`

const AddGiveaway = ({ onClose, onCreate, isLoading, existedPeriods }) => {
    const [startAt, setStartAt] = useState(null)
    const [finishAt, setFinishAt] = useState(null)
    const [period, setPeriod] = useState(null)
    const [error, setError] = useState(false)

    const handleChange = useCallback(date => {
        setPeriod(format(date, 'MM-yyyy'))
        setStartAt(format(startOfMonth(date), 'yyyy-MM-dd'))
        setFinishAt(format(endOfMonth(date), 'yyyy-MM-dd'))
    }, [])

    const handleCreate = useCallback(() => {
        if (!period) {
            setError('Period is required!')
            return
        }

        if (existedPeriods.includes(period)) {
            setError('Giveaway on current period is already exists')
            return
        }
        onCreate({ period, startAt, finishAt })
    }, [period, existedPeriods, onCreate, startAt, finishAt])

    return (
        <Fragment>
            <DialogTitle>Add new giveaway</DialogTitle>
            <DialogContent>
                <InlineDatePicker
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    openTo="year"
                    views={['year', 'month']}
                    label="Period"
                    error={Boolean(error)}
                    helperText={error || 'Select period...'}
                    value={startAt}
                    onChange={handleChange}
                    format="MMM yyyy"
                    autoFocus
                    minDate={startOfMonth(new Date())}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="contained">
                    Cancel
                </Button>
                <Button
                    onClick={handleCreate}
                    color="primary"
                    disabled={isLoading}
                >
                    {isLoading && (
                        <LoaderContainer>
                            <CircularProgress size={20} />
                        </LoaderContainer>
                    )}
                    Add
                </Button>
            </DialogActions>
        </Fragment>
    )
}

AddGiveaway.propTypes = {
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    isLoading: PropTypes.bool,
    existedPeriods: PropTypes.arrayOf(PropTypes.string),
}

AddGiveaway.defaultProps = {
    onClose: noop,
    onCreate: noop,
    isLoading: false,
    existedPeriods: [],
}

export default AddGiveaway
