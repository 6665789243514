import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import RedirectIcon from '@material-ui/icons/Loop'
import {
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Paper,
    withStyles,
} from '@material-ui/core'

import BaseSection from '../../../shared/components/BaseSection'
import { columnTypes, getColumnValue } from '../../../shared/columnTypes'

const columns = [
    {
        name: 'id',
        label: 'Id',
        getter: ({ id }) => get(atob(id).split(':'), '1', null),
        type: columnTypes.COPIED_TEXT,
    },
    {
        name: 'from',
        label: 'From',
        getter: 'from',
        type: columnTypes.TEXT,
    },
    {
        name: 'to',
        label: 'To',
        getter: 'to',
        type: columnTypes.TEXT,
    },
    {
        name: 'entityName',
        label: 'Entity name',
        getter: 'entityName',
        type: columnTypes.TEXT,
    },
    {
        name: 'entityCuid',
        label: 'Entity cuid',
        getter: 'entityCuid',
        type: columnTypes.COPIED_TEXT,
    },
    {
        name: 'createdAt',
        label: 'Created At',
        getter: 'createdAt',
        type: columnTypes.DATE_TIME,
    },
    {
        name: 'updatedAt',
        label: 'Updated At',
        getter: 'updatedAt',
        type: columnTypes.DATE_TIME,
    },
]

const _Redirects = ({ redirects, reload, classes }) => {
    return (
        <BaseSection
            title="Redirects"
            count={redirects.length}
            icon={<RedirectIcon />}
            reload={reload}
        >
            <Paper className={classes.containerWrapper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map(({ name, label }) => (
                                <TableCell key={`redirect-header-${name}`}>
                                    {label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {redirects.map(redirect => (
                            <TableRow key={`redirect-${redirect.id}`}>
                                {columns.map(
                                    ({ type, name, label, getter }) => (
                                        <TableCell
                                            key={`redirect-${redirect.id}-${name}`}
                                        >
                                            {getColumnValue(redirect, {
                                                getter,
                                                type,
                                                name,
                                            })}
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </BaseSection>
    )
}

const styles = {
    containerWrapper: {
        overflow: 'scroll',
    },
}

export const Redirects = withStyles(styles)(_Redirects)

_Redirects.propTypes = {
    redirects: PropTypes.array.isRequired,
    reload: PropTypes.func,
}
