import React from 'react'
import { Checkbox } from '@material-ui/core'
import isFunction from 'lodash/isFunction'
import get from 'lodash/get'
import format from 'date-fns/format'
import styled from 'styled-components'

import { CopyToClipboard } from './components/CopyToClipboard'

const DateTimeContainer = styled.div`
    white-space: nowrap;
`

export const columnTypes = {
    COUNTRY_LIST: 'COUNTRY_LIST',
    BOOLEAN: 'BOOLEAN',
    TEXT: 'TEXT',
    DATE_TIME: 'DATE_TIME',
    COPIED_TEXT: 'COPIED_TEXT',
}

const columnValues = {
    [columnTypes.DATE_TIME]: value =>
        value ? (
            <DateTimeContainer>
                {format(new Date(value), 'dd MMM yyyy')}
                <br />
                {format(new Date(value), 'kk:mm:ss OOOO')}
            </DateTimeContainer>
        ) : null,
    [columnTypes.BOOLEAN]: (value, { isReadOnly }) => (
        <Checkbox checked={!!value} disabled={isReadOnly} />
    ),
    [columnTypes.TEXT]: value => value,
    [columnTypes.COPIED_TEXT]: value => (
        <CopyToClipboard text={value}>{value}</CopyToClipboard>
    ),
}

export const getColumnValue = (value, { getter, type, ...props }) => {
    const formattedValue = isFunction(getter)
        ? getter(value)
        : get(value, getter, null)

    return columnValues[type]
        ? columnValues[type](formattedValue, { ...props })
        : null
}
