import React, { createContext, useCallback, useState, useContext } from 'react'

import { Dialog, withMobileDialog } from '@material-ui/core'
import noop from 'lodash/noop'
import IssuePremiumModal from './IssuePremium'
import ErrorModal from './Error'
import AnalyzeSupportModal from './AnalyzeSupport'
import BlockUser from './BlockUser'
import ConfirmationModal from './Confirmation'
import CreateBlacklistedPhrasesModal from './CreateBlacklistedPhrases'
import AddPaymentStatsModal from './AddPaymentStats'
import AddPromotionModal from './AddPromotion'
import AddGiveawayModal from './AddGiveaway'
import CreateThemeModal from './CreateTheme'

const modalTypes = {
    Error: 'Error',
    IssuePremium: 'IssuePremium',
    BlockUser: 'BlockUser',
    AnalyzeSupport: 'AnalyzeSupport',
    Confirmation: 'Confirmation',
    AddPaymentStats: 'AddPaymentStats',
    CreateBlacklistedPhrases: 'CreateBlacklistedPhrases',
    AddPromotion: 'AddPromotion',
    AddGiveaway: 'AddGiveaway',
    CreateTheme: 'CreateTheme',
}

const modalMap = {
    [modalTypes.Error]: ErrorModal,
    [modalTypes.IssuePremium]: IssuePremiumModal,
    [modalTypes.BlockUser]: BlockUser,
    [modalTypes.AnalyzeSupport]: AnalyzeSupportModal,
    [modalTypes.Confirmation]: ConfirmationModal,
    [modalTypes.CreateBlacklistedPhrases]: CreateBlacklistedPhrasesModal,
    [modalTypes.AddPaymentStats]: AddPaymentStatsModal,
    [modalTypes.AddPromotion]: AddPromotionModal,
    [modalTypes.AddGiveaway]: AddGiveawayModal,
    [modalTypes.CreateTheme]: CreateThemeModal,
}

const ModalContext = createContext({
    openModal: noop,
    closeModal: noop,
    modalTypes,
    openIssuePremiumModal: noop,
    openBlockUserModal: noop,
    openErrorModal: noop,
    openAnalyzeSupportModal: noop,
    openConfirmationModal: noop,
    openCreateBlacklistedPhrasesModal: noop,
    openAddPaymentStatsModal: noop,
    openAddGiveawayModal: noop,
})

const { Provider, Consumer } = ModalContext

const ModalProvider = withMobileDialog()(({ children, fullScreen }) => {
    const [{ component: Component, props }, setState] = useState({
        component: null,
        props: null,
    })
    const [isLoading, setIsLoading] = useState(false)

    const closeModal = useCallback(() => {
        setState({
            component: null,
            props: null,
        })
    }, [])

    const openModal = useCallback((modalType, props) => {
        if (!modalMap[modalType]) {
            return null
        }

        setState({ component: modalMap[modalType], props })
    }, [])

    const closable = useCallback(
        fn => async (...args) => {
            if (fn) {
                setIsLoading(true)
                let result

                try {
                    result = await fn(...args)
                } finally {
                    setIsLoading(false)
                }

                closeModal()
                return result
            }
            closeModal()
        },
        [closeModal, setIsLoading]
    )

    const loadable = useCallback(
        fn => async (...args) => {
            if (!fn) {
                return
            }
            setIsLoading(true)
            let result

            try {
                result = await fn(...args)
            } finally {
                setIsLoading(false)
            }

            return result
        },
        [setIsLoading]
    )

    const openIssuePremiumModal = useCallback(
        /**
         *
         * @param onConfirm
         * @param onClose
         * @param premiumTypes
         */
        ({ onConfirm, onClose, premiumTypes }) => {
            openModal(modalTypes.IssuePremium, {
                onConfirm: closable(onConfirm),
                onClose: closable(onClose),
                premiumTypes,
            })
        },
        [closable, openModal]
    )

    const openBlockUserModal = useCallback(
        /**
         *
         * @param onConfirm
         * @param onClose
         */
        ({ onConfirm, onClose }) => {
            openModal(modalTypes.BlockUser, {
                onConfirm: closable(onConfirm),
                onClose: closable(onClose),
            })
        },
        [closable, openModal]
    )

    const openErrorModal = useCallback(
        /**
         *
         * @param error
         * @param isContactAdminShown
         */
        ({ error, isContactAdminShown }) => {
            openModal(modalTypes.Error, {
                onConfirm: closable,
                onClose: closeModal,
                isContactAdminShown,
                error,
            })
        },
        [closable, closeModal, openModal]
    )

    const openAnalyzeSupportModal = useCallback(
        /**
         *
         * @param onAnalyze
         */
        ({ onAnalyze }) => {
            openModal(modalTypes.AnalyzeSupport, {
                onAnalyze: loadable(onAnalyze),
                onClose: closeModal,
            })
        },
        [loadable, closeModal, openModal]
    )

    const openConfirmationModal = useCallback(
        /**
         *
         * @param onConfirm
         * @param title
         */
        ({ onConfirm, title }) => {
            openModal(modalTypes.Confirmation, {
                title,
                onConfirm: closable(onConfirm),
                onClose: closeModal,
            })
        },
        [closable, closeModal, openModal]
    )

    const openCreateBlacklistedPhrasesModal = useCallback(
        /**
         *
         * @param onCreate
         */
        ({ onCreate }) => {
            openModal(modalTypes.CreateBlacklistedPhrases, {
                onCreate: closable(onCreate),
                onClose: closeModal,
            })
        },
        [closable, closeModal, openModal]
    )

    const openAddPaymentStatsModal = useCallback(
        /**
         *
         * @param onConfirm
         */
        ({ onConfirm }) => {
            openModal(modalTypes.AddPaymentStats, {
                onConfirm: closable(loadable(onConfirm)),
                onClose: closeModal,
            })
        },
        [closable, closeModal, openModal, loadable]
    )

    const openAddPromotionModal = useCallback(
        /**
         *
         * @param onCreate
         * @param existedNames
         */
        ({ onCreate, existedNames }) => {
            openModal(modalTypes.AddPromotion, {
                onCreate: closable(loadable(onCreate)),
                onClose: closeModal,
                existedNames,
            })
        },
        [closable, closeModal, openModal, loadable]
    )

    const openAddGiveawayModal = useCallback(
        /**
         *
         * @param onCreate
         * @param existedPeriods
         */
        ({ onCreate, existedPeriods }) => {
            openModal(modalTypes.AddGiveaway, {
                onCreate: closable(loadable(onCreate)),
                onClose: closeModal,
                existedPeriods,
            })
        },
        [closable, closeModal, openModal, loadable]
    )

    const openCreateThemeModal = useCallback(
        /**
         *
         * @param onCreate
         */
        ({ onCreate }) => {
            openModal(modalTypes.CreateTheme, {
                onCreate: closable(onCreate),
                onClose: closeModal,
            })
        },
        [closable, closeModal, openModal]
    )

    return (
        <Provider
            value={{
                closeModal,
                openModal,
                modalTypes,
                openIssuePremiumModal,
                openBlockUserModal,
                openErrorModal,
                openAnalyzeSupportModal,
                openConfirmationModal,
                openCreateBlacklistedPhrasesModal,
                openAddPaymentStatsModal,
                openAddPromotionModal,
                openAddGiveawayModal,
                openCreateThemeModal,
            }}
        >
            <Dialog
                open={!!Component}
                onClose={closeModal}
                fullScreen={fullScreen}
            >
                {!!Component && <Component {...props} isLoading={isLoading} />}
            </Dialog>
            {children}
        </Provider>
    )
})

const withModals = Component => props => (
    <Consumer>{modals => <Component {...props} {...modals} />}</Consumer>
)

const useModals = () => useContext(ModalContext)

export { Consumer, ModalProvider as Provider, withModals, useModals }
