/**
 * @flow
 * @relayHash ab307632706be91f84f8885adc7cc98c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppManagementCreateBlacklistedPhraseInput = {|
  phrases: $ReadOnlyArray<?AppManagementBlacklistedPhraseInput>,
  clientMutationId?: ?string,
|};
export type AppManagementBlacklistedPhraseInput = {|
  text: string
|};
export type ChatConfigContainerCreateBlacklistedPhrasesMutationVariables = {|
  input: AppManagementCreateBlacklistedPhraseInput
|};
export type ChatConfigContainerCreateBlacklistedPhrasesMutationResponse = {|
  +appManagementCreateBlacklistedPhrases: ?{|
    +success: ?boolean,
    +phrases: $ReadOnlyArray<?{|
      +text: string,
      +createdAt: ?any,
      +createdBy: ?string,
      +id: string,
    |}>,
    +errors: ?$ReadOnlyArray<?{|
      +statusCode: ?number,
      +message: ?string,
      +meta: ?any,
      +errorCode: ?string,
      +code: ?number,
    |}>,
  |}
|};
export type ChatConfigContainerCreateBlacklistedPhrasesMutation = {|
  variables: ChatConfigContainerCreateBlacklistedPhrasesMutationVariables,
  response: ChatConfigContainerCreateBlacklistedPhrasesMutationResponse,
|};
*/


/*
mutation ChatConfigContainerCreateBlacklistedPhrasesMutation(
  $input: AppManagementCreateBlacklistedPhraseInput!
) {
  appManagementCreateBlacklistedPhrases(input: $input) {
    success
    phrases {
      text
      createdAt
      createdBy
      id
    }
    errors {
      statusCode
      message
      meta
      errorCode
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AppManagementCreateBlacklistedPhraseInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "appManagementCreateBlacklistedPhrases",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AppManagementCreateBlacklistedPhrasePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "phrases",
        "storageKey": null,
        "args": null,
        "concreteType": "AppManagementBlacklistedPhrase",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "text",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdBy",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "AppManagementError",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "meta",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "errorCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ChatConfigContainerCreateBlacklistedPhrasesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ChatConfigContainerCreateBlacklistedPhrasesMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ChatConfigContainerCreateBlacklistedPhrasesMutation",
    "id": null,
    "text": "mutation ChatConfigContainerCreateBlacklistedPhrasesMutation(\n  $input: AppManagementCreateBlacklistedPhraseInput!\n) {\n  appManagementCreateBlacklistedPhrases(input: $input) {\n    success\n    phrases {\n      text\n      createdAt\n      createdBy\n      id\n    }\n    errors {\n      statusCode\n      message\n      meta\n      errorCode\n      code\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '715da5d0185e0c84ab44e753a9185943';
module.exports = node;
