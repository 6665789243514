import { CircularProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    margin-left: 10px;
    align-items: center;
    display: flex;
`

const Loader = () => (
    <Container>
        <CircularProgress size={24} />
    </Container>
)

export default Loader
