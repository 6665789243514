/**
 * @flow
 * @relayHash 9f4423ea3dfa68b901a3e35e5a072f44
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MainApp_userManagement$ref = any;
export type AppQueryVariables = {||};
export type AppQueryResponse = {|
  +userManagement: ?{|
    +currentUser: ?{|
      +name: ?string,
      +role: ?string,
      +roles: ?$ReadOnlyArray<?string>,
    |},
    +$fragmentRefs: MainApp_userManagement$ref,
  |}
|};
export type AppQuery = {|
  variables: AppQueryVariables,
  response: AppQueryResponse,
|};
*/

/*
query AppQuery {
  userManagement {
    ...MainApp_userManagement
    currentUser {
      name
      role
      roles
      id
    }
    id
  }
}

fragment MainApp_userManagement on UserManagement {
  currentUser {
    name
    avatarUrl
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = {
            kind: 'ScalarField',
            alias: null,
            name: 'name',
            args: null,
            storageKey: null,
        },
        v1 = {
            kind: 'ScalarField',
            alias: null,
            name: 'role',
            args: null,
            storageKey: null,
        },
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'roles',
            args: null,
            storageKey: null,
        },
        v3 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'AppQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'userManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'UserManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'currentUser',
                            storageKey: null,
                            args: null,
                            concreteType: 'UserManagementUser',
                            plural: false,
                            selections: [
                                (v0 /*: any*/),
                                (v1 /*: any*/),
                                (v2 /*: any*/),
                            ],
                        },
                        {
                            kind: 'FragmentSpread',
                            name: 'MainApp_userManagement',
                            args: null,
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'AppQuery',
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'userManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'UserManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'currentUser',
                            storageKey: null,
                            args: null,
                            concreteType: 'UserManagementUser',
                            plural: false,
                            selections: [
                                (v0 /*: any*/),
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'avatarUrl',
                                    args: null,
                                    storageKey: null,
                                },
                                (v3 /*: any*/),
                                (v1 /*: any*/),
                                (v2 /*: any*/),
                            ],
                        },
                        (v3 /*: any*/),
                    ],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'AppQuery',
            id: null,
            text:
                'query AppQuery {\n  userManagement {\n    ...MainApp_userManagement\n    currentUser {\n      name\n      role\n      roles\n      id\n    }\n    id\n  }\n}\n\nfragment MainApp_userManagement on UserManagement {\n  currentUser {\n    name\n    avatarUrl\n    id\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = 'ac1e58eaf1a8e557abf4a8825171ecd0';
module.exports = node
