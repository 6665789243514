import React, { Fragment } from 'react'
import { createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import Typography from '@material-ui/core/Typography'

import User from '../../../models/User'

const UserMiniPage = props => {
    const user = new User(props.user)

    return (
        <Fragment>
            <Typography>{user.name}</Typography>
            <br />
            <Typography variant="caption">role: {user.role}</Typography>
            <Typography variant="caption">email: {user.email}</Typography>
            <Typography variant="caption">
                account type: {user.accountType}
            </Typography>
        </Fragment>
    )
}

export default createFragmentContainer(UserMiniPage, {
    user: graphql`
        fragment UserMiniPage_user on AdminManagementUser {
            id
            cuid
            name
            email
            role
            accountType
        }
    `,
})
