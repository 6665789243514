import React from 'react'
import PropTypes from 'prop-types'
import { useFragment } from 'relay-hooks'
import get from 'lodash/get'
import graphql from 'babel-plugin-relay/macro'
import LogsIcon from '@material-ui/icons/FormatListBulleted'

import BaseSection from '../../../shared/components/BaseSection'
import {
    columnTypes,
    createPaginableSortableTable,
} from '../../../shared/components/PaginableSortableTable'

const columnsConfig = [
    {
        label: 'Column',
        sortable: false,
        getter: 'column',
        name: 'column',
        type: columnTypes.TEXT,
    },
    {
        label: 'Payment',
        sortable: false,
        getter: 'payment',
        name: 'payment',
        type: columnTypes.TEXT,
    },
    {
        label: 'Value',
        sortable: false,
        getter: 'value',
        name: 'value',
        type: columnTypes.DYNAMIC,
    },
    {
        label: 'Old value',
        sortable: false,
        getter: 'oldValue',
        name: 'oldValue',
        type: columnTypes.DYNAMIC,
    },
    {
        label: 'Description',
        sortable: false,
        getter: 'description',
        name: 'description',
        type: columnTypes.TEXT,
    },
    {
        label: 'Created at',
        sortable: false,
        getter: 'createdAt',
        name: 'createdAt',
        type: columnTypes.DATE_TIME_1,
    },
    {
        label: 'Created by',
        sortable: false,
        getter: 'createdByCuid',
        name: 'createdByCuid',
        type: columnTypes.MINI_PAGE,
        options: {
            to: '/users/{{value}}',
        },
    },
]

const refetchQuery = graphql`
    query LogsRefetchQuery($first: Int, $skip: Int) {
        bankingManagement {
            logs(first: $first, offset: $skip)
                @connection(key: "Logs_logs", filters: ["first", "skip"]) {
                edges {
                    node {
                        id
                        column
                        payment
                        value
                        oldValue
                        createdAt
                        createdByCuid
                        description
                    }
                }
                ...Logs_data
            }
        }
    }
`

const fragmentSpec = graphql`
    fragment Logs_data on BankingManagementPaymentLogConnection {
        count
        edges {
            node {
                id
                column
                payment
                value
                oldValue
                createdAt
                createdByCuid
                description
            }
        }
    }
`

const PaginableSortableTable = createPaginableSortableTable(
    { data: fragmentSpec },
    refetchQuery
)

export const Logs = ({ logs }) => {
    const logsEntity = useFragment(fragmentSpec, logs)
    return (
        <BaseSection
            title="Payment logs"
            count={get(logsEntity, 'count')}
            icon={<LogsIcon />}
        >
            <PaginableSortableTable
                dataGetter="edges"
                countGetter="count"
                columnsConfig={columnsConfig}
                data={logs}
            />
        </BaseSection>
    )
}

Logs.propTypes = {
    logs: PropTypes.shape({
        edges: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                oldValue: PropTypes.string,
                createdByCuid: PropTypes.string,
                createdAt: PropTypes.string,
                description: PropTypes.string,
            })
        ),
        count: PropTypes.number,
    }),
}
