/**
 * @flow
 * @relayHash 4a9f87d5b90ae148f184fd1eea1dd745
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RedirectsContainerQueryVariables = {||};
export type RedirectsContainerQueryResponse = {|
  +appManagement: ?{|
    +redirects: ?$ReadOnlyArray<?{|
      +id: string,
      +from: ?string,
      +to: ?string,
      +entityName: ?string,
      +entityCuid: ?string,
      +createdAt: ?any,
      +updatedAt: ?any,
    |}>
  |}
|};
export type RedirectsContainerQuery = {|
  variables: RedirectsContainerQueryVariables,
  response: RedirectsContainerQueryResponse,
|};
*/

/*
query RedirectsContainerQuery {
  appManagement {
    redirects {
      id
      from
      to
      entityName
      entityCuid
      createdAt
      updatedAt
    }
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        },
        v1 = {
            kind: 'LinkedField',
            alias: null,
            name: 'redirects',
            storageKey: null,
            args: null,
            concreteType: 'AppManagementRedirect',
            plural: true,
            selections: [
                (v0 /*: any*/),
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'from',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'to',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'entityName',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'entityCuid',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'createdAt',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'updatedAt',
                    args: null,
                    storageKey: null,
                },
            ],
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'RedirectsContainerQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'appManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AppManagement',
                    plural: false,
                    selections: [(v1 /*: any*/)],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'RedirectsContainerQuery',
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'appManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AppManagement',
                    plural: false,
                    selections: [(v1 /*: any*/), (v0 /*: any*/)],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'RedirectsContainerQuery',
            id: null,
            text:
                'query RedirectsContainerQuery {\n  appManagement {\n    redirects {\n      id\n      from\n      to\n      entityName\n      entityCuid\n      createdAt\n      updatedAt\n    }\n    id\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = 'b90b355c4a5e4138dbf989eea9d3ecc9';
module.exports = node
