/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Payments_payments$ref: FragmentReference;
declare export opaque type Payments_payments$fragmentType: Payments_payments$ref;
export type Payments_payments = $ReadOnlyArray<{|
  +id: string,
  +name: ?string,
  +shortName: string,
  +isEnabled: ?boolean,
  +order: number,
  +excludedCountries: ?$ReadOnlyArray<?string>,
  +allowedCountries: ?$ReadOnlyArray<?string>,
  +suggested: ?$ReadOnlyArray<?string>,
  +suggestAll: ?boolean,
  +forceMethod: ?string,
  +paymentGroup: ?string,
  +minAmount: ?number,
  +maxAmount: ?number,
  +amountSteps: ?$ReadOnlyArray<?number>,
  +icon: ?string,
  +currency: ?string,
  +isNew: ?boolean,
  +$refType: Payments_payments$ref,
|}>;
export type Payments_payments$data = Payments_payments;
export type Payments_payments$key = $ReadOnlyArray<{
  +$data?: Payments_payments$data,
  +$fragmentRefs: Payments_payments$ref,
}>;
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "Payments_payments",
  "type": "BankingManagementPayment",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shortName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "isEnabled",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "order",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "excludedCountries",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "allowedCountries",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "suggested",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "suggestAll",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "forceMethod",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paymentGroup",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "minAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "maxAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amountSteps",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "icon",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currency",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isNew",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'c8be03e123fb4bbb16e050af38beb970';
module.exports = node;
