/**
 * @flow
 */

/* eslint-disable */

'use strict'

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Giveaways_caseManagement$ref: FragmentReference;
declare export opaque type Giveaways_caseManagement$fragmentType: Giveaways_caseManagement$ref;
export type Giveaways_caseManagement = {|
  +products: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +cuid: ?string,
        +name: ?string,
        +slug: ?string,
        +price: ?number,
        +imgUrl: ?{|
          +x2: ?{|
            +default: ?string
          |}
        |},
      |}
    |}>
  |},
  +$refType: Giveaways_caseManagement$ref,
|};
export type Giveaways_caseManagement$data = Giveaways_caseManagement;
export type Giveaways_caseManagement$key = {
  +$data?: Giveaways_caseManagement$data,
  +$fragmentRefs: Giveaways_caseManagement$ref,
};
*/

const node /*: ReaderFragment*/ = {
    kind: 'Fragment',
    name: 'Giveaways_caseManagement',
    type: 'CaseManagement',
    metadata: {
        connection: [
            {
                count: null,
                cursor: null,
                direction: 'forward',
                path: ['products'],
            },
        ],
    },
    argumentDefinitions: [
        {
            kind: 'LocalArgument',
            name: 'searchText',
            type: 'String',
            defaultValue: '',
        },
    ],
    selections: [
        {
            kind: 'LinkedField',
            alias: 'products',
            name: '__Giveaways_products_connection',
            storageKey: null,
            args: [
                {
                    kind: 'Literal',
                    name: 'orderBy',
                    value: ['price'],
                },
                {
                    kind: 'Literal',
                    name: 'orderDirections',
                    value: ['DESC'],
                },
                {
                    kind: 'Variable',
                    name: 'searchText',
                    variableName: 'searchText',
                },
            ],
            concreteType: 'CaseManagementProductConnection',
            plural: false,
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'edges',
                    storageKey: null,
                    args: null,
                    concreteType: 'CaseManagementProductEdge',
                    plural: true,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'node',
                            storageKey: null,
                            args: null,
                            concreteType: 'CaseManagementProduct',
                            plural: false,
                            selections: [
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'cuid',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'name',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'slug',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'price',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'imgUrl',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'CaseManagementImageURL',
                                    plural: false,
                                    selections: [
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'x2',
                                            storageKey: null,
                                            args: null,
                                            concreteType: 'CaseManagementImage',
                                            plural: false,
                                            selections: [
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'default',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: '__typename',
                                    args: null,
                                    storageKey: null,
                                },
                            ],
                        },
                        {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'cursor',
                            args: null,
                            storageKey: null,
                        },
                    ],
                },
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'pageInfo',
                    storageKey: null,
                    args: null,
                    concreteType: 'PageInfo',
                    plural: false,
                    selections: [
                        {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'endCursor',
                            args: null,
                            storageKey: null,
                        },
                        {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'hasNextPage',
                            args: null,
                            storageKey: null,
                        },
                    ],
                },
            ],
        },
    ],
}
// prettier-ignore
;(node/*: any*/).hash = '96982c93a7f90e95c4875aaab04529ab';
module.exports = node
