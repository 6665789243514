/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserLog_logs$ref: FragmentReference;
declare export opaque type UserLog_logs$fragmentType: UserLog_logs$ref;
export type UserLog_logs = {|
  +count: ?number,
  +edges: ?$ReadOnlyArray<?{|
    +node: ?{|
      +id: string,
      +column: ?string,
      +value: ?any,
      +modifiedByUserCuid: ?string,
      +createdAt: ?any,
      +notes: ?string,
    |}
  |}>,
  +$refType: UserLog_logs$ref,
|};
export type UserLog_logs$data = UserLog_logs;
export type UserLog_logs$key = {
  +$data?: UserLog_logs$data,
  +$fragmentRefs: UserLog_logs$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "UserLog_logs",
  "type": "AdminManagementLogConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "count",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "AdminManagementLogEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "AdminManagementLog",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "column",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "value",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "modifiedByUserCuid",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "createdAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "notes",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'b61729d2299c1584568508cb72520755';
module.exports = node;
