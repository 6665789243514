/**
 * @flow
 * @relayHash e69815b4c59173e2ca01a93e86b2403d
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Payments_payments$ref = any;
export type PaymentsContainerQueryVariables = {||};
export type PaymentsContainerQueryResponse = {|
  +bankingManagement: ?{|
    +id: string,
    +payments: ?$ReadOnlyArray<?{|
      +$fragmentRefs: Payments_payments$ref
    |}>,
  |},
  +userManagement: ?{|
    +currentUser: ?{|
      +role: ?string,
      +roles: ?$ReadOnlyArray<?string>,
    |}
  |},
|};
export type PaymentsContainerQuery = {|
  variables: PaymentsContainerQueryVariables,
  response: PaymentsContainerQueryResponse,
|};
*/

/*
query PaymentsContainerQuery {
  bankingManagement {
    id
    payments {
      ...Payments_payments
      id
    }
  }
  userManagement {
    currentUser {
      role
      roles
      id
    }
    id
  }
}

fragment Payments_payments on BankingManagementPayment {
  id
  name
  shortName
  isEnabled
  order
  excludedCountries
  allowedCountries
  suggested
  suggestAll
  forceMethod
  paymentGroup
  minAmount
  maxAmount
  amountSteps
  icon
  currency
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        },
        v1 = {
            kind: 'ScalarField',
            alias: null,
            name: 'role',
            args: null,
            storageKey: null,
        },
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'roles',
            args: null,
            storageKey: null,
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'PaymentsContainerQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'bankingManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'BankingManagement',
                    plural: false,
                    selections: [
                        (v0 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'payments',
                            storageKey: null,
                            args: null,
                            concreteType: 'BankingManagementPayment',
                            plural: true,
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: 'Payments_payments',
                                    args: null,
                                },
                            ],
                        },
                    ],
                },
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'userManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'UserManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'currentUser',
                            storageKey: null,
                            args: null,
                            concreteType: 'UserManagementUser',
                            plural: false,
                            selections: [(v1 /*: any*/), (v2 /*: any*/)],
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'PaymentsContainerQuery',
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'bankingManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'BankingManagement',
                    plural: false,
                    selections: [
                        (v0 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'payments',
                            storageKey: null,
                            args: null,
                            concreteType: 'BankingManagementPayment',
                            plural: true,
                            selections: [
                                (v0 /*: any*/),
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'name',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'shortName',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'isEnabled',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'order',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'excludedCountries',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'allowedCountries',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'suggested',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'suggestAll',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'forceMethod',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'paymentGroup',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'minAmount',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'maxAmount',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'amountSteps',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'icon',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'currency',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ClientExtension',
                                    selections: [
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'isNew',
                                            args: null,
                                            storageKey: null,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'userManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'UserManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'currentUser',
                            storageKey: null,
                            args: null,
                            concreteType: 'UserManagementUser',
                            plural: false,
                            selections: [
                                (v1 /*: any*/),
                                (v2 /*: any*/),
                                (v0 /*: any*/),
                            ],
                        },
                        (v0 /*: any*/),
                    ],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'PaymentsContainerQuery',
            id: null,
            text:
                'query PaymentsContainerQuery {\n  bankingManagement {\n    id\n    payments {\n      ...Payments_payments\n      id\n    }\n  }\n  userManagement {\n    currentUser {\n      role\n      roles\n      id\n    }\n    id\n  }\n}\n\nfragment Payments_payments on BankingManagementPayment {\n  id\n  name\n  shortName\n  isEnabled\n  order\n  excludedCountries\n  allowedCountries\n  suggested\n  suggestAll\n  forceMethod\n  paymentGroup\n  minAmount\n  maxAmount\n  amountSteps\n  icon\n  currency\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '6ccadf2ead9a0792cddb0423cb0a716d';
module.exports = node
