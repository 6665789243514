import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { DebounceInput } from 'react-debounce-input'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import FormHelperText from '@material-ui/core/FormHelperText'
import CircularProgress from '@material-ui/core/CircularProgress'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import ErrorIcon from '@material-ui/icons/Error'

import red from '@material-ui/core/colors/red'

import GoogleIcon from '../../../components/icons/Google'
import green from '@material-ui/core/colors/green'

const __DEV__ = process.env.NODE_ENV === 'development'

const SignIn = props => {
    const {
        email: emailError,
        password: passwordError,
        form: formError,
    } = props.errors
    const { classes, isLoading } = props

    const googleAuthButton = (
        <Button
            color="default"
            variant="contained"
            disabled={isLoading}
            fullWidth
        >
            Google
            <GoogleIcon className={classes.buttonIcon} />
        </Button>
    )

    return (
        <div className={classes.container}>
            <Paper className={classNames(classes.form, classes.mainForm)}>
                <div>
                    <Typography variant="h6" align="center">
                        SIGN IN
                    </Typography>
                    <FormControl fullWidth margin="normal" error={!!emailError}>
                        <InputLabel htmlFor="signin-email-input">
                            Email
                        </InputLabel>
                        <Input
                            id="signin-email-input"
                            name="email"
                            type="email"
                            autoFocus
                            inputComponent={DebounceInput}
                            inputProps={{
                                debounceTimeout: 500,
                            }}
                            onChange={props.onInputChange}
                            value={props.email}
                        />
                        {emailError && (
                            <FormHelperText>{emailError}</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl
                        fullWidth
                        margin="normal"
                        error={!!passwordError}
                    >
                        <InputLabel htmlFor="signin-password-input">
                            Password
                        </InputLabel>
                        <Input
                            id="signin-password-input"
                            name="password"
                            inputComponent={DebounceInput}
                            inputProps={{
                                debounceTimeout: 500,
                            }}
                            type={props.isShowPassword ? 'text' : 'password'}
                            onChange={props.onInputChange}
                            value={props.password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={props.toggleShowPassword}
                                    >
                                        {props.isShowPassword ? (
                                            <VisibilityOffIcon />
                                        ) : (
                                            <VisibilityIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {passwordError && (
                            <FormHelperText>{passwordError}</FormHelperText>
                        )}
                    </FormControl>
                    <div className={classes.formActions}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={props.onSubmit}
                            disabled={isLoading}
                            fullWidth
                        >
                            Login
                            <ExitToAppIcon className={classes.buttonIcon} />
                            {isLoading && (
                                <CircularProgress
                                    size={24}
                                    className={classes.buttonProgress}
                                />
                            )}
                        </Button>
                        <Typography variant="caption" align="center">
                            or sign in with
                        </Typography>
                        {isLoading ? (
                            googleAuthButton
                        ) : (
                            <a
                                href={`${
                                    (__DEV__ ? process.env : window)
                                        .REACT_APP_DRAKEMALL_URL
                                }/auth/google`}
                                className={classes.link}
                            >
                                {googleAuthButton}
                            </a>
                        )}
                    </div>
                </div>
            </Paper>
            {formError && (
                <Paper className={classNames(classes.form, classes.errorCard)}>
                    <ErrorIcon className={classes.errorCardIcon} />
                    {formError}
                </Paper>
            )}
        </div>
    )
}

SignIn.propTypes = {
    onInputChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    isShowPassword: PropTypes.bool.isRequired,
    toggleShowPassword: PropTypes.func.isRequired,
    errors: PropTypes.shape({
        email: PropTypes.string,
        password: PropTypes.string,
    }),
}

const styles = theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
    mainForm: {
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
    },
    form: {
        padding: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formActions: {
        marginTop: 15,
        textTransform: 'uppercase',
        '& > *': {
            marginBottom: 10,
        },
    },
    buttonIcon: {
        marginLeft: 5,
    },
    link: {
        textDecoration: 'none',
    },
    errorCard: {
        backgroundColor: red[400],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        width: '100%',
        maxWidth: 458,
    },
    errorCardIcon: {
        marginRight: 15,
    },
    buttonProgress: {
        color: green[500],
        marginLeft: 5,
    },
})

export default withStyles(styles, { withTheme: true })(SignIn)
