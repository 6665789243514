import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import graphql from 'babel-plugin-relay/macro'
import slugify from 'slugify'
import classNames from 'classnames'

import { withStyles } from '@material-ui/core/styles'

import Input from '@material-ui/core/Input/Input'
import Chip from '@material-ui/core/Chip/Chip'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import FormControl from '@material-ui/core/FormControl/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'

import get from 'lodash/get'
import chunk from 'lodash/chunk'
import orderBy from 'lodash/orderBy'
import isFunction from 'lodash/isFunction'

import getEnvVariable from '../../../../utils/getEnvVariable'
import {
    columnTypes,
    createPaginableSortableTable,
} from '../../../../shared/components/PaginableSortableTable'

import { TextField, InputAdornment } from '@material-ui/core'
import pull from 'lodash/pull'
import JsonPreView from '../../../../shared/components/JsonPreView'

const actionTypes = {
    DELIVERY_CANCEL: 'deliveryCancel',
    PROMO_CODE: 'promoCode',
    REPLENISH: 'replenish',
    REPLENISH_DECLINED: 'replenishDeclined',
    INCREASE_BALANCE: 'increaseBalance',
    DECREASE_BALANCE: 'decreaseBalance',
    WITHDRAW: 'withdraw',
    SELL: 'sell',
    BUY: 'buy',
    DROP: 'drop',
    REFERRAL_BONUS: 'referralBonus',
    AFFILIATE_BONUS: 'affiliateBonus',
    FTD_BONUS: 'ftdBonus',
    DECLINE_BONUS: 'declineBonus',
    PREMIUM_BUY: 'premiumBuy',
    PREMIUM_ISSUE: 'premiumIssue',
    KING_OF_BOX: 'kingOfBox',
    BATTLE_CREATE: 'battleCreate',
    BATTLE_JOIN: 'battleJoin',
    BATTLE_CANCEL: 'battleCancel',
    BATTLE_DRAW: 'battleDraw',
    BATTLE_RESOLVE: 'battleResolve',
    DEPOSIT_PROMO: 'depositPromo',
    GIVEAWAY: 'giveaway',
    WEEKLY_PROMOCODE: 'weeklyPromoCode',
}

const actionTypesList = orderBy(Object.values(actionTypes), null, ['asc'])

const fragmentSpec = {
    data: graphql`
        fragment UserTransactions_data on AdminManagementUser
            @argumentDefinitions(
                first: { type: "Int", defaultValue: 100 }
                skip: { type: "Int", defaultValue: 0 }
                orderBy: { type: "String", defaultValue: "createdAt" }
                orderDirection: {
                    type: "AdminManagementOrderDirections"
                    defaultValue: "DESC"
                }
                types: { type: "[String]", defaultValue: [] }
                paymentSystem: { type: "String" }
                paymentMethod: { type: "String" }
                source: { type: "String" }
            ) {
            rows: transactions(
                orderBy: $orderBy
                orderDirection: $orderDirection
                first: $first
                skip: $skip
                types: $types
                paymentSystem: $paymentSystem
                paymentMethod: $paymentMethod
                source: $source
            ) {
                count
                sum
                depositsSum
                winProductsPricesSum
                edges {
                    node {
                        id
                        cuid
                        createdAt
                        actionType
                        details
                        amount
                    }
                }
            }
        }
    `,
}

const refetchQuery = graphql`
    query UserTransactionsRefetchQuery(
        $userId: String!
        $orderBy: String
        $orderDirection: AdminManagementOrderDirections
        $first: Int
        $skip: Int
        $types: [String]
        $paymentSystem: String
        $paymentMethod: String
        $source: String
    ) {
        adminManagement {
            user(userId: $userId) {
                ...UserTransactions_data
                    @arguments(
                        orderBy: $orderBy
                        orderDirection: $orderDirection
                        first: $first
                        skip: $skip
                        types: $types
                        paymentSystem: $paymentSystem
                        paymentMethod: $paymentMethod
                        source: $source
                    )
            }
        }
    }
`

const getColumnsConfig = classes => [
    {
        label: 'Transaction ID',
        sortable: true,
        getter: 'cuid',
        name: 'cuid',
        type: columnTypes.TEXT,
    },
    {
        label: 'Date',
        sortable: true,
        getter: 'createdAt',
        name: 'createdAt',
        type: columnTypes.DATE_TIME_1,
    },
    {
        label: 'Action type',
        sortable: true,
        getter: 'actionType',
        name: 'actionType',
        type: columnTypes.TEXT,
    },
    {
        label: 'Amount',
        sortable: true,
        getter: ({ amount }) => (
            <span
                className={classNames(classes.amountItem, {
                    [classes.positiveAmountItem]: amount > 0,
                })}
            >
                {amount}
            </span>
        ),
        name: 'amount',
        type: columnTypes.NUMBER,
    },
    {
        label: 'Details',
        getter: action => {
            const url = getEnvVariable('REACT_APP_DRAKEMALL_FRONTEND_URL')
            const productName = get(
                action,
                'details.product.name',
                get(action, 'details.product.title')
            )
            const productSlugName = get(
                action,
                'details.product.slug',
                slugify(productName || '', { lower: true })
            )
            const boxName = get(action, 'details.box.name', '')
            const boxSlugName = get(
                action,
                'details.box.slug',
                slugify(boxName || '', { lower: true })
            )
            const boxLink = (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${url}/boxes/${boxSlugName}`}
                >
                    {boxName}
                </a>
            )
            const productLink = (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${url}/products/${productSlugName}`}
                >
                    {productName}
                </a>
            )
            const battleName = get(action, 'details.battleName')
            const battleCuid = get(action, 'details.battleID')
            const battleLink = (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${url}/battles/${battleCuid}`}
                >
                    {battleName}
                </a>
            )

            switch (action.actionType) {
                case actionTypes.BUY:
                    return productLink

                case actionTypes.SELL:
                    return (
                        <span>
                            {productLink}
                            {boxName && (
                                <Fragment>opened in {boxLink}</Fragment>
                            )}
                        </span>
                    )

                case actionTypes.DROP: {
                    return (
                        <Fragment>
                            <div>Open {boxLink}</div>
                            <div>
                                Win {productLink} which costs{' '}
                                {get(action, 'details.product.price', '-')}
                            </div>
                        </Fragment>
                    )
                }

                case actionTypes.WITHDRAW:
                    const addressConfig = [
                        { label: 'Country', name: 'country' },
                        { label: 'City', name: 'city' },
                        { label: 'Region', name: 'region' },
                        { label: 'Street', name: 'street' },
                        { label: 'Apartment', name: 'apartment' },
                        { label: 'Building', name: 'building' },
                        { label: 'Zip code', name: 'zipCode' },
                        { label: 'Phone', name: 'phone' },
                        { label: 'E-mail', name: 'email' },
                        {
                            label: 'CPF',
                            name: 'CPF',
                            isHidden: ({ country }) => country !== 'BR',
                        },
                    ]

                    return (
                        <Fragment>
                            {productLink}
                            <div>Delivery info:</div>
                            <div className={classes.flexContainer}>
                                {chunk(
                                    addressConfig.filter(
                                        address =>
                                            !(
                                                isFunction(address.isHidden) &&
                                                address.isHidden(
                                                    get(
                                                        action,
                                                        `details.userInfo`,
                                                        {}
                                                    )
                                                )
                                            )
                                    ),
                                    addressConfig.length / 2 + 1
                                ).map((chunk, i) => {
                                    const key = `Action-${action.cuid}-deliveryInfo`
                                    return (
                                        <div
                                            className={classes.deliveryInfoItem}
                                            key={`${key}-${i}`}
                                        >
                                            {chunk.map(address => (
                                                <div
                                                    key={`${key}-${address.label}`}
                                                >
                                                    <i>{address.label}: </i>
                                                    {get(
                                                        action,
                                                        `details.userInfo.${address.name}`,
                                                        ''
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    )
                                })}
                            </div>
                        </Fragment>
                    )

                case actionTypes.INCREASE_BALANCE:
                case actionTypes.DECREASE_BALANCE:
                    const modifiedByUserCuid = get(
                        action,
                        'details.modifiedByUserCuid',
                        ''
                    )
                    const notes = get(action, 'details.notes', '')
                    return (
                        <Fragment>
                            <div>
                                <i>Notes: </i> {notes}
                            </div>
                            {modifiedByUserCuid && (
                                <div>
                                    Changed by{' '}
                                    <Link to={`/users/${modifiedByUserCuid}`}>
                                        {modifiedByUserCuid}
                                    </Link>
                                </div>
                            )}
                        </Fragment>
                    )

                case actionTypes.REPLENISH:
                case actionTypes.REPLENISH_DECLINED:
                case actionTypes.PROMO_CODE:
                    const orderId = get(action, 'details.orderID')
                    const paymentSystem = get(action, 'details.paymentSystem')
                    const amount = get(
                        action,
                        'details.paymentSystemResp.amount'
                    )
                    const voucherCode = get(action, 'details.meta.code')
                    const source = get(action, 'details.meta.source')
                    const forceMethod = get(action, 'details.meta.forceMethod')
                    const paymentMethod = get(action, 'details.paymentMethod')
                    const forceMethodCode = get(
                        action,
                        'details.paymentMethodCode'
                    )
                    const card = get(action, 'details.card')
                    const declineReason = get(action, 'details.declineReason')
                    const declineReasonCode = get(
                        action,
                        'details.declineReasonCode'
                    )

                    const scansLinks = {
                        bsc: 'https://bscscan.com/tx',
                        ethereum: 'https://etherscan.io/tx',
                        polygon: 'https://polygonscan.com/tx',
                    }

                    return (
                        <Fragment>
                            <div>
                                <i>Order ID: </i> {orderId}
                            </div>
                            <div>
                                <i>Payment system: </i> <b>{paymentSystem}</b>
                            </div>
                            {(forceMethod || paymentMethod) && (
                                <div>
                                    <i>Method: </i>{' '}
                                    {forceMethod || paymentMethod}
                                    {forceMethodCode && `(${forceMethodCode})`}
                                </div>
                            )}
                            {declineReason && (
                                <div>
                                    <i>Reason: </i> {declineReason}
                                    {declineReasonCode &&
                                        `(${declineReasonCode})`}
                                </div>
                            )}
                            {card && (
                                <div>
                                    Card info: <JsonPreView>{card}</JsonPreView>
                                </div>
                            )}
                            {source && (
                                <div>
                                    <i>Source: </i> {source}
                                </div>
                            )}
                            {action.actionType ===
                                actionTypes.REPLENISH_DECLINED && (
                                <div>
                                    <i>Amount: </i> {amount}
                                </div>
                            )}
                            {paymentSystem === 'authorize.net' && (
                                <>
                                    <div>
                                        <i>Authorize.net Transaction ID: </i>
                                        {action.details.meta.transactionId}
                                    </div>
                                    <div>
                                        <i>Auth code: </i>
                                        {action.details.meta.authCode}
                                    </div>
                                </>
                            )}
                            {paymentSystem === 'depay' && (
                                <>
                                    <div>
                                        <i>Blockchain: </i>
                                        {action.details.meta.blockchain}
                                    </div>
                                    <div>
                                        <i>Transaction ID: </i>
                                        {action.details.meta.transaction}{' '}
                                        <a
                                            href={`${
                                                scansLinks[
                                                    action.details.meta
                                                        .blockchain
                                                ]
                                            }/${
                                                action.details.meta.transaction
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Explore transaction
                                        </a>
                                    </div>
                                    <div>
                                        <i>From address: </i>
                                        {action.details.meta.sender}
                                    </div>
                                </>
                            )}
                            {voucherCode && (
                                <div>
                                    <i>Voucher Code: </i> {voucherCode}
                                </div>
                            )}
                        </Fragment>
                    )

                case actionTypes.DELIVERY_CANCEL:
                    return get(action, 'details.statusType', '')

                case actionTypes.REFERRAL_BONUS:
                    const affiliateLink = `/users/${get(
                        action,
                        'details.meta.affiliateId'
                    )}`
                    return (
                        <Fragment>
                            From user{' '}
                            <Link to={affiliateLink}>
                                {get(action, 'details.meta.affiliateId')}
                            </Link>
                        </Fragment>
                    )

                case actionTypes.AFFILIATE_BONUS:
                    const referralLink = `/users/${get(
                        action,
                        'details.meta.referralId'
                    )}`
                    return (
                        <Fragment>
                            From user{' '}
                            <Link to={referralLink}>
                                {get(action, 'details.meta.referralName')}
                            </Link>
                        </Fragment>
                    )

                case actionTypes.FTD_BONUS:
                    return (
                        <Fragment>
                            <div>
                                {get(action, 'details.percentageAmount')}% bonus
                                for {get(action, 'details.depositAmount')}$
                                deposit
                            </div>
                            <div>
                                <i> Order ID: </i>{' '}
                                {get(action, 'details.depositOrderId')}
                            </div>
                        </Fragment>
                    )

                case actionTypes.DECLINE_BONUS:
                    return (
                        <Fragment>
                            Bonus for {get(action, 'details.depositAmount')}$
                            deposit by Voucherify
                        </Fragment>
                    )

                case actionTypes.PREMIUM_ISSUE:
                    return (
                        <Fragment>
                            {get(action, 'details.description')}
                        </Fragment>
                    )

                case actionTypes.KING_OF_BOX:
                    return (
                        <Fragment>
                            {productLink} prize for King of {boxLink}
                        </Fragment>
                    )

                case actionTypes.GIVEAWAY:
                    return <Fragment>{productLink} prize for Giveaway</Fragment>

                case actionTypes.WEEKLY_PROMOCODE:
                    return <Fragment>{action.details.promoCode}</Fragment>

                case actionTypes.BATTLE_CREATE:
                case actionTypes.BATTLE_JOIN:
                case actionTypes.BATTLE_DRAW:
                case actionTypes.BATTLE_CANCEL:
                case actionTypes.BATTLE_RESOLVE:
                    return battleLink
                case actionTypes.DEPOSIT_PROMO:
                    return (
                        <Fragment>
                            Code: {get(action, 'details.meta.promoCode', '-')}
                        </Fragment>
                    )

                default:
                    return null
            }
        },
        name: 'details',
        type: columnTypes.TEXT,
    },
]

const PaginableSortableTable = createPaginableSortableTable(
    fragmentSpec,
    refetchQuery
)

class UserTransactions extends Component {
    state = {
        filters: [],
        isOnlyERealm: false,
    }

    table = React.createRef()

    get classes() {
        return this.props.classes
    }

    getFilters = ({ isLoading, data }) => {
        const id = 'select-action-type'

        const sumColumns = [
            {
                label: 'Total',
                value: get(data, 'rows.sum', null),
            },
            {
                label: 'Deposits sum',
                value: get(data, 'rows.depositsSum', null),
            },
            {
                label: 'Win products prices sum',
                value: get(data, 'rows.winProductsPricesSum', null),
            },
        ]

        return (
            <div className={this.classes.filters}>
                <FormControl
                    className={this.classes.filter}
                    disabled={isLoading}
                >
                    <InputLabel htmlFor={id}>Action types</InputLabel>
                    <Select
                        multiple
                        value={this.state.filters}
                        onChange={this.handleFilterChange}
                        onClose={this.handleFilterSelectToggle}
                        onOpen={this.handleFilterSelectToggle}
                        input={<Input id={id} />}
                        open={this.state.isFiltersSelectOpened}
                        renderValue={selected => (
                            <div>
                                {selected.map(value => (
                                    <Chip
                                        key={value}
                                        label={value}
                                        className={this.classes.filterChip}
                                        onDelete={this.handleFilterDelete.bind(
                                            this,
                                            value
                                        )}
                                    />
                                ))}
                            </div>
                        )}
                    >
                        {actionTypesList.map(name => (
                            <MenuItem key={`ActionType-${name}`} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {sumColumns.map(
                    ({ value, label }, i) =>
                        Number.isFinite(value) && (
                            <TextField
                                key={`${label}-${value}`}
                                className={this.classes.filter}
                                label={label}
                                disabled
                                defaultValue={value}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )
                )}
                {this.state.filters.includes(actionTypes.REPLENISH) && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isOnlyERealm"
                                checked={this.state.isOnlyERealm}
                                onChange={this.handleChange}
                                color="primary"
                            />
                        }
                        label="Only ERealm"
                    />
                )}
            </div>
        )
    }

    handleFilterDelete = value => {
        const filters = [...this.state.filters]
        pull(filters, value)
        this.setState({ filters }, this.table.current._refetchDebounced)
    }

    handleFilterChange = event => {
        this.setState(
            {
                filters: event.target.value,
                isFiltersSelectOpened: false,
            },
            this.table.current._refetchDebounced
        )
    }

    handleFilterSelectToggle = () => {
        this.setState(state => ({
            isFiltersSelectOpened: !state.isFiltersSelectOpened,
        }))
    }

    getAdditionalFragmentVariables = () => {
        console.log(this.state)
        return {
            types: this.state.filters,
            ...(this.state.isOnlyERealm && { source: 'erealm' }),
        }
    }

    columnsConfig = getColumnsConfig(this.classes)

    handleChange = e => {
        console.log(e.target)
        this.setState(
            {
                [e.target.name]: e.target.checked,
            },
            this.table.current._refetchDebounced
        )
    }

    render() {
        return (
            <div className={this.props.className}>
                <PaginableSortableTable
                    innerRef={this.table}
                    columnsConfig={this.columnsConfig}
                    getAdditionalFragmentVariables={
                        this.getAdditionalFragmentVariables
                    }
                    data={this.props.user || []}
                    toolbarTop={this.getFilters}
                />
            </div>
        )
    }
}

UserTransactions.propTypes = {
    classNames: PropTypes.string,
    user: PropTypes.object,
}

UserTransactions.defaultProps = {
    classNames: '',
    user: {},
}

const styles = {
    flexContainer: {
        display: 'flex',
    },
    deliveryInfoItem: {
        padding: '0 10px',
    },
    positiveAmountItem: {
        color: '#43d1b3',
    },
    amountItem: {
        fontWeight: 'bold',
    },
    filter: {
        minWidth: 200,
        margin: 15,
    },
    filters: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    filterChip: {
        margin: '0 5px',
        textTransform: 'uppercase',
    },
}

export default withStyles(styles)(UserTransactions)
