import graphql from 'babel-plugin-relay/macro'

export default graphql`
    mutation BlockUserMutation(
        $input: AdminManagementBlockUserInput!
        $userCuid: String!
    ) {
        adminManagementBlockUser(input: $input) {
            user {
                isBlocked
                blockedExpireAt
                blockedReason
            }
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
            adminManagement {
                user(userId: $userCuid) {
                    isBlocked
                    blockedExpireAt
                    blockedReason
                    logs {
                        ...UserLog_logs
                    }
                }
            }
        }
    }
`
