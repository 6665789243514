import {
    columnTypes,
    createPaginableSortableTable,
} from '../../../../shared/components/PaginableSortableTable'
import React, { Component, Fragment } from 'react'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import pickBy from 'lodash/pickBy'
import isUndefined from 'lodash/isUndefined'
import identity from 'lodash/identity'
import result from 'lodash/result'
import { Img } from 'react-image'
import graphql from 'babel-plugin-relay/macro'
import classNames from 'classnames'
import moment from 'moment'

import {
    withStyles,
    Button,
    FormControl,
    TextField,
    MenuItem,
    InputAdornment,
} from '@material-ui/core'

import PanoramaIcon from '@material-ui/icons/Panorama'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import DateRangeIcon from '@material-ui/icons/DateRange'

import getEnvVariable from '../../../../utils/getEnvVariable'
import Loader from '../../../../components/Loader'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import Input from '@material-ui/core/Input/Input'
import Chip from '@material-ui/core/Chip/Chip'
import pull from 'lodash/pull'
import { CopyToClipboard } from '../../../../shared/components/CopyToClipboard'

const fragmentSpec = {
    data: graphql`
        fragment UserInventory_data on AdminManagementUser
            @argumentDefinitions(
                first: { type: "Int", defaultValue: 100 }
                skip: { type: "Int", defaultValue: 0 }
                orderBy: { type: "String", defaultValue: "" }
                orderDirection: {
                    type: "AdminManagementOrderDirections"
                    defaultValue: "DESC"
                }
                startDate: { type: "DateTime", defaultValue: null }
                endDate: { type: "DateTime", defaultValue: null }
                statuses: { type: "[String]", defaultValue: [] }
            ) {
            inventory(
                orderBy: $orderBy
                orderDirection: $orderDirection
                first: $first
                skip: $skip
                startDate: $startDate
                endDate: $endDate
                statuses: $statuses
            ) {
                count
                edges {
                    node {
                        id
                        cuid
                        status
                        createdAt
                        disposedAt
                        type
                        caseBox {
                            title
                            slug
                            price
                        }
                        product {
                            title
                            slug
                            price
                            image {
                                x1 {
                                    default
                                }
                            }
                        }
                        meta
                        cart {
                            cuid
                            status
                        }
                    }
                }
            }
        }
    `,
}

const refetchQuery = graphql`
    query UserInventoryRefetchQuery(
        $userId: String!
        $orderBy: String
        $orderDirection: AdminManagementOrderDirections
        $first: Int
        $skip: Int
        $startDate: DateTime
        $endDate: DateTime
        $statuses: [String]
    ) {
        adminManagement {
            user(userId: $userId) {
                ...UserInventory_data
                    @arguments(
                        orderBy: $orderBy
                        orderDirection: $orderDirection
                        first: $first
                        skip: $skip
                        startDate: $startDate
                        endDate: $endDate
                        statuses: $statuses
                    )
            }
        }
    }
`

const itemStatuses = {
    NEW: 'default',
    SOLD: 'sold',
    WITHDRAWN: 'withdrawn',
}

const itemStatusesList = Object.values(itemStatuses)

const itemStatusNames = {
    [itemStatuses.NEW]: 'new',
    [itemStatuses.SOLD]: 'sold',
    [itemStatuses.WITHDRAWN]: 'delivery request',
}

const itemTypes = {
    DROPPED: 'dropped',
    BOUGHT: 'bought',
    DELIVERY_CANCEL: 'deliveryCancel',
    KING_OF_BOX: 'kingOfBox',
    CASE_BATTLE: 'caseBattle',
    GIVEAWAY: 'giveaway',
}

const getColumnsConfig = classes => {
    const url = getEnvVariable('REACT_APP_DRAKEMALL_FRONTEND_URL')

    return [
        {
            label: 'Product',
            sortable: true,
            getter: item => {
                const title = get(item, 'product.title')
                const imageUrl = get(item, 'product.image.x1.default')
                const slug = get(item, 'product.slug')

                return (
                    <a
                        href={`${url}/products/${slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.productListView}
                    >
                        <Img
                            src={imageUrl}
                            alt={title}
                            className={classes.productImageListView}
                            unloader={
                                <PanoramaIcon
                                    fontSize="large"
                                    className={classes.productImageListView}
                                />
                            }
                            loader={<Loader size={30} />}
                        />
                        {title}
                    </a>
                )
            },
            name: 'product.title',
            type: columnTypes.TEXT,
        },
        {
            label: 'Item ID',
            sortable: true,
            getter: 'cuid',
            name: 'cuid',
            type: columnTypes.TEXT,
        },
        {
            label: 'Price',
            sortable: true,
            getter: 'product.price',
            name: 'product.price',
            type: columnTypes.MONEY,
        },
        {
            label: 'Incoming date',
            sortable: true,
            getter: 'createdAt',
            name: 'createdAt',
            type: columnTypes.DATE_TIME_1,
        },
        {
            label: 'Incoming description',
            getter: item => {
                const caseBox = get(item, 'caseBox', {})
                const type = get(item, 'type')
                const actionCuid = get(item, 'meta.actionCuid')
                const transactionIdText = actionCuid
                    ? `Transaction ID: ${actionCuid}.`
                    : ''

                if (type === itemTypes.BOUGHT) {
                    return `Bought. ${transactionIdText}`
                }

                if (type === itemTypes.DELIVERY_CANCEL) {
                    return `Returned caused by delivery cancel. ${transactionIdText}`
                }

                if (type === itemTypes.KING_OF_BOX) {
                    return (
                        <div>
                            Prize for King of{' '}
                            <a href={`${url}/boxes/${caseBox.slug}`}>
                                {caseBox.title}
                            </a>
                        </div>
                    )
                }

                if (
                    type === itemTypes.DROPPED &&
                    caseBox.title &&
                    caseBox.slug
                ) {
                    return (
                        <div>
                            Got in{' '}
                            <a
                                href={`${url}/boxes/${caseBox.slug}`}
                                className={classes.noWordWrap}
                            >
                                {caseBox.title}
                            </a>
                            <span className={classes.verticalCenter}>
                                for <AttachMoneyIcon fontSize="small" />
                                {caseBox.price}
                            </span>
                            <span className={classes.verticalCenter}>
                                {transactionIdText}
                            </span>
                        </div>
                    )
                }

                if (type === itemTypes.CASE_BATTLE) {
                    const battleCuid = get(item, 'meta.battleID')
                    const battleName = get(item, 'meta.battleName')
                    return (
                        <div>
                            Got in case battle:{' '}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.noWordWrap}
                                href={`${url}/battles/${battleCuid}`}
                            >
                                {battleName}
                            </a>
                            <span className={classes.verticalCenter}>
                                {transactionIdText}
                            </span>
                        </div>
                    )
                }

                if (type === itemTypes.GIVEAWAY) {
                    return item?.meta?.giveawayName
                        ? `Got in giveaway "${item?.meta?.giveawayName}" for ${item?.meta?.place}`
                        : `Got in giveaway`
                }

                return null
            },
            name: 'description',
        },
        {
            label: 'Outgoing date',
            sortable: true,
            getter: 'disposedAt',
            name: 'disposedAt',
            type: columnTypes.DATE_TIME_1,
        },
        {
            label: 'Outgoing description',
            sortable: true,
            getter: item => {
                const status = get(item, 'status')
                const withdrawActionCuid = get(item, 'meta.withdrawActionCuid')
                const transactionIdText = withdrawActionCuid ? (
                    <Fragment>
                        <b> Transaction ID </b>: ${withdrawActionCuid}.
                    </Fragment>
                ) : null
                const cartCuid = get(item, 'cart.cuid')
                const cartStatus = get(item, 'cart.status')

                return (
                    <div>
                        <div
                            className={classNames(classes.itemStatus, {
                                [classes.itemNew]: status === itemStatuses.NEW,
                                [classes.itemSold]:
                                    status === itemStatuses.SOLD,
                                [classes.itemWithdrawn]:
                                    status === itemStatuses.WITHDRAWN,
                            })}
                        >
                            {itemStatusNames[status]}
                        </div>
                        {transactionIdText && <div>{transactionIdText}</div>}
                        {cartStatus && (
                            <div>
                                <b> Cart status: </b> {cartStatus}
                            </div>
                        )}
                        {cartCuid && (
                            <div>
                                <b> Cart ID: </b>
                                <CopyToClipboard text={cartCuid}>
                                    {cartCuid}
                                </CopyToClipboard>
                            </div>
                        )}
                    </div>
                )
            },
            name: 'status',
        },
    ]
}

const dateFilters = [
    {
        label: 'Yesterday',
        name: 'yesterday',
        startDate: () =>
            moment()
                .subtract(1, 'days')
                .startOf('day')
                .toDate(),
        endDate: () =>
            moment()
                .subtract(1, 'days')
                .endOf('day')
                .toDate(),
    },
    {
        label: 'Today',
        name: 'today',
        startDate: () =>
            moment()
                .startOf('day')
                .toDate(),
        endDate: null,
    },
    {
        label: 'Last 30 days',
        name: 'last30Days',
        startDate: () =>
            moment()
                .subtract(30, 'days')
                .toDate(),
        endDate: null,
    },
    {
        label: 'Last 60 days',
        name: 'last60Days',
        startDate: () =>
            moment()
                .subtract(60, 'days')
                .toDate(),
        endDate: null,
    },
    {
        label: 'Current month',
        name: 'currentMonth',
        startDate: () =>
            moment()
                .startOf('month')
                .toDate(),
        endDate: null,
    },
    {
        label: 'Last month',
        name: 'lastMonth',
        startDate: () =>
            moment()
                .subtract(1, 'month')
                .startOf('month')
                .toDate(),
        endDate: () =>
            moment()
                .subtract(1, 'month')
                .endOf('month')
                .toDate(),
    },
]

const PaginableSortableTable = createPaginableSortableTable(
    fragmentSpec,
    refetchQuery
)

class UserTransactions extends Component {
    state = {
        dateFilter: '',
        statusFilters: [],
        isDateFilterOpened: false,
        isStatusFiltersSelectOpened: false,
        startDate: null,
        endDate: null,
        customStartDate: moment()
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
        customEndDate: moment().format('YYYY-MM-DD'),
    }

    dateFilters = keyBy(dateFilters, 'name')
    dateFilterMenuItems = dateFilters.map(filter => (
        <MenuItem value={filter.name}>{filter.label}</MenuItem>
    ))

    table = React.createRef()

    handleDateFilterChange = event => {
        const { value } = event.target

        if (!isUndefined(value)) {
            this.changeDateFilter({
                value,
                startDate: result(this.dateFilters[value], 'startDate'),
                endDate: result(this.dateFilters[value], 'endDate'),
            })
        }
    }

    handleChange = name => event =>
        this.setState({ [name]: event.target.value })

    changeDateFilter = ({ startDate, endDate, value }) => {
        this.setState({
            isDateFilterOpened: false,
            dateFilter: value,
            startDate,
            endDate,
        })

        this.table.current._refetchDebounced()
    }

    applyCustomDateFilter = () => {
        this.changeDateFilter({
            startDate: new Date(this.state.customStartDate),
            endDate: new Date(this.state.customEndDate),
            value: 'custom',
        })
    }

    get isApplyCustomDateEnabled() {
        return this.state.customStartDate && this.state.customEndDate
    }

    toggleDateFilterSelect = () => {
        this.setState(state => ({
            isDateFilterOpened: !state.isDateFilterOpened,
        }))
    }

    customDateFormat = date => moment(date).format('DD.MM.YYYY')

    renderSelectValue = value => {
        if (value === 'custom') {
            const { customStartDate, customEndDate } = this.state
            return `From ${this.customDateFormat(
                customStartDate
            )} to ${this.customDateFormat(customEndDate)}`
        }

        return this.dateFilters[value].label
    }

    getDateFilters = ({ isLoading }) => {
        return (
            <FormControl
                className={this.classes.dateFilter}
                disabled={isLoading}
            >
                <TextField
                    select
                    label="Date"
                    SelectProps={{
                        open: this.state.isDateFilterOpened,
                        onOpen: this.toggleDateFilterSelect,
                        onClose: () => {}, // this.toggleDateFilterSelect,
                        onChange: this.handleDateFilterChange,
                        renderValue: this.renderSelectValue,
                    }}
                    value={this.state.dateFilter}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <DateRangeIcon />
                            </InputAdornment>
                        ),
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {this.dateFilterMenuItems}
                    <div className={this.classes.customDateContainer}>
                        Custom date
                        <div className={this.classes.customDate}>
                            <TextField
                                value={this.state.customStartDate}
                                onChange={this.handleChange('customStartDate')}
                                label="Start date"
                                placeholder="DD.MM.YYYY"
                                type="date"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                value={this.state.customEndDate}
                                onChange={this.handleChange('customEndDate')}
                                label="End date"
                                placeholder="DD.MM.YYYY"
                                type="date"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                            />
                        </div>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={this.applyCustomDateFilter}
                            disabled={!this.isApplyCustomDateEnabled}
                        >
                            Apply custom
                        </Button>
                    </div>
                </TextField>
            </FormControl>
        )
    }

    handleStatusFilterDelete = value => {
        const statusFilters = [...this.state.statusFilters]
        pull(statusFilters, value)
        this.setState({ statusFilters }, this.table.current._refetchDebounced)
    }

    handleStatusFilterChange = event => {
        this.setState(
            {
                statusFilters: event.target.value,
                isStatusFiltersSelectOpened: false,
            },
            this.table.current._refetchDebounced
        )
    }

    handleStatusFilterSelectToggle = () => {
        this.setState(state => ({
            isStatusFiltersSelectOpened: !state.isStatusFiltersSelectOpened,
        }))
    }

    getStatusFilter = ({ isLoading }) => {
        const id = 'select-status-type'

        return (
            <FormControl
                className={this.classes.statusFilter}
                disabled={isLoading}
            >
                <InputLabel htmlFor={id}>Statuses</InputLabel>
                <Select
                    multiple
                    value={this.state.statusFilters}
                    onChange={this.handleStatusFilterChange}
                    onClose={this.handleStatusFilterSelectToggle}
                    onOpen={this.handleStatusFilterSelectToggle}
                    input={<Input id={id} />}
                    open={this.state.isStatusFiltersSelectOpened}
                    renderValue={selected => (
                        <div>
                            {selected.map(value => (
                                <Chip
                                    key={value}
                                    label={itemStatusNames[value]}
                                    className={this.classes.filterChip}
                                    onDelete={this.handleStatusFilterDelete.bind(
                                        this,
                                        value
                                    )}
                                />
                            ))}
                        </div>
                    )}
                >
                    {itemStatusesList.map(name => (
                        <MenuItem key={`StatusType-${name}`} value={name}>
                            {itemStatusNames[name]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }

    get classes() {
        return this.props.classes
    }

    columnConfig = getColumnsConfig(this.classes)

    getAdditionalFragmentVariables = () => {
        const { startDate, endDate, statusFilters } = this.state
        return pickBy(
            {
                startDate,
                endDate,
                statuses: statusFilters,
            },
            identity
        )
    }

    getToolbarTop = options => {
        return (
            <Fragment>
                {this.getDateFilters(options)}
                {this.getStatusFilter(options)}
            </Fragment>
        )
    }

    render() {
        return (
            <div className={this.props.className}>
                <PaginableSortableTable
                    dataGetter="inventory.edges"
                    countGetter="inventory.count"
                    innerRef={this.table}
                    columnsConfig={this.columnConfig}
                    data={this.props.user || []}
                    toolbarTop={this.getToolbarTop}
                    getAdditionalFragmentVariables={
                        this.getAdditionalFragmentVariables
                    }
                />
            </div>
        )
    }
}

const styles = {
    productListView: {
        fontWeight: 'bold',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        color: 'black',
        transition: 'all 0.2s',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
    productImageListView: {
        width: 70,
        marginRight: 15,
    },
    verticalCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    noWordWrap: {
        whiteSpace: 'nowrap',
    },
    itemStatus: {
        display: 'table-cell',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        fontSize: '0.5em',
        color: 'white',
        padding: 10,
        borderRadius: 20,
        fontWeight: 'bolder',
    },
    itemNew: {
        backgroundColor: '#45d4b6',
    },
    itemSold: {
        backgroundColor: '#ffad0d',
    },
    itemWithdrawn: {
        backgroundColor: '#00dcff',
    },
    dateFilter: {
        minWidth: 200,
        marginRight: 15,
    },
    statusFilter: {
        minWidth: 200,
    },
    customDateContainer: {
        padding: '15px 15px 0 15px',
        outline: 'none',
    },
    customDate: {
        padding: '15px 0 10px 0',
    },
    filterChip: {
        margin: '0 5px',
        textTransform: 'uppercase',
    },
}

export default withStyles(styles)(UserTransactions)
