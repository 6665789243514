import React, { PureComponent } from 'react'
import { createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import isBoolean from 'lodash/isBoolean'
import isFunction from 'lodash/isFunction'
import format from 'date-fns/format'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import TablePagination from '@material-ui/core/TablePagination'

import withStyles from '@material-ui/core/styles/withStyles'
import UserMiniPageContainer from '../UserMiniPage/UserMiniPageContainer'

const dataTypes = {
    LINK: 'LINK',
    MINI_PAGE: 'MINI_PAGE',
    DYNAMIC: 'DYNAMIC',
}

const columns = [
    {
        label: 'Column',
        getter: 'column',
    },
    {
        label: 'Value',
        getter: 'value',
        type: dataTypes.DYNAMIC,
    },
    {
        label: 'Modified By',
        getter: 'modifiedByUserCuid',
        type: dataTypes.MINI_PAGE,
        options: {
            to: '/users/{{value}}',
        },
    },
    {
        label: 'Notes',
        getter: 'notes',
    },
    {
        label: 'Modified On',
        getter: value =>
            format(new Date(value.createdAt), 'dd.MM.yyyy kk:mm:ss OOOO'),
    },
]

const getCell = (row, columnConfig) => {
    const { getter } = columnConfig
    const value = isFunction(getter) ? getter(row) : get(row, getter, '')

    const wrapperValue = (() => {
        if (columnConfig.type === dataTypes.DYNAMIC && isBoolean(value)) {
            return <Checkbox checked={value} disabled />
        }

        if ([dataTypes.LINK, dataTypes.MINI_PAGE].includes(columnConfig.type)) {
            const link = get(columnConfig, 'options.to', '').replace(
                '{{value}}',
                value
            )

            switch (columnConfig.type) {
                case dataTypes.LINK:
                    return <Link to={link}>{value}</Link>

                case dataTypes.MINI_PAGE:
                    return (
                        <UserMiniPageContainer userId={value} linkTo={link} />
                    )
                default:
                    return value
            }
        }

        return typeof value === 'object'
            ? JSON.stringify(value, null, '\t')
            : value
    })()

    const key = `${row.id}-${columnConfig.getter}`

    return <TableCell key={key}>{wrapperValue}</TableCell>
}

class UserLog extends PureComponent {
    state = {
        rowsPerPage: 10,
        page: 0,
    }

    handleChangePage = (e, page) => {
        this.setState({ page })
    }

    handleChangeRowsPerPage = e => {
        this.setState({ rowsPerPage: e.target.value })
    }

    get noData() {
        return (
            <div className={this.props.classes.centeredContainer}>No data.</div>
        )
    }

    get header() {
        return (
            <TableRow>
                {columns.map(column => (
                    <TableCell key={column.getter}>{column.label}</TableCell>
                ))}
            </TableRow>
        )
    }

    get body() {
        const { rowsPerPage, page } = this.state
        const { logs } = this.props

        return logs.edges
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(edge => (
                <TableRow key={edge.node.id}>
                    {columns.map(column => getCell(edge.node, column))}
                </TableRow>
            ))
    }

    render() {
        const { rowsPerPage, page } = this.state
        const { logs, classes } = this.props

        return (
            <Paper className={classes.tableContainer}>
                <TablePagination
                    component="div"
                    count={logs.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <Table>
                    <TableHead>{this.header}</TableHead>
                    <TableBody>{this.body}</TableBody>
                </Table>
                {logs.count === 0 && this.noData}
            </Paper>
        )
    }
}

const styles = {
    centeredContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
    },
    tableContainer: {
        overflow: 'scroll',
    },
}

export default createFragmentContainer(withStyles(styles)(UserLog), {
    logs: graphql`
        fragment UserLog_logs on AdminManagementLogConnection {
            count
            edges {
                node {
                    id
                    column
                    value
                    modifiedByUserCuid
                    createdAt
                    notes
                }
            }
        }
    `,
})
