import React, { createContext, useContext, useCallback } from 'react'
import {
    ReactRelayContext,
    commitMutation,
    commitLocalUpdate,
} from 'react-relay'
import noop from 'lodash/noop'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'

import { useModals } from '../../modals'
const RelayUtilsContext = createContext({
    commitMutation: noop,
})

export const RelayUtilsProvider = props => {
    const { environment = props.environment } = useContext(ReactRelayContext)
    const modals = useModals()

    return (
        <RelayUtilsContext.Provider
            value={{
                commitLocalUpdate: useCallback(
                    updater => commitLocalUpdate(environment, updater),
                    [environment]
                ),
                commitMutation: ({
                    mutation,
                    variables,
                    onCompleted,
                    onError,
                    ...rest
                }) =>
                    new Promise((resolve, reject) =>
                        commitMutation(environment, {
                            mutation,
                            variables,
                            onCompleted(result, error) {
                                const rootFieldName = get(
                                    mutation(),
                                    'operation.selections.0.name',
                                    ''
                                )
                                const errors = get(
                                    result,
                                    `${rootFieldName}.errors`,
                                    []
                                )

                                if ((errors && errors.length > 0) || error) {
                                    reject()
                                    console.error(errors)
                                    modals.openErrorModal({
                                        error: get(
                                            errors,
                                            '0.message',
                                            get(errors, '0.errorCode', error)
                                        ),
                                        isContactAdminShown: !!error,
                                    })
                                    return
                                }

                                if (isFunction(onCompleted)) {
                                    onCompleted(result, error)
                                }

                                resolve(get(result, rootFieldName))
                            },
                            onError(error) {
                                if (error) {
                                    console.error(error)
                                    modals.openErrorModal({
                                        isContactAdminShown: true,
                                        error: error.res
                                            ? JSON.stringify(
                                                  error.res.json,
                                                  null,
                                                  '\t'
                                              ) || error.res.text
                                            : error.message,
                                    })
                                }

                                if (isFunction(onError)) {
                                    onError(error)
                                }

                                reject(error)
                            },
                            ...rest,
                        })
                    ),
            }}
            {...props}
        />
    )
}

export const useRelayUtils = () => useContext(RelayUtilsContext)
export const withRelayUtils = Component => props => {
    const relayUtils = useRelayUtils()
    return <Component {...props} relayUtils={relayUtils} />
}
