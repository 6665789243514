/**
 * @flow
 * @relayHash 2be8da1c57814189ff762a9a407528e4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserTransactions_data$ref = any;
export type AdminManagementOrderDirections = "ASC" | "DESC" | "%future added value";
export type UserTransactionsRefetchQueryVariables = {|
  userId: string,
  orderBy?: ?string,
  orderDirection?: ?AdminManagementOrderDirections,
  first?: ?number,
  skip?: ?number,
  types?: ?$ReadOnlyArray<?string>,
  paymentSystem?: ?string,
  paymentMethod?: ?string,
  source?: ?string,
|};
export type UserTransactionsRefetchQueryResponse = {|
  +adminManagement: ?{|
    +user: ?{|
      +$fragmentRefs: UserTransactions_data$ref
    |}
  |}
|};
export type UserTransactionsRefetchQuery = {|
  variables: UserTransactionsRefetchQueryVariables,
  response: UserTransactionsRefetchQueryResponse,
|};
*/


/*
query UserTransactionsRefetchQuery(
  $userId: String!
  $orderBy: String
  $orderDirection: AdminManagementOrderDirections
  $first: Int
  $skip: Int
  $types: [String]
  $paymentSystem: String
  $paymentMethod: String
  $source: String
) {
  adminManagement {
    user(userId: $userId) {
      ...UserTransactions_data_1G1nGT
      id
    }
    id
  }
}

fragment UserTransactions_data_1G1nGT on AdminManagementUser {
  rows: transactions(orderBy: $orderBy, orderDirection: $orderDirection, first: $first, skip: $skip, types: $types, paymentSystem: $paymentSystem, paymentMethod: $paymentMethod, source: $source) {
    count
    sum
    depositsSum
    winProductsPricesSum
    edges {
      node {
        id
        cuid
        createdAt
        actionType
        details
        amount
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "userId",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderDirection",
    "type": "AdminManagementOrderDirections",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "skip",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "types",
    "type": "[String]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "paymentSystem",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "paymentMethod",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "source",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "orderDirection",
    "variableName": "orderDirection"
  },
  {
    "kind": "Variable",
    "name": "paymentMethod",
    "variableName": "paymentMethod"
  },
  {
    "kind": "Variable",
    "name": "paymentSystem",
    "variableName": "paymentSystem"
  },
  {
    "kind": "Variable",
    "name": "skip",
    "variableName": "skip"
  },
  {
    "kind": "Variable",
    "name": "source",
    "variableName": "source"
  },
  {
    "kind": "Variable",
    "name": "types",
    "variableName": "types"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserTransactionsRefetchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "adminManagement",
        "storageKey": null,
        "args": null,
        "concreteType": "AdminManagement",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "AdminManagementUser",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "UserTransactions_data",
                "args": (v2/*: any*/)
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserTransactionsRefetchQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "adminManagement",
        "storageKey": null,
        "args": null,
        "concreteType": "AdminManagement",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "AdminManagementUser",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": "rows",
                "name": "transactions",
                "storageKey": null,
                "args": (v2/*: any*/),
                "concreteType": "AdminManagementTransactionConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "count",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "sum",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "depositsSum",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "winProductsPricesSum",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AdminManagementTransactionEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AdminManagementTransaction",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "cuid",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdAt",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "actionType",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "details",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "amount",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v3/*: any*/)
            ]
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserTransactionsRefetchQuery",
    "id": null,
    "text": "query UserTransactionsRefetchQuery(\n  $userId: String!\n  $orderBy: String\n  $orderDirection: AdminManagementOrderDirections\n  $first: Int\n  $skip: Int\n  $types: [String]\n  $paymentSystem: String\n  $paymentMethod: String\n  $source: String\n) {\n  adminManagement {\n    user(userId: $userId) {\n      ...UserTransactions_data_1G1nGT\n      id\n    }\n    id\n  }\n}\n\nfragment UserTransactions_data_1G1nGT on AdminManagementUser {\n  rows: transactions(orderBy: $orderBy, orderDirection: $orderDirection, first: $first, skip: $skip, types: $types, paymentSystem: $paymentSystem, paymentMethod: $paymentMethod, source: $source) {\n    count\n    sum\n    depositsSum\n    winProductsPricesSum\n    edges {\n      node {\n        id\n        cuid\n        createdAt\n        actionType\n        details\n        amount\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '412f7508f10371140cfa9a62fe71fc2b';
module.exports = node;
