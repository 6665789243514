/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserTransactions_data$ref: FragmentReference;
declare export opaque type UserTransactions_data$fragmentType: UserTransactions_data$ref;
export type UserTransactions_data = {|
  +rows: ?{|
    +count: ?number,
    +sum: ?number,
    +depositsSum: ?number,
    +winProductsPricesSum: ?number,
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +cuid: ?string,
        +createdAt: ?any,
        +actionType: ?string,
        +details: ?any,
        +amount: ?number,
      |}
    |}>,
  |},
  +$refType: UserTransactions_data$ref,
|};
export type UserTransactions_data$data = UserTransactions_data;
export type UserTransactions_data$key = {
  +$data?: UserTransactions_data$data,
  +$fragmentRefs: UserTransactions_data$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "UserTransactions_data",
  "type": "AdminManagementUser",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 100
    },
    {
      "kind": "LocalArgument",
      "name": "skip",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "orderBy",
      "type": "String",
      "defaultValue": "createdAt"
    },
    {
      "kind": "LocalArgument",
      "name": "orderDirection",
      "type": "AdminManagementOrderDirections",
      "defaultValue": "DESC"
    },
    {
      "kind": "LocalArgument",
      "name": "types",
      "type": "[String]",
      "defaultValue": []
    },
    {
      "kind": "LocalArgument",
      "name": "paymentSystem",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "paymentMethod",
      "type": "String",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "source",
      "type": "String",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "rows",
      "name": "transactions",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "orderDirection",
          "variableName": "orderDirection"
        },
        {
          "kind": "Variable",
          "name": "paymentMethod",
          "variableName": "paymentMethod"
        },
        {
          "kind": "Variable",
          "name": "paymentSystem",
          "variableName": "paymentSystem"
        },
        {
          "kind": "Variable",
          "name": "skip",
          "variableName": "skip"
        },
        {
          "kind": "Variable",
          "name": "source",
          "variableName": "source"
        },
        {
          "kind": "Variable",
          "name": "types",
          "variableName": "types"
        }
      ],
      "concreteType": "AdminManagementTransactionConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sum",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "depositsSum",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "winProductsPricesSum",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "AdminManagementTransactionEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "AdminManagementTransaction",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "cuid",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "actionType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "details",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "amount",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '3b4ee778b8bbd23a634bb87316aa53f5';
module.exports = node;
