import React from 'react'
import { useQuery } from 'relay-hooks'
import graphql from 'babel-plugin-relay/macro'
import get from 'lodash/get'

import ContainerError from '../../Users/UserPage/UserInventory/UserInventoryContainer'
import Loader from '../../Users/UsersList/UsersContainer'
import { Logs } from './Logs'

const __DEV__ = process.env.NODE_ENV === 'development'

const LogsContainerQuery = graphql`
    query LogsContainerQuery($first: Int, $skip: Int) {
        bankingManagement {
            logs(first: $first, offset: $skip)
                @connection(key: "Logs_logs", filters: ["first", "skip"]) {
                edges {
                    node {
                        id
                        column
                        payment
                        value
                        oldValue
                        createdAt
                        createdByCuid
                        description
                    }
                }
                ...Logs_data
            }
        }
    }
`

const LogsContainer = externalProps => {
    const { props, error } = useQuery({
        query: LogsContainerQuery,
        variables: {
            first: 100,
            skip: 0,
        },
    })

    if (error) {
        console.error(error)

        if (__DEV__) {
            return <div>{error.message}</div>
        }

        return <ContainerError />
    }

    if (props) {
        const logs = get(props, 'bankingManagement.logs')
        return <Logs {...externalProps} logs={logs} />
    }

    return <Loader />
}

export { LogsContainer }
