import React, { Fragment, PureComponent, createRef } from 'react'
import { createRefetchContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import get from 'lodash/get'
import map from 'lodash/map'
import pick from 'lodash/pick'
import isFunction from 'lodash/isFunction'
import debounce from 'lodash/debounce'
import Highlight from 'react-highlight-words'
import { Link, withRouter } from 'react-router-dom'
import format from 'date-fns/format'
import styled from 'styled-components'

import { withStyles } from '@material-ui/core/styles'

import { Textsms as TextBubbleIcon } from '@material-ui/icons'
import red from '@material-ui/core/colors/red'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import Toolbar from '@material-ui/core/Toolbar'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'

// import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import CircularProgress from '@material-ui/core/CircularProgress'

import CloseIcon from '@material-ui/icons/Close'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import WarningIcon from '@material-ui/icons/Warning'
import PersonIcon from '@material-ui/icons/Person'
import SearchIcon from '@material-ui/icons/Search'

import green from '@material-ui/core/colors/green'
import yellow from '@material-ui/core/colors/yellow'

import { PremiumBadgeIcon } from '../../../shared/icons'
import ActionsContainer from '../../../shared/components/ActionsContainer'
import { withModals } from '../../../shared/modals'

const OrderDirections = {
    ASC: 'asc',
    DESC: 'desc',
}

const UsersActionsContainer = styled.div`
    margin-left: 5px;
`

class UsersList extends PureComponent {
    state = {
        page: 0,
        rowsPerPage: 25,
        orderBy: 'createdAt',
        orderDirection: OrderDirections.DESC,
        isLoading: false,
        searchText: '',
    }

    constructor(...props) {
        super(...props)

        this.keyDown = debounce(this.keyDown.bind(this), 100)
    }

    searchInput = createRef()
    classes = this.props.classes

    static dataTypes = {
        NUMERIC: 0,
        TEXT: 1,
        BOOLEAN: 2,
        HIGHLIGHTED_TEXT: 3,
        HIGHLIGHTED_LINK: 4,
        LINK: 5,
    }

    columnsConfig = [
        {
            label: 'ID',
            type: UsersList.dataTypes.HIGHLIGHTED_LINK,
            href: 'users/{param}',
            getter: 'cuid',
        },
        {
            label: 'Name',
            type: UsersList.dataTypes.HIGHLIGHTED_TEXT,
            getter: 'name',
        },
        {
            label: 'Is VIP',
            getter: 'premium',
            processor: value =>
                value &&
                new Date(value.expirationDate) >= new Date() && (
                    <PremiumBadgeIcon
                        className={this.classes.premiumBadge}
                        fontSize="large"
                    />
                ),
        },
        {
            label: 'Email',
            type: UsersList.dataTypes.HIGHLIGHTED_TEXT,
            getter: 'email',
        },
        {
            label: 'Email verified',
            getter: 'isVerified',
            processor: value =>
                value ? (
                    <VerifiedUserIcon className={this.classes.verifiedIcon} />
                ) : (
                    <WarningIcon className={this.classes.warningIcon} />
                ),
        },
        {
            label: 'Social email',
            type: UsersList.dataTypes.HIGHLIGHTED_TEXT,
            getter: 'socialEmail',
        },
        {
            label: 'Facebook ID',
            type: UsersList.dataTypes.TEXT,
            getter: 'facebookId',
        },
        { label: 'Role', type: UsersList.dataTypes.TEXT, getter: 'role' },
        {
            label: 'Boost',
            type: UsersList.dataTypes.BOOLEAN,
            getter: 'isBlogger',
        },
        {
            label: 'Is Blocked',
            type: UsersList.dataTypes.BOOLEAN,
            getter: 'isBlocked',
        },
        {
            label: 'Is Deleted',
            type: UsersList.dataTypes.BOOLEAN,
            getter: 'isDeleted',
        },
        {
            label: 'Account type',
            type: UsersList.dataTypes.TEXT,
            getter: 'accountType',
        },
        {
            label: 'Created At',
            type: UsersList.dataTypes.TEXT,
            getter: 'createdAt',
            processor: value =>
                format(new Date(value), 'dd MM yyyy kk:mm:ss OOOO'),
        },
        {
            label: 'Deleted At',
            type: UsersList.dataTypes.TEXT,
            getter: 'deletedAt',
            processor: value =>
                value
                    ? format(new Date(value), 'dd MM yyyy kk:mm:ss OOOO')
                    : '-',
        },
    ]

    actions = [
        {
            name: 'analyzeSupportCommunication',
            label: 'Analyze support communication',
            icon: <TextBubbleIcon />,
            isEnabled: true,
            action: () => {
                this.props.openAnalyzeSupportModal({
                    onAnalyze: this.props.addContactSupport,
                })
            },
        },
    ]

    getColumnContentByType = (row, columnConfig) => {
        const { searchText } = this.state
        const { getter } = columnConfig
        let value = isFunction(getter) ? getter(row) : get(row, getter, '')

        if (isFunction(columnConfig.processor)) {
            value = columnConfig.processor(value)
        }

        const getColumnByType = (value, type) => {
            switch (type) {
                case UsersList.dataTypes.BOOLEAN:
                    return <Checkbox checked={value} disabled />

                case UsersList.dataTypes.HIGHLIGHTED_TEXT:
                    return value && searchText ? (
                        <Highlight
                            textToHighlight={value}
                            searchWords={[searchText]}
                            autoEscape
                        />
                    ) : (
                        value
                    )

                case UsersList.dataTypes.HIGHLIGHTED_LINK:
                    return (
                        <Link to={columnConfig.href.replace('{param}', value)}>
                            {getColumnByType(
                                value,
                                UsersList.dataTypes.HIGHLIGHTED_TEXT
                            )}
                        </Link>
                    )

                default:
                    return value
            }
        }

        return getColumnByType(value, columnConfig.type)
    }

    get data() {
        return map(get(this.props, 'adminManagement.users.edges', []), 'node')
    }

    get count() {
        return get(this.props, 'adminManagement.users.count')
    }

    get tableToolbar() {
        return (
            <Fragment>
                <div className={this.classes.toolbarContainer}>
                    <Typography variant="h6" className={this.classes.title}>
                        Users (<PersonIcon /> {this.count})
                        <UsersActionsContainer>
                            <ActionsContainer actions={this.actions} />
                        </UsersActionsContainer>
                    </Typography>
                    <FormControl>
                        <div className={this.classes.searchBlock}>
                            <InputLabel htmlFor="search-input">
                                Search text
                            </InputLabel>
                            <Input
                                fullWidth
                                id="search-input"
                                value={this.state.searchText}
                                onChange={this.handleSearchInput}
                                onKeyDown={this.handleSearchKeyPress}
                                endAdornment={
                                    <InputAdornment position="start">
                                        {this.state.searchText && (
                                            <CloseIcon
                                                onClick={this.clearSearch}
                                            />
                                        )}
                                    </InputAdornment>
                                }
                                inputRef={this.searchInput}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                className={this.classes.searchButton}
                                disabled={this.state.isLoading}
                                onClick={this.handleSearch}
                            >
                                <SearchIcon /> Search
                            </Button>
                        </div>
                    </FormControl>
                </div>
            </Fragment>
        )
    }

    get tableHeaders() {
        // const { orderBy, orderDirection } = this.state

        return (
            <TableRow>
                {this.columnsConfig.map(column => (
                    <TableCell sortDirection="desc" key={column.getter}>
                        {/*<Tooltip*/}
                        {/*title="Sort"*/}
                        {/*placement="bottom-start"*/}
                        {/*enterDelay={300}*/}
                        {/*PopperProps={{*/}
                        {/*anchorEl: document.getElementsByTagName('html')*/}
                        {/*}}*/}
                        {/*>*/}
                        {/*<TableSortLabel*/}
                        {/*active={orderBy === column.getter}*/}
                        {/*direction={orderDirection}*/}
                        {/*onClick={this.handleSort.bind(this, column.getter)}*/}
                        {/*>*/}
                        {column.label}
                        {/*</TableSortLabel>*/}
                        {/*</Tooltip>*/}
                    </TableCell>
                ))}
            </TableRow>
        )
    }

    get tableRows() {
        return this.data.map(row => (
            <TableRow
                key={row.cuid}
                data-id={row.cuid}
                onDoubleClick={this.goToUserPage}
                hover
                style={
                    row.isDeleted && {
                        backgroundColor: red[100],
                    }
                }
            >
                {this.columnsConfig.map(columnConfig => (
                    <TableCell key={columnConfig.getter}>
                        {this.getColumnContentByType(row, columnConfig)}
                    </TableCell>
                ))}
            </TableRow>
        ))
    }

    get tablePagination() {
        const { page, rowsPerPage, isLoading } = this.state

        return (
            <TablePagination
                component="div"
                count={this.count}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{ disabled: isLoading || page <= 0 }}
                nextIconButtonProps={{ disabled: isLoading }}
                SelectProps={{ disabled: isLoading }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPageOptions={[25, 50, 100]}
            />
        )
    }

    get loader() {
        return (
            <div className={this.classes.centeredContainer}>
                <CircularProgress />
            </div>
        )
    }

    get noData() {
        return <div className={this.classes.centeredContainer}>No data.</div>
    }

    handleChangePage = (event, page) => {
        this.setState({ page }, () => {
            this.refetch()
            this.saveListState()
        })
    }

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value }, () => {
            this.refetch()
            this.saveListState()
        })
    }

    handleSearchKeyPress = event => {
        if (event.key === 'Escape') {
            this.clearSearch()
            return
        }
        if (event.key !== 'Enter') {
            return
        }

        this.handleSearch()
    }

    handleSearchInput = event => {
        this.setState({ searchText: event.target.value })
    }

    handleSearch = () => {
        this.setState({ page: 0 }, () => {
            this.saveListState()
            this.refetch()
        })
    }

    handleSort = columnKey => {
        if (this.state.isLoading) {
            return
        }

        this.setState(
            state => ({
                orderBy: columnKey,
                orderDirection:
                    state.orderBy === columnKey &&
                    state.orderDirection === OrderDirections.DESC
                        ? OrderDirections.ASC
                        : OrderDirections.DESC,
            }),
            () => {
                this.refetch()
                this.saveListState()
            }
        )
    }

    refetch = () => {
        const {
            page,
            rowsPerPage,
            orderDirection,
            orderBy,
            searchText,
        } = this.state

        this.setState({ isLoading: true }, () => {
            this.props.relay.refetch(
                fragmentVariables => ({
                    ...fragmentVariables,
                    first: rowsPerPage,
                    offset: page * rowsPerPage,
                    orderDirection: orderDirection.toUpperCase(),
                    orderBy,
                    searchText,
                }),
                null,
                this.onLoadEnd,
                { force: true }
            )
        })
    }

    saveListState = () => {
        const listState = pick(this.state, [
            'page',
            'rowsPerPage',
            'orderBy',
            'orderDirection',
            'searchText',
        ])

        if (listState) {
            sessionStorage.setItem('userList', JSON.stringify(listState))
        }
    }

    onLoadEnd = () => {
        this.setState({ isLoading: false })
    }

    goToUserPage = event => {
        const id = event.currentTarget.getAttribute('data-id')
        this.props.history.push(`/users/${id}`)
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDown)
    }

    componentDidMount() {
        const listState = sessionStorage.getItem('userList')

        if (!listState) {
            return
        }

        try {
            this.setState({
                ...this.state,
                ...JSON.parse(listState),
            })
        } catch (e) {}

        window.addEventListener('keydown', this.keyDown)
    }

    keyDown = e => {
        if (e.ctrlKey && (e.key === 'f' || e.key === 'F')) {
            e.preventDefault()
            this.searchInput.current.focus()
        }
    }

    clearSearch = () => {
        this.setState({ searchText: '' }, () => {
            this.saveListState()
            this.refetch()
        })
    }

    render() {
        const { isLoading } = this.state

        return (
            <Fragment>
                <Paper className={this.classes.sectionHeader}>
                    <Toolbar>{this.tableToolbar}</Toolbar>
                </Paper>
                <Paper>
                    {this.tablePagination}
                    <div className={this.classes.tableContainer}>
                        <Table>
                            <TableHead>{this.tableHeaders}</TableHead>
                            <TableBody>
                                {!isLoading && this.tableRows}
                            </TableBody>
                        </Table>
                    </div>
                    {this.data.length === 0 && !isLoading && this.noData}
                    {isLoading && this.loader}
                </Paper>
            </Fragment>
        )
    }
}

const styles = theme => ({
    sectionHeader: {
        marginBottom: 15,
    },
    spacer: {
        flex: '1 1 100%',
    },
    title: {
        flex: '0 0 auto',
        alignItems: 'center',
        display: 'flex',
    },
    centeredContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
    },
    tableContainer: {
        overflow: 'auto',
        clear: 'both',
    },
    toolbarContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        padding: '15px 0',
        justifyContent: 'space-between',
        '& > *': {
            'flex-grow': 1,
        },
    },
    searchBlock: {
        display: 'flex',
        alignItems: 'end',
    },
    searchButton: {
        margin: theme.spacing.unit,
    },
    verifiedIcon: {
        color: green['A700'],
    },
    warningIcon: {
        color: yellow['A700'],
    },
    premiumBadge: {
        color: yellow['A700'],
    },
})

export default createRefetchContainer(
    withModals(withRouter(withStyles(styles)(UsersList))),
    {
        adminManagement: graphql`
            fragment UsersList_adminManagement on AdminManagement
                @argumentDefinitions(
                    first: { type: "Int", defaultValue: 25 }
                    offset: { type: "Int", defaultValue: 0 }
                    orderBy: { type: "String", defaultValue: "" }
                    orderDirection: {
                        type: "AdminManagementOrderDirections"
                        defaultValue: "DESC"
                    }
                    searchText: { type: "String", defaultValue: "" }
                ) {
                users(
                    orderBy: $orderBy
                    orderDirection: $orderDirection
                    first: $first
                    offset: $offset
                    searchText: $searchText
                ) {
                    count
                    edges {
                        node {
                            id
                            cuid
                            facebookId
                            name
                            email
                            socialEmail
                            role
                            isBlogger
                            isBlocked
                            accountType
                            createdAt
                            isVerified
                            premium {
                                type
                                expirationDate
                            }
                            isDeleted
                            deletedAt
                        }
                    }
                }
            }
        `,
    },
    graphql`
        query UsersListRefetchQuery(
            $orderBy: String
            $orderDirection: AdminManagementOrderDirections
            $first: Int
            $offset: Int
            $searchText: String
        ) {
            adminManagement {
                ...UsersList_adminManagement
                    @arguments(
                        orderBy: $orderBy
                        orderDirection: $orderDirection
                        first: $first
                        offset: $offset
                        searchText: $searchText
                    )
            }
        }
    `
)
