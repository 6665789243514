import React, { Component, Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createRefetchContainer } from 'react-relay'
import { Link } from 'react-router-dom'

import Paper from '@material-ui/core/Paper/Paper'
import Typography from '@material-ui/core/Typography/Typography'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableBody from '@material-ui/core/TableBody/TableBody'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination/TablePagination'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { withStyles } from '@material-ui/core'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import {
    TextField,
    Button,
    IconButton,
    RootRef,
    Checkbox,
} from '@material-ui/core'
import {
    FirstPage as FirstPageIcon,
    LastPage as LastPageIcon,
    KeyboardArrowLeft,
    KeyboardArrowRight,
} from '@material-ui/icons'

import moment from 'moment'
import Sticky from 'react-sticky-el'
import classNames from 'classnames'

import camelCase from 'lodash/camelCase'
import debounce from 'lodash/debounce'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import map from 'lodash/map'
import get from 'lodash/get'
import isBoolean from 'lodash/isBoolean'

import UserMiniPageContainer from '../../../modules/Users/UserMiniPage/UserMiniPageContainer'

export const columnTypes = {
    DATE_TIME: 'DATE_TIME',
    DATE_TIME_1: 'DATE_TIME_1',
    TEXT: 'TEXT',
    NUMBER: 'NUMBER',
    IMAGE: 'IMAGE',
    MONEY: 'MONEY',
    DYNAMIC: 'DYNAMIC',
    MINI_PAGE: 'MINI_PAGE',
    LINK: 'LINK',
}

export const orderDirections = {
    ASC: 'asc',
    DESC: 'desc',
}

const PaginationActions = ({
    page,
    onChangePage,
    count,
    rowsPerPage,
    classes,
}) => {
    const [value, setPage] = useState(page + 1)
    useEffect(() => setPage(page + 1), [page])

    const handleSubmitChangePage = e => onChangePage(e, value - 1)
    const handleEnterPress = e => {
        if (e.key === 'Enter') {
            handleSubmitChangePage(e)
        }
    }
    const handleFirstPageButtonClick = e => {
        onChangePage(e, 0)
    }

    const handleBackButtonClick = e => {
        onChangePage(e, page - 1)
    }

    const handleNextButtonClick = e => {
        onChangePage(e, page + 1)
    }

    const handleLastPageButtonClick = e => {
        onChangePage(e, Math.floor(count / rowsPerPage))
    }

    return (
        <Fragment>
            <Tooltip title="First page" aria-label="First page">
                <div>
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                        aria-label="First Page"
                    >
                        <FirstPageIcon />
                    </IconButton>
                </div>
            </Tooltip>
            <Tooltip title="Previous page" aria-label="Previous page">
                <div>
                    <IconButton
                        onClick={handleBackButtonClick}
                        disabled={page === 0}
                        aria-label="Previous Page"
                    >
                        <KeyboardArrowLeft />
                    </IconButton>
                </div>
            </Tooltip>
            <Tooltip title="Next page" aria-label="Next page">
                <div>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.floor(count / rowsPerPage)}
                        aria-label="Next Page"
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                </div>
            </Tooltip>
            <Tooltip title="Last page" aria-label="Last page">
                <div>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.floor(count / rowsPerPage)}
                        aria-label="Last Page"
                    >
                        <LastPageIcon />
                    </IconButton>
                </div>
            </Tooltip>
            Page:
            <TextField
                className={classes.goToPageInput}
                value={value}
                onKeyPress={handleEnterPress}
                onChange={e => setPage(parseInt(e.target.value, 10))}
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Button
                variant="contained"
                size="small"
                color="primary"
                disabled={page + 1 === value}
                onClick={handleSubmitChangePage}
            >
                Go
            </Button>
        </Fragment>
    )
}

const actionsStyles = {
    goToPageInput: {
        margin: '0 10px',
    },
}

const PaginationActionsStyles = withStyles(actionsStyles)(PaginationActions)

export const createPaginableSortableTable = (fragmentSpec, refetchQuery) => {
    class PaginableSortableTable extends Component {
        state = {
            page: 0,
            rowsPerPage: this.props.rowsPerPage[2],
            orderBy: this.props.initialOrderBy,
            orderDirection: orderDirections.DESC,
            isLoading: false,
        }

        table = React.createRef()

        constructor(...args) {
            super(...args)
            this._refetch = this._refetch.bind(this)
            this._refetchDebounced = debounce(this._refetch.bind(this), 1000, {
                leading: false,
                trailing: true,
            })
        }

        formatCell(columnConfig, value) {
            const { type } = columnConfig
            switch (type) {
                case columnTypes.DATE_TIME:
                    return value ? moment(value).format('LLL') : '-'

                case columnTypes.DATE_TIME_1:
                    return value ? (
                        <Fragment>
                            <div className={this.classes.dateTime1Date}>
                                {moment(value).format('DD.MM.YYYY')}
                            </div>
                            <div className={this.classes.dateTime1Time}>
                                {moment(value).format('HH:mm:ss Z')}
                            </div>
                        </Fragment>
                    ) : (
                        '-'
                    )

                case columnTypes.IMAGE:
                    return <img src={value} alt="" />

                case columnTypes.MONEY:
                    return (
                        <b className={this.classes.verticalCenteredContainer}>
                            <AttachMoneyIcon fontSize="small" />
                            {value}
                        </b>
                    )

                case columnTypes.DYNAMIC:
                    if (isBoolean(value)) {
                        return <Checkbox checked={value} disabled />
                    }

                    return JSON.stringify(value)

                case columnTypes.MINI_PAGE:
                case columnTypes.LINK:
                    const link = get(columnConfig, 'options.to', '').replace(
                        '{{value}}',
                        value
                    )

                    if (type === columnTypes.LINK) {
                        return <Link to={link}>{value}</Link>
                    }

                    return (
                        <UserMiniPageContainer userId={value} linkTo={link} />
                    )

                default:
                    return value
            }
        }

        get count() {
            const { countGetter } = this.props

            if (isFunction(countGetter)) {
                return countGetter(this.props)
            }

            const path = isString(countGetter) ? countGetter : 'rows.count'

            return get(this.props, `data.${path}`, 0)
        }

        get tableHead() {
            const { orderBy, orderDirection } = this.state

            return (
                <TableRow>
                    {this.props.columnsConfig.map(config => {
                        const label = config.sortable ? (
                            <TableSortLabel
                                active={orderBy === config.name}
                                direction={orderDirection}
                                onClick={this.handleSort.bind(
                                    this,
                                    config.name
                                )}
                            >
                                {config.label}
                            </TableSortLabel>
                        ) : (
                            <span>{config.label}</span>
                        )

                        return (
                            <TableCell
                                key={`TableColumnLabelFor${camelCase(
                                    config.label
                                )}`}
                            >
                                {config.sortable ? (
                                    <Tooltip
                                        title={`Sort by ${config.label}`}
                                        placement="bottom-start"
                                        enterDelay={300}
                                    >
                                        {label}
                                    </Tooltip>
                                ) : (
                                    label
                                )}
                            </TableCell>
                        )
                    })}
                </TableRow>
            )
        }

        get classes() {
            return this.props.classes
        }

        get data() {
            const { dataGetter } = this.props

            if (isFunction(dataGetter)) {
                return dataGetter(this.props)
            }

            return map(
                get(
                    this.props,
                    `data.${isString(dataGetter) ? dataGetter : 'rows.edges'}`,
                    []
                ),
                'node'
            )
        }

        get tableBody() {
            return this.data.map(row => (
                <TableRow key={get(row, this.props.primaryColumn)}>
                    {this.props.columnsConfig.map(config => (
                        <TableCell
                            key={`TableCellFor${camelCase(config.label)}${get(
                                row,
                                this.props.primaryColumn
                            )}`}
                        >
                            {this.formatCell(
                                config,
                                isFunction(config.getter)
                                    ? config.getter(row)
                                    : get(row, config.getter)
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            ))
        }

        get pagination() {
            const { page, rowsPerPage } = this.state

            return (
                <TablePagination
                    component="div"
                    count={this.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    rowsPerPageOptions={this.props.rowsPerPage}
                    ActionsComponent={PaginationActionsStyles}
                />
            )
        }

        get loader() {
            return (
                <div className={this.classes.centeredContainer}>
                    <CircularProgress />
                </div>
            )
        }

        get noData() {
            return (
                <div className={this.classes.centeredContainer}>No data.</div>
            )
        }

        get toolbarBottom() {
            const { toolbarBottom } = this.props
            return isFunction(toolbarBottom)
                ? toolbarBottom({
                      isLoading: this.state.isLoading,
                      data: this.props.data,
                  })
                : toolbarBottom
        }

        get toolbarTop() {
            const { toolbarTop } = this.props
            return isFunction(toolbarTop)
                ? toolbarTop({
                      isLoading: this.state.isLoading,
                      data: this.props.data,
                  })
                : toolbarTop
        }

        handleChangePage = (event, page) => {
            this.setState({ page }, this._refetchDebounced)
        }

        handleChangeRowsPerPage = event => {
            this.setState(
                { rowsPerPage: event.target.value },
                this._refetchDebounced
            )
        }

        handleSort = columnKey => {
            if (this.state.isLoading) {
                return
            }

            this.setState(
                state => ({
                    orderBy: columnKey,
                    orderDirection:
                        state.orderBy === columnKey &&
                        state.orderDirection === orderDirections.DESC
                            ? orderDirections.ASC
                            : orderDirections.DESC,
                }),
                this._refetchDebounced
            )
        }

        _refetch() {
            const { page, rowsPerPage, orderDirection, orderBy } = this.state

            this.setState({ isLoading: true }, () => {
                this.props.relay.refetch(
                    fragmentVariables => ({
                        ...fragmentVariables,
                        first: rowsPerPage,
                        skip: page * rowsPerPage,
                        orderDirection: orderDirection.toUpperCase(),
                        orderBy,
                        ...this.props.getAdditionalFragmentVariables(
                            this.state
                        ),
                    }),
                    null,
                    this.onLoadEnd,
                    { force: true }
                )
            })
        }

        onLoadEnd = () => {
            this.setState({ isLoading: false }, () => {
                this.table.current.scrollIntoView()
            })
        }

        render() {
            const { isLoading } = this.state
            const { title } = this.props

            return (
                <RootRef rootRef={this.table}>
                    <Paper className={this.classes.tableContainer}>
                        {title && (
                            <Typography
                                variant="h6"
                                className={this.classes.titleContainer}
                            >
                                {title}
                            </Typography>
                        )}
                        <div className={this.classes.tableToolbarTopContainer}>
                            {this.toolbarTop}
                        </div>
                        <Table>
                            <TableHead>{this.tableHead}</TableHead>
                            <TableBody>
                                {!isLoading && this.tableBody}
                            </TableBody>
                        </Table>
                        {this.data.length === 0 && !isLoading && this.noData}
                        {isLoading && this.loader}
                        <Sticky
                            mode="bottom"
                            stickyClassName={classNames(
                                'sticky',
                                this.classes.tableToolbarContainerSticky
                            )}
                        >
                            <div
                                className={
                                    this.classes.tableToolbarBottomContainer
                                }
                            >
                                {this.toolbarBottom}
                                <div
                                    className={this.classes.paginationContainer}
                                >
                                    {this.pagination}
                                </div>
                            </div>
                        </Sticky>
                    </Paper>
                </RootRef>
            )
        }
    }

    PaginableSortableTable.propTypes = {
        columnsConfig: PropTypes.array.isRequired,
        countGetter: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        data: PropTypes.object.isRequired,
        dataGetter: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
        getAdditionalFragmentVariables: PropTypes.func,
        initialOrderBy: PropTypes.string,
        primaryColumn: PropTypes.string.isRequired,
        rowsPerPage: PropTypes.arrayOf(PropTypes.number).isRequired,
        title: PropTypes.string,
        toolbarBottom: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
        toolbarTop: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    }

    PaginableSortableTable.defaultProps = {
        columnsConfig: [],
        data: {},
        getAdditionalFragmentVariables: () => ({}),
        initialOrderBy: 'createdAt',
        primaryColumn: 'id',
        rowsPerPage: [25, 50, 100, 250, 500, 1000],
        toolbarBottom: null,
        toolbarTop: null,
    }

    const styles = {
        tableContainer: {
            overflow: 'scroll',
        },
        titleContainer: {
            display: 'flex',
            justifyContent: 'center',
            padding: '15px 15px 0 15px',
        },
        verticalCenteredContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        centeredContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 15,
        },
        tableToolbarTopContainer: {
            display: 'flex',
            padding: 15,
        },
        tableToolbarBottomContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: Number.MAX_SAFE_INTEGER,
            backgroundColor: 'white',
        },
        tableToolbarContainerSticky: {
            boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
        },
        paginationContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        dateTime1Time: {
            color: 'grey',
        },
    }

    return createRefetchContainer(
        withStyles(styles)(PaginableSortableTable),
        fragmentSpec,
        refetchQuery
    )
}
