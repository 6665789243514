/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AdminManagementKycStatus = "accepted" | "declined" | "pending" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserDeliveryInfo_deliveryInfo$ref: FragmentReference;
declare export opaque type UserDeliveryInfo_deliveryInfo$fragmentType: UserDeliveryInfo_deliveryInfo$ref;
export type UserDeliveryInfo_deliveryInfo = {|
  +edges: ?$ReadOnlyArray<?{|
    +node: ?{|
      +id: string,
      +cuid: ?string,
      +default: ?boolean,
      +apartment: ?string,
      +building: ?string,
      +city: ?string,
      +country: ?string,
      +region: ?string,
      +phone: ?string,
      +street: ?string,
      +zipCode: ?string,
      +firstName: ?string,
      +lastName: ?string,
      +CPF: ?string,
      +kycStatus: ?AdminManagementKycStatus,
      +isKycRequired: ?boolean,
    |}
  |}>,
  +$refType: UserDeliveryInfo_deliveryInfo$ref,
|};
export type UserDeliveryInfo_deliveryInfo$data = UserDeliveryInfo_deliveryInfo;
export type UserDeliveryInfo_deliveryInfo$key = {
  +$data?: UserDeliveryInfo_deliveryInfo$data,
  +$fragmentRefs: UserDeliveryInfo_deliveryInfo$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "UserDeliveryInfo_deliveryInfo",
  "type": "AdminManagementDeliveryInfoConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "AdminManagementDeliveryInfoEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "AdminManagementDeliveryInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cuid",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "default",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "apartment",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "building",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "city",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "country",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "region",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "phone",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "street",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "zipCode",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "CPF",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "kycStatus",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "isKycRequired",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0d5284176ae356a66cdc364def09ff24';
module.exports = node;
