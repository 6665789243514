import React, { useMemo, useCallback, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFragment } from 'relay-hooks'
import graphql from 'babel-plugin-relay/macro'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import compose from 'lodash/fp/compose'
import fpUniq from 'lodash/fp/uniq'
import fpMap from 'lodash/fp/map'
import fpSortBy from 'lodash/fp/sortBy'
import identity from 'lodash/identity'

import DeleteIcon from '@material-ui/icons/Delete'
import ShowChartIcon from '@material-ui/icons/ShowChart'

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    FormControlLabel,
    Switch,
    Typography,
    Tooltip,
    Button,
    TextField,
    InputAdornment,
    MenuItem,
} from '@material-ui/core'
import {
    ExpandMore as ExpandMoreIcon,
    Payment as PaymentIcon,
    Add as AddIcon,
    Search as SearchIcon,
} from '@material-ui/icons'

import { Payment } from './Payment'
import BaseSection from '../../../shared/components/BaseSection'
import IconButton from '@material-ui/core/IconButton'
import { useModals } from '../../../shared/modals'

const PaymentTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`

const ToolbarContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px 0;
    > *:not(:last-child) {
        margin-right: 20px;
        margin-bottom: 10px;
    }
`

const Toolbar = ({
    canEdit,
    onCreate,
    onSearch,
    payments,
    onPaymentGroupSelect,
}) => {
    const paymentGroups = useMemo(
        () =>
            compose(
                fpSortBy(identity),
                fpUniq,
                fpMap('paymentGroup')
            )(payments),
        [payments]
    )

    const handleSearch = useCallback(
        e => {
            if (onSearch) {
                onSearch(e.target.value.toLowerCase())
            }
        },
        [onSearch]
    )

    const [selected, setSelected] = useState(null)
    const handleSelect = useCallback(
        e => {
            const { value } = e.target

            setSelected(value)
            if (onPaymentGroupSelect) {
                onPaymentGroupSelect(value)
            }
        },
        [onPaymentGroupSelect, setSelected]
    )

    return (
        <ToolbarContainer>
            <TextField
                select
                label="Payment system"
                margin="dense"
                style={{ width: 233 }}
                InputLabelProps={{ shrink: true }}
                value={selected}
                onChange={handleSelect}
            >
                <MenuItem key="all" value={null}>
                    All
                </MenuItem>
                {paymentGroups.map(group => (
                    <MenuItem key={group} value={group}>
                        {group}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                label="Search"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    inputComponent: DebounceInput,
                    inputProps: {
                        debounceTimeout: 500,
                    },
                }}
                margin="dense"
                onChange={handleSearch}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={onCreate}
                disabled={!canEdit}
            >
                Create new
                <AddIcon />
            </Button>
        </ToolbarContainer>
    )
}

export const Payments = ({
    payments,
    save,
    add,
    cancelAdd,
    addStats,
    remove,
    currentUser,
}) => {
    const paymentProviders = useFragment(
        graphql`
            fragment Payments_payments on BankingManagementPayment
                @relay(plural: true) {
                id
                name
                shortName
                isEnabled
                order
                excludedCountries
                allowedCountries
                suggested
                suggestAll
                forceMethod
                paymentGroup
                minAmount
                maxAmount
                amountSteps
                icon
                currency
                isNew
            }
        `,
        payments
    ).filter(payment => payment)

    const orderedPayments = orderBy(
        paymentProviders,
        ['isNew', 'isEnabled', 'order'],
        ['asc', 'desc', 'desc']
    )

    const canEdit = useMemo(() => get(currentUser, 'role') === 'superAdmin', [
        currentUser,
    ])

    const [expandedPanel, setExpandedPanel] = useState(null)
    const handleExpanded = useCallback(
        panel => (_, expanded) => {
            setExpandedPanel(expanded ? panel : null)
        },
        [setExpandedPanel]
    )

    const [searchText, setSearchText] = useState('')
    const [selectedPaymentGroup, setSelectedPaymentGroup] = useState(null)

    const canAddStats = useMemo(
        () =>
            get(currentUser, 'role') === 'superAdmin' ||
            get(currentUser, 'roles', []).includes('paymentReportManager'),
        [currentUser]
    )

    const { openAddPaymentStatsModal } = useModals()

    const actions = useMemo(
        () => [
            {
                name: 'addPaymentStats',
                label: 'Add payment stats',
                icon: <ShowChartIcon />,
                isEnabled: canAddStats,
                action: () => {
                    openAddPaymentStatsModal({ onConfirm: addStats })
                },
            },
        ],
        [canAddStats, openAddPaymentStatsModal, addStats]
    )

    return (
        <BaseSection
            title="Payments"
            count={orderedPayments.length}
            icon={<PaymentIcon />}
            toolbar={
                <Toolbar
                    onCreate={add}
                    canEdit={canEdit}
                    onSearch={setSearchText}
                    payments={orderedPayments}
                    onPaymentGroupSelect={setSelectedPaymentGroup}
                />
            }
            actions={actions}
        >
            {orderedPayments
                .filter(({ paymentGroup }) =>
                    selectedPaymentGroup
                        ? selectedPaymentGroup === paymentGroup
                        : true
                )
                .filter(({ shortName, name, paymentGroup, forceMethod }) =>
                    searchText
                        ? (shortName &&
                              shortName.toLowerCase().includes(searchText)) ||
                          (name && name.toLowerCase().includes(searchText)) ||
                          (paymentGroup &&
                              paymentGroup
                                  .toLowerCase()
                                  .includes(searchText)) ||
                          (forceMethod &&
                              forceMethod.toLowerCase().includes(searchText))
                        : true
                )
                .map(payment => (
                    <ExpansionPanel
                        key={payment.shortName}
                        expanded={expandedPanel === payment.shortName}
                        {...(payment.isNew && { expanded: true })}
                        onChange={handleExpanded(payment.shortName)}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <PaymentTitleContainer>
                                <Typography variant="subtitle1">
                                    {payment.name}
                                </Typography>
                                <div>
                                    <Tooltip
                                        title={
                                            payment.isEnabled
                                                ? 'Disable'
                                                : 'Enable'
                                        }
                                        aria-label={
                                            payment.isEnabled
                                                ? 'Disable'
                                                : 'Enable'
                                        }
                                    >
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={payment.isEnabled}
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                    }}
                                                    onChange={e => {
                                                        e.stopPropagation()
                                                        save({
                                                            id: payment.id,
                                                            shortName:
                                                                payment.shortName,
                                                            isEnabled:
                                                                e.target
                                                                    .checked,
                                                        })
                                                    }}
                                                    disabled={!canEdit}
                                                />
                                            }
                                            label="Is enabled"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton
                                            disabled={!canEdit}
                                            onClick={e => {
                                                e.stopPropagation()
                                                remove({
                                                    id: payment.id,
                                                    name: payment.name,
                                                })
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </PaymentTitleContainer>
                        </ExpansionPanelSummary>
                        {(expandedPanel === payment.shortName ||
                            payment.isNew) && (
                            <Payment
                                payment={payment}
                                save={save}
                                cancelAdd={cancelAdd}
                                canEdit={canEdit}
                            />
                        )}
                    </ExpansionPanel>
                ))}
        </BaseSection>
    )
}

Payments.propTypes = {
    currentUser: PropTypes.shape({
        role: PropTypes.string,
    }),
    payments: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            shortName: PropTypes.string,
            isEnabled: PropTypes.bool,
            order: PropTypes.number,
            excludedCountries: PropTypes.arrayOf(PropTypes.string),
            allowedCountries: PropTypes.arrayOf(PropTypes.string),
            forceMethod: PropTypes.string,
            paymentGroup: PropTypes.string,
        })
    ),
    save: PropTypes.func.isRequired,
    add: PropTypes.func.isRequired,
    cancelAdd: PropTypes.func.isRequired,
    addStats: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
}
