import graphql from 'babel-plugin-relay/macro'

export default graphql`
    mutation DeleteUserMutation(
        $input: AdminManagementDeleteUserInput!
        $userCuid: String!
    ) {
        adminManagementDeleteUser(input: $input) {
            user {
                isDeleted
                deletedAt
                deletedBy
            }
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
            adminManagement {
                user(userId: $userCuid) {
                    isDeleted
                    deletedAt
                    deletedBy
                    logs {
                        ...UserLog_logs
                    }
                }
            }
        }
    }
`
