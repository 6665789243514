import React, { useCallback } from 'react'
import graphql from 'babel-plugin-relay/macro'

import Loader from '../../../components/Loader'
import ContainerError from '../../../components/ContainerError'
import UsersListQuery from './UsersListQuery'

import UsersList from './UsersList'
import { useRelayUtils } from '../../../shared/hooks/RelayUtils'
import { useQuery } from 'relay-hooks'
import { useSessionStorage } from '../../../shared/hooks'

const __DEV__ = process.env.NODE_ENV === 'development'

const AddContactsSupportMutation = graphql`
    mutation UsersContainerMutation(
        $input: AdminManagementAddContactsSupportInput!
    ) {
        adminManagementAddContactsSupport(input: $input) {
            success
            count
            whoMadeDepositsCount
            whoMadeDeposits
            depositsSum
            errors {
                code
                errorCode
                statusCode
                message
                meta
            }
        }
    }
`

const UsersContainer = () => {
    const getVariablesFromState = useCallback(
        ({
            rowsPerPage = 25,
            page = 0,
            orderDirection = 'desc',
            searchText = '',
            orderBy = 'createdAt',
        }) => ({
            first: rowsPerPage,
            offset: page * rowsPerPage,
            orderDirection: orderDirection.toUpperCase(),
            orderBy,
            searchText,
        }),
        []
    )

    const [listState] = useSessionStorage('userList', {})
    const variables = getVariablesFromState(listState)

    const { props, error } = useQuery({
        query: UsersListQuery,
        variables,
    })

    const { commitMutation } = useRelayUtils()

    const addContactSupport = useCallback(
        data =>
            commitMutation({
                mutation: AddContactsSupportMutation,
                variables: {
                    input: {
                        data,
                    },
                },
            }),
        [commitMutation]
    )

    if (error) {
        console.error(error)

        if (__DEV__) {
            return <div>{error.message}</div>
        }

        return <ContainerError />
    }

    if (props) {
        return (
            <UsersList
                adminManagement={props.adminManagement}
                addContactSupport={addContactSupport}
            />
        )
    }

    return <Loader />
}

export default UsersContainer
