import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import packageJson from '../package.json'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { RelayEnvironmentProvider } from 'relay-hooks'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import DateFnsUtils from '@date-io/date-fns'
import * as Sentry from '@sentry/browser'

import environment from './Environment'
import { Provider as ModalProvider } from './shared/modals'
import { RelayUtilsProvider } from './shared/hooks/RelayUtils'

import './index.css'
import App from './App'

const __DEV__ = process.env.NODE_ENV === 'development'

const { version, name } = packageJson

Sentry.init({
    dsn: (__DEV__ ? process.env : window).REACT_APP_SENTRY_DSN,
    release: `${name}@${version}`,
    environment: __DEV__ ? 'development' : 'production',
})

/**
 * https://github.com/skinholdings/drakemall-admin-front/issues/6
 */
// import registerServiceWorker from './registerServiceWorker'

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
})

ReactDOM.render(
    <RelayEnvironmentProvider environment={environment}>
        <ModalProvider>
            <RelayUtilsProvider>
                <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ModalProvider>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </ModalProvider>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </RelayUtilsProvider>
        </ModalProvider>
    </RelayEnvironmentProvider>,
    document.getElementById('root')
)

/**
 * https://github.com/skinholdings/drakemall-admin-front/issues/6
 */
// registerServiceWorker()
