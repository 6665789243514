/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MainApp_userManagement$ref: FragmentReference;
declare export opaque type MainApp_userManagement$fragmentType: MainApp_userManagement$ref;
export type MainApp_userManagement = {|
  +currentUser: ?{|
    +name: ?string,
    +avatarUrl: ?string,
  |},
  +$refType: MainApp_userManagement$ref,
|};
export type MainApp_userManagement$data = MainApp_userManagement;
export type MainApp_userManagement$key = {
  +$data?: MainApp_userManagement$data,
  +$fragmentRefs: MainApp_userManagement$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "MainApp_userManagement",
  "type": "UserManagement",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "currentUser",
      "storageKey": null,
      "args": null,
      "concreteType": "UserManagementUser",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "avatarUrl",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd8be733007017f5f5c4d02f62b7f72fc';
module.exports = node;
