import React, { Fragment, memo, useState, useCallback } from 'react'
import {
    DialogActions,
    DialogTitle,
    Button,
    CircularProgress,
    DialogContent,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    IconButton,
    Divider,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutlined'
import DeleteIcon from '@material-ui/icons/Delete'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import set from 'lodash/set'

import { DatePicker } from '../components/DatePicker'

const LoaderContainer = styled.div`
    margin-right: 5px;
    align-items: center;
    display: flex;
`

const ButtonIconContainer = styled.div`
    margin-left: 5px;
    display: flex;
    align-items: center;
`

const services = [
    {
        name: 'payop',
        label: 'PayOP',
    },
    {
        name: 'cardpay',
        label: 'CardPay',
    },
    {
        name: 'g2a',
        label: 'G2A',
    },
    {
        name: 'ecommpay',
        label: 'EcommPay',
    },
    {
        name: 'skinpay',
        label: 'Skinpay',
    },
    {
        name: 'coins_paid',
        label: 'Coinspaid',
    },
    {
        name: 'voucherifyio',
        label: 'Voucherify',
    },
]

const AddPaymentStats = ({ onConfirm, onClose, isLoading }) => {
    const [data, setData] = useState([
        {
            date: new Date(),
            paymentServiceName: services[0].name,
            refund: 0,
            chargeback: 0,
            hold: 0,
            fee: 0,
            RR: 0,
        },
    ])

    const existedServices = data.map(
        ({ paymentServiceName }) => paymentServiceName
    )

    const allowedServices = services.filter(
        ({ name }) => !existedServices.includes(name)
    )

    const handleSave = useCallback(() => {
        const result = data.reduce(
            (total, { date: rawDate, paymentServiceName, ...stats }) => {
                const date = rawDate.toISOString().split('T')[0]

                return ['refund', 'chargeback', 'hold', 'fee', 'RR'].reduce(
                    (paymentStats, statType) => {
                        paymentStats.push({
                            date,
                            paymentServiceName,
                            type: statType,
                            amount: parseFloat(stats[statType]) || 0,
                        })

                        return paymentStats
                    },
                    total
                )
            },
            []
        )
        onConfirm(result)
    }, [data, onConfirm])

    const handleChange = useCallback(
        (path, value) => {
            const newData = set([...data], path, value)
            setData(newData)
        },
        [data, setData]
    )

    const handlePaymentServiceChange = useCallback(
        (index, { target }) => {
            handleChange(`${index}.paymentServiceName`, target.value)
        },
        [handleChange]
    )

    const onChange = useCallback(
        e => {
            const index = e.target.getAttribute('data-index')
            handleChange(`${index}.${e.target.name}`, e.target.value)
        },
        [handleChange]
    )

    const handleAdd = useCallback(() => {
        setData([
            ...data,
            {
                date: new Date(),
                paymentServiceName: allowedServices[0].name,
                refund: 0,
                chargeback: 0,
                hold: 0,
                fee: 0,
                RR: 0,
            },
        ])
    }, [setData, data, allowedServices])

    const handleDelete = useCallback(
        index => {
            const newData = [...data]
            newData.splice(index, 1)
            setData(newData)
        },
        [setData, data]
    )

    return (
        <Fragment>
            <DialogTitle>Add payment stats</DialogTitle>
            <DialogContent>
                {data.map(
                    (
                        {
                            date,
                            paymentServiceName,
                            refund,
                            chargeback,
                            hold,
                            fee,
                            RR,
                        },
                        i
                    ) => (
                        <Fragment>
                            <Grid container spacing={8}>
                                <Grid item xs={11} container spacing={16}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl margin="dense" fullWidth>
                                            <InputLabel
                                                shrink
                                                htmlFor="payment-service"
                                            >
                                                Service
                                            </InputLabel>
                                            <Select
                                                inputProps={{
                                                    name: 'payment-service',
                                                    id: 'payment-service',
                                                    'data-index': i,
                                                }}
                                                value={paymentServiceName}
                                                onChange={handlePaymentServiceChange.bind(
                                                    null,
                                                    i
                                                )}
                                            >
                                                {[
                                                    ...allowedServices,
                                                    ...(paymentServiceName
                                                        ? [
                                                              services.find(
                                                                  ({ name }) =>
                                                                      name ===
                                                                      paymentServiceName
                                                              ),
                                                          ]
                                                        : []),
                                                ].map(({ name, label }) => (
                                                    <MenuItem value={name}>
                                                        {label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            label="Date"
                                            margin="dense"
                                            variant="inline"
                                            value={date}
                                            disableFuture
                                            fullWidth
                                            onChange={handleChange.bind(
                                                null,
                                                `${i}.date`
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    container
                                    spacing={16}
                                    alignItems="center"
                                >
                                    {data.length !== 1 && (
                                        <div>
                                            <IconButton
                                                onClick={handleDelete.bind(
                                                    null,
                                                    i
                                                )}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={6} md={2}>
                                    <TextField
                                        label="Refund"
                                        margin="dense"
                                        name="refund"
                                        inputProps={{
                                            type: 'number',
                                            step: 0.1,
                                            min: 0,
                                            'data-index': i,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        value={refund}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <TextField
                                        label="Chargeback"
                                        margin="dense"
                                        name="chargeback"
                                        inputProps={{
                                            type: 'number',
                                            step: 0.1,
                                            min: 0,
                                            'data-index': i,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        value={chargeback}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <TextField
                                        label="Hold"
                                        margin="dense"
                                        name="hold"
                                        inputProps={{
                                            type: 'number',
                                            step: 0.1,
                                            min: 0,
                                            'data-index': i,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        value={hold}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <TextField
                                        label="Fee"
                                        margin="dense"
                                        name="fee"
                                        inputProps={{
                                            type: 'number',
                                            step: 0.1,
                                            min: 0,
                                            'data-index': i,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        value={fee}
                                        onChange={onChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <TextField
                                        label="RR"
                                        margin="dense"
                                        name="RR"
                                        inputProps={{
                                            type: 'number',
                                            step: 0.1,
                                            min: 0,
                                            'data-index': i,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        value={RR}
                                        onChange={onChange}
                                    />
                                </Grid>
                            </Grid>
                            {i !== data.length - 1 && (
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            )}
                        </Fragment>
                    )
                )}
                {allowedServices.length !== 0 && (
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Button variant="contained" onClick={handleAdd}>
                                Add more
                                <ButtonIconContainer>
                                    <AddIcon />
                                </ButtonIconContainer>
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="contained">
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                    color="primary"
                    disabled={isLoading}
                >
                    {isLoading && (
                        <LoaderContainer>
                            <CircularProgress size={20} />
                        </LoaderContainer>
                    )}
                    Add
                </Button>
            </DialogActions>
        </Fragment>
    )
}

AddPaymentStats.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    isLoading: PropTypes.bool,
}

AddPaymentStats.defaultProps = {
    onClose: noop,
    onConfirm: noop,
    isLoading: false,
}

export default memo(AddPaymentStats)
