/**
 * @flow
 */

/* eslint-disable */

'use strict'

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Website_themes$ref: FragmentReference;
declare export opaque type Website_themes$fragmentType: Website_themes$ref;
export type Website_themes = $ReadOnlyArray<{|
  +id: string,
  +name: string,
  +startAt: ?any,
  +finishAt: ?any,
  +createdAt: ?any,
  +createdByUserCuid: ?string,
  +$refType: Website_themes$ref,
|}>;
export type Website_themes$data = Website_themes;
export type Website_themes$key = $ReadOnlyArray<{
  +$data?: Website_themes$data,
  +$fragmentRefs: Website_themes$ref,
}>;
*/

const node /*: ReaderFragment*/ = {
    kind: 'Fragment',
    name: 'Website_themes',
    type: 'AppManagementTheme',
    metadata: {
        plural: true,
    },
    argumentDefinitions: [],
    selections: [
        {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'name',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'startAt',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'finishAt',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'createdAt',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'createdByUserCuid',
            args: null,
            storageKey: null,
        },
    ],
}
// prettier-ignore
;(node/*: any*/).hash = '470c5e8f2df4602a4d60d06aa348475f';
module.exports = node
