import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import styled from 'styled-components'
import { CopyToClipboard } from '../components/CopyToClipboard'

const ErrorPreview = styled.div`
    max-height: 400px;
    white-space: pre;
    overflow: scroll;
`

const ErrorModal = ({ onClose, error, isContactAdminShown }) => (
    <Fragment>
        <DialogTitle>Error!</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {isContactAdminShown ? (
                    <Fragment>
                        Please, contact system administrator
                        <CopyToClipboard text={error}>
                            <ErrorPreview>{error}</ErrorPreview>
                        </CopyToClipboard>
                    </Fragment>
                ) : (
                    error
                )}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Ok
            </Button>
        </DialogActions>
    </Fragment>
)

ErrorModal.propTypes = {
    onClose: PropTypes.func,
    error: PropTypes.string.isRequired,
    isContactAdminShown: PropTypes.bool,
}

ErrorModal.defaultProps = {
    onClose: noop,
    isContactAdminShown: false,
}

export default ErrorModal
