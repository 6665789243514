import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core'
import React, { Fragment, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import styled from 'styled-components'
import { Lock as LockIcon } from '@material-ui/icons'

import Select from '../components/Select'
import { DateTimePicker } from '../components/DateTimePicker'

const Title = styled.div`
    display: flex;
    align-items: center;

    & > svg {
        margin-left: 10px;
    }
`

const InputsContainer = styled.div`
    padding: 10px 0;
    & > * {
        margin-bottom: 15px;
    }
`

const LoaderContainer = styled.div`
    margin-right: 5px;
    align-items: center;
    display: flex;
`

const blockReasons = [
    {
        name: 'under18YearsOld',
        label: 'Under 18 years old',
        value: 'UNDER_18_YEARS_OLD',
    },
    {
        name: 'multiAccounting',
        label: 'Multi-Accounting',
        value: 'MULTI_ACCOUNTING',
    },
    {
        name: 'chargebackFraud',
        label: 'Chargeback / Fraud',
        value: 'CHARGEBACK_FRAUD',
    },
    {
        name: 'suspiciousActivity',
        label: 'Suspicious activity',
        value: 'SUSPICIOUS_ACTIVITY',
    },
    {
        name: 'responsibleGambling',
        label: 'Responsible gambling',
        value: 'RESPONSIBLE_GAMBLING',
    },
    {
        name: 'customerRequest',
        label: 'Customer request',
        value: 'CUSTOMER_REQUEST',
    },
]

const OTHER_REASON = 'Other'

const BlockUserModal = ({ onClose, onConfirm, isLoading }) => {
    const [reason, setReason] = useState(blockReasons[0].value)
    const [expireAt, setExpireAt] = useState(null)
    const [textReason, setTestReason] = useState('')

    const handleReasonChange = useCallback(e => setReason(e.target.value), [
        setReason,
    ])
    const handleTextReasonChange = useCallback(
        e => setTestReason(e.target.value),
        [setTestReason]
    )
    const handleConfirm = useCallback(
        () =>
            onConfirm({
                reason: reason === OTHER_REASON ? textReason : reason,
                expireAt,
            }),
        [textReason, reason, expireAt, onConfirm]
    )

    return (
        <Fragment>
            <DialogTitle>
                <Title>
                    Block user <LockIcon fontSize="large" />
                </Title>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please, select block expire time (blank means permanently),
                    block type or{' '}
                    <i>
                        <b>Other</b>
                    </i>{' '}
                    to manually specify description (<b>text</b> or{' '}
                    <b>PhraseApp key</b>) (will be visible when user will try to
                    log in)
                </DialogContentText>
                <InputsContainer>
                    <DateTimePicker
                        autoFocus
                        label="Expire at"
                        value={expireAt}
                        onChange={setExpireAt}
                        disablePast
                        isClearable
                    />
                    <Select
                        options={[
                            ...blockReasons,
                            {
                                name: 'Other',
                                label: 'Other...',
                                value: OTHER_REASON,
                            },
                        ]}
                        value={reason}
                        onChange={handleReasonChange}
                        name="blockReason"
                        label="Reason"
                    />
                    {reason === OTHER_REASON && (
                        <TextField
                            multiline
                            rows={5}
                            rowsMax={10}
                            margin="dense"
                            id="blockReason"
                            label="Reason"
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={textReason}
                            onChange={handleTextReasonChange}
                        />
                    )}
                </InputsContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    {isLoading && (
                        <LoaderContainer>
                            <CircularProgress size={20} />
                        </LoaderContainer>
                    )}
                    Block
                </Button>
            </DialogActions>
        </Fragment>
    )
}

BlockUserModal.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    isLoading: PropTypes.bool,
}

BlockUserModal.defaultProps = {
    onClose: noop,
    onConfirm: noop,
    isLoading: false,
}

export default BlockUserModal
