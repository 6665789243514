import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    ExpansionPanelActions,
    ExpansionPanelDetails,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Switch,
    Tooltip,
    Typography,
} from '@material-ui/core'
import styled from 'styled-components'
import pick from 'lodash/pick'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import LaunchIcon from '@material-ui/icons/Launch'

import ImageUpload from '../../shared/components/ImageUpload'
import Loader from '../../shared/components/Loader'

const Container = styled.div`
    flex-grow: 1;
`

const InputLabelProps = { shrink: true }

const getPhraseAppUrl = key =>
    `https://app.phrase.com/accounts/ekingdom/projects/web-drakemall/editor?search=${btoa(
        JSON.stringify({
            filters: [],
            query: key,
            sorting: 'score',
            locale_id: '8dcded8313a1d88d1db38c96da0167e7',
        })
    )}&target_locale_id=8dcded8313a1d88d1db38c96da0167e7`

const getPhraseAppInputProps = key => ({
    endAdornment: (
        <InputAdornment position="end">
            <a
                href={getPhraseAppUrl(key)}
                target="_blank"
                rel="noopener noreferrer"
            >
                <IconButton color="primary">
                    <LaunchIcon fontSize="small" />
                </IconButton>
            </a>
        </InputAdornment>
    ),
})

const Promotion = ({ promotion, save, cancelAdd }) => {
    const [changed, setChanged] = useState(new Set())
    const [isSaving, setIsSaving] = useState(false)

    const [errors, setErrors] = useState({})
    const [entity, setEntity] = useState(promotion)

    const reset = useCallback(() => {
        setEntity(promotion)
        setChanged(new Set())
        setIsSaving(false)
    }, [promotion, setEntity, setChanged])

    useEffect(() => {
        reset()
    }, [promotion, reset])

    const handleChange = useCallback(
        e => {
            if (!e.target || !e.target.name) {
                return
            }
            const { checked, value, type, name } = e.target

            setChanged(new Set([...changed, name]))
            setEntity({
                ...entity,
                [name]:
                    type === 'checkbox'
                        ? checked
                        : type === 'number'
                        ? parseFloat(value)
                        : value,
            })
        },
        [entity, setEntity, changed]
    )

    const handleImageChange = useCallback(
        url => {
            setChanged(new Set([...changed, 'imageUrl']))
            setEntity({
                ...entity,
                imageUrl: url,
            })
            setErrors({
                ...errors,
                imageUrl: null,
            })
        },
        [entity, setEntity, changed, errors]
    )

    const handleSave = useCallback(async () => {
        if (entity.isNew) {
            const validationErrors = {}
            if (entity.title.length < 3) {
                validationErrors.title = 'Min length 3'
            }

            if (entity.title.length === 0) {
                validationErrors.title = 'Is required'
            }

            if (entity.description.length < 3) {
                validationErrors.description = 'Min length 3'
            }

            if (entity.description.length === 0) {
                validationErrors.description = 'Is required'
            }

            if (entity.imageUrl.length === 0) {
                validationErrors.imageUrl = 'Is required'
            }

            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors)
                return
            }
        }
        setIsSaving(true)
        await save(pick(entity, [...changed, 'slug']), entity)
        setErrors({})
    }, [save, entity, changed, setErrors, setIsSaving])

    const handleCancelAdd = useCallback(() => {
        cancelAdd({ slug: entity.slug })
    }, [cancelAdd, entity])

    const handleImageError = useCallback(
        e => {
            setErrors({
                ...errors,
                imageUrl: e.message,
            })
        },
        [errors]
    )

    return (
        <>
            <ExpansionPanelDetails>
                <Container>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                fullWidth
                                label="Title"
                                name="title"
                                onChange={handleChange}
                                value={entity.title}
                                InputProps={
                                    !entity.isNew
                                        ? getPhraseAppInputProps(entity.title)
                                        : {}
                                }
                                margin="normal"
                                InputLabelProps={InputLabelProps}
                                disabled={!entity.isNew || isSaving}
                                helperText={errors.title}
                                error={Boolean(errors.title)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                fullWidth
                                label="Description"
                                name="description"
                                onChange={handleChange}
                                value={entity.description}
                                InputProps={
                                    !entity.isNew
                                        ? getPhraseAppInputProps(
                                              entity.description
                                          )
                                        : {}
                                }
                                margin="normal"
                                InputLabelProps={InputLabelProps}
                                disabled={!entity.isNew || isSaving}
                                helperText={errors.description}
                                error={Boolean(errors.description)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                fullWidth
                                label="CTA label"
                                name="ctaLabel"
                                onChange={handleChange}
                                value={entity.ctaLabel || ''}
                                InputLabelProps={InputLabelProps}
                                InputProps={
                                    !entity.isNew
                                        ? getPhraseAppInputProps(
                                              entity.ctaLabel
                                          )
                                        : {}
                                }
                                margin="normal"
                                disabled={!entity.isNew || isSaving}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                fullWidth
                                label="CTA Url"
                                InputLabelProps={InputLabelProps}
                                value={entity.ctaUrl || ''}
                                margin="normal"
                                name="ctaUrl"
                                onChange={handleChange}
                                disabled={isSaving}
                                helperText={'Start with "/"'}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={3} lg={2}>
                            <TextField
                                fullWidth
                                type="number"
                                label="Order"
                                InputLabelProps={InputLabelProps}
                                value={entity.order}
                                margin="normal"
                                name="order"
                                onChange={handleChange}
                                disabled={isSaving}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} lg={1}>
                            <FormControl margin="normal">
                                <Tooltip
                                    title={
                                        entity.hasPage
                                            ? 'Without page'
                                            : 'With page'
                                    }
                                    aria-label={
                                        entity.hasPage
                                            ? 'Without page'
                                            : 'With page'
                                    }
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="hasPage"
                                                checked={entity.hasPage}
                                                onChange={handleChange}
                                                disabled={isSaving}
                                            />
                                        }
                                        label="With page"
                                    />
                                </Tooltip>
                            </FormControl>
                        </Grid>
                        {entity.hasPage && (
                            <>
                                <Grid item xs={12} md={6} lg={3}>
                                    <TextField
                                        fullWidth
                                        label="Page Url"
                                        InputLabelProps={InputLabelProps}
                                        margin="normal"
                                        value={entity.pageUrl || ''}
                                        name="pageUrl"
                                        onChange={handleChange}
                                        disabled={isSaving}
                                        helperText={'Start with "/"'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <TextField
                                        fullWidth
                                        label="Page SEO title"
                                        name="SEOTitle"
                                        onChange={handleChange}
                                        value={entity.SEOTitle}
                                        InputProps={
                                            !entity.isNew
                                                ? getPhraseAppInputProps(
                                                      entity.SEOTitle
                                                  )
                                                : {}
                                        }
                                        margin="normal"
                                        disabled={!entity.isNew || isSaving}
                                        InputLabelProps={InputLabelProps}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <TextField
                                        fullWidth
                                        label="Page SEO Description"
                                        name="SEODescription"
                                        onChange={handleChange}
                                        value={entity.SEODescription}
                                        InputProps={
                                            !entity.isNew
                                                ? getPhraseAppInputProps(
                                                      entity.SEODescription
                                                  )
                                                : {}
                                        }
                                        margin="normal"
                                        disabled={!entity.isNew || isSaving}
                                        InputLabelProps={InputLabelProps}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid container spacing={16}>
                        <Grid item xs={12} lg={6}>
                            <FormControl margin="normal">
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    Background
                                </Typography>
                                <ImageUpload
                                    defaultUrl={entity.imageUrl}
                                    styles={{ maxHeight: '300px' }}
                                    onChange={handleImageChange}
                                    onError={handleImageError}
                                    isDisabled={isSaving}
                                    maxSize={524288}
                                />
                                {errors.imageUrl && (
                                    <FormHelperText
                                        error={Boolean(errors.imageUrl)}
                                    >
                                        {errors.imageUrl}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Container>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
                <Button
                    color="secondary"
                    variant="contained"
                    disabled={!entity.isNew && !changed.size}
                    onClick={entity.isNew ? handleCancelAdd : reset}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={!changed.size || isSaving}
                    onClick={handleSave}
                >
                    Save
                    {isSaving && <Loader />}
                </Button>
            </ExpansionPanelActions>
        </>
    )
}

Promotion.propTypes = {
    save: PropTypes.func.isRequired,
    cancelAdd: PropTypes.func.isRequired,
    promotion: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        SEOTitle: PropTypes.string,
        SEODescription: PropTypes.string,
        pageUrl: PropTypes.string,
        ctaLabel: PropTypes.string,
        ctaUrl: PropTypes.string,
        hasBanner: PropTypes.bool.isRequired,
        isEnabled: PropTypes.bool.isRequired,
    }).isRequired,
}

export default Promotion
