import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import FileUploader from '../FileUploader'
import { Button } from '@material-ui/core'
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons'
import styled from 'styled-components'
import noop from 'lodash/noop'

const Container = styled.div`
    display: flex;

    > *:not(:last-child) {
        margin-right: 10px;
    }

    // TODO: Make configurable
    > img {
        max-height: ${({ maxHeight }) => maxHeight || '60px'};
        max-width: ${({ maxWidth }) => maxWidth || '150px'};
        border-radius: 10px;
    }
`

const ButtonIcon = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
`

const ImageUpload = ({
    defaultUrl,
    onChange,
    onError,
    isDisabled,
    styles,
    maxSize,
}) => {
    const [imageUrl, setImageUrl] = useState(defaultUrl)
    const handleUpload = useCallback(
        url => {
            setImageUrl(url)
            onChange(url)
        },
        [setImageUrl, onChange]
    )

    useEffect(() => {
        setImageUrl(defaultUrl)
    }, [defaultUrl])

    return (
        <Container {...styles}>
            <FileUploader
                accept="image/png"
                resultType="url"
                onUpload={handleUpload}
                onError={onError}
                maxSize={maxSize}
            >
                <Button
                    color="primary"
                    variant="contained"
                    disabled={isDisabled}
                >
                    Upload
                    <ButtonIcon>
                        <CloudUploadIcon />
                    </ButtonIcon>
                </Button>
            </FileUploader>
            {imageUrl && <img src={imageUrl} alt="" />}
        </Container>
    )
}

ImageUpload.propTypes = {
    defaultUrl: PropTypes.string,
    onChange: PropTypes.func,
    onError: PropTypes.func,
    isDisabled: PropTypes.bool,
    styles: PropTypes.object,
    maxSize: PropTypes.number,
}

ImageUpload.defaultProps = {
    defaultUrl: null,
    onChange: noop,
    onError: noop,
    isDisabled: false,
    styles: {},
    maxSize: null,
}

export default ImageUpload
