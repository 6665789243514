import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.pre`
    background-color: #fafafa;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
`

const JsonPreView = ({ data, children = null }) => (
    <Container>{JSON.stringify(data || children, null, '\t')}</Container>
)

JsonPreView.propTypes = {
    data: PropTypes.any,
    children: PropTypes.any,
}

export default JsonPreView
