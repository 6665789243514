import React, { PureComponent, Fragment } from 'react'
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
    withRouter,
} from 'react-router-dom'
import * as Sentry from '@sentry/browser'

/**
 * https://github.com/skinholdings/drakemall-admin-front/issues/7
 */
// import Loadable from 'react-loadable';
import { QueryRenderer } from 'react-relay'

import MainApp from './modules/MainApp'

import environment from './Environment'
import Loader from './components/Loader'
import ContainerError from './components/ContainerError'
import AppQuery from './AppQuery'
import SignIn from './modules/Auth/SignIn/SignInContainer'
import UserPageContainer from './modules/Users/UserPage/UserPageContainer'
import UsersContainer from './modules/Users/UsersList/UsersContainer'
import WebsiteContainer from './modules/Website/WebsiteContainer'
import { RedirectsContainer } from './modules/SEO'
import { PaymentsContainer } from './modules/Payments/Payments'
import { PromoContainer, GiveawayContainer } from './modules/Rewards'
import { LogsContainer } from './modules/Payments/Logs'
import ChatConfigContainer from './modules/Chat/ChatConfig'
import { AppContextProvider } from './modules/AppContext'

/**
 * https://github.com/skinholdings/drakemall-admin-front/issues/7
 */
// const UsersContainer = Loadable({
//     loader: () => import('./modules/Users/UsersList/UsersContainer'),
//     loading: Loader
// })
//
// const UserPageContainer = Loadable({
//     loader: () => import('./modules/Users/UserPage/UserPageContainer'),
//     loading: Loader
// })

const ADMIN_ROLES = ['admin', 'superAdmin']
const __DEV__ = process.env.NODE_ENV === 'development'

class App extends PureComponent {
    login = () => {
        const params = new URLSearchParams(this.props.location.search)
        const token = params.get('token')
        debugger

        localStorage.setItem('token', token)
        window.location.assign(params.get('redirect') || '/')
    }

    logout = () => {
        localStorage.removeItem('token')
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key])
            })
            Sentry.captureException(error)
        })
    }

    renderApp = ({ error, props }) => {
        if (error) {
            console.error(error)

            if (__DEV__) {
                return <div>{error.message}</div>
            }

            return <ContainerError />
        }

        if (props) {
            const currentUser = props?.userManagement?.currentUser
            const role = currentUser?.role
            const hasAccess = ADMIN_ROLES.includes(role)

            const isAccessError = localStorage.getItem('token') && !hasAccess

            if (isAccessError) {
                this.logout()
            }

            return hasAccess ? (
                <AppContextProvider value={{ currentUser }}>
                    <BrowserRouter>
                        <MainApp userManagement={props.userManagement}>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    component={UsersContainer}
                                />
                                <Route
                                    exact
                                    path="/themes"
                                    component={WebsiteContainer}
                                />
                                <Route
                                    exact
                                    path="/users/:userId"
                                    component={UserPageContainer}
                                />
                                <Route
                                    exact
                                    path="/users"
                                    component={UsersContainer}
                                />
                                <Route
                                    exact
                                    path="/redirects"
                                    component={RedirectsContainer}
                                />
                                <Route
                                    exact
                                    path="/payments/logs"
                                    component={LogsContainer}
                                />
                                <Route
                                    exact
                                    path="/payments"
                                    component={PaymentsContainer}
                                />
                                <Route
                                    exact
                                    path="/chat/blacklisted-phrases"
                                    component={ChatConfigContainer}
                                />
                                <Route
                                    exact
                                    path="/rewards/promo/:promo?"
                                    component={PromoContainer}
                                />
                                <Route
                                    exact
                                    path="/rewards/giveaways/:giveaway?"
                                    component={GiveawayContainer}
                                />
                                <Redirect to="/users" />
                            </Switch>
                        </MainApp>
                    </BrowserRouter>
                </AppContextProvider>
            ) : (
                <Fragment>
                    <Route exact path="/sign-in" render={this.login} />
                    <Redirect to="/" />
                    <SignIn
                        error={
                            isAccessError
                                ? 'Insufficient access rights. Please contact system administrators!'
                                : null
                        }
                    />
                </Fragment>
            )
        }

        return <Loader />
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={AppQuery}
                render={this.renderApp}
            />
        )
    }
}

export default withRouter(App)
