import React, { useState, useCallback } from 'react'
import graphql from 'babel-plugin-relay/macro'
import styled from 'styled-components/macro'
import { Switch, TextField, Fab } from '@material-ui/core'
import { Save as SaveIcon } from '@material-ui/icons'

import User from '../../../../models/User'
import { getCountryName } from '../../../../shared/countryCodes'
import {
    createSimpleTable,
    DataTypes,
} from '../../../../shared/components/SimpleTable'
import Loader from '../../../../shared/components/Loader'

const Container = styled.div`
    display: flex;
    min-width: 250px;

    > *:first-child {
        margin-right: 5px;
    }
`

const PhoneEdit = ({ onSave, cuid, ...props }) => {
    const [phone, setPhone] = useState(props.phone)
    const [isSaving, setIsSaving] = useState(false)

    const handlePhoneChange = useCallback(e => setPhone(e.target.value), [
        setPhone,
    ])

    const handleSave = useCallback(async () => {
        if (!onSave) {
            return
        }
        setIsSaving(true)
        try {
            await onSave({ cuid, phone })
        } finally {
            setIsSaving(false)
        }
    }, [onSave, phone, cuid, setIsSaving])

    return (
        <Container>
            <TextField value={phone} onChange={handlePhoneChange} />
            {isSaving ? (
                <Loader />
            ) : (
                <Fab
                    size="small"
                    color="primary"
                    disabled={phone === props.phone}
                    onClick={handleSave}
                >
                    <SaveIcon fontSize="small" />
                </Fab>
            )}
        </Container>
    )
}

const getColumns = props => [
    {
        label: 'KYC status',
        name: 'kycStatus',
    },
    {
        label: 'Is KYC required',
        name: 'isKycRequired',
        getter: ({ cuid, isKycRequired, kycStatus }) => (
            <Switch
                disabled={
                    isKycRequired || kycStatus === User.kycStatus.ACCEPTED
                }
                checked={isKycRequired}
                color="secondary"
                onChange={props.createRequireAddressKycHandler(cuid)}
            />
        ),
    },
    {
        label: 'Phone',
        name: 'phone',
        getter: ({ phone, cuid }) => (
            <PhoneEdit
                cuid={cuid}
                phone={phone}
                style={{ minWidth: 150 }}
                onSave={props.onSave}
            />
        ),
    },
    {
        label: 'Country',
        name: 'country',
        getter: ({ country }) => (country ? getCountryName(country) : country),
    },
    {
        label: 'City',
        name: 'city',
    },
    {
        label: 'Region',
        name: 'region',
    },
    {
        label: 'Street',
        name: 'street',
    },
    {
        label: 'Building',
        name: 'building',
    },
    {
        label: 'Apartment',
        name: 'apartment',
    },
    {
        label: 'Zip-code',
        name: 'zipCode',
    },
    {
        label: 'First name',
        name: 'firstName',
    },
    {
        label: 'Last name',
        name: 'lastName',
    },
    {
        label: 'CPF (only for Brazil)',
        name: 'CPF',
    },
    {
        label: 'Default',
        name: 'default',
        type: DataTypes.BOOLEAN,
    },
    {
        label: 'Cuid',
        name: 'cuid',
    },
]

const fragmentSpec = {
    deliveryInfo: graphql`
        fragment UserDeliveryInfo_deliveryInfo on AdminManagementDeliveryInfoConnection {
            edges {
                node {
                    id
                    cuid
                    default
                    apartment
                    building
                    city
                    country
                    region
                    phone
                    street
                    zipCode
                    firstName
                    lastName
                    CPF
                    kycStatus
                    isKycRequired
                }
            }
        }
    `,
}

const SimpleTable = createSimpleTable(fragmentSpec)

const UserDeliveryInfo = props => {
    return (
        <SimpleTable
            dataGetter="deliveryInfo.edges"
            dataMapper="node"
            columns={getColumns(props)}
            {...props}
        />
    )
}

export default UserDeliveryInfo
