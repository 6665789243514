import get from 'lodash/get'

import BaseEntity from './BaseEntity'

export default class User extends BaseEntity {
    name
    email
    facebookId
    role
    isBlogger
    isBlocked
    accountType
    notes
    phone
    dateOfBirth
    firstName
    lastName
    kycStatus
    isVerified

    constructor(props = {}) {
        super(props)
        this.accountType = get(props, 'accountType', User.accountTypes.GAMING)
        this.phone = props.phone || 'No phone'
        const dateOfBirth = Date.parse(props.dateOfBirth)
        this.dateOfBirth = dateOfBirth ? new Date(dateOfBirth) : 'No birth date'
        this.firstName = props.firstName || ''
        this.lastName = props.lastName || ''
        this.isVerified = props.isVerified
        this.kycStatus = props.kycStatus || 'unknown'
        this.isKycRequired = props.isKycRequired
        this.winItemMaxPrice = props.winItemMaxPrice
        this.winEpicItemMinPrice = props.winEpicItemMinPrice
        this.premium = props.premium
        this.isPremium =
            !!props.premium &&
            new Date(props.premium.expirationDate) >= new Date()
        this.blockedExpireAt = props.blockedExpireAt
            ? new Date(props.blockedExpireAt)
            : null
        this.isBlocked =
            (this.isBlocked && !this.blockedExpireAt) ||
            (this.isBlocked &&
                this.blockedExpireAt &&
                this.blockedExpireAt > new Date())
        this.isDeleted = Boolean(props?.isDeleted)
        this.isAMLRequired = get(props, 'aml.amlRequired', false)
        this.AMLStatus = get(props, 'aml.amlStatus', 'unknown')
    }

    static accountTypes = {
        GAMING: 'gaming',
        SPONSORED: 'sponsored',
        TEST: 'test',
    }

    static kycStatus = {
        ACCEPTED: 'accepted',
        DECLINED: 'declined',
        PENDING: 'pending',
    }

    static amlStatus = {
        ACCEPTED: 'accepted',
        DECLINED: 'declined',
        PENDING: 'pending',
    }
}
