import React, { Component } from 'react'
import { createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import PropTypes from 'prop-types'
import moment from 'moment'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import compose from 'lodash/fp/compose'
import validator from 'validator'

import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import withMobileDialog from '@material-ui/core/withMobileDialog'

export const balanceEditType = {
    WITHDRAW: 'withdraw',
    DEPOSIT: 'deposit',
}

class BalanceEditComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...this.initialState,
            validators: {
                category: [
                    {
                        validator: value =>
                            value && !validator.isEmpty(value.trim()),
                        message: 'Field is required',
                    },
                ],
                notes: [
                    {
                        validator: value => !validator.isEmpty(value.trim()),
                        message: 'Field is required',
                    },
                ],
                money: [
                    {
                        validator: value =>
                            parseFloat(value.replace(/,/g, '')) !== 0,
                        message: 'Field is required',
                    },
                    {
                        validator: value => {
                            return this.props.type === balanceEditType.WITHDRAW
                                ? parseFloat(value.replace(/,/g, '')) <=
                                      this.props.user.balance
                                : true
                        },
                        message: 'Not enough money on balance',
                    },
                ],
            },
        }
    }

    get initialState() {
        return {
            entity: {
                notes: '',
                money: '0',
                category: null,
            },
            validationErrors: {
                notes: [],
                money: [],
                category: [],
            },
        }
    }

    get defaultManualBalanceChangeReason() {
        return get(
            this.props,
            'adminManagement.manualBalanceChangeReasons.edges.0.node.cuid',
            ''
        )
    }

    get manualBalanceChangeReasons() {
        return this.props.adminManagement.manualBalanceChangeReasons.edges.map(
            reason => {
                const { cuid, value } = reason.node
                return (
                    <MenuItem
                        key={cuid}
                        value={cuid}
                        className={this.props.classes.menuItem}
                    >
                        {value}
                    </MenuItem>
                )
            }
        )
    }

    validate = () => {
        let isValid = true
        const validationErrors = reduce(
            this.state.validators,
            (validationResult, validators, fieldName) => {
                validationResult[fieldName] = []
                validators.forEach(validator => {
                    if (!validator.validator(this.state.entity[fieldName])) {
                        validationResult[fieldName].push(validator.message)
                        isValid = false
                    }
                })

                return validationResult
            },
            {}
        )

        this.setState({
            validationErrors,
        })

        return isValid
    }

    getEntityObject() {
        const { money, notes, category: categoryCuid } = this.state.entity
        return {
            money: parseFloat(money.replace(/,/g, '.')),
            notes,
            categoryCuid,
        }
    }

    editBalance = () => {
        if (this.validate()) {
            this.handleClose()
            this.props.editBalance(this.getEntityObject())
        }
    }

    handleClose = () => {
        this.setState(this.initialState, () => this.props.onClose())
    }

    handleChange = e => {
        const { name: fieldName, value } = e.target
        this.setState(state => ({
            entity: {
                ...state.entity,
                [fieldName]: value,
            },
        }))
    }

    render() {
        const { open, type } = this.props
        const {
            notes: [notesError],
            money: [moneyError],
            category: [categoryError],
        } = this.state.validationErrors

        let title = 'increase'
        let changeBalanceButtonLabel = 'Increase balance'
        let operationCaptionTitle = 'replenish'

        if (type === balanceEditType.WITHDRAW) {
            title = 'decrease'
            changeBalanceButtonLabel = 'Decrease balance'
            operationCaptionTitle = 'debit from'
        }

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                fullScreen={this.props.fullScreen}
            >
                <DialogTitle>Balance edit ({title})</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Amount of money to {operationCaptionTitle} balance
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Modified On"
                        fullWidth
                        value={moment(new Date())
                            .format('LLL')
                            .toString()}
                        disabled
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Modified By"
                        fullWidth
                        value={this.props.userManagement.currentUser.name}
                        disabled
                    />
                    <FormControl error={!!moneyError} fullWidth margin="dense">
                        <InputLabel
                            htmlFor={`${this.displayName}-money`}
                            shrink
                        >
                            Money
                        </InputLabel>
                        <Input
                            id={`${this.displayName}-money`}
                            name="money"
                            value={this.state.entity.money}
                            autoFocus
                            onChange={this.handleChange}
                            startAdornment={
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            }
                            inputProps={{
                                type: 'number',
                                step: 1,
                            }}
                        />
                        <FormHelperText id="money-error-text">
                            {moneyError}
                        </FormHelperText>
                    </FormControl>
                    <FormControl error={!!categoryError} fullWidth>
                        <InputLabel
                            htmlFor={`${this.displayName}-category`}
                            shrink
                        >
                            Category
                        </InputLabel>
                        <Select
                            inputProps={{
                                name: 'category',
                                id: `${this.displayName}-category`,
                            }}
                            onChange={this.handleChange}
                            value={this.state.entity.category}
                        >
                            {this.manualBalanceChangeReasons}
                        </Select>
                        <FormHelperText id="category-error-text">
                            {categoryError}
                        </FormHelperText>
                    </FormControl>

                    <FormControl error={!!notesError} fullWidth margin="dense">
                        <InputLabel
                            htmlFor={`${this.displayName}-notes`}
                            shrink
                        >
                            Notes
                        </InputLabel>
                        <Input
                            id={`${this.displayName}-notes`}
                            name="notes"
                            value={this.state.entity.notes}
                            onChange={this.handleChange}
                            multiline
                            rows={4}
                            rowsMax={8}
                        />
                        <FormHelperText id="notes-error-text">
                            {notesError}
                        </FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleClose}
                        color="secondary"
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button onClick={this.editBalance} color="primary">
                        {changeBalanceButtonLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

BalanceEditComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    editBalance: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.values(balanceEditType)),
    user: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,
}

const styles = {
    menuItem: {
        textTransform: 'capitalize',
    },
}

export const BalanceEdit = createFragmentContainer(
    compose(
        withMobileDialog(),
        withStyles(styles)
    )(BalanceEditComponent),
    {
        adminManagement: graphql`
            fragment BalanceEdit_adminManagement on AdminManagement {
                manualBalanceChangeReasons {
                    edges {
                        node {
                            id
                            cuid
                            key
                            value
                            createdAt
                        }
                    }
                }
            }
        `,
        userManagement: graphql`
            fragment BalanceEdit_userManagement on UserManagement {
                currentUser {
                    name
                    avatarUrl
                }
            }
        `,
    }
)
