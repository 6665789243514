import graphql from 'babel-plugin-relay/macro'

export default graphql`
    mutation UpdateDeliveryInfoMutation(
        $input: AdminManagementUpdateDeliveryInfoInput!
        $userCuid: String!
    ) {
        adminManagementUpdateDeliveryInfo(input: $input) {
            info {
                phone
            }
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
            adminManagement {
                user(userId: $userCuid) {
                    ...UserTransactions_data
                    logs {
                        ...UserLog_logs
                    }
                }
            }
        }
    }
`
