import graphql from 'babel-plugin-relay/macro'

export default graphql`
    mutation UpdateUserMutation(
        $input: AdminManagementUpdateUserInput!
        $userId: String!
    ) {
        adminManagementUpdateUser(input: $input) {
            success
            adminManagement {
                user(userId: $userId) {
                    ...UserPage_user
                }
            }
        }
    }
`
