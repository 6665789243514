/**
 * @flow
 * @relayHash 2bd6f37eac60e6a0e4717737d66a2c45
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ChatConfig_blacklistedPhrases$ref = any;
export type ChatConfigContainerQueryVariables = {||};
export type ChatConfigContainerQueryResponse = {|
  +appManagement: ?{|
    +id: string,
    +blacklistedPhrases: ?$ReadOnlyArray<?{|
      +text: string,
      +$fragmentRefs: ChatConfig_blacklistedPhrases$ref,
    |}>,
  |},
  +userManagement: ?{|
    +currentUser: ?{|
      +role: ?string,
      +roles: ?$ReadOnlyArray<?string>,
    |}
  |},
|};
export type ChatConfigContainerQuery = {|
  variables: ChatConfigContainerQueryVariables,
  response: ChatConfigContainerQueryResponse,
|};
*/


/*
query ChatConfigContainerQuery {
  appManagement {
    id
    blacklistedPhrases {
      text
      ...ChatConfig_blacklistedPhrases
      id
    }
  }
  userManagement {
    currentUser {
      role
      roles
      id
    }
    id
  }
}

fragment ChatConfig_blacklistedPhrases on AppManagementBlacklistedPhrase {
  id
  text
  createdAt
  createdBy
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "text",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "role",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "roles",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ChatConfigContainerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "appManagement",
        "storageKey": null,
        "args": null,
        "concreteType": "AppManagement",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blacklistedPhrases",
            "storageKey": null,
            "args": null,
            "concreteType": "AppManagementBlacklistedPhrase",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "FragmentSpread",
                "name": "ChatConfig_blacklistedPhrases",
                "args": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userManagement",
        "storageKey": null,
        "args": null,
        "concreteType": "UserManagement",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currentUser",
            "storageKey": null,
            "args": null,
            "concreteType": "UserManagementUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ChatConfigContainerQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "appManagement",
        "storageKey": null,
        "args": null,
        "concreteType": "AppManagement",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "blacklistedPhrases",
            "storageKey": null,
            "args": null,
            "concreteType": "AppManagementBlacklistedPhrase",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdBy",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "userManagement",
        "storageKey": null,
        "args": null,
        "concreteType": "UserManagement",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "currentUser",
            "storageKey": null,
            "args": null,
            "concreteType": "UserManagementUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v0/*: any*/)
            ]
          },
          (v0/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "ChatConfigContainerQuery",
    "id": null,
    "text": "query ChatConfigContainerQuery {\n  appManagement {\n    id\n    blacklistedPhrases {\n      text\n      ...ChatConfig_blacklistedPhrases\n      id\n    }\n  }\n  userManagement {\n    currentUser {\n      role\n      roles\n      id\n    }\n    id\n  }\n}\n\nfragment ChatConfig_blacklistedPhrases on AppManagementBlacklistedPhrase {\n  id\n  text\n  createdAt\n  createdBy\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a4b47b14a0be44f22488990cd4aabd3f';
module.exports = node;
