import graphql from 'babel-plugin-relay/macro'

export default graphql`
    mutation IssuePremiumMutation(
        $input: AdminManagementIssuePremiumInput!
        $userCuid: String!
    ) {
        adminManagementIssuePremium(input: $input) {
            user {
                premium {
                    expirationDate
                    type
                }
            }
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
            adminManagement {
                user(userId: $userCuid) {
                    ...UserTransactions_data
                    logs {
                        ...UserLog_logs
                    }
                }
            }
        }
    }
`
