import React, { Fragment, useState, useCallback } from 'react'
import {
    DialogActions,
    DialogTitle,
    Button,
    CircularProgress,
    DialogContent,
    DialogContentText,
    TextField,
} from '@material-ui/core'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

const LoaderContainer = styled.div`
    margin-right: 5px;
    align-items: center;
    display: flex;
`

const CreateBlacklistedPhrases = ({ onClose, onCreate, isLoading }) => {
    const [phrases, setPhrases] = useState('')

    const handlePhrasesChange = useCallback(e => setPhrases(e.target.value), [
        setPhrases,
    ])

    const handleCreate = useCallback(() => onCreate({ phrases }), [
        onCreate,
        phrases,
    ])

    return (
        <Fragment>
            <DialogTitle>Add new blacklisted(spam) phrases</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter phrases delimited by semicolon (;) or new line to add
                    multiple phrases
                </DialogContentText>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Phrases"
                    fullWidth
                    multiline
                    rows={5}
                    rowsMax={15}
                    value={phrases}
                    onChange={handlePhrasesChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="contained">
                    Cancel
                </Button>
                <Button
                    onClick={handleCreate}
                    color="primary"
                    disabled={isLoading}
                >
                    {isLoading && (
                        <LoaderContainer>
                            <CircularProgress size={20} />
                        </LoaderContainer>
                    )}
                    Add
                </Button>
            </DialogActions>
        </Fragment>
    )
}

CreateBlacklistedPhrases.propTypes = {
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    isLoading: PropTypes.bool,
}

CreateBlacklistedPhrases.defaultProps = {
    onClose: noop,
    onCreate: noop,
    isLoading: false,
}

export default CreateBlacklistedPhrases
