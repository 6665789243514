import React, { Fragment, useCallback, useMemo } from 'react'

import BaseSection from '../../shared/components/BaseSection'
import WebsiteIcon from '@material-ui/icons/FormatColorFill'
import { createSimpleTable } from '../../shared/components/SimpleTable'
import graphql from 'babel-plugin-relay/macro'
import format from 'date-fns/format'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import UserMiniPageContainer from '../Users/UserMiniPage/UserMiniPageContainer'
import { Button } from '@material-ui/core'
import { useModals } from '../../shared/modals'
import { Delete as DeleteIcon } from '@material-ui/icons'
import styled from 'styled-components/macro'

const fragmentSpec = {
    themes: graphql`
        fragment Website_themes on AppManagementTheme @relay(plural: true) {
            id
            name
            startAt
            finishAt
            createdAt
            createdByUserCuid
        }
    `,
}

const WebsiteTable = createSimpleTable(fragmentSpec)

const RowActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const columns = ({ onDelete }) => [
    {
        label: 'Name',
        name: 'name',
    },
    {
        label: 'Created At',
        name: 'createdAt',
        getter: ({ createdAt }) => (
            <Fragment>
                {format(new Date(createdAt), 'dd MMM yyyy')}
                <br />
                {format(new Date(createdAt), 'kk:mm:ss OOOO')}
            </Fragment>
        ),
    },
    {
        label: 'Start At',
        name: 'startAt',
        getter: ({ startAt }) =>
            startAt ? (
                <Fragment>
                    {format(new Date(startAt), 'dd MMM yyyy')}
                    <br />
                    {format(new Date(startAt), 'kk:mm:ss OOOO')}
                </Fragment>
            ) : (
                '-'
            ),
    },
    {
        label: 'Finish At',
        name: 'createdAt',
        getter: ({ finishAt }) =>
            finishAt ? (
                <Fragment>
                    {format(new Date(finishAt), 'dd MMM yyyy')}
                    <br />
                    {format(new Date(finishAt), 'kk:mm:ss OOOO')}
                </Fragment>
            ) : (
                '-'
            ),
    },
    {
        label: 'Created By',
        name: 'createdByUserCuid',
        getter: ({ createdByUserCuid }) => (
            <UserMiniPageContainer userId={createdByUserCuid} linkTo={'link'} />
        ),
    },
    {
        label: '',
        getter: props => (
            <RowActionContainer>
                <IconButton onClick={onDelete(props)}>
                    <DeleteIcon />
                </IconButton>
            </RowActionContainer>
        ),
    },
]

const Toolbar = ({ onCreate }) => (
    <div>
        <Button variant="contained" color="primary" onClick={onCreate}>
            Add new theme schedule
            <AddIcon />
        </Button>
    </div>
)

const Website = ({ themes, createTheme, deleteTheme }) => {
    const modals = useModals()

    const onCreate = useCallback(() => {
        modals.openCreateThemeModal({
            onCreate: createTheme,
        })
    }, [createTheme, modals])

    const onDelete = useCallback(
        ({ name, id }) => () => {
            modals.openConfirmationModal({
                title: `You want to delete "${name}" theme schedule`,
                onConfirm: () => deleteTheme({ id }),
            })
        },
        [modals, deleteTheme]
    )

    const tableColumns = useMemo(() => columns({ onDelete }), [onDelete])

    return (
        <BaseSection
            title="Themes schedule"
            icon={<WebsiteIcon />}
            count={themes.length}
            toolbar={<Toolbar onCreate={onCreate} />}
        >
            <WebsiteTable
                dataGetter="themes"
                columns={tableColumns}
                themes={themes}
            />
        </BaseSection>
    )
}

export default Website
