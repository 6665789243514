import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import {
    IconButton,
    Menu,
    MenuItem,
    Typography,
    ListItemIcon,
} from '@material-ui/core'
import { MoreVert as MoreVertIcon } from '@material-ui/icons'

const ActionsContainer = ({ actions, isDisabled }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = useCallback(event => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }, [])

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleAction = useCallback(
        action => e => {
            e.stopPropagation()
            handleClose()
            action()
        },
        [handleClose]
    )

    return (
        <div>
            <IconButton
                aria-label="More"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                disabled={isDisabled}
            >
                <MoreVertIcon />
            </IconButton>
            {actions.length > 0 && (
                <Menu
                    anchorEl={anchorEl}
                    keepMounted={false}
                    open={!!anchorEl}
                    onClose={handleClose}
                >
                    {actions.map(({ name, label, action, icon, isEnabled }) => (
                        <MenuItem
                            key={name}
                            onClick={handleAction(action)}
                            disabled={!isEnabled}
                        >
                            {icon && <ListItemIcon>{icon}</ListItemIcon>}
                            <Typography variant="inherit" noWrap>
                                {label}
                            </Typography>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </div>
    )
}

ActionsContainer.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string,
            icon: PropTypes.element,
            action: PropTypes.func.isRequired,
        })
    ).isRequired,
    isDisabled: PropTypes.bool,
}

ActionsContainer.defaultProps = {
    actions: [],
    isDisabled: false,
}

export default ActionsContainer
