import graphql from 'babel-plugin-relay/macro'

export default graphql`
    query UserMiniPageQuery($userId: String!) {
        adminManagement {
            user(userId: $userId) {
                ...UserMiniPage_user
            }
        }
    }
`
