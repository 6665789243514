import graphql from 'babel-plugin-relay/macro'

export default graphql`
    query AppQuery {
        userManagement {
            ...MainApp_userManagement
            currentUser {
                name
                role
                roles
            }
        }
    }
`
