/**
 * @flow
 * @relayHash 5f9f8c447d7f643b4b8f268c4c436a84
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Promotions_promotion$ref = any;
export type RewardsUpdatePromotionInput = {|
  slug: string,
  imageUrl?: ?string,
  ctaUrl?: ?string,
  hasBanner?: ?boolean,
  hasPage?: ?boolean,
  isEnabled?: ?boolean,
  pageUrl?: ?string,
  order?: ?number,
  clientMutationId?: ?string,
|};
export type PromoContainerUpdatePromoMutationVariables = {|
  input: RewardsUpdatePromotionInput
|};
export type PromoContainerUpdatePromoMutationResponse = {|
  +rewardsUpdatePromotion: ?{|
    +promotion: ?{|
      +$fragmentRefs: Promotions_promotion$ref
    |},
    +success: ?boolean,
    +errors: ?$ReadOnlyArray<?{|
      +statusCode: ?number,
      +message: ?string,
      +meta: ?any,
      +errorCode: ?string,
      +code: ?number,
    |}>,
  |}
|};
export type PromoContainerUpdatePromoMutation = {|
  variables: PromoContainerUpdatePromoMutationVariables,
  response: PromoContainerUpdatePromoMutationResponse,
|};
*/

/*
mutation PromoContainerUpdatePromoMutation(
  $input: RewardsUpdatePromotionInput!
) {
  rewardsUpdatePromotion(input: $input) {
    promotion {
      ...Promotions_promotion
      id
    }
    success
    errors {
      statusCode
      message
      meta
      errorCode
      code
    }
  }
}

fragment Promotions_promotion on RewardsPromotion {
  id
  name
  slug
  title
  description
  SEOTitle
  SEODescription
  ctaLabel
  ctaUrl
  imageUrl
  hasBanner
  hasPage
  pageUrl
  createdAt
  createdByCuid
  updatedAt
  updatedByCuid
  order
  isEnabled
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'input',
                type: 'RewardsUpdatePromotionInput!',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'Variable',
                name: 'input',
                variableName: 'input',
            },
        ],
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'success',
            args: null,
            storageKey: null,
        },
        v3 = {
            kind: 'LinkedField',
            alias: null,
            name: 'errors',
            storageKey: null,
            args: null,
            concreteType: 'RewardsError',
            plural: true,
            selections: [
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'statusCode',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'message',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'meta',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'errorCode',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'code',
                    args: null,
                    storageKey: null,
                },
            ],
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'PromoContainerUpdatePromoMutation',
            type: 'Mutation',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'rewardsUpdatePromotion',
                    storageKey: null,
                    args: (v1 /*: any*/),
                    concreteType: 'RewardsUpdatePromotionPayload',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'promotion',
                            storageKey: null,
                            args: null,
                            concreteType: 'RewardsPromotion',
                            plural: false,
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: 'Promotions_promotion',
                                    args: null,
                                },
                            ],
                        },
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'PromoContainerUpdatePromoMutation',
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'rewardsUpdatePromotion',
                    storageKey: null,
                    args: (v1 /*: any*/),
                    concreteType: 'RewardsUpdatePromotionPayload',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'promotion',
                            storageKey: null,
                            args: null,
                            concreteType: 'RewardsPromotion',
                            plural: false,
                            selections: [
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'id',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'name',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'slug',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'title',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'description',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'SEOTitle',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'SEODescription',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'ctaLabel',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'ctaUrl',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'imageUrl',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'hasBanner',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'hasPage',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'pageUrl',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'createdAt',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'createdByCuid',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'updatedAt',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'updatedByCuid',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'order',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'isEnabled',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ClientExtension',
                                    selections: [
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'isNew',
                                            args: null,
                                            storageKey: null,
                                        },
                                    ],
                                },
                            ],
                        },
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                    ],
                },
            ],
        },
        params: {
            operationKind: 'mutation',
            name: 'PromoContainerUpdatePromoMutation',
            id: null,
            text:
                'mutation PromoContainerUpdatePromoMutation(\n  $input: RewardsUpdatePromotionInput!\n) {\n  rewardsUpdatePromotion(input: $input) {\n    promotion {\n      ...Promotions_promotion\n      id\n    }\n    success\n    errors {\n      statusCode\n      message\n      meta\n      errorCode\n      code\n    }\n  }\n}\n\nfragment Promotions_promotion on RewardsPromotion {\n  id\n  name\n  slug\n  title\n  description\n  SEOTitle\n  SEODescription\n  ctaLabel\n  ctaUrl\n  imageUrl\n  hasBanner\n  hasPage\n  pageUrl\n  createdAt\n  createdByCuid\n  updatedAt\n  updatedByCuid\n  order\n  isEnabled\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '664aebced6e9a8bd6795d93943da721b';
module.exports = node
