import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'

import SignIn from './SignIn'
import { commitMutation } from 'react-relay'
import SignInMutation from './mutations/SignIn'
import environment from '../../../Environment'

export default class SignInContainer extends PureComponent {
    state = {
        email: '',
        password: '',
        isShowPassword: false,
        isLoading: false,
        errors: {
            email: '',
            password: '',
            form: this.props.error,
        },
    }

    onInputChange = e => {
        const { name, value } = e.target

        if (!name) {
            return
        }

        this.setState({ [name]: value })
    }

    toggleShowPassword = () => {
        this.setState(state => ({ isShowPassword: !state.isShowPassword }))
    }

    signIn = () => {
        const { email, password } = this.state

        this.setState({ isLoading: true }, () => {
            commitMutation(environment, {
                mutation: SignInMutation,
                variables: {
                    input: { email, password },
                },
                onCompleted: (response, error) => {
                    this.setState({ isLoading: false })

                    const { token, errors } = response.userManagementSignIn

                    if (error) {
                        return
                    }

                    if (errors) {
                        const errorMessage = errors[0].message

                        this.setState({
                            errors: {
                                email: errorMessage,
                                password: errorMessage,
                            },
                        })

                        return
                    }

                    localStorage.setItem('token', token)
                    window.location.assign('/')
                },
                onError: err => console.error(err),
            })
        })
    }

    render() {
        return (
            <Fragment>
                <SignIn
                    onInputChange={this.onInputChange}
                    toggleShowPassword={this.toggleShowPassword}
                    isShowPassword={this.state.isShowPassword}
                    isLoading={this.state.isLoading}
                    onSubmit={this.signIn}
                    errors={this.state.errors}
                />
            </Fragment>
        )
    }
}

SignIn.propTypes = {
    error: PropTypes.string,
}
