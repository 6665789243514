/**
 * @flow
 * @relayHash 3a698d623fee0b1866b51b91ca811a2b
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Promotions_promotion$ref = any;
export type PromoContainerQueryVariables = {||};
export type PromoContainerQueryResponse = {|
  +rewards: ?{|
    +id: string,
    +promotions: ?{|
      +data: ?$ReadOnlyArray<?{|
        +slug: string,
        +$fragmentRefs: Promotions_promotion$ref,
      |}>,
      +count: ?number,
    |},
  |}
|};
export type PromoContainerQuery = {|
  variables: PromoContainerQueryVariables,
  response: PromoContainerQueryResponse,
|};
*/

/*
query PromoContainerQuery {
  rewards {
    id
    promotions {
      data {
        slug
        ...Promotions_promotion
        id
      }
      count
    }
  }
}

fragment Promotions_promotion on RewardsPromotion {
  id
  name
  slug
  title
  description
  SEOTitle
  SEODescription
  ctaLabel
  ctaUrl
  imageUrl
  hasBanner
  hasPage
  pageUrl
  createdAt
  createdByCuid
  updatedAt
  updatedByCuid
  order
  isEnabled
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        },
        v1 = {
            kind: 'ScalarField',
            alias: null,
            name: 'slug',
            args: null,
            storageKey: null,
        },
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'count',
            args: null,
            storageKey: null,
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'PromoContainerQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'rewards',
                    storageKey: null,
                    args: null,
                    concreteType: 'Rewards',
                    plural: false,
                    selections: [
                        (v0 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'promotions',
                            storageKey: null,
                            args: null,
                            concreteType: 'RewardsPromotions',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'data',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'RewardsPromotion',
                                    plural: true,
                                    selections: [
                                        (v1 /*: any*/),
                                        {
                                            kind: 'FragmentSpread',
                                            name: 'Promotions_promotion',
                                            args: null,
                                        },
                                    ],
                                },
                                (v2 /*: any*/),
                            ],
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'PromoContainerQuery',
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'rewards',
                    storageKey: null,
                    args: null,
                    concreteType: 'Rewards',
                    plural: false,
                    selections: [
                        (v0 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'promotions',
                            storageKey: null,
                            args: null,
                            concreteType: 'RewardsPromotions',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'data',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'RewardsPromotion',
                                    plural: true,
                                    selections: [
                                        (v1 /*: any*/),
                                        (v0 /*: any*/),
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'name',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'title',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'description',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'SEOTitle',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'SEODescription',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'ctaLabel',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'ctaUrl',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'imageUrl',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'hasBanner',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'hasPage',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'pageUrl',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'createdAt',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'createdByCuid',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'updatedAt',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'updatedByCuid',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'order',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'isEnabled',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ClientExtension',
                                            selections: [
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'isNew',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                            ],
                                        },
                                    ],
                                },
                                (v2 /*: any*/),
                            ],
                        },
                    ],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'PromoContainerQuery',
            id: null,
            text:
                'query PromoContainerQuery {\n  rewards {\n    id\n    promotions {\n      data {\n        slug\n        ...Promotions_promotion\n        id\n      }\n      count\n    }\n  }\n}\n\nfragment Promotions_promotion on RewardsPromotion {\n  id\n  name\n  slug\n  title\n  description\n  SEOTitle\n  SEODescription\n  ctaLabel\n  ctaUrl\n  imageUrl\n  hasBanner\n  hasPage\n  pageUrl\n  createdAt\n  createdByCuid\n  updatedAt\n  updatedByCuid\n  order\n  isEnabled\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '4cdb0d612740f4a4332e2874cadfc357';
module.exports = node
