/**
 * @flow
 * @relayHash fda755b4b7f401e8c204ae062a9d491e
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Website_themes$ref = any;
export type WebsiteContainerQueryVariables = {||};
export type WebsiteContainerQueryResponse = {|
  +appManagement: ?{|
    +id: string,
    +themes: ?$ReadOnlyArray<?{|
      +$fragmentRefs: Website_themes$ref
    |}>,
  |}
|};
export type WebsiteContainerQuery = {|
  variables: WebsiteContainerQueryVariables,
  response: WebsiteContainerQueryResponse,
|};
*/

/*
query WebsiteContainerQuery {
  appManagement {
    id
    themes {
      ...Website_themes
      id
    }
  }
}

fragment Website_themes on AppManagementTheme {
  id
  name
  startAt
  finishAt
  createdAt
  createdByUserCuid
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = {
        kind: 'ScalarField',
        alias: null,
        name: 'id',
        args: null,
        storageKey: null,
    }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'WebsiteContainerQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'appManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AppManagement',
                    plural: false,
                    selections: [
                        (v0 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'themes',
                            storageKey: null,
                            args: null,
                            concreteType: 'AppManagementTheme',
                            plural: true,
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: 'Website_themes',
                                    args: null,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'WebsiteContainerQuery',
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'appManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AppManagement',
                    plural: false,
                    selections: [
                        (v0 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'themes',
                            storageKey: null,
                            args: null,
                            concreteType: 'AppManagementTheme',
                            plural: true,
                            selections: [
                                (v0 /*: any*/),
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'name',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'startAt',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'finishAt',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'createdAt',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'createdByUserCuid',
                                    args: null,
                                    storageKey: null,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'WebsiteContainerQuery',
            id: null,
            text:
                'query WebsiteContainerQuery {\n  appManagement {\n    id\n    themes {\n      ...Website_themes\n      id\n    }\n  }\n}\n\nfragment Website_themes on AppManagementTheme {\n  id\n  name\n  startAt\n  finishAt\n  createdAt\n  createdByUserCuid\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = 'b5ed78df11a3a9a11b9d87d1fa5cded2';
module.exports = node
