/**
 * @flow
 * @relayHash cfa76e3ce722e9f8112ede6dca720092
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserLog_logs$ref = any;
export type AdminManagementBlockUserInput = {|
  reason: string,
  expireAt?: ?any,
  userCuid: string,
  clientMutationId?: ?string,
|};
export type BlockUserMutationVariables = {|
  input: AdminManagementBlockUserInput,
  userCuid: string,
|};
export type BlockUserMutationResponse = {|
  +adminManagementBlockUser: ?{|
    +user: ?{|
      +isBlocked: ?boolean,
      +blockedExpireAt: ?any,
      +blockedReason: ?string,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +statusCode: ?number,
      +message: ?string,
      +meta: ?any,
      +errorCode: ?string,
      +code: ?number,
    |}>,
    +adminManagement: ?{|
      +user: ?{|
        +isBlocked: ?boolean,
        +blockedExpireAt: ?any,
        +blockedReason: ?string,
        +logs: ?{|
          +$fragmentRefs: UserLog_logs$ref
        |},
      |}
    |},
  |}
|};
export type BlockUserMutation = {|
  variables: BlockUserMutationVariables,
  response: BlockUserMutationResponse,
|};
*/

/*
mutation BlockUserMutation(
  $input: AdminManagementBlockUserInput!
  $userCuid: String!
) {
  adminManagementBlockUser(input: $input) {
    user {
      isBlocked
      blockedExpireAt
      blockedReason
      id
    }
    errors {
      statusCode
      message
      meta
      errorCode
      code
    }
    adminManagement {
      user(userId: $userCuid) {
        isBlocked
        blockedExpireAt
        blockedReason
        logs {
          ...UserLog_logs
        }
        id
      }
      id
    }
  }
}

fragment UserLog_logs on AdminManagementLogConnection {
  count
  edges {
    node {
      id
      column
      value
      modifiedByUserCuid
      createdAt
      notes
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'input',
                type: 'AdminManagementBlockUserInput!',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'userCuid',
                type: 'String!',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'Variable',
                name: 'input',
                variableName: 'input',
            },
        ],
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'isBlocked',
            args: null,
            storageKey: null,
        },
        v3 = {
            kind: 'ScalarField',
            alias: null,
            name: 'blockedExpireAt',
            args: null,
            storageKey: null,
        },
        v4 = {
            kind: 'ScalarField',
            alias: null,
            name: 'blockedReason',
            args: null,
            storageKey: null,
        },
        v5 = {
            kind: 'LinkedField',
            alias: null,
            name: 'errors',
            storageKey: null,
            args: null,
            concreteType: 'AdminManagementError',
            plural: true,
            selections: [
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'statusCode',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'message',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'meta',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'errorCode',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'code',
                    args: null,
                    storageKey: null,
                },
            ],
        },
        v6 = [
            {
                kind: 'Variable',
                name: 'userId',
                variableName: 'userCuid',
            },
        ],
        v7 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'BlockUserMutation',
            type: 'Mutation',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagementBlockUser',
                    storageKey: null,
                    args: (v1 /*: any*/),
                    concreteType: 'AdminManagementBlockUserPayload',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'user',
                            storageKey: null,
                            args: null,
                            concreteType: 'AdminManagementUser',
                            plural: false,
                            selections: [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                            ],
                        },
                        (v5 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'adminManagement',
                            storageKey: null,
                            args: null,
                            concreteType: 'AdminManagement',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'user',
                                    storageKey: null,
                                    args: (v6 /*: any*/),
                                    concreteType: 'AdminManagementUser',
                                    plural: false,
                                    selections: [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'logs',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'AdminManagementLogConnection',
                                            plural: false,
                                            selections: [
                                                {
                                                    kind: 'FragmentSpread',
                                                    name: 'UserLog_logs',
                                                    args: null,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'BlockUserMutation',
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagementBlockUser',
                    storageKey: null,
                    args: (v1 /*: any*/),
                    concreteType: 'AdminManagementBlockUserPayload',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'user',
                            storageKey: null,
                            args: null,
                            concreteType: 'AdminManagementUser',
                            plural: false,
                            selections: [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v7 /*: any*/),
                            ],
                        },
                        (v5 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'adminManagement',
                            storageKey: null,
                            args: null,
                            concreteType: 'AdminManagement',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'user',
                                    storageKey: null,
                                    args: (v6 /*: any*/),
                                    concreteType: 'AdminManagementUser',
                                    plural: false,
                                    selections: [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'logs',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'AdminManagementLogConnection',
                                            plural: false,
                                            selections: [
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'count',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'LinkedField',
                                                    alias: null,
                                                    name: 'edges',
                                                    storageKey: null,
                                                    args: null,
                                                    concreteType:
                                                        'AdminManagementLogEdge',
                                                    plural: true,
                                                    selections: [
                                                        {
                                                            kind: 'LinkedField',
                                                            alias: null,
                                                            name: 'node',
                                                            storageKey: null,
                                                            args: null,
                                                            concreteType:
                                                                'AdminManagementLog',
                                                            plural: false,
                                                            selections: [
                                                                (v7 /*: any*/),
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'column',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'value',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'modifiedByUserCuid',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'createdAt',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'notes',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        (v7 /*: any*/),
                                    ],
                                },
                                (v7 /*: any*/),
                            ],
                        },
                    ],
                },
            ],
        },
        params: {
            operationKind: 'mutation',
            name: 'BlockUserMutation',
            id: null,
            text:
                'mutation BlockUserMutation(\n  $input: AdminManagementBlockUserInput!\n  $userCuid: String!\n) {\n  adminManagementBlockUser(input: $input) {\n    user {\n      isBlocked\n      blockedExpireAt\n      blockedReason\n      id\n    }\n    errors {\n      statusCode\n      message\n      meta\n      errorCode\n      code\n    }\n    adminManagement {\n      user(userId: $userCuid) {\n        isBlocked\n        blockedExpireAt\n        blockedReason\n        logs {\n          ...UserLog_logs\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment UserLog_logs on AdminManagementLogConnection {\n  count\n  edges {\n    node {\n      id\n      column\n      value\n      modifiedByUserCuid\n      createdAt\n      notes\n    }\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '1c188943e8b97ad45e6ef64f8905ddad';
module.exports = node
