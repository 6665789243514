/**
 * @flow
 * @relayHash 6611dd81f8374d32a65a4e5323da57f4
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Giveaways_caseManagement$ref = any;
type Giveaways_rewards$ref = any;
export type GiveawayContainerQueryVariables = {||};
export type GiveawayContainerQueryResponse = {|
  +rewards: ?{|
    +$fragmentRefs: Giveaways_rewards$ref
  |},
  +caseManagement: ?{|
    +$fragmentRefs: Giveaways_caseManagement$ref
  |},
|};
export type GiveawayContainerQuery = {|
  variables: GiveawayContainerQueryVariables,
  response: GiveawayContainerQueryResponse,
|};
*/

/*
query GiveawayContainerQuery {
  rewards {
    ...Giveaways_rewards
    id
  }
  caseManagement {
    ...Giveaways_caseManagement
    id
  }
}

fragment Giveaways_rewards on Rewards {
  id
  giveaways {
    id
    period
    startAt
    finishAt
    participants
    prizes {
      cuid
      name
      slug
      imageUrl
      price
      id
    }
  }
}

fragment Giveaways_caseManagement on CaseManagement {
  products(first: 30, searchText: "", orderBy: ["price"], orderDirections: [DESC]) {
    edges {
      node {
        cuid
        name
        slug
        price
        imgUrl {
          x2 {
            default
            id
          }
          id
        }
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        },
        v1 = {
            kind: 'ScalarField',
            alias: null,
            name: 'cuid',
            args: null,
            storageKey: null,
        },
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'name',
            args: null,
            storageKey: null,
        },
        v3 = {
            kind: 'ScalarField',
            alias: null,
            name: 'slug',
            args: null,
            storageKey: null,
        },
        v4 = {
            kind: 'ScalarField',
            alias: null,
            name: 'price',
            args: null,
            storageKey: null,
        },
        v5 = [
            {
                kind: 'Literal',
                name: 'first',
                value: 30,
            },
            {
                kind: 'Literal',
                name: 'orderBy',
                value: ['price'],
            },
            {
                kind: 'Literal',
                name: 'orderDirections',
                value: ['DESC'],
            },
            {
                kind: 'Literal',
                name: 'searchText',
                value: '',
            },
        ]
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'GiveawayContainerQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'rewards',
                    storageKey: null,
                    args: null,
                    concreteType: 'Rewards',
                    plural: false,
                    selections: [
                        {
                            kind: 'FragmentSpread',
                            name: 'Giveaways_rewards',
                            args: null,
                        },
                    ],
                },
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'caseManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'CaseManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'FragmentSpread',
                            name: 'Giveaways_caseManagement',
                            args: null,
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'GiveawayContainerQuery',
            argumentDefinitions: [],
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'rewards',
                    storageKey: null,
                    args: null,
                    concreteType: 'Rewards',
                    plural: false,
                    selections: [
                        (v0 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'giveaways',
                            storageKey: null,
                            args: null,
                            concreteType: 'RewardsGiveaway',
                            plural: true,
                            selections: [
                                (v0 /*: any*/),
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'period',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'startAt',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'finishAt',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'participants',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'prizes',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'RewardsGiveawayPrizeProduct',
                                    plural: true,
                                    selections: [
                                        (v1 /*: any*/),
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'imageUrl',
                                            args: null,
                                            storageKey: null,
                                        },
                                        (v4 /*: any*/),
                                        (v0 /*: any*/),
                                    ],
                                },
                                {
                                    kind: 'ClientExtension',
                                    selections: [
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'isNew',
                                            args: null,
                                            storageKey: null,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'caseManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'CaseManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'products',
                            storageKey:
                                'products(first:30,orderBy:["price"],orderDirections:["DESC"],searchText:"")',
                            args: (v5 /*: any*/),
                            concreteType: 'CaseManagementProductConnection',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'edges',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'CaseManagementProductEdge',
                                    plural: true,
                                    selections: [
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'node',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'CaseManagementProduct',
                                            plural: false,
                                            selections: [
                                                (v1 /*: any*/),
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                (v4 /*: any*/),
                                                {
                                                    kind: 'LinkedField',
                                                    alias: null,
                                                    name: 'imgUrl',
                                                    storageKey: null,
                                                    args: null,
                                                    concreteType:
                                                        'CaseManagementImageURL',
                                                    plural: false,
                                                    selections: [
                                                        {
                                                            kind: 'LinkedField',
                                                            alias: null,
                                                            name: 'x2',
                                                            storageKey: null,
                                                            args: null,
                                                            concreteType:
                                                                'CaseManagementImage',
                                                            plural: false,
                                                            selections: [
                                                                {
                                                                    kind:
                                                                        'ScalarField',
                                                                    alias: null,
                                                                    name:
                                                                        'default',
                                                                    args: null,
                                                                    storageKey: null,
                                                                },
                                                                (v0 /*: any*/),
                                                            ],
                                                        },
                                                        (v0 /*: any*/),
                                                    ],
                                                },
                                                (v0 /*: any*/),
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: '__typename',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'cursor',
                                            args: null,
                                            storageKey: null,
                                        },
                                    ],
                                },
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'pageInfo',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'PageInfo',
                                    plural: false,
                                    selections: [
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'endCursor',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'hasNextPage',
                                            args: null,
                                            storageKey: null,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            kind: 'LinkedHandle',
                            alias: null,
                            name: 'products',
                            args: (v5 /*: any*/),
                            handle: 'connection',
                            key: 'Giveaways_products',
                            filters: [
                                'searchText',
                                'orderBy',
                                'orderDirections',
                            ],
                        },
                        (v0 /*: any*/),
                    ],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'GiveawayContainerQuery',
            id: null,
            text:
                'query GiveawayContainerQuery {\n  rewards {\n    ...Giveaways_rewards\n    id\n  }\n  caseManagement {\n    ...Giveaways_caseManagement\n    id\n  }\n}\n\nfragment Giveaways_rewards on Rewards {\n  id\n  giveaways {\n    id\n    period\n    startAt\n    finishAt\n    participants\n    prizes {\n      cuid\n      name\n      slug\n      imageUrl\n      price\n      id\n    }\n  }\n}\n\nfragment Giveaways_caseManagement on CaseManagement {\n  products(first: 30, searchText: "", orderBy: ["price"], orderDirections: [DESC]) {\n    edges {\n      node {\n        cuid\n        name\n        slug\n        price\n        imgUrl {\n          x2 {\n            default\n            id\n          }\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '0ccb0610a528f5415cb5d3d6beb0917b';
module.exports = node
