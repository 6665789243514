/**
 * @flow
 * @relayHash 9213a86a8c221d7a03bb70a6c4610f5f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankingManagementDeletePaymentInput = {|
  id?: ?string,
  clientMutationId?: ?string,
|};
export type PaymentsContainerDeletePaymentMutationVariables = {|
  input: BankingManagementDeletePaymentInput
|};
export type PaymentsContainerDeletePaymentMutationResponse = {|
  +bankingManagementDeletePayment: ?{|
    +success: ?boolean,
    +errors: ?$ReadOnlyArray<?{|
      +statusCode: ?number,
      +message: ?string,
      +meta: ?any,
      +errorCode: ?string,
      +code: ?number,
    |}>,
  |}
|};
export type PaymentsContainerDeletePaymentMutation = {|
  variables: PaymentsContainerDeletePaymentMutationVariables,
  response: PaymentsContainerDeletePaymentMutationResponse,
|};
*/


/*
mutation PaymentsContainerDeletePaymentMutation(
  $input: BankingManagementDeletePaymentInput!
) {
  bankingManagementDeletePayment(input: $input) {
    success
    errors {
      statusCode
      message
      meta
      errorCode
      code
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "BankingManagementDeletePaymentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "bankingManagementDeletePayment",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BankingManagementDeletePaymentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "success",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "BankingManagementError",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "statusCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "meta",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "errorCode",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PaymentsContainerDeletePaymentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "PaymentsContainerDeletePaymentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "PaymentsContainerDeletePaymentMutation",
    "id": null,
    "text": "mutation PaymentsContainerDeletePaymentMutation(\n  $input: BankingManagementDeletePaymentInput!\n) {\n  bankingManagementDeletePayment(input: $input) {\n    success\n    errors {\n      statusCode\n      message\n      meta\n      errorCode\n      code\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae02eca515a954ec45c75b0660defc10';
module.exports = node;
