/**
 * @flow
 */

/* eslint-disable */

'use strict'

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserPage_currentUser$ref: FragmentReference;
declare export opaque type UserPage_currentUser$fragmentType: UserPage_currentUser$ref;
export type UserPage_currentUser = {|
  +role: ?string,
  +$refType: UserPage_currentUser$ref,
|};
export type UserPage_currentUser$data = UserPage_currentUser;
export type UserPage_currentUser$key = {
  +$data?: UserPage_currentUser$data,
  +$fragmentRefs: UserPage_currentUser$ref,
};
*/

const node /*: ReaderFragment*/ = {
    kind: 'Fragment',
    name: 'UserPage_currentUser',
    type: 'UserManagementUser',
    metadata: null,
    argumentDefinitions: [],
    selections: [
        {
            kind: 'ScalarField',
            alias: null,
            name: 'role',
            args: null,
            storageKey: null,
        },
    ],
}
// prettier-ignore
;(node/*: any*/).hash = 'bf67f4a2c35e2fbcae6dcf7b949414d6';
module.exports = node
