import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createFragmentContainer } from 'react-relay'

import map from 'lodash/map'
import get from 'lodash/get'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import withStyles from '@material-ui/core/styles/withStyles'

export const DataTypes = {
    BOOLEAN: 2,
}

class SimpleTable extends Component {
    get rows() {
        return map(
            get(this, `props.${this.props.dataGetter}`, []),
            this.props.dataMapper
        )
    }

    getColumnValue = (column, info) => {
        const value = column.getter
            ? column.getter(info)
            : get(info, column.name)

        switch (column.type) {
            case DataTypes.BOOLEAN:
                return <Checkbox checked={value} disabled />
            default:
                return value
        }
    }

    get noData() {
        return (
            <div className={this.props.classes.centeredContainer}>No data.</div>
        )
    }

    render() {
        return (
            <Paper className={this.props.classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {this.props.columns.map(({ label, name }) => (
                                <TableCell key={name}>{label}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.rows.map((row, i) => {
                            return (
                                <TableRow key={row.cuid || i}>
                                    {this.props.columns.map(column => (
                                        <TableCell>
                                            {this.getColumnValue(column, row)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                {this.rows.length === 0 && this.noData}
            </Paper>
        )
    }
}

const styles = {
    centeredContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
    },
    tableContainer: {
        overflow: 'auto',
        flex: 1,
    },
}

SimpleTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            getter: PropTypes.func,
        })
    ).isRequired,
    dataGetter: PropTypes.string.isRequired,
    dataMapper: PropTypes.string,
}

SimpleTable.defaultProps = {
    dataMapper: null,
}

export const createSimpleTable = fragmentSpec =>
    createFragmentContainer(withStyles(styles)(SimpleTable), fragmentSpec)
