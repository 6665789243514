/**
 * @flow
 */

/* eslint-disable */

'use strict'

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Giveaways_rewards$ref: FragmentReference;
declare export opaque type Giveaways_rewards$fragmentType: Giveaways_rewards$ref;
export type Giveaways_rewards = {|
  +id: string,
  +giveaways: ?$ReadOnlyArray<?{|
    +id: string,
    +isNew: ?boolean,
    +period: ?string,
    +startAt: ?any,
    +finishAt: ?any,
    +participants: ?number,
    +prizes: ?$ReadOnlyArray<?{|
      +cuid: ?string,
      +name: ?string,
      +slug: ?string,
      +imageUrl: ?string,
      +price: ?number,
    |}>,
  |}>,
  +$refType: Giveaways_rewards$ref,
|};
export type Giveaways_rewards$data = Giveaways_rewards;
export type Giveaways_rewards$key = {
  +$data?: Giveaways_rewards$data,
  +$fragmentRefs: Giveaways_rewards$ref,
};
*/

const node /*: ReaderFragment*/ = (function() {
    var v0 = {
        kind: 'ScalarField',
        alias: null,
        name: 'id',
        args: null,
        storageKey: null,
    }
    return {
        kind: 'Fragment',
        name: 'Giveaways_rewards',
        type: 'Rewards',
        metadata: null,
        argumentDefinitions: [],
        selections: [
            (v0 /*: any*/),
            {
                kind: 'LinkedField',
                alias: null,
                name: 'giveaways',
                storageKey: null,
                args: null,
                concreteType: 'RewardsGiveaway',
                plural: true,
                selections: [
                    (v0 /*: any*/),
                    {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'period',
                        args: null,
                        storageKey: null,
                    },
                    {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'startAt',
                        args: null,
                        storageKey: null,
                    },
                    {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'finishAt',
                        args: null,
                        storageKey: null,
                    },
                    {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'participants',
                        args: null,
                        storageKey: null,
                    },
                    {
                        kind: 'LinkedField',
                        alias: null,
                        name: 'prizes',
                        storageKey: null,
                        args: null,
                        concreteType: 'RewardsGiveawayPrizeProduct',
                        plural: true,
                        selections: [
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'cuid',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'name',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'slug',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'imageUrl',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'price',
                                args: null,
                                storageKey: null,
                            },
                        ],
                    },
                    {
                        kind: 'ClientExtension',
                        selections: [
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'isNew',
                                args: null,
                                storageKey: null,
                            },
                        ],
                    },
                ],
            },
        ],
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '6104a27d500079e3e36fbf52e56ad28d';
module.exports = node
