import graphql from 'babel-plugin-relay/macro'

export default graphql`
    query UsersListQuery(
        $orderBy: String
        $orderDirection: AdminManagementOrderDirections
        $first: Int
        $offset: Int
        $searchText: String
    ) {
        adminManagement {
            ...UsersList_adminManagement
                @arguments(
                    orderBy: $orderBy
                    orderDirection: $orderDirection
                    first: $first
                    offset: $offset
                    searchText: $searchText
                )
        }
    }
`
