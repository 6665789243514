/**
 * @flow
 * @relayHash 01b122a371c90f1e0d8e980d74336d1c
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserMiniPage_user$ref = any;
export type UserMiniPageQueryVariables = {|
  userId: string
|};
export type UserMiniPageQueryResponse = {|
  +adminManagement: ?{|
    +user: ?{|
      +$fragmentRefs: UserMiniPage_user$ref
    |}
  |}
|};
export type UserMiniPageQuery = {|
  variables: UserMiniPageQueryVariables,
  response: UserMiniPageQueryResponse,
|};
*/

/*
query UserMiniPageQuery(
  $userId: String!
) {
  adminManagement {
    user(userId: $userId) {
      ...UserMiniPage_user
      id
    }
    id
  }
}

fragment UserMiniPage_user on AdminManagementUser {
  id
  cuid
  name
  email
  role
  accountType
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'userId',
                type: 'String!',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'Variable',
                name: 'userId',
                variableName: 'userId',
            },
        ],
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'UserMiniPageQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AdminManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'user',
                            storageKey: null,
                            args: (v1 /*: any*/),
                            concreteType: 'AdminManagementUser',
                            plural: false,
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: 'UserMiniPage_user',
                                    args: null,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'UserMiniPageQuery',
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AdminManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'user',
                            storageKey: null,
                            args: (v1 /*: any*/),
                            concreteType: 'AdminManagementUser',
                            plural: false,
                            selections: [
                                (v2 /*: any*/),
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'cuid',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'name',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'email',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'role',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'accountType',
                                    args: null,
                                    storageKey: null,
                                },
                            ],
                        },
                        (v2 /*: any*/),
                    ],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'UserMiniPageQuery',
            id: null,
            text:
                'query UserMiniPageQuery(\n  $userId: String!\n) {\n  adminManagement {\n    user(userId: $userId) {\n      ...UserMiniPage_user\n      id\n    }\n    id\n  }\n}\n\nfragment UserMiniPage_user on AdminManagementUser {\n  id\n  cuid\n  name\n  email\n  role\n  accountType\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = 'e68f2362bfc1e7c10570e99f11aae71c';
module.exports = node
