import { QueryRenderer } from 'react-relay'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import environment from '../../../../Environment'
import TableLoader from '../../../../components/TableLoader'
import ContainerError from '../../../../components/ContainerError'
import UserInventoryQuery from './UserInventoryQuery'

import UserInventory from './index'

const __DEV__ = process.env.NODE_ENV === 'development'

class UserInventoryContainer extends PureComponent {
    componentDidMount() {
        this.props.onLoad()
    }

    renderPage = ({ error, props }) => {
        if (error) {
            console.error(error)

            if (__DEV__) {
                return <div>{error.message}</div>
            }

            return <ContainerError />
        }

        if (props) {
            return <UserInventory user={props.adminManagement.user} />
        }

        return <TableLoader />
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                variables={{
                    userId: this.props.userId,
                }}
                query={UserInventoryQuery}
                render={this.renderPage}
            />
        )
    }
}

UserInventoryContainer.propTypes = {
    onLoad: PropTypes.func,
}
UserInventoryContainer.defaultProps = {
    onLoad: () => {},
}

export default UserInventoryContainer
