import React, { useCallback, useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useFragment } from 'relay-hooks'
import PromoIcon from '@material-ui/icons/VolumeUp'
import noop from 'lodash/noop'
import orderBy from 'lodash/orderBy'
import {
    Button,
    ExpansionPanel,
    ExpansionPanelSummary,
    FormControlLabel,
    Switch,
    Tooltip,
    Typography,
} from '@material-ui/core'
import {
    Add as AddIcon,
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import styled from 'styled-components'

import BaseSection from '../../shared/components/BaseSection'
import Promotion from './Promotion'
import graphql from 'babel-plugin-relay/macro'
import Access from '../../shared/components/Access'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

const SummaryContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
`

const Toolbar = ({ onCreate = noop }) => (
    <div>
        <Button variant="contained" color="primary" onClick={onCreate}>
            Add new
            <AddIcon />
        </Button>
    </div>
)

const Promotions = ({
    reload,
    count,
    save,
    add,
    cancelAdd,
    change,
    remove,
    ...props
}) => {
    const promotions = useFragment(
        graphql`
            fragment Promotions_promotion on RewardsPromotion
                @relay(plural: true) {
                id
                name
                slug
                title
                description
                SEOTitle
                SEODescription
                ctaLabel
                ctaUrl
                imageUrl
                hasBanner
                hasPage
                pageUrl
                createdAt
                createdByCuid
                updatedAt
                updatedByCuid
                order
                isEnabled
                isNew
            }
        `,
        props.promotions
    )

    const handleChange = useCallback(
        entity => e => {
            e.stopPropagation()
            const { name, checked } = e.target
            save({ slug: entity.slug, [name]: checked }, entity)
        },
        [save]
    )

    const handleClick = useCallback(e => {
        e.stopPropagation()
    }, [])

    const { params } = useRouteMatch()
    const history = useHistory()

    const [expandedPanel, setExpandedPanel] = useState(params?.promo || null)
    const handleExpanded = useCallback(
        panel => (_, expanded) => {
            setExpandedPanel(expanded ? panel : null)
            history.replace(
                expanded ? `/rewards/promo/${panel}` : '/rewards/promo'
            )
        },
        [setExpandedPanel, history]
    )

    return (
        <BaseSection
            title="Promotions"
            count={count}
            icon={<PromoIcon />}
            reload={reload}
            toolbar={<Toolbar onCreate={add} />}
        >
            {orderBy(
                Array.isArray(promotions) ? promotions : [],
                ['order', 'createdAt'],
                ['desc', 'desc']
            )
                .filter(promo => promo)
                .map(promo => (
                    <ExpansionPanel
                        key={promo.slug}
                        expanded={expandedPanel === promo.slug}
                        {...(promo.isNew && { expanded: true })}
                        onChange={handleExpanded(promo.slug)}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <SummaryContainer>
                                <Typography variant="subtitle1">
                                    {promo.name}
                                </Typography>
                                {promo.hasPage && promo.pageUrl && (
                                    <Typography variant="subtitle1">
                                        {promo.pageUrl}
                                    </Typography>
                                )}

                                <div>
                                    {!promo.isNew && (
                                        <>
                                            <Tooltip
                                                title={
                                                    promo.isEnabled
                                                        ? 'Disable'
                                                        : 'Enable'
                                                }
                                                aria-label={
                                                    promo.isEnabled
                                                        ? 'Disable'
                                                        : 'Enable'
                                                }
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={
                                                                promo.isEnabled
                                                            }
                                                            onClick={
                                                                handleClick
                                                            }
                                                            onChange={handleChange(
                                                                promo
                                                            )}
                                                            name="isEnabled"
                                                        />
                                                    }
                                                    label="Is enabled"
                                                />
                                            </Tooltip>
                                            <Tooltip
                                                title={
                                                    promo.isEnabled
                                                        ? 'Disable banner'
                                                        : 'Enable banner'
                                                }
                                                aria-label={
                                                    promo.isEnabled
                                                        ? 'Disable banner'
                                                        : 'Enable banner'
                                                }
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={
                                                                promo.hasBanner
                                                            }
                                                            onClick={
                                                                handleClick
                                                            }
                                                            onChange={handleChange(
                                                                promo
                                                            )}
                                                            name="hasBanner"
                                                        />
                                                    }
                                                    label="Has banner"
                                                />
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <Access roles="promotionManager">
                                                    {({ hasAccess }) => (
                                                        <IconButton
                                                            onClick={e => {
                                                                e.stopPropagation()
                                                                remove({
                                                                    id:
                                                                        promo.id,
                                                                    slug:
                                                                        promo.slug,
                                                                    name:
                                                                        promo.name,
                                                                })
                                                            }}
                                                            disabled={
                                                                !hasAccess ||
                                                                promo.isNew
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </Access>
                                            </Tooltip>
                                        </>
                                    )}
                                </div>
                            </SummaryContainer>
                        </ExpansionPanelSummary>
                        <Promotion
                            promotion={promo}
                            save={save}
                            cancelAdd={cancelAdd}
                        />
                    </ExpansionPanel>
                ))}
        </BaseSection>
    )
}

Promotions.propTypes = {
    reload: PropTypes.func.isRequired,
    promotions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            SEOTitle: PropTypes.string,
            SEODescription: PropTypes.string,
            pageUrl: PropTypes.string,
            ctaLabel: PropTypes.string,
            ctaUrl: PropTypes.string,
            hasBanner: PropTypes.bool.isRequired,
            hasPage: PropTypes.bool.isRequired,
            isEnabled: PropTypes.bool.isRequired,
        })
    ),
    count: PropTypes.number,
    save: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    cancelAdd: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
}

Promotions.defaultProps = {
    count: 0,
    promotions: [],
}

export default Promotions
