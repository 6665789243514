import { useQuery } from 'relay-hooks'
import graphql from 'babel-plugin-relay/macro'
import ContainerError from '../../Users/UserPage/UserInventory/UserInventoryContainer'
import React from 'react'
import get from 'lodash/get'

import { Redirects } from './Redirects'
import Loader from '../../Users/UsersList/UsersContainer'

const __DEV__ = process.env.NODE_ENV === 'development'

export const RedirectsContainer = () => {
    const { props, error, retry } = useQuery({
        query: graphql`
            query RedirectsContainerQuery {
                appManagement {
                    redirects {
                        id
                        from
                        to
                        entityName
                        entityCuid
                        createdAt
                        updatedAt
                    }
                }
            }
        `,
        dataFrom: 'NETWORK_ONLY',
    })

    if (props) {
        return (
            <Redirects
                redirects={get(props, 'appManagement.redirects', [])}
                reload={retry}
            />
        )
    }

    if (error) {
        console.error(error)

        if (__DEV__) {
            return <div>{error.message}</div>
        }

        return <ContainerError />
    }

    return <Loader />
}
