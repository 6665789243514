/**
 * @flow
 * @relayHash e6f9d04f81bf2983213be1a42f9b94e4
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppManagementDeleteThemeInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type WebsiteContainerDeleteThemeMutationVariables = {|
  input: AppManagementDeleteThemeInput
|};
export type WebsiteContainerDeleteThemeMutationResponse = {|
  +appManagementDeleteTheme: ?{|
    +success: ?boolean,
    +theme: ?{|
      +id: string,
      +name: string,
      +createdAt: ?any,
      +createdByUserCuid: ?string,
      +startAt: ?any,
      +finishAt: ?any,
    |},
    +errors: ?$ReadOnlyArray<?{|
      +statusCode: ?number,
      +message: ?string,
      +meta: ?any,
      +errorCode: ?string,
      +code: ?number,
    |}>,
  |}
|};
export type WebsiteContainerDeleteThemeMutation = {|
  variables: WebsiteContainerDeleteThemeMutationVariables,
  response: WebsiteContainerDeleteThemeMutationResponse,
|};
*/

/*
mutation WebsiteContainerDeleteThemeMutation(
  $input: AppManagementDeleteThemeInput!
) {
  appManagementDeleteTheme(input: $input) {
    success
    theme {
      id
      name
      createdAt
      createdByUserCuid
      startAt
      finishAt
    }
    errors {
      statusCode
      message
      meta
      errorCode
      code
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'input',
                type: 'AppManagementDeleteThemeInput!',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'LinkedField',
                alias: null,
                name: 'appManagementDeleteTheme',
                storageKey: null,
                args: [
                    {
                        kind: 'Variable',
                        name: 'input',
                        variableName: 'input',
                    },
                ],
                concreteType: 'AppManagementDeleteThemePayload',
                plural: false,
                selections: [
                    {
                        kind: 'ScalarField',
                        alias: null,
                        name: 'success',
                        args: null,
                        storageKey: null,
                    },
                    {
                        kind: 'LinkedField',
                        alias: null,
                        name: 'theme',
                        storageKey: null,
                        args: null,
                        concreteType: 'AppManagementTheme',
                        plural: false,
                        selections: [
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'id',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'name',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'createdAt',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'createdByUserCuid',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'startAt',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'finishAt',
                                args: null,
                                storageKey: null,
                            },
                        ],
                    },
                    {
                        kind: 'LinkedField',
                        alias: null,
                        name: 'errors',
                        storageKey: null,
                        args: null,
                        concreteType: 'AppManagementError',
                        plural: true,
                        selections: [
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'statusCode',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'message',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'meta',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'errorCode',
                                args: null,
                                storageKey: null,
                            },
                            {
                                kind: 'ScalarField',
                                alias: null,
                                name: 'code',
                                args: null,
                                storageKey: null,
                            },
                        ],
                    },
                ],
            },
        ]
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'WebsiteContainerDeleteThemeMutation',
            type: 'Mutation',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: (v1 /*: any*/),
        },
        operation: {
            kind: 'Operation',
            name: 'WebsiteContainerDeleteThemeMutation',
            argumentDefinitions: (v0 /*: any*/),
            selections: (v1 /*: any*/),
        },
        params: {
            operationKind: 'mutation',
            name: 'WebsiteContainerDeleteThemeMutation',
            id: null,
            text:
                'mutation WebsiteContainerDeleteThemeMutation(\n  $input: AppManagementDeleteThemeInput!\n) {\n  appManagementDeleteTheme(input: $input) {\n    success\n    theme {\n      id\n      name\n      createdAt\n      createdByUserCuid\n      startAt\n      finishAt\n    }\n    errors {\n      statusCode\n      message\n      meta\n      errorCode\n      code\n    }\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = 'ef7ac7d01e8c322ef2cdd5a77940eb36';
module.exports = node
