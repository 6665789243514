import React from 'react'

import Paper from '@material-ui/core/Paper/Paper'
import { withStyles } from '@material-ui/core'

import Loader from '../Loader'

const styles = {
    tablePlaceholder: {
        height: 200,
    },
}

const TableLoader = props => (
    <Paper className={props.classes.tablePlaceholder}>
        <Loader />
    </Paper>
)

export default withStyles(styles)(TableLoader)
