import React from 'react'
import PropTypes from 'prop-types'
import {
    MenuItem,
    Select as MUISelect,
    FormControl,
    InputLabel,
} from '@material-ui/core'

const Select = ({ options, value, onChange, name, label, autoFocus }) => (
    <FormControl fullWidth>
        <InputLabel htmlFor={name} shrink>
            {label}
        </InputLabel>
        <MUISelect
            inputProps={{ id: name }}
            value={value}
            onChange={onChange}
            autoFocus={autoFocus}
            margin="dense"
        >
            {options.map(({ name, label, value: optionValue }) => (
                <MenuItem
                    value={optionValue}
                    key={name}
                    selected={optionValue === value}
                >
                    {label}
                </MenuItem>
            ))}
        </MUISelect>
    </FormControl>
)

Select.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            name: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    autoFocus: PropTypes.bool,
}

Select.defaultProps = {
    options: [],
    name: '',
    autoFocus: false,
}

export default Select
