import graphql from 'babel-plugin-relay/macro'

export default graphql`
    mutation SignInMutation($input: UserManagementSignInInput!) {
        userManagementSignIn(input: $input) {
            success
            errors {
                message
                errorCode
            }
            token
        }
    }
`
