/**
 * @flow
 * @relayHash 2f9a82ff4ab5a0cbbae10bad1300a6e0
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Logs_data$ref = any;
export type LogsRefetchQueryVariables = {|
  first?: ?number,
  skip?: ?number,
|};
export type LogsRefetchQueryResponse = {|
  +bankingManagement: ?{|
    +logs: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +column: ?string,
          +payment: ?string,
          +value: ?any,
          +oldValue: ?any,
          +createdAt: ?any,
          +createdByCuid: ?string,
          +description: ?string,
        |}
      |}>,
      +$fragmentRefs: Logs_data$ref,
    |}
  |}
|};
export type LogsRefetchQuery = {|
  variables: LogsRefetchQueryVariables,
  response: LogsRefetchQueryResponse,
|};
*/

/*
query LogsRefetchQuery(
  $first: Int
  $skip: Int
) {
  bankingManagement {
    logs(first: $first, offset: $skip) {
      edges {
        node {
          id
          column
          payment
          value
          oldValue
          createdAt
          createdByCuid
          description
          __typename
        }
        cursor
      }
      ...Logs_data
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}

fragment Logs_data on BankingManagementPaymentLogConnection {
  count
  edges {
    node {
      id
      column
      payment
      value
      oldValue
      createdAt
      createdByCuid
      description
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'first',
                type: 'Int',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'skip',
                type: 'Int',
                defaultValue: null,
            },
        ],
        v1 = {
            kind: 'Variable',
            name: 'first',
            variableName: 'first',
        },
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        },
        v3 = {
            kind: 'LinkedField',
            alias: null,
            name: 'edges',
            storageKey: null,
            args: null,
            concreteType: 'BankingManagementPaymentLogEdge',
            plural: true,
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'node',
                    storageKey: null,
                    args: null,
                    concreteType: 'BankingManagementPaymentLog',
                    plural: false,
                    selections: [
                        (v2 /*: any*/),
                        {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'column',
                            args: null,
                            storageKey: null,
                        },
                        {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'payment',
                            args: null,
                            storageKey: null,
                        },
                        {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'value',
                            args: null,
                            storageKey: null,
                        },
                        {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'oldValue',
                            args: null,
                            storageKey: null,
                        },
                        {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'createdAt',
                            args: null,
                            storageKey: null,
                        },
                        {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'createdByCuid',
                            args: null,
                            storageKey: null,
                        },
                        {
                            kind: 'ScalarField',
                            alias: null,
                            name: 'description',
                            args: null,
                            storageKey: null,
                        },
                        {
                            kind: 'ScalarField',
                            alias: null,
                            name: '__typename',
                            args: null,
                            storageKey: null,
                        },
                    ],
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'cursor',
                    args: null,
                    storageKey: null,
                },
            ],
        },
        v4 = {
            kind: 'LinkedField',
            alias: null,
            name: 'pageInfo',
            storageKey: null,
            args: null,
            concreteType: 'PageInfo',
            plural: false,
            selections: [
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'endCursor',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'hasNextPage',
                    args: null,
                    storageKey: null,
                },
            ],
        },
        v5 = [
            (v1 /*: any*/),
            {
                kind: 'Variable',
                name: 'offset',
                variableName: 'skip',
            },
        ]
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'LogsRefetchQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'bankingManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'BankingManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: 'logs',
                            name: '__Logs_logs_connection',
                            storageKey: null,
                            args: [(v1 /*: any*/)],
                            concreteType:
                                'BankingManagementPaymentLogConnection',
                            plural: false,
                            selections: [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    kind: 'FragmentSpread',
                                    name: 'Logs_data',
                                    args: null,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'LogsRefetchQuery',
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'bankingManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'BankingManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'logs',
                            storageKey: null,
                            args: (v5 /*: any*/),
                            concreteType:
                                'BankingManagementPaymentLogConnection',
                            plural: false,
                            selections: [
                                (v3 /*: any*/),
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'count',
                                    args: null,
                                    storageKey: null,
                                },
                                (v4 /*: any*/),
                            ],
                        },
                        {
                            kind: 'LinkedHandle',
                            alias: null,
                            name: 'logs',
                            args: (v5 /*: any*/),
                            handle: 'connection',
                            key: 'Logs_logs',
                            filters: ['first', 'skip'],
                        },
                        (v2 /*: any*/),
                    ],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'LogsRefetchQuery',
            id: null,
            text:
                'query LogsRefetchQuery(\n  $first: Int\n  $skip: Int\n) {\n  bankingManagement {\n    logs(first: $first, offset: $skip) {\n      edges {\n        node {\n          id\n          column\n          payment\n          value\n          oldValue\n          createdAt\n          createdByCuid\n          description\n          __typename\n        }\n        cursor\n      }\n      ...Logs_data\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n\nfragment Logs_data on BankingManagementPaymentLogConnection {\n  count\n  edges {\n    node {\n      id\n      column\n      payment\n      value\n      oldValue\n      createdAt\n      createdByCuid\n      description\n    }\n  }\n}\n',
            metadata: {
                connection: [
                    {
                        count: 'first',
                        cursor: null,
                        direction: 'forward',
                        path: ['bankingManagement', 'logs'],
                    },
                ],
            },
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = 'dcf06a29e07ab68501f1bb1d6c40fe72';
module.exports = node
