import React, { useCallback } from 'react'
import pick from 'lodash/pick'
import { useQuery } from 'relay-hooks'
import get from 'lodash/get'
import map from 'lodash/map'
import graphql from 'babel-plugin-relay/macro'
import slugify from 'slugify'

import Loader from '../../components/Loader'
import ContainerError from '../../components/ContainerError'
import Promotions from './Promotions'
import { useRelayUtils } from '../../shared/hooks/RelayUtils'
import { useModals } from '../../shared/modals'

const PromoQuery = graphql`
    query PromoContainerQuery {
        rewards {
            id
            promotions {
                data {
                    slug
                    ...Promotions_promotion
                }
                count
            }
        }
    }
`

const UpdatePromoMutation = graphql`
    mutation PromoContainerUpdatePromoMutation(
        $input: RewardsUpdatePromotionInput!
    ) {
        rewardsUpdatePromotion(input: $input) {
            promotion {
                ...Promotions_promotion
            }
            success
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const CreatePromoMutation = graphql`
    mutation PromoContainerCreatePromoMutation(
        $input: RewardsCreatePromotionInput!
    ) {
        rewardsCreatePromotion(input: $input) {
            promotion {
                ...Promotions_promotion
            }
            success
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const DeletePromoMutation = graphql`
    mutation PromoContainerDeletePromoMutation(
        $input: RewardsDeletePromotionInput!
    ) {
        rewardsDeletePromotion(input: $input) {
            success
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const __DEV__ = process.env.NODE_ENV === 'development'

const updateFields = [
    'slug',
    'imageUrl',
    'ctaUrl',
    'pageUrl',
    'order',
    'hasBanner',
    'hasPage',
    'isEnabled',
]
const createFields = [
    'name',
    'title',
    'description',
    'ctaLabel',
    'SEOTitle',
    'SEODescription',
    ...updateFields,
]

const PromoContainer = () => {
    const { props, error, retry } = useQuery({
        query: PromoQuery,
        dataFrom: 'NETWORK_ONLY',
    })

    const modals = useModals()
    const { commitMutation, commitLocalUpdate } = useRelayUtils()
    const rewardsId = get(props, 'rewards.id')

    const save = useCallback(
        (update, entity) => {
            const updater = store => {
                const promotion = store.get(entity.id)

                if (!promotion) {
                    return
                }

                promotion.setValue(false, 'isNew')
            }

            const variables = pick(
                entity.isNew ? entity : update,
                entity.isNew ? createFields : updateFields
            )

            return commitMutation({
                mutation: entity.isNew
                    ? CreatePromoMutation
                    : UpdatePromoMutation,
                variables: {
                    input: variables,
                },
                optimisticResponse: {
                    [entity.isNew
                        ? 'rewardsCreatePromotion'
                        : 'rewardsUpdatePromotion']: {
                        success: true,
                        errors: null,
                        promotion: {
                            ...pick(entity, [
                                ...createFields,
                                'id',
                                'createdAt',
                                'createdByCuid',
                                'updatedAAt',
                                'updatedByCuid',
                            ]),
                            ...variables,
                            title: `Promotions.${entity.slug}.title`,
                            ...(entity.isNew && {
                                description: `Promotions.${entity.slug}.description`,
                                SEOTitle: `Promotions.${entity.slug}.SEOTitle`,
                                SEODescription: `Promotions.${entity.slug}.SEODescription`,
                                ctaLabel: `Promotions.${entity.slug}.ctaLabel`,
                            }),
                        },
                    },
                },
                updater,
            })
        },
        [commitMutation]
    )

    const handleAdd = useCallback(
        ({ name }) => {
            commitLocalUpdate(store => {
                const slug = slugify(name, { lower: true })
                const id = btoa(`RewardsPromotion:${slug}`)

                const promotion = store.create(id, 'RewardsPromotion')

                promotion.setValue(id, 'id')
                promotion.setValue(name, 'name')
                promotion.setValue(slug, 'slug')
                promotion.setValue('', 'title')
                promotion.setValue('', 'description')
                promotion.setValue('', 'SEOTitle')
                promotion.setValue('', 'SEODescription')
                promotion.setValue('', 'imageUrl')
                promotion.setValue(true, 'isNew')
                promotion.setValue(true, 'hasPage')
                promotion.setValue(slug, 'pageUrl')
                promotion.setValue(false, 'hasBanner')
                promotion.setValue(false, 'isEnabled')

                const rewards = store.get(rewardsId)

                if (!rewards) {
                    return
                }

                const promotions = rewards.getLinkedRecord('promotions')

                if (!promotions) {
                    return
                }

                const data = promotions.getLinkedRecords('data')

                if (!data) {
                    return
                }

                promotions.setLinkedRecords([promotion, ...data], 'data')
                promotions.setValue(data.length + 1, 'count')
            })
        },
        [commitLocalUpdate, rewardsId]
    )

    const add = useCallback(() => {
        modals.openAddPromotionModal({
            onCreate: handleAdd,
            existedNames: map(get(props, 'rewards.promotions.data'), 'slug'),
        })
    }, [handleAdd, modals, props])

    const cancelAdd = useCallback(
        ({ slug }) => {
            commitLocalUpdate(store => {
                const id = btoa(`RewardsPromotion:${slug}`)
                const promotion = store.get(id)

                if (!promotion || !promotion.getValue('isNew')) {
                    return
                }

                const rewards = store.get(rewardsId)

                if (!rewards) {
                    return
                }

                const promotions = rewards.getLinkedRecord('promotions')

                if (!promotions) {
                    return
                }

                const data = promotions.getLinkedRecords('data')

                if (!data) {
                    return
                }

                const newData = data.filter(item => item.getDataID() !== id)

                store.delete(id)
                promotions.setLinkedRecords(newData, 'data')
                promotions.setValue(promotions.getValue('count') - 1, 'count')
            })
        },
        [commitLocalUpdate, rewardsId]
    )

    const change = useCallback(
        ({ id, name, value }) => {
            commitLocalUpdate(store => {
                const promotion = store.get(id)

                if (!promotion) {
                    return
                }

                promotion.setValue(value, name)
            })
        },
        [commitLocalUpdate]
    )

    const remove = useCallback(
        ({ id, name, slug }) => {
            modals.openConfirmationModal({
                title: `You want to delete promotion "${name}"`,
                onConfirm: () => {
                    const updater = store => {
                        const rewards = store.get(rewardsId)

                        if (!rewards) {
                            return
                        }

                        const promotions = rewards.getLinkedRecord('promotions')

                        if (!promotions) {
                            return
                        }

                        const data = promotions.getLinkedRecords('data')

                        if (!data) {
                            return
                        }

                        const newData = data.filter(
                            item => item.getDataID() !== id
                        )

                        promotions.setLinkedRecords(newData, 'data')

                        store.delete(id)
                        promotions.setValue(
                            promotions.getValue('count') - 1,
                            'count'
                        )
                    }
                    return commitMutation({
                        mutation: DeletePromoMutation,
                        variables: {
                            input: { slug },
                        },
                        optimisticResponse: {
                            rewardsDeletePromotion: {
                                success: true,
                                errors: null,
                            },
                        },
                        updater,
                        optimisticUpdater: updater,
                    })
                },
            })
        },
        [modals, commitMutation, rewardsId]
    )

    if (error) {
        console.error(error)

        if (__DEV__) {
            return <div>{error.message}</div>
        }

        return <ContainerError />
    }

    if (!props) {
        return <Loader />
    }

    return (
        <Promotions
            promotions={get(props, 'rewards.promotions.data', [])}
            add={add}
            change={change}
            count={get(props, 'rewards.promotions.count', 0) || 0}
            reload={retry}
            save={save}
            cancelAdd={cancelAdd}
            remove={remove}
        />
    )
}

export default PromoContainer
