import React from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '@material-ui/core'
import {
    DateTimePicker as MUIDateTimePicker,
    InlineDateTimePicker as MUIInlineDateTimePicker,
} from 'material-ui-pickers'

/**
 * https://material-ui-pickers.dev/api/DateTimePicker
 * @param value
 * @param onChange
 * @param disabled
 * @param disableFuture
 * @param minDate
 * @param maxDate
 * @param autoOk
 * @param format
 * @param label
 * @param ref
 * @param isClearable
 * @param variant
 * @returns {*}
 * @constructor
 */
const DateTimePicker = ({
    value,
    onChange,
    disabled,
    disableFuture,
    disablePast,
    minDate,
    maxDate,
    autoOk,
    format,
    label,
    ref,
    isClearable,
    variant,
}) => {
    const Component =
        variant === 'inline' ? MUIInlineDateTimePicker : MUIDateTimePicker
    return (
        <FormControl margin="dense" fullWidth>
            <Component
                value={value}
                onChange={onChange}
                disabled={disabled}
                disableFuture={disableFuture}
                disablePast={disablePast}
                minDate={minDate}
                maxDate={maxDate}
                autoOk={autoOk}
                format={format}
                label={label}
                ref={ref}
                InputLabelProps={{
                    shrink: true,
                }}
                clearable={isClearable}
            />
        </FormControl>
    )
}

DateTimePicker.propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    disableFuture: PropTypes.bool,
    isClearable: PropTypes.bool,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    format: PropTypes.string,
    views: PropTypes.array,
    label: PropTypes.string,
    inputRef: PropTypes.any,
    variant: PropTypes.oneOf(['static', 'inline', 'dialog']),
}

DateTimePicker.defaultProps = {
    disabled: false,
    disableFuture: false,
    disablePast: false,
    minDate: new Date('1900-01-01'),
    maxDate: new Date('2100-01-01'),
    format: 'dd.MM.yyyy kk:mm:ss zzzz',
    views: ['year', 'month', 'day', 'hours', 'minutes'],
    label: '',
    isClearable: false,
    variant: 'dialog',
}

export { DateTimePicker }
