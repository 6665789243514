/**
 * @flow
 */

/* eslint-disable */

'use strict'

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AdminManagementAccountType = "gaming" | "sponsored" | "test" | "%future added value";
export type AdminManagementUserRole = "admin" | "superAdmin" | "user" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UsersList_adminManagement$ref: FragmentReference;
declare export opaque type UsersList_adminManagement$fragmentType: UsersList_adminManagement$ref;
export type UsersList_adminManagement = {|
  +users: ?{|
    +count: ?number,
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +cuid: ?string,
        +facebookId: ?string,
        +name: ?string,
        +email: ?string,
        +socialEmail: ?string,
        +role: ?AdminManagementUserRole,
        +isBlogger: ?boolean,
        +isBlocked: ?boolean,
        +accountType: ?AdminManagementAccountType,
        +createdAt: ?any,
        +isVerified: ?boolean,
        +premium: ?{|
          +type: ?string,
          +expirationDate: ?any,
        |},
        +isDeleted: ?boolean,
        +deletedAt: ?any,
      |}
    |}>,
  |},
  +$refType: UsersList_adminManagement$ref,
|};
export type UsersList_adminManagement$data = UsersList_adminManagement;
export type UsersList_adminManagement$key = {
  +$data?: UsersList_adminManagement$data,
  +$fragmentRefs: UsersList_adminManagement$ref,
};
*/

const node /*: ReaderFragment*/ = {
    kind: 'Fragment',
    name: 'UsersList_adminManagement',
    type: 'AdminManagement',
    metadata: null,
    argumentDefinitions: [
        {
            kind: 'LocalArgument',
            name: 'first',
            type: 'Int',
            defaultValue: 25,
        },
        {
            kind: 'LocalArgument',
            name: 'offset',
            type: 'Int',
            defaultValue: 0,
        },
        {
            kind: 'LocalArgument',
            name: 'orderBy',
            type: 'String',
            defaultValue: '',
        },
        {
            kind: 'LocalArgument',
            name: 'orderDirection',
            type: 'AdminManagementOrderDirections',
            defaultValue: 'DESC',
        },
        {
            kind: 'LocalArgument',
            name: 'searchText',
            type: 'String',
            defaultValue: '',
        },
    ],
    selections: [
        {
            kind: 'LinkedField',
            alias: null,
            name: 'users',
            storageKey: null,
            args: [
                {
                    kind: 'Variable',
                    name: 'first',
                    variableName: 'first',
                },
                {
                    kind: 'Variable',
                    name: 'offset',
                    variableName: 'offset',
                },
                {
                    kind: 'Variable',
                    name: 'orderBy',
                    variableName: 'orderBy',
                },
                {
                    kind: 'Variable',
                    name: 'orderDirection',
                    variableName: 'orderDirection',
                },
                {
                    kind: 'Variable',
                    name: 'searchText',
                    variableName: 'searchText',
                },
            ],
            concreteType: 'AdminManagementUserConnection',
            plural: false,
            selections: [
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'count',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'edges',
                    storageKey: null,
                    args: null,
                    concreteType: 'AdminManagementUserEdge',
                    plural: true,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'node',
                            storageKey: null,
                            args: null,
                            concreteType: 'AdminManagementUser',
                            plural: false,
                            selections: [
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'id',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'cuid',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'facebookId',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'name',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'email',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'socialEmail',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'role',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'isBlogger',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'isBlocked',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'accountType',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'createdAt',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'isVerified',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'premium',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'AdminManagementpremium',
                                    plural: false,
                                    selections: [
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'type',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'expirationDate',
                                            args: null,
                                            storageKey: null,
                                        },
                                    ],
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'isDeleted',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'deletedAt',
                                    args: null,
                                    storageKey: null,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
}
// prettier-ignore
;(node/*: any*/).hash = '3be425f9392b024c7a317b5286fd41ee';
module.exports = node
