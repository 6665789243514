import graphql from 'babel-plugin-relay/macro'

export default graphql`
    query UserAgentsQuery($userId: String!) {
        adminManagement {
            user(userId: $userId) {
                userAgents {
                    ...UserAgents_userAgents
                }
            }
        }
    }
`
