import React, { useState, useEffect } from 'react'
import ReactCopyToClipboard from 'react-copy-to-clipboard'
import styled, { css } from 'styled-components'
import { Tooltip } from '@material-ui/core'

import { CopyIcon } from '../../icons'

const Container = styled.div`
    align-items: center;
    display: flex;

    svg {
        opacity: 0;
        transition: all 0.3s linear;
        margin-left: 5px;
    }

    &:hover {
        cursor: pointer;

        svg {
            opacity: 1;
        }
    }

    ${props =>
        props.hidden &&
        css`
            display: none;
        `}
`

const CopiedContainer = styled.div`
    color: green;
`

export const CopyToClipboard = ({ text, children }) => {
    const [isCopied, setIsCopied] = useState(false)
    let timer = null

    useEffect(() => () => timer && clearTimeout(timer), [timer])

    const onCopy = () => {
        setIsCopied(true)
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => setIsCopied(false), 3000)
    }

    return (
        <ReactCopyToClipboard text={text} onCopy={onCopy}>
            <Tooltip title="Copy to clipboard" aria-label="Copy to clipboard">
                <div>
                    {isCopied && <CopiedContainer>Is copied!</CopiedContainer>}
                    <Container hidden={isCopied}>
                        {children}
                        <CopyIcon />
                    </Container>
                </div>
            </Tooltip>
        </ReactCopyToClipboard>
    )
}
