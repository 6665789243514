import { QueryRenderer, ReactRelayContext } from 'react-relay'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import compose from 'lodash/fp/compose'
import * as Sentry from '@sentry/browser'

import Loader from '../../../components/Loader'
import ContainerError from '../../../components/ContainerError'
import UserPageQuery from './UserPageQuery'

import UserPage from './UserPage'
import {
    IssuePremiumMutation,
    BlockUserMutation,
    DeleteUserMutation,
    UpdateDeliveryInfoMutation,
} from './mutations'
import { withModals } from '../../../shared/modals'
import { withRelayUtils } from '../../../shared/hooks/RelayUtils'

const __DEV__ = process.env.NODE_ENV === 'development'

class UserPageContainer extends PureComponent {
    state = {
        isError: false,
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo)
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key])
            })
            Sentry.captureException(error)
        })
        this.setState({
            isError: true,
        })
    }

    blockUser = ({ userCuid, reason, expireAt }) =>
        this.props.relayUtils.commitMutation({
            mutation: BlockUserMutation,
            variables: {
                userCuid,
                input: {
                    userCuid,
                    reason,
                    expireAt,
                },
            },
        })

    deleteUser = ({ userCuid }) =>
        this.props.relayUtils.commitMutation({
            mutation: DeleteUserMutation,
            variables: {
                userCuid,
                input: {
                    userCuid,
                },
            },
        })

    /**
     *
     * @param userCuid
     * @param type
     * @param description
     */
    issuePremium = ({ userCuid, type, description }) =>
        this.props.relayUtils.commitMutation({
            mutation: IssuePremiumMutation,
            variables: {
                userCuid,
                input: {
                    userCuid,
                    type,
                    description,
                },
            },
        })

    updateAddress = ({ cuid, phone }) => {
        const userCuid = this.props.match.params.userId
        return this.props.relayUtils.commitMutation({
            mutation: UpdateDeliveryInfoMutation,
            variables: {
                userCuid,
                input: {
                    userCuid,
                    cuid,
                    phone,
                },
            },
        })
    }

    renderPage = ({ error, props }, componentProps) => {
        if (error) {
            console.error(error)

            if (__DEV__) {
                return <div>{error.message}</div>
            }

            return <ContainerError />
        }

        if (this.state.isError) {
            return <ContainerError />
        }

        if (props) {
            return (
                <UserPage
                    user={props.adminManagement.user}
                    adminManagement={props.adminManagement}
                    userManagement={props.userManagement}
                    currentUser={get(props, 'userManagement.currentUser')}
                    premiumConfig={get(
                        props,
                        'appManagement.config.premiumConfig'
                    )}
                    tab={new URLSearchParams(
                        get(componentProps, 'location.search')
                    ).get('tab')}
                    issuePremium={this.issuePremium}
                    blockUser={this.blockUser}
                    deleteUser={this.deleteUser}
                    {...componentProps}
                    updateAddress={this.updateAddress}
                />
            )
        }

        return <Loader />
    }

    render() {
        return (
            <QueryRenderer
                environment={this.context.environment}
                variables={{
                    userId: this.props.match.params.userId,
                }}
                query={UserPageQuery}
                render={response => this.renderPage(response, this.props)}
            />
        )
    }
}

UserPageContainer.defaultProps = {
    relayUtils: {
        commitMutation: PropTypes.func.isRequired,
    },
}

UserPageContainer.contextType = ReactRelayContext

export default compose(
    withModals,
    withRouter,
    withRelayUtils
)(UserPageContainer)
