import React, { Fragment, useState, useCallback } from 'react'
import {
    DialogActions,
    DialogTitle,
    Button,
    CircularProgress,
    DialogContent,
    TextField,
} from '@material-ui/core'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import slugify from 'slugify'

const LoaderContainer = styled.div`
    margin-right: 5px;
    align-items: center;
    display: flex;
`

const AddPromotion = ({ onClose, onCreate, isLoading, existedNames }) => {
    const [name, setName] = useState('')
    const [error, setError] = useState(false)

    const handleNameChange = useCallback(e => setName(e.target.value), [
        setName,
    ])

    const handleCreate = useCallback(() => {
        if (name.length < 3) {
            setError('Min length 3')
            return
        }

        if (existedNames.includes(slugify(name, { lower: true }))) {
            setError('Promotion with that name is already exists')
            return
        }
        onCreate({ name })
    }, [onCreate, name, existedNames])

    const handleKeyPress = useCallback(
        e => {
            if (e.key === 'Enter') {
                handleCreate()
            }
        },
        [handleCreate]
    )

    return (
        <Fragment>
            <DialogTitle>Add new promotion</DialogTitle>
            <DialogContent>
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={handleNameChange}
                    error={Boolean(error)}
                    helperText={error}
                    onKeyPress={handleKeyPress}
                    autoFocus
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="contained">
                    Cancel
                </Button>
                <Button
                    onClick={handleCreate}
                    color="primary"
                    disabled={isLoading}
                >
                    {isLoading && (
                        <LoaderContainer>
                            <CircularProgress size={20} />
                        </LoaderContainer>
                    )}
                    Add
                </Button>
            </DialogActions>
        </Fragment>
    )
}

AddPromotion.propTypes = {
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    isLoading: PropTypes.bool,
    existedNames: PropTypes.arrayOf(PropTypes.string),
}

AddPromotion.defaultProps = {
    onClose: noop,
    onCreate: noop,
    isLoading: false,
    existedNames: [],
}

export default AddPromotion
