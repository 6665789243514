import { QueryRenderer } from 'react-relay'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import environment from '../../../../Environment'
import TableLoader from '../../../../components/TableLoader'
import ContainerError from '../../../../components/ContainerError'
import UserAgentsQuery from './UserAgentsQuery'
import UserAgents from './index'

const __DEV__ = process.env.NODE_ENV === 'development'

class UserAgentsContainer extends PureComponent {
    componentDidMount() {
        this.props.onLoad()
    }

    renderPage = ({ error, props }) => {
        if (error) {
            console.error(error)

            if (__DEV__) {
                return <div>{error.message}</div>
            }

            return <ContainerError />
        }

        if (props) {
            return (
                <UserAgents
                    userAgents={get(props, 'adminManagement.user.userAgents')}
                />
            )
        }

        return <TableLoader />
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                variables={{
                    userId: this.props.userId,
                }}
                query={UserAgentsQuery}
                render={this.renderPage}
            />
        )
    }
}

UserAgentsContainer.propTypes = {
    onLoad: PropTypes.func,
}
UserAgentsContainer.defaultProps = {
    onLoad: () => {},
}

export default UserAgentsContainer
