import React, { Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import throttle from 'lodash/throttle'
import noop from 'lodash/noop'

import {
    Toolbar,
    Typography,
    Paper,
    Button,
    withStyles,
} from '@material-ui/core'
import ReloadIcon from '@material-ui/icons/Replay'
import ActionsContainer from '../ActionsContainer'

const ToolbarContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

const SectionActionsContainer = styled.div`
    margin-left: 5px;
`

const BaseSection = ({
    title,
    classes,
    children,
    icon,
    count,
    reload,
    toolbar,
    actions,
}) => {
    const handleReload = useCallback(throttle(reload, 10000), [])

    return (
        <Fragment>
            <Paper>
                <Toolbar>
                    <ToolbarContainer>
                        <Typography
                            variant="h6"
                            className={classes.sectionHeader}
                        >
                            <span>{title}</span>
                            <span>
                                ({icon}
                                {count})
                            </span>
                            {actions && (
                                <SectionActionsContainer>
                                    <ActionsContainer
                                        actions={actions}
                                        isDisabled={actions.every(
                                            ({ isEnabled }) => !isEnabled
                                        )}
                                    />
                                </SectionActionsContainer>
                            )}
                        </Typography>
                        {toolbar && (
                            <div className={classes.toolbar}>{toolbar}</div>
                        )}
                        {reload !== noop && (
                            <div className={classes.reloadContainer}>
                                <Button color="primary" onClick={handleReload}>
                                    Refresh
                                    <ReloadIcon />
                                </Button>
                            </div>
                        )}
                    </ToolbarContainer>
                </Toolbar>
            </Paper>
            <div className={classes.contentContainer}>{children}</div>
        </Fragment>
    )
}

BaseSection.propTypes = {
    title: PropTypes.string,
    count: PropTypes.number,
    icon: PropTypes.element,
    reload: PropTypes.func,
    toolbar: PropTypes.oneOfType(
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ),
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            isEnabled: PropTypes.bool,
        })
    ),
}

BaseSection.defaultProps = {
    reload: noop,
    toolbar: null,
}

const styles = {
    sectionHeader: {
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        flexGrow: 1,
        '&> span': {
            display: 'flex',
            alignItems: 'center',
            marginRight: 10,
        },
    },
    contentContainer: {
        marginTop: 15,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    toolbar: {
        margin: '10px 5px 10px 0',
    },
    reloadContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}

export default withStyles(styles)(BaseSection)
