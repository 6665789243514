/**
 * @flow
 * @relayHash 4575294e6356b7629e45ec9225057b42
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserInventory_data$ref = any;
export type AdminManagementOrderDirections = "ASC" | "DESC" | "%future added value";
export type UserInventoryRefetchQueryVariables = {|
  userId: string,
  orderBy?: ?string,
  orderDirection?: ?AdminManagementOrderDirections,
  first?: ?number,
  skip?: ?number,
  startDate?: ?any,
  endDate?: ?any,
  statuses?: ?$ReadOnlyArray<?string>,
|};
export type UserInventoryRefetchQueryResponse = {|
  +adminManagement: ?{|
    +user: ?{|
      +$fragmentRefs: UserInventory_data$ref
    |}
  |}
|};
export type UserInventoryRefetchQuery = {|
  variables: UserInventoryRefetchQueryVariables,
  response: UserInventoryRefetchQueryResponse,
|};
*/

/*
query UserInventoryRefetchQuery(
  $userId: String!
  $orderBy: String
  $orderDirection: AdminManagementOrderDirections
  $first: Int
  $skip: Int
  $startDate: DateTime
  $endDate: DateTime
  $statuses: [String]
) {
  adminManagement {
    user(userId: $userId) {
      ...UserInventory_data_2Riqfa
      id
    }
    id
  }
}

fragment UserInventory_data_2Riqfa on AdminManagementUser {
  inventory(orderBy: $orderBy, orderDirection: $orderDirection, first: $first, skip: $skip, startDate: $startDate, endDate: $endDate, statuses: $statuses) {
    count
    edges {
      node {
        id
        cuid
        status
        createdAt
        disposedAt
        type
        caseBox {
          title
          slug
          price
          id
        }
        product {
          title
          slug
          price
          image {
            x1 {
              default
              id
            }
            id
          }
          id
        }
        meta
        cart {
          cuid
          status
        }
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'userId',
                type: 'String!',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'orderBy',
                type: 'String',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'orderDirection',
                type: 'AdminManagementOrderDirections',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'first',
                type: 'Int',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'skip',
                type: 'Int',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'startDate',
                type: 'DateTime',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'endDate',
                type: 'DateTime',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'statuses',
                type: '[String]',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'Variable',
                name: 'userId',
                variableName: 'userId',
            },
        ],
        v2 = [
            {
                kind: 'Variable',
                name: 'endDate',
                variableName: 'endDate',
            },
            {
                kind: 'Variable',
                name: 'first',
                variableName: 'first',
            },
            {
                kind: 'Variable',
                name: 'orderBy',
                variableName: 'orderBy',
            },
            {
                kind: 'Variable',
                name: 'orderDirection',
                variableName: 'orderDirection',
            },
            {
                kind: 'Variable',
                name: 'skip',
                variableName: 'skip',
            },
            {
                kind: 'Variable',
                name: 'startDate',
                variableName: 'startDate',
            },
            {
                kind: 'Variable',
                name: 'statuses',
                variableName: 'statuses',
            },
        ],
        v3 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        },
        v4 = {
            kind: 'ScalarField',
            alias: null,
            name: 'cuid',
            args: null,
            storageKey: null,
        },
        v5 = {
            kind: 'ScalarField',
            alias: null,
            name: 'status',
            args: null,
            storageKey: null,
        },
        v6 = {
            kind: 'ScalarField',
            alias: null,
            name: 'title',
            args: null,
            storageKey: null,
        },
        v7 = {
            kind: 'ScalarField',
            alias: null,
            name: 'slug',
            args: null,
            storageKey: null,
        },
        v8 = {
            kind: 'ScalarField',
            alias: null,
            name: 'price',
            args: null,
            storageKey: null,
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'UserInventoryRefetchQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AdminManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'user',
                            storageKey: null,
                            args: (v1 /*: any*/),
                            concreteType: 'AdminManagementUser',
                            plural: false,
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: 'UserInventory_data',
                                    args: (v2 /*: any*/),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'UserInventoryRefetchQuery',
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AdminManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'user',
                            storageKey: null,
                            args: (v1 /*: any*/),
                            concreteType: 'AdminManagementUser',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'inventory',
                                    storageKey: null,
                                    args: (v2 /*: any*/),
                                    concreteType:
                                        'AdminManagementInventoryConnection',
                                    plural: false,
                                    selections: [
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'count',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'edges',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'AdminManagementInventoryEdge',
                                            plural: true,
                                            selections: [
                                                {
                                                    kind: 'LinkedField',
                                                    alias: null,
                                                    name: 'node',
                                                    storageKey: null,
                                                    args: null,
                                                    concreteType:
                                                        'AdminManagementInventory',
                                                    plural: false,
                                                    selections: [
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/),
                                                        {
                                                            kind: 'ScalarField',
                                                            alias: null,
                                                            name: 'createdAt',
                                                            args: null,
                                                            storageKey: null,
                                                        },
                                                        {
                                                            kind: 'ScalarField',
                                                            alias: null,
                                                            name: 'disposedAt',
                                                            args: null,
                                                            storageKey: null,
                                                        },
                                                        {
                                                            kind: 'ScalarField',
                                                            alias: null,
                                                            name: 'type',
                                                            args: null,
                                                            storageKey: null,
                                                        },
                                                        {
                                                            kind: 'LinkedField',
                                                            alias: null,
                                                            name: 'caseBox',
                                                            storageKey: null,
                                                            args: null,
                                                            concreteType:
                                                                'AdminManagementInventoryCaseBox',
                                                            plural: false,
                                                            selections: [
                                                                (v6 /*: any*/),
                                                                (v7 /*: any*/),
                                                                (v8 /*: any*/),
                                                                (v3 /*: any*/),
                                                            ],
                                                        },
                                                        {
                                                            kind: 'LinkedField',
                                                            alias: null,
                                                            name: 'product',
                                                            storageKey: null,
                                                            args: null,
                                                            concreteType:
                                                                'AdminManagementInventoryProduct',
                                                            plural: false,
                                                            selections: [
                                                                (v6 /*: any*/),
                                                                (v7 /*: any*/),
                                                                (v8 /*: any*/),
                                                                {
                                                                    kind:
                                                                        'LinkedField',
                                                                    alias: null,
                                                                    name:
                                                                        'image',
                                                                    storageKey: null,
                                                                    args: null,
                                                                    concreteType:
                                                                        'AdminManagementImage',
                                                                    plural: false,
                                                                    selections: [
                                                                        {
                                                                            kind:
                                                                                'LinkedField',
                                                                            alias: null,
                                                                            name:
                                                                                'x1',
                                                                            storageKey: null,
                                                                            args: null,
                                                                            concreteType:
                                                                                'AdminManagementImageUrl',
                                                                            plural: false,
                                                                            selections: [
                                                                                {
                                                                                    kind:
                                                                                        'ScalarField',
                                                                                    alias: null,
                                                                                    name:
                                                                                        'default',
                                                                                    args: null,
                                                                                    storageKey: null,
                                                                                },
                                                                                (v3 /*: any*/),
                                                                            ],
                                                                        },
                                                                        (v3 /*: any*/),
                                                                    ],
                                                                },
                                                                (v3 /*: any*/),
                                                            ],
                                                        },
                                                        {
                                                            kind: 'ScalarField',
                                                            alias: null,
                                                            name: 'meta',
                                                            args: null,
                                                            storageKey: null,
                                                        },
                                                        {
                                                            kind: 'LinkedField',
                                                            alias: null,
                                                            name: 'cart',
                                                            storageKey: null,
                                                            args: null,
                                                            concreteType:
                                                                'AdminManagementInventoryCart',
                                                            plural: false,
                                                            selections: [
                                                                (v4 /*: any*/),
                                                                (v5 /*: any*/),
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                (v3 /*: any*/),
                            ],
                        },
                        (v3 /*: any*/),
                    ],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'UserInventoryRefetchQuery',
            id: null,
            text:
                'query UserInventoryRefetchQuery(\n  $userId: String!\n  $orderBy: String\n  $orderDirection: AdminManagementOrderDirections\n  $first: Int\n  $skip: Int\n  $startDate: DateTime\n  $endDate: DateTime\n  $statuses: [String]\n) {\n  adminManagement {\n    user(userId: $userId) {\n      ...UserInventory_data_2Riqfa\n      id\n    }\n    id\n  }\n}\n\nfragment UserInventory_data_2Riqfa on AdminManagementUser {\n  inventory(orderBy: $orderBy, orderDirection: $orderDirection, first: $first, skip: $skip, startDate: $startDate, endDate: $endDate, statuses: $statuses) {\n    count\n    edges {\n      node {\n        id\n        cuid\n        status\n        createdAt\n        disposedAt\n        type\n        caseBox {\n          title\n          slug\n          price\n          id\n        }\n        product {\n          title\n          slug\n          price\n          image {\n            x1 {\n              default\n              id\n            }\n            id\n          }\n          id\n        }\n        meta\n        cart {\n          cuid\n          status\n        }\n      }\n    }\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = 'e06c72be1cac5f9ac1128da6bd0ff337';
module.exports = node
