import graphql from 'babel-plugin-relay/macro'

export default graphql`
    query UserPageQuery($userId: String!) {
        adminManagement {
            user(userId: $userId) {
                ...UserPage_user
            }
            ...BalanceEdit_adminManagement
        }
        userManagement {
            ...BalanceEdit_userManagement
            currentUser {
                ...UserPage_currentUser
            }
        }
        appManagement {
            config {
                premiumConfig {
                    ...UserPage_premiumConfig
                }
            }
        }
    }
`
