import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import bytes from 'bytes'

const resultTypes = {
    URL: 'url',
    TEXT: 'text',
}

const FileUploader = ({
    children,
    onUpload,
    accept,
    resultType,
    maxSize,
    onError,
}) => {
    const ref = useRef()

    const _handleUpload = useCallback(
        e => {
            const reader = new FileReader()
            const [file] = e.target.files

            if (!file) {
                return
            }

            if (accept && file.type !== accept) {
                onError({
                    code: 'INVALID_FILE_TYPE',
                    message: `File mime type is not allowed ${file.type}`,
                    type: file.type,
                    accept,
                })
                return
            }

            if (maxSize && file.size > maxSize) {
                onError({
                    code: 'MAX_SIZE_EXCEEDED',
                    message: `Max size exceeded ${bytes(file.size)} / ${bytes(
                        maxSize
                    )}`,
                    size: file.size,
                })
                return
            }

            reader.onload = () => {
                if (onUpload) {
                    onUpload(reader.result)
                }
            }

            if (resultType === resultTypes.URL) {
                reader.readAsDataURL(file)
                return
            }

            reader.readAsText(file)
        },
        [accept, maxSize, resultType, onError, onUpload]
    )

    const _handleUploadOpen = useCallback(() => {
        ref.current.click()
    }, [ref])

    return (
        <div onClick={_handleUploadOpen}>
            {children}
            <input
                ref={ref}
                type="file"
                style={{ display: 'none' }}
                onChange={_handleUpload}
                {...(accept && { accept })}
            />
        </div>
    )
}

FileUploader.propTypes = {
    onUpload: PropTypes.func,
    onError: PropTypes.func,
    accept: PropTypes.string,
    resultType: PropTypes.oneOf(Object.values(resultTypes)),
    maxSize: PropTypes.number,
}

FileUploader.defaultProps = {
    onUpload: null,
    onError: noop,
    accept: null,
    resultType: resultTypes.TEXT,
    maxSize: null,
}

export default FileUploader
