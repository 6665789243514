/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserAgents_userAgents$ref: FragmentReference;
declare export opaque type UserAgents_userAgents$fragmentType: UserAgents_userAgents$ref;
export type UserAgents_userAgents = $ReadOnlyArray<{|
  +action: ?string,
  +date: ?any,
  +userAgent: ?string,
  +ip: ?string,
  +countryCode: ?string,
  +countryName: ?string,
  +deviceType: ?string,
  +device: ?{|
    +type: ?string,
    +vendor: ?string,
    +model: ?string,
  |},
  +browser: ?{|
    +name: ?string,
    +version: ?string,
  |},
  +os: ?{|
    +name: ?string,
    +version: ?string,
  |},
  +engine: ?{|
    +name: ?string,
    +version: ?string,
  |},
  +$refType: UserAgents_userAgents$ref,
|}>;
export type UserAgents_userAgents$data = UserAgents_userAgents;
export type UserAgents_userAgents$key = $ReadOnlyArray<{
  +$data?: UserAgents_userAgents$data,
  +$fragmentRefs: UserAgents_userAgents$ref,
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "version",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "UserAgents_userAgents",
  "type": "AdminManagementUserAgent",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "action",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "date",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "userAgent",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ip",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "countryCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "countryName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "deviceType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "device",
      "storageKey": null,
      "args": null,
      "concreteType": "AdminManagementUserAgentDevice",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "vendor",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "model",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "browser",
      "storageKey": null,
      "args": null,
      "concreteType": "AdminManagementUserAgentBrowser",
      "plural": false,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "os",
      "storageKey": null,
      "args": null,
      "concreteType": "AdminManagementUserAgentOS",
      "plural": false,
      "selections": (v0/*: any*/)
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "engine",
      "storageKey": null,
      "args": null,
      "concreteType": "AdminManagementUserAgentEngine",
      "plural": false,
      "selections": (v0/*: any*/)
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '2ce809daf96d5872844e63280fa10d62';
module.exports = node;
