import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = {
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
}

const Loader = props => (
    <div className={props.classes.loaderContainer}>
        <CircularProgress size={props.size} />
    </div>
)

Loader.defaultProps = {
    size: 60,
}

export default withStyles(styles)(Loader)
