import React, { Fragment, PureComponent } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import format from 'date-fns/format'
import { commitMutation, createFragmentContainer } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import isDate from 'lodash/isDate'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import chunk from 'lodash/chunk'
import keyBy from 'lodash/keyBy'
import set from 'lodash/set'
import compose from 'lodash/fp/compose'
import classNames from 'classnames'
import Sticky from 'react-sticky-el'
import subYears from 'date-fns/subYears'

import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormHelperText from '@material-ui/core/FormHelperText'
import Dialog from '@material-ui/core/Dialog'
import InputAdornment from '@material-ui/core/InputAdornment'
import MuiTabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { Fab, Tooltip, withWidth } from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import HistoryIcon from '@material-ui/icons/History'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import EmailIcon from '@material-ui/icons/Email'
import PersonIcon from '@material-ui/icons/Person'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import DateRangeIcon from '@material-ui/icons/DateRange'
import PhoneIcon from '@material-ui/icons/Phone'
import AssignmentIcon from '@material-ui/icons/Assignment'
import UserAgentIcon from '@material-ui/icons/PhoneIphoneOutlined'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import WarningIcon from '@material-ui/icons/Warning'
import HelpIcon from '@material-ui/icons/Help'
import CakeIcon from '@material-ui/icons/Cake'
import LockIcon from '@material-ui/icons/Lock'
import InfoIcon from '@material-ui/icons/Info'
import DeleteIcon from '@material-ui/icons/Delete'
import LaunchIcon from '@material-ui/icons/Launch'

import withStyles from '@material-ui/core/styles/withStyles'
import { green, red, yellow } from '@material-ui/core/colors'

import User from '../../../models/User'
import UpdateUserMutation from './mutations/UpdateUser'
import RequireAddressKycMutation from './mutations/RequireAddressKyc'
import environment from '../../../Environment'
import UserLog from './UserLog'
import UserTransactions from './UserTransactions'
import UserInventory from './UserInventory/UserInventoryContainer'
import { BalanceEdit, balanceEditType } from './BalanceEdit'
import UserDeliveryInfo from './UserDeliveryInfo'
import UpdateUserDto from '../../../models/UpdateUserDto'
import UserAgents from './UserAgents/UserAgentsContainer'
import { FacebookIcon, PremiumBadgeIcon } from '../../../shared/icons'
import { DatePicker } from '../../../shared/components/DatePicker'
import ActionsContainer from '../../../shared/components/ActionsContainer'
import { withModals } from '../../../shared/modals'
import ReactCountryFlag from 'react-country-flag'
import CountrySelect from '../../../shared/components/CountrySelect'
import IconButton from '@material-ui/core/IconButton'

const dataTypes = {
    NUMERIC: 0,
    TEXT: 1,
    BOOLEAN: 2,
    HIGHLIGHTED_TEXT: 3,
    LABEL: 4,
    ENUM: 5,
    DATE: 6,
    DATE_TIME: 7,
    COUNTRY: 8,
}

const userPageTabs = {
    TRANSACTIONS: 'transactions',
    INVENTORY: 'inventory',
    LOGS: 'logs',
    DELIVERY_ADDRESSES: 'delivery-addresses',
    USER_AGENTS: 'user-agents',
    USER_INFO: 'info',
}

const tabs = {
    [userPageTabs.TRANSACTIONS]: 0,
    [userPageTabs.INVENTORY]: 1,
    [userPageTabs.LOGS]: 2,
    [userPageTabs.DELIVERY_ADDRESSES]: 3,
    [userPageTabs.USER_AGENTS]: 4,
    [userPageTabs.USER_INFO]: 5,
    0: [userPageTabs.TRANSACTIONS],
    1: [userPageTabs.INVENTORY],
    2: [userPageTabs.LOGS],
    3: [userPageTabs.DELIVERY_ADDRESSES],
    4: [userPageTabs.USER_AGENTS],
    5: [userPageTabs.USER_INFO],
}

const kycIcons = classes => ({
    [User.kycStatus.ACCEPTED]: (
        <VerifiedUserIcon className={classes.verifiedIcon} />
    ),
    [User.kycStatus.PENDING]: <HelpIcon className={classes.warningIcon} />,
    [User.kycStatus.DECLINED]: <WarningIcon className={classes.declinedIcon} />,
})

const amlIcons = classes => ({
    [User.amlStatus.ACCEPTED]: (
        <VerifiedUserIcon className={classes.verifiedIcon} />
    ),
    [User.amlStatus.PENDING]: <HelpIcon className={classes.warningIcon} />,
    [User.amlStatus.DECLINED]: <WarningIcon className={classes.declinedIcon} />,
})

const canEditUserInfo = user =>
    [User.kycStatus.ACCEPTED, User.kycStatus.PENDING].includes(user.kycStatus)

const Tabs = withStyles({
    flexContainer: {
        height: '100%',
    },
})(MuiTabs)

const ExpansionPanelDetails = withStyles({
    root: {
        flexDirection: 'column',
        paddingTop: 0,
    },
})(MuiExpansionPanelDetails)

const ExpansionPanelSummary = withStyles({
    content: {
        flexDirection: 'column',
    },
    expanded: {
        '&> div:first-child': {
            margin: '12px 0',
        },
    },
})(MuiExpansionPanelSummary)

class UserPage extends PureComponent {
    get user() {
        return new User(get(this.props, 'user', {}))
    }

    state = {
        isChanged: false,
        changedValues: new Set(),
        oldEntity: {
            ...this.user,
        },
        entity: {
            ...this.user,
        },
        validationErrors: {
            notes: [],
        },
        validators: {
            notes: [
                (value = '') => {
                    const { oldEntity, entity } = this.state

                    return !oldEntity.isBlocked &&
                        entity.isBlocked &&
                        value.trim().length === 0
                        ? 'Block status set. Field is required'
                        : null
                },
            ],
            firstName: [
                value =>
                    this.state.changedValues.has('firstName') &&
                    !value.trim() &&
                    'Is required',
            ],
            lastName: [
                value =>
                    this.state.changedValues.has('lastName') &&
                    !value.trim() &&
                    'Is required',
            ],
            // TODO: Admin config to use same validation on front-end and back-end
            winItemMaxPrice: [
                value =>
                    this.state.changedValues.has('winItemMaxPrice') &&
                    value < 100 &&
                    'Min limit is 100',
                (value, { winEpicItemMinPrice }) =>
                    this.state.changedValues.has('winItemMaxPrice') &&
                    winEpicItemMinPrice &&
                    value <= winEpicItemMinPrice &&
                    'Max price should be greater than min price!',
                (value, { isBlogger }) =>
                    this.state.changedValues.has('isBlogger') &&
                    isBlogger &&
                    !value &&
                    'Is required when user is boosted',
            ],
            winEpicItemMinPrice: [
                value =>
                    this.state.changedValues.has('winEpicItemMinPrice') &&
                    value < 0.1 &&
                    'Min limit is 0.1',
                (value, { winItemMaxPrice }) =>
                    this.state.changedValues.has('winEpicItemMinPrice') &&
                    winItemMaxPrice &&
                    value >= winItemMaxPrice &&
                    'Min epic price should be less than max price!',
                (value, { isBlogger }) =>
                    this.state.changedValues.has('isBlogger') &&
                    isBlogger &&
                    !value &&
                    'Is required when user is boosted',
            ],
        },
        isSaving: false,
        isSavingSuccess: true,
        isEditBalance: false,
        balanceEditType: balanceEditType.DEPOSIT,
        tabIndex: tabs[this.props.tab] || 0,
    }
    displayName = 'UserPage'
    tabsNode = React.createRef()

    columnsConfig = [
        { label: 'Id', name: 'cuid', type: dataTypes.TEXT, isReadOnly: true },
        { label: 'Name', name: 'name', type: dataTypes.TEXT, isReadOnly: true },
        {
            label: 'First name',
            name: 'firstName',
            type: dataTypes.TEXT,
            isReadOnly: canEditUserInfo,
        },
        {
            label: 'Last name',
            name: 'lastName',
            type: dataTypes.TEXT,
            isReadOnly: canEditUserInfo,
        },
        {
            label: 'Email',
            name: 'email',
            type: dataTypes.TEXT,
            isReadOnly: true,
            endAdornment: (value, { isVerified }) =>
                isVerified ? (
                    <VerifiedUserIcon className={this.classes.verifiedIcon} />
                ) : (
                    <WarningIcon className={this.classes.warningIcon} />
                ),
        },
        {
            label: 'Social Email',
            name: 'socialEmail',
            type: dataTypes.TEXT,
            isReadOnly: true,
        },
        {
            label: 'Date of Birth',
            name: 'dateOfBirth',
            type: dataTypes.DATE,
            maxDate: subYears(new Date(), 18),
            disableFuture: true,
            getter: ({ dateOfBirth }) => new Date(dateOfBirth),
            isReadOnly: canEditUserInfo,
            endAdornment: <CakeIcon />,
        },
        {
            label: 'Created At',
            name: 'createdAt',
            getter: value =>
                format(new Date(value.createdAt), 'dd.MM.yyyy kk:mm:ss'),
            type: dataTypes.TEXT,
            isReadOnly: true,
        },
        {
            label: 'VIP expire at',
            name: 'premium.expirationDate',
            type: dataTypes.DATE_TIME,
            getter: ({ premium }) =>
                premium && new Date(premium.expirationDate),
            isReadOnly: true,
            endAdornment: <PremiumBadgeIcon color={yellow['A700']} />,
        },
        {
            label: 'VIP type',
            name: 'premium.type',
            type: dataTypes.TEXT,
            getter: ({ premium }) => get(premium, 'type'),
            isReadOnly: true,
        },
        {
            label: 'Phone number',
            name: 'phone',
            type: dataTypes.TEXT,
            startAdornment: <PhoneIcon />,
        },
        {
            label: 'Country',
            name: 'countryCode',
            type: dataTypes.COUNTRY,
            startAdornment: (_, { countryCode }) =>
                countryCode ? (
                    <ReactCountryFlag code={countryCode} svg />
                ) : null,
            isReadOnly: canEditUserInfo,
        },
    ]

    columnsConfigMap = keyBy(this.columnsConfig, 'name')

    classes = this.props.classes

    get actions() {
        return [
            {
                name: 'issuePremiumSubscription',
                label: 'Issue VIP subscription',
                icon: <PremiumBadgeIcon color={yellow['A700']} />,
                isEnabled: !this.user.isPremium && !this.state.isSaving,
                action: () => {
                    this.props.openIssuePremiumModal({
                        premiumTypes: this.props.premiumConfig.types,
                        onConfirm: ({ type, description }) =>
                            this.props.issuePremium({
                                type,
                                description,
                                userCuid: this.user.cuid,
                            }),
                    })
                },
            },
            {
                name: 'blockUser',
                label: 'Block user',
                icon: <LockIcon />,
                isEnabled: !this.user.isBlocked && !this.state.isSaving,
                action: () => {
                    this.props.openBlockUserModal({
                        onConfirm: ({ type, reason, expireAt }) =>
                            this.props.blockUser({
                                type,
                                expireAt,
                                reason,
                                userCuid: this.user.cuid,
                            }),
                    })
                },
            },
            {
                name: 'deleteUser',
                label: 'Delete user',
                icon: <DeleteIcon color="error" />,
                isEnabled: !this.user.isDeleted && !this.state.isSaving,
                action: () => {
                    this.props.openConfirmationModal({
                        title: 'You want to delete user',
                        onConfirm: () =>
                            this.props.deleteUser({
                                userCuid: this.user.cuid,
                            }),
                    })
                },
            },
        ]
    }

    reset = e => {
        e.stopPropagation()
        this.setState(state => ({
            isChanged: false,
            changedValues: new Set(),
            entity: {
                ...state.oldEntity,
            },
            validationErrors: { notes: [] },
        }))
    }

    validate = () => {
        let isValid = true
        const validationErrors = reduce(
            this.state.validators,
            (result, validators = [], field) => {
                result[field] = validators.reduce((total, validator) => {
                    const validationResult = validator(
                        this.state.entity[field],
                        this.state.entity
                    )
                    if (validationResult) {
                        if (isValid) {
                            if (this[field]) {
                                this[field].scrollIntoView()
                            }
                            isValid = false
                        }
                        total.push(validationResult)
                    }

                    return total
                }, [])

                return result
            },
            {}
        )

        this.setState({ validationErrors })

        return isValid
    }

    handleTabChange = (event, value) => {
        this.setState({
            tabIndex: value,
        })
        this.props.history.replace(`?tab=${tabs[value]}`)
    }

    save = e => {
        e.preventDefault()
        this.setState({ isSaving: true }, () => {
            if (!this.validate()) {
                this.setState({ isSaving: false })
                return
            }

            const variables = reduce(
                this.state.entity,
                (total, value, prop) => {
                    if (!isEqual(this.state.oldEntity[prop], value)) {
                        total[prop] = value
                    }

                    return total
                },
                { cuid: this.state.entity.cuid }
            )

            commitMutation(environment, {
                mutation: UpdateUserMutation,
                variables: {
                    input: new UpdateUserDto(variables),
                    userId: this.user.cuid,
                },
                onCompleted: (response, errors) => {
                    this.setState({ isSaving: false })
                    if (errors || !response.adminManagementUpdateUser.success) {
                        this.toggleSavingErrorDialog()
                        return
                    }

                    this.onSaved()
                },
                onError: err => {
                    console.error(err)
                    this.setState({ isSaving: false })
                    this.toggleSavingErrorDialog()
                },
            })
        })
    }

    onSaved = () => {
        this.setState(state => ({
            isChanged: false,
            changedValues: new Set(),
            isSaving: false,
            oldEntity: {
                ...state.entity,
            },
        }))
    }

    getChanged = (property, value) => {
        const oldEntity = this.state.oldEntity
        const changedValues = new Set()
        for (const prop in oldEntity) {
            if (!oldEntity.hasOwnProperty(prop)) {
                continue
            }

            if (
                (prop !== property &&
                    !isEqual(oldEntity[prop], this.state.entity[prop])) ||
                (prop === property && !isEqual(oldEntity[prop], value))
            ) {
                changedValues.add(property)
            }
        }

        return changedValues
    }

    _handlePropertyChange = (property, value) => {
        let newValue = value

        if (
            property === 'winItemMaxPrice' ||
            property === 'winEpicItemMinPrice'
        ) {
            newValue = parseFloat(newValue.replace(/,/g, ''))
        }

        const changed = this.getChanged(property, newValue)
        this.setState(state => {
            const entity = { ...state.entity }
            set(entity, property, newValue)
            return {
                isChanged: changed.size !== 0,
                changedValues: changed,
                entity,
            }
        })
    }

    handlePropertyChange = ({ target }) => {
        const property = target.name
        const value = target.type === 'checkbox' ? target.checked : target.value

        if (!property) {
            return
        }

        this._handlePropertyChange(property, value)
    }

    createRequireAddressKycHandler = addressCuid => () => {
        const areYouSure = window.confirm('Are you sure?')
        if (!areYouSure) {
            return
        }

        commitMutation(environment, {
            mutation: RequireAddressKycMutation,
            variables: {
                input: {
                    userCuid: this.user.cuid,
                    addressCuid,
                },
            },
            onError: err => console.error(err),
        })
    }

    increaseBalance = () => {
        this.setState({
            balanceEditType: balanceEditType.DEPOSIT,
        })
        this.toggleEditBalance()
    }

    decreaseBalance = () => {
        this.setState({
            balanceEditType: balanceEditType.WITHDRAW,
        })
        this.toggleEditBalance()
    }

    toggleEditBalance = () => {
        this.setState(state => ({
            isEditBalance: !state.isEditBalance,
        }))
    }

    toggleSavingErrorDialog = () => {
        this.setState(state => ({ isSavingSuccess: !state.isSavingSuccess }))
    }

    onBalanceChanged() {
        this.setState(state => ({
            oldEntity: { ...state.oldEntity, balance: state.entity.balance },
        }))
    }

    editBalance = balanceChangeProps => {
        const {
            balanceEditType: entityBalanceEditType,
            entity: { balance },
        } = this.state

        const newBalance =
            balance +
            balanceChangeProps.money *
                (entityBalanceEditType === balanceEditType.DEPOSIT ? 1 : -1)

        this.setState(
            state => ({
                isSaving: true,
                entity: {
                    ...state.entity,
                    balance: newBalance,
                },
            }),
            () => {
                const variables = {
                    cuid: this.state.entity.cuid,
                    balance: newBalance,
                    balanceNotes: balanceChangeProps.notes,
                    manualBalanceChangeReasonCuid:
                        balanceChangeProps.categoryCuid,
                }

                commitMutation(environment, {
                    mutation: UpdateUserMutation,
                    variables: {
                        input: new UpdateUserDto(variables),
                        userId: this.user.cuid,
                    },
                    onCompleted: (response, errors) => {
                        this.setState({ isSaving: false })
                        if (
                            errors ||
                            !response.adminManagementUpdateUser.success
                        ) {
                            this.toggleSavingErrorDialog()
                            return
                        }

                        this.onBalanceChanged()
                    },
                    onError: err => {
                        console.error(err)
                        this.setState(state => ({
                            isSaving: false,
                            balance: state.oldEntity.balance,
                        }))
                        this.toggleSavingErrorDialog()
                    },
                })
            }
        )
    }

    getKycIcon = kycStatus =>
        kycIcons(this.classes)[kycStatus] || (
            <HelpIcon className={this.classes.warningIcon} />
        )

    getAMLIcon = amlStatus =>
        amlIcons(this.classes)[amlStatus] || (
            <HelpIcon className={this.classes.warningIcon} />
        )

    stopPropagation = e => e.stopPropagation()

    get header() {
        const { isChanged, entity: user, isSaving } = this.state

        return (
            <Sticky
                stickyClassName={classNames(
                    'sticky',
                    this.classes.headerContainer
                )}
            >
                <ExpansionPanel
                    defaultExpanded={!['xs', 'sm'].includes(this.props.width)}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            variant="h6"
                            className={this.classes.toolbarTitle}
                        >
                            {user.name} ({user.cuid})
                            {user.isPremium && (
                                <PremiumBadgeIcon
                                    color={yellow['A700']}
                                    fontSize="large"
                                />
                            )}
                        </Typography>
                        <div className={this.classes.actions}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classNames(
                                    this.classes.saveButton,
                                    this.classes.button
                                )}
                                onClick={this.stopPropagation}
                                size="large"
                                disabled={!isChanged || isSaving}
                                type="submit"
                            >
                                Save
                                {isSaving && (
                                    <CircularProgress
                                        size={24}
                                        className={this.classes.buttonProgress}
                                    />
                                )}
                            </Button>
                            {isChanged && !isSaving && (
                                <Button
                                    variant="contained"
                                    color="default"
                                    size="large"
                                    disabled={!isChanged}
                                    onClick={this.reset}
                                    className={this.classes.button}
                                >
                                    Cancel
                                </Button>
                            )}
                            <ActionsContainer actions={this.actions} />
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography
                            variant="subtitle1"
                            className={this.classes.headerInfoContainer}
                            style={{ alignItems: 'center', display: 'flex' }}
                        >
                            KYC status: {user.kycStatus}{' '}
                            {this.getKycIcon(user.kycStatus)}
                            {user.kycStatus === User.kycStatus.DECLINED && (
                                <Typography variant="subtitle1" color="error">
                                    {user.kycDeclineReason}
                                </Typography>
                            )}
                            {user.kycVerificationId && (
                                <Tooltip title="Open in Veriff Station">
                                    <a
                                        href={`https://station.veriff.com/verifications/${user.kycVerificationId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <IconButton
                                            size="small"
                                            style={{ padding: 6 }}
                                        >
                                            <LaunchIcon />
                                        </IconButton>
                                    </a>
                                </Tooltip>
                            )}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={this.classes.headerInfoContainer}
                        >
                            AML status: {user.AMLStatus}{' '}
                            {this.getAMLIcon(user.AMLStatus)}
                        </Typography>
                        <Typography variant="subtitle1">
                            <div className={this.classes.headerInfoContainer}>
                                <span className={this.classes.headerInfoItem}>
                                    <AttachMoneyIcon fontSize="small" />{' '}
                                    {user.balance}
                                </span>
                                <span className={this.classes.headerInfoItem}>
                                    <EmailIcon fontSize="small" />{' '}
                                    {user.socialEmail || user.email}
                                </span>
                                {user.facebookId && (
                                    <span
                                        className={this.classes.headerInfoItem}
                                    >
                                        <FacebookIcon fontSize="small" />{' '}
                                        {user.facebookId}
                                    </span>
                                )}
                                <span className={this.classes.headerInfoItem}>
                                    <SupervisedUserCircleIcon fontSize="small" />{' '}
                                    {user.role}
                                </span>
                                <span className={this.classes.headerInfoItem}>
                                    <PersonIcon fontSize="small" />{' '}
                                    {user.firstName || 'No first name'}{' '}
                                    {user.lastName || 'No last name'}
                                </span>
                                <span className={this.classes.headerInfoItem}>
                                    <DateRangeIcon fontSize="small" />
                                    {isDate(user.dateOfBirth)
                                        ? format(
                                              user.dateOfBirth,
                                              'do MMMM yyyy'
                                          )
                                        : user.dateOfBirth}
                                </span>
                                <span className={this.classes.headerInfoItem}>
                                    <PhoneIcon fontSize="small" /> {user.phone}
                                </span>
                            </div>
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Sticky>
        )
    }

    scrollIntoTab = () => {
        this.tabsNode.current.scrollIntoView()
    }

    render() {
        const {
            entity: user,
            isSaving,
            validationErrors,
            tabIndex,
        } = this.state

        if (!this.user.cuid) {
            return <Redirect to="/users" />
        }

        return (
            <form onSubmit={this.save}>
                {this.header}
                <div className={this.classes.balanceContainer}>
                    <FormControl
                        className={this.classes.field}
                        disabled
                        margin="normal"
                        fullWidth={false}
                    >
                        <InputLabel
                            htmlFor={`${this.displayName}-balance-input`}
                            shrink
                        >
                            Balance
                        </InputLabel>
                        <Input
                            id={`${this.displayName}-balance-input`}
                            value={user.balance}
                            startAdornment={
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <span>
                        <Fab
                            size="small"
                            color="primary"
                            onClick={this.increaseBalance}
                            className={classNames(
                                this.classes.balanceButton,
                                this.classes.increaseBalance
                            )}
                        >
                            <AddIcon />
                        </Fab>
                        <Fab
                            size="small"
                            color="secondary"
                            onClick={this.decreaseBalance}
                            className={this.classes.balanceButton}
                        >
                            <RemoveIcon />
                        </Fab>
                    </span>
                </div>
                <FormGroup className={this.classes.pageContainer}>
                    {chunk(this.columnsConfig, 2).map(
                        (columnConfigChunk, i) => (
                            <FormGroup
                                key={`${this.displayName}-formGroup-${i}`}
                                row
                            >
                                {columnConfigChunk.map(columnConfig => {
                                    const {
                                        getter,
                                        endAdornment,
                                        startAdornment,
                                        name,
                                        isReadOnly,
                                    } = columnConfig
                                    const key = `${this.displayName}-${name}-input`

                                    const value = isFunction(getter)
                                        ? getter(user)
                                        : get(user, name, '')
                                    const error = get(
                                        validationErrors[name],
                                        '0'
                                    )
                                    const isDisabled =
                                        (isFunction(isReadOnly)
                                            ? isReadOnly(user)
                                            : isReadOnly) || isSaving

                                    const input = (() => {
                                        switch (columnConfig.type) {
                                            case dataTypes.COUNTRY:
                                                return (
                                                    <CountrySelect
                                                        isDisabled={isDisabled}
                                                        label="Country"
                                                        value={
                                                            value
                                                                ? [value]
                                                                : null
                                                        }
                                                        onChange={this._handlePropertyChange.bind(
                                                            this,
                                                            columnConfig.name
                                                        )}
                                                    />
                                                )
                                            case dataTypes.DATE:
                                            case dataTypes.DATE_TIME:
                                                return (
                                                    <DatePicker
                                                        value={value}
                                                        disabled={isDisabled}
                                                        maxDate={
                                                            columnConfig.maxDate
                                                        }
                                                        onChange={this._handlePropertyChange.bind(
                                                            this,
                                                            columnConfig.name
                                                        )}
                                                        disableFuture={
                                                            columnConfig.disableFuture
                                                        }
                                                        autoOk
                                                        label={
                                                            columnConfig.label
                                                        }
                                                        format={`dd.MM.yyyy${
                                                            columnConfig.type ===
                                                            dataTypes.DATE_TIME
                                                                ? ' HH:mm:ss OOOO'
                                                                : ''
                                                        }`}
                                                        views={[
                                                            'year',
                                                            'month',
                                                            'day',
                                                        ]}
                                                    />
                                                )
                                            default:
                                                return (
                                                    <Fragment>
                                                        <InputLabel
                                                            htmlFor={key}
                                                            shrink
                                                        >
                                                            {columnConfig.label}
                                                        </InputLabel>
                                                        <Input
                                                            id={key}
                                                            value={value}
                                                            name={name}
                                                            startAdornment={
                                                                startAdornment && (
                                                                    <InputAdornment position="start">
                                                                        {isFunction(
                                                                            startAdornment
                                                                        )
                                                                            ? startAdornment(
                                                                                  value,
                                                                                  user
                                                                              )
                                                                            : startAdornment}
                                                                    </InputAdornment>
                                                                )
                                                            }
                                                            endAdornment={
                                                                endAdornment && (
                                                                    <InputAdornment position="end">
                                                                        {isFunction(
                                                                            endAdornment
                                                                        )
                                                                            ? endAdornment(
                                                                                  value,
                                                                                  user
                                                                              )
                                                                            : endAdornment}
                                                                    </InputAdornment>
                                                                )
                                                            }
                                                            onChange={
                                                                this
                                                                    .handlePropertyChange
                                                            }
                                                            inputRef={ref =>
                                                                (this[
                                                                    name
                                                                ] = ref)
                                                            }
                                                        />
                                                    </Fragment>
                                                )
                                        }
                                    })()

                                    return (
                                        <FormControl
                                            className={this.classes.field}
                                            key={key}
                                            disabled={isDisabled}
                                            margin="normal"
                                            error={!!error}
                                        >
                                            {input}
                                            <FormHelperText>
                                                {error}
                                            </FormHelperText>
                                        </FormControl>
                                    )
                                })}
                            </FormGroup>
                        )
                    )}
                </FormGroup>
                <div ref={this.tabsNode}>
                    <Tabs
                        value={tabIndex}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="on"
                        variant="scrollable"
                    >
                        <Tab label="Transactions" icon={<AttachMoneyIcon />} />
                        <Tab label="Inventory" icon={<BusinessCenterIcon />} />
                        <Tab label="Logs" icon={<HistoryIcon />} />
                        <Tab label="Delivery Info" icon={<AssignmentIcon />} />
                        <Tab label="User agents" icon={<UserAgentIcon />} />
                        <Tab label="User info" icon={<InfoIcon />} />
                    </Tabs>
                </div>
                <UserTransactions
                    className={classNames(this.classes.transactionsContainer, {
                        [this.classes.hidden]: tabIndex !== 0,
                    })}
                    user={this.props.user}
                />
                {tabIndex === 1 && (
                    <UserInventory
                        userId={this.user.cuid}
                        onLoad={this.scrollIntoTab}
                    />
                )}
                {tabIndex === 2 && (
                    <UserLog logs={get(this.props, 'user.logs')} />
                )}
                {tabIndex === 3 && (
                    <UserDeliveryInfo
                        deliveryInfo={get(this.props, 'user.deliveryInfo')}
                        createRequireAddressKycHandler={
                            this.createRequireAddressKycHandler
                        }
                        onSave={this.props.updateAddress}
                    />
                )}
                {tabIndex === 4 && <UserAgents userId={this.user.cuid} />}
                {tabIndex === 5 && (
                    <Paper className={this.classes.infoTab}>
                        <FormGroup row>
                            <FormControl className={this.classes.field}>
                                <InputLabel
                                    htmlFor={`${this.displayName}-accountType`}
                                >
                                    Account Type
                                </InputLabel>
                                <Select
                                    value={user.accountType}
                                    disabled={isSaving}
                                    inputProps={{
                                        name: 'accountType',
                                        id: `${this.displayName}-accountType`,
                                    }}
                                    name="accountType"
                                    onChange={this.handlePropertyChange}
                                >
                                    <MenuItem value={'gaming'}>Gaming</MenuItem>
                                    <MenuItem value={'sponsored'}>
                                        Sponsored
                                    </MenuItem>
                                    <MenuItem value={'test'}>Test</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={this.classes.field}>
                                <InputLabel
                                    htmlFor={`${this.displayName}-AMLStatus`}
                                >
                                    AML status
                                </InputLabel>
                                <Select
                                    value={user.AMLStatus}
                                    disabled={
                                        isSaving ||
                                        !user.isAMLRequired ||
                                        user.AMLStatus ===
                                            User.amlStatus.ACCEPTED
                                    }
                                    inputProps={{
                                        name: 'AMLStatus',
                                        id: `${this.displayName}-AMLStatus`,
                                    }}
                                    name="AMLStatus"
                                    onChange={this.handlePropertyChange}
                                >
                                    <MenuItem value={User.amlStatus.ACCEPTED}>
                                        Accepted
                                    </MenuItem>
                                    <MenuItem value={User.amlStatus.DECLINED}>
                                        Declined
                                    </MenuItem>
                                    <MenuItem value={User.amlStatus.PENDING}>
                                        Pending
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <div className={this.classes.field} />
                        </FormGroup>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={
                                            isSaving ||
                                            this.state.oldEntity.isBlogger ||
                                            this.props.currentUser.role !==
                                                'superAdmin'
                                        }
                                        checked={user.isBlogger}
                                        color="primary"
                                        name="isBlogger"
                                        onChange={this.handlePropertyChange}
                                    />
                                }
                                label={`Boost (${
                                    user.isBlogger ? 'yes' : 'no'
                                })`}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={
                                            isSaving ||
                                            !this.state.oldEntity.isBlocked
                                        }
                                        checked={user.isBlocked}
                                        color="secondary"
                                        name="isBlocked"
                                        onChange={this.handlePropertyChange}
                                    />
                                }
                                label={`Is Blocked (${
                                    user.isBlocked ? 'yes' : 'no'
                                })`}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={
                                            isSaving ||
                                            this.state.oldEntity
                                                .isKycRequired ||
                                            this.state.oldEntity.kycStatus ===
                                                User.kycStatus.ACCEPTED
                                        }
                                        checked={user.isKycRequired}
                                        color="secondary"
                                        name="isKycRequired"
                                        onChange={this.handlePropertyChange}
                                    />
                                }
                                label={`Is KYC required (${
                                    user.isKycRequired ? 'yes' : 'no'
                                })`}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        disabled={
                                            isSaving ||
                                            user.AMLStatus ===
                                                User.amlStatus.ACCEPTED
                                        }
                                        checked={user.isAMLRequired}
                                        color="secondary"
                                        name="isAMLRequired"
                                        onChange={this.handlePropertyChange}
                                    />
                                }
                                label={`Is AML required (${
                                    user.isAMLRequired ? 'yes' : 'no'
                                })`}
                            />
                        </FormGroup>
                        <FormGroup row>
                            <FormControl
                                disabled
                                className={this.classes.field}
                            >
                                <InputLabel
                                    htmlFor={`UserPage-blockedExpireAt-input`}
                                    shrink
                                >
                                    Block expire at
                                </InputLabel>
                                <Input
                                    id={`${this.displayName}-blockedExpireAt-input`}
                                    value={
                                        user.blockedExpireAt &&
                                        format(
                                            user.blockedExpireAt,
                                            'dd.MM.yyyy kk:mm:ss'
                                        )
                                    }
                                    type="text"
                                    name="blockedExpireAt"
                                />
                            </FormControl>
                            <FormControl
                                disabled={
                                    !user.isBlogger ||
                                    isSaving ||
                                    this.props.currentUser.role !== 'superAdmin'
                                }
                                className={this.classes.field}
                                margin="dense"
                            >
                                <InputLabel
                                    htmlFor={`UserPage-chancesCounter-input`}
                                    shrink
                                >
                                    Chances counter
                                </InputLabel>
                                <Input
                                    id={`${this.displayName}-chancesCounter-input`}
                                    value={user.chancesCounter}
                                    type="number"
                                    inputProps={{ min: 0, step: 1 }}
                                    name="chancesCounter"
                                    onChange={this.handlePropertyChange}
                                />
                            </FormControl>
                            <FormControl
                                disabled={
                                    !user.isBlogger ||
                                    isSaving ||
                                    this.props.currentUser.role !== 'superAdmin'
                                }
                                className={this.classes.field}
                                error={
                                    !!get(
                                        validationErrors,
                                        'winEpicItemMinPrice.0'
                                    )
                                }
                                margin="dense"
                            >
                                <InputLabel
                                    htmlFor={`${this.displayName}-winEpicItemMinPrice`}
                                    shrink
                                >
                                    Min win epic item price
                                </InputLabel>
                                <Input
                                    id={`${this.displayName}-winEpicItemMinPrice`}
                                    name="winEpicItemMinPrice"
                                    value={
                                        this.state.entity.winEpicItemMinPrice
                                    }
                                    startAdornment={
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    }
                                    onChange={this.handlePropertyChange}
                                    inputProps={{
                                        name: 'winEpicItemMinPrice',
                                        type: 'number',
                                        ref: ref => (this[ref] = ref),
                                    }}
                                />
                                {get(
                                    validationErrors,
                                    'winEpicItemMinPrice.0'
                                ) && (
                                    <FormHelperText>
                                        {
                                            validationErrors
                                                .winEpicItemMinPrice[0]
                                        }
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                disabled={
                                    isSaving ||
                                    this.props.currentUser.role !== 'superAdmin'
                                }
                                className={this.classes.field}
                                error={
                                    !!get(validationErrors, 'winItemMaxPrice.0')
                                }
                                margin="dense"
                            >
                                <InputLabel
                                    htmlFor={`${this.displayName}-winItemMaxPrice`}
                                    shrink
                                >
                                    Max win item price
                                </InputLabel>
                                <Input
                                    id={`${this.displayName}-winItemMaxPrice`}
                                    name="winItemMaxPrice"
                                    value={this.state.entity.winItemMaxPrice}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    }
                                    onChange={this.handlePropertyChange}
                                    inputProps={{
                                        name: 'winItemMaxPrice',
                                        type: 'number',
                                        ref: ref => (this[ref] = ref),
                                    }}
                                />
                                {get(validationErrors, 'winItemMaxPrice.0') && (
                                    <FormHelperText>
                                        {validationErrors.winItemMaxPrice[0]}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </FormGroup>
                        <FormGroup row>
                            <FormControl
                                error={validationErrors.notes.length > 0}
                                className={this.classes.field}
                                margin="dense"
                            >
                                <InputLabel htmlFor="name-input" shrink>
                                    Notes
                                </InputLabel>
                                <Input
                                    multiline
                                    rows={4}
                                    rowsMax={8}
                                    id={`${this.displayName}-notes-input`}
                                    value={user.notes}
                                    name="notes"
                                    onChange={this.handlePropertyChange}
                                    disabled={isSaving}
                                    className={this.classes.textboxBg}
                                    inputRef={ref => (this.notes = ref)}
                                />
                                {validationErrors.notes[0] && (
                                    <FormHelperText>
                                        {validationErrors.notes[0]}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </FormGroup>
                    </Paper>
                )}
                <Dialog open={!this.state.isSavingSuccess}>
                    <DialogTitle>Save error</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Something went wrong...
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.toggleSavingErrorDialog}
                            color="primary"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <BalanceEdit
                    userManagement={this.props.userManagement}
                    adminManagement={this.props.adminManagement}
                    open={this.state.isEditBalance}
                    type={this.state.balanceEditType}
                    user={this.user}
                    onClose={this.toggleEditBalance}
                    editBalance={this.editBalance}
                />
            </form>
        )
    }
}

const styles = theme => ({
    headerContainer: {
        zIndex: Number.MAX_SAFE_INTEGER,
    },
    transactionsContainer: {
        paddingTop: 0,
    },
    textboxBg: {
        backgroundColor: 'white',
    },
    button: {
        marginRight: 15,
        height: 'fit-content',
    },
    saveButton: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    balanceContainer: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    increaseBalance: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        marginLeft: 5,
    },
    field: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
        minWidth: 250,
        marginRight: 30,
        '&:last-child': {
            marginRight: 0,
        },
    },
    row: {
        display: 'flex',
    },
    flexColumn: {
        flexDirection: 'column',
        display: 'flex',
    },
    pageContainer: {
        marginBottom: 15,
    },
    balanceButton: {
        minWidth: 'unset',
        margin: '0 2px',
    },
    [theme.breakpoints.down('sm')]: {
        field: {
            marginRight: 0,
        },
    },
    hidden: {
        display: 'none',
    },
    headerInfoItem: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 15,
    },
    headerInfoContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        color: 'grey',
    },
    verifiedIcon: {
        color: green['A700'],
    },
    warningIcon: {
        color: yellow['A700'],
    },
    declinedIcon: {
        color: red['A700'],
    },
    flexContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    flexGrow: {
        flex: 1,
        minWidth: 150,
        marginRight: 30,
        marginBottom: 30,
        '&:last-child': {
            marginRight: 0,
        },
    },
    toolbarTitle: {
        alignItems: 'center',
        display: 'flex',
    },
    actions: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: 10,
    },
    infoTab: {
        padding: 15,
    },
})

export default createFragmentContainer(
    compose(
        withWidth(),
        withModals,
        withRouter,
        withStyles(styles, { withTheme: true })
    )(UserPage),
    {
        currentUser: graphql`
            fragment UserPage_currentUser on UserManagementUser {
                role
            }
        `,
        premiumConfig: graphql`
            fragment UserPage_premiumConfig on AppManagementPremiumConfig {
                types {
                    type
                    cost
                    duration
                }
            }
        `,
        user: graphql`
            fragment UserPage_user on AdminManagementUser {
                id
                cuid
                balance
                chancesCounter
                name
                email
                facebookId
                role
                isBlogger
                isBlocked
                blockedExpireAt
                blockedReason
                accountType
                notes
                createdAt
                socialEmail
                deliveryInfo {
                    ...UserDeliveryInfo_deliveryInfo
                }
                logs {
                    ...UserLog_logs
                }
                ...UserTransactions_data
                phone
                dateOfBirth
                firstName
                lastName
                kycStatus
                isKycRequired
                isVerified
                winItemMaxPrice
                winEpicItemMinPrice
                premium {
                    type
                    expirationDate
                }
                countryCode
                aml {
                    amlRequired
                    amlStatus
                }
                isDeleted
                deletedAt
                deletedBy
                kycVerificationId
                kycDeclineReasonCode
                kycDeclineReason
                kycDocument
                kycPerson
            }
        `,
    }
)
