import React, { useCallback } from 'react'
import { useQuery } from 'relay-hooks'
import graphql from 'babel-plugin-relay/macro'
import get from 'lodash/get'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import isNull from 'lodash/isNull'
import shortid from 'shortid'

import ContainerError from '../../Users/UserPage/UserInventory/UserInventoryContainer'
import Loader from '../../Users/UsersList/UsersContainer'
import { Payments } from './Payments'
import { useRelayUtils } from '../../../shared/hooks/RelayUtils'
import { useModals } from '../../../shared/modals'

const __DEV__ = process.env.NODE_ENV === 'development'

const PaymentsContainerQuery = graphql`
    query PaymentsContainerQuery {
        bankingManagement {
            id
            payments {
                ...Payments_payments
            }
        }
        userManagement {
            currentUser {
                role
                roles
            }
        }
    }
`

const UpdatePaymentMutation = graphql`
    mutation PaymentsContainerUpdatePaymentMutation(
        $input: BankingManagementUpdatePaymentInput!
    ) {
        bankingManagementUpdatePayment(input: $input) {
            payment {
                ...Payments_payments
            }
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const CreatePaymentMutation = graphql`
    mutation PaymentsContainerCreatePaymentMutation(
        $input: BankingManagementCreatePaymentInput!
    ) {
        bankingManagementCreatePayment(input: $input) {
            payment {
                ...Payments_payments
            }
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const DeletePaymentMutation = graphql`
    mutation PaymentsContainerDeletePaymentMutation(
        $input: BankingManagementDeletePaymentInput!
    ) {
        bankingManagementDeletePayment(input: $input) {
            success
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const AddPaymentStatsMutation = graphql`
    mutation PaymentsContainerAddPaymentStatsMutation(
        $input: BankingManagementAddPaymentStatsInput!
    ) {
        bankingManagementAddPaymentStats(input: $input) {
            success
            errors {
                statusCode
                message
                meta
                errorCode
                code
            }
        }
    }
`

const PaymentsContainer = externalProps => {
    const { props, error } = useQuery({
        query: PaymentsContainerQuery,
    })

    const bankingManagementId = get(props, 'bankingManagement.id')
    const { commitMutation, commitLocalUpdate } = useRelayUtils()
    const modals = useModals()

    const remove = useCallback(
        ({ id, name }) => {
            modals.openConfirmationModal({
                title: `You want to delete ${name}`,
                onConfirm: () => {
                    const updater = store => store.delete(id)
                    return commitMutation({
                        mutation: DeletePaymentMutation,
                        variables: {
                            input: { id },
                        },
                        optimisticResponse: {
                            bankingManagementDeletePayment: {
                                success: true,
                                errors: null,
                            },
                        },
                        updater,
                        optimisticUpdater: updater,
                    })
                },
            })
        },
        [modals, commitMutation]
    )

    const cancelAdd = useCallback(
        id =>
            commitLocalUpdate(store => {
                const payment = store.get(id)

                if (!payment || !payment.getValue('isNew')) {
                    return
                }

                const bankingManagement = store.get(bankingManagementId)

                if (!bankingManagement) {
                    return
                }

                const payments = bankingManagement.getLinkedRecords('payments')

                if (!payments) {
                    return
                }

                bankingManagement.setLinkedRecords(
                    payments.filter(item => item.getDataID() !== id),
                    'payments'
                )
            }),
        [bankingManagementId, commitLocalUpdate]
    )

    const createNew = useCallback(
        () =>
            commitLocalUpdate(store => {
                const id = shortid()
                const paymentId = btoa(`BankingManagementPayment:${id}`)
                const payment = store.create(
                    paymentId,
                    'BankingManagementPayment'
                )

                payment.setValue(paymentId, 'id')
                payment.setValue(true, 'isNew')
                payment.setValue('', 'shortName')
                payment.setValue(1, 'order')
                payment.setValue(false, 'isEnabled')
                payment.setValue([], 'allowedCountries')
                payment.setValue([], 'excludedCountries')
                payment.setValue(false, 'suggestAll')
                payment.setValue([], 'suggested')
                payment.setValue(5, 'minAmount')
                payment.setValue(5000, 'maxAmount')
                payment.setValue([50, 100, 250, 30], 'amountSteps')
                payment.setValue('USD', 'currency')

                const bankingManagement = store.get(bankingManagementId)

                if (!bankingManagement) {
                    return
                }

                const payments = bankingManagement.getLinkedRecords('payments')

                if (!payments) {
                    return
                }

                bankingManagement.setLinkedRecords(
                    [...payments, payment],
                    'payments'
                )
            }),
        [commitLocalUpdate, bankingManagementId]
    )

    const save = useCallback(
        ({
            order,
            id,
            allowedCountries,
            excludedCountries,
            suggested,
            suggestAll,
            isEnabled,
            minAmount,
            maxAmount,
            amountSteps,
            icon,
            isNew,
            name,
            paymentGroup,
            forceMethod,
            currency,
        }) => {
            const payload = omitBy(
                {
                    order,
                    id,
                    allowedCountries,
                    excludedCountries,
                    suggested,
                    suggestAll,
                    isEnabled,
                    minAmount,
                    maxAmount,
                    amountSteps,
                    icon,
                    ...(isNew && {
                        name,
                        paymentGroup,
                        forceMethod,
                        currency,
                    }),
                },
                value => isUndefined(value) || isNull(value)
            )

            return commitMutation({
                mutation: isNew ? CreatePaymentMutation : UpdatePaymentMutation,
                variables: {
                    input: payload,
                },
                optimisticResponse: {
                    [isNew
                        ? 'bankingManagementCreatePayment'
                        : 'bankingManagementUpdatePayment']: {
                        payment: payload,
                    },
                },
                updater: store => {
                    if (!isNew) {
                        return
                    }
                    const payload = store.getRootField(
                        'bankingManagementCreatePayment'
                    )

                    if (!payload) {
                        return
                    }

                    const savedPayment = payload.getLinkedRecord('payment')

                    if (!savedPayment) {
                        return
                    }

                    const payment = store.get(id)

                    if (!payment) {
                        return
                    }

                    payment.setValue(false, 'isNew')
                    payment.setValue(savedPayment.getDataID(), 'id')
                    payment.copyFieldsFrom(savedPayment)
                },
            })
        },
        [commitMutation]
    )

    const addStats = useCallback(
        stats =>
            commitMutation({
                mutation: AddPaymentStatsMutation,
                variables: {
                    input: {
                        stats,
                    },
                },
            }),
        [commitMutation]
    )

    if (error) {
        console.error(error)

        if (__DEV__) {
            return <div>{error.message}</div>
        }

        return <ContainerError />
    }

    if (props) {
        const payments = get(props, 'bankingManagement.payments', [])
        const currentUser = get(props, 'userManagement.currentUser', null)

        return (
            <Payments
                {...externalProps}
                payments={payments}
                save={save}
                add={createNew}
                remove={remove}
                cancelAdd={cancelAdd}
                currentUser={currentUser}
                addStats={addStats}
            />
        )
    }

    return <Loader />
}

export { PaymentsContainer }
