/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserInventory_data$ref: FragmentReference;
declare export opaque type UserInventory_data$fragmentType: UserInventory_data$ref;
export type UserInventory_data = {|
  +inventory: ?{|
    +count: ?number,
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +cuid: ?string,
        +status: ?string,
        +createdAt: ?any,
        +disposedAt: ?any,
        +type: ?string,
        +caseBox: ?{|
          +title: ?string,
          +slug: ?string,
          +price: ?string,
        |},
        +product: ?{|
          +title: ?string,
          +slug: ?string,
          +price: ?string,
          +image: ?{|
            +x1: ?{|
              +default: ?string
            |}
          |},
        |},
        +meta: ?any,
        +cart: ?{|
          +cuid: ?string,
          +status: ?string,
        |},
      |}
    |}>,
  |},
  +$refType: UserInventory_data$ref,
|};
export type UserInventory_data$data = UserInventory_data;
export type UserInventory_data$key = {
  +$data?: UserInventory_data$data,
  +$fragmentRefs: UserInventory_data$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cuid",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "UserInventory_data",
  "type": "AdminManagementUser",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "first",
      "type": "Int",
      "defaultValue": 100
    },
    {
      "kind": "LocalArgument",
      "name": "skip",
      "type": "Int",
      "defaultValue": 0
    },
    {
      "kind": "LocalArgument",
      "name": "orderBy",
      "type": "String",
      "defaultValue": ""
    },
    {
      "kind": "LocalArgument",
      "name": "orderDirection",
      "type": "AdminManagementOrderDirections",
      "defaultValue": "DESC"
    },
    {
      "kind": "LocalArgument",
      "name": "startDate",
      "type": "DateTime",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "endDate",
      "type": "DateTime",
      "defaultValue": null
    },
    {
      "kind": "LocalArgument",
      "name": "statuses",
      "type": "[String]",
      "defaultValue": []
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "inventory",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "endDate",
          "variableName": "endDate"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "orderDirection",
          "variableName": "orderDirection"
        },
        {
          "kind": "Variable",
          "name": "skip",
          "variableName": "skip"
        },
        {
          "kind": "Variable",
          "name": "startDate",
          "variableName": "startDate"
        },
        {
          "kind": "Variable",
          "name": "statuses",
          "variableName": "statuses"
        }
      ],
      "concreteType": "AdminManagementInventoryConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "count",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "AdminManagementInventoryEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "AdminManagementInventory",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "createdAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "disposedAt",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "type",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "caseBox",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AdminManagementInventoryCaseBox",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/)
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "product",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AdminManagementInventoryProduct",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "image",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "AdminManagementImage",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "x1",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "AdminManagementImageUrl",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "alias": null,
                              "name": "default",
                              "args": null,
                              "storageKey": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "meta",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "cart",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "AdminManagementInventoryCart",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e01b2cfa48df6c182200c12ad307d864';
module.exports = node;
