import pick from 'lodash/pick'
import set from 'lodash/set'
import format from 'date-fns/format'

class UpdateUserDto {
    constructor(args) {
        const props = pick(args, [
            'cuid',
            'notes',
            'accountType',
            'isBlogger',
            'isBlocked',
            'chancesCounter',
            'balance',
            'balanceNotes',
            'manualBalanceChangeReasonCuid',
            'isKycRequired',
            'firstName',
            'lastName',
            'dateOfBirth',
            'winItemMaxPrice',
            'winEpicItemMinPrice',
            'phone',
            'countryCode',
        ])

        Object.keys(props).forEach(prop => {
            this[prop] = props[prop]
        })

        if (args.hasOwnProperty('isAMLRequired')) {
            set(this, 'aml.amlRequired', args.isAMLRequired)
        }

        if (args.hasOwnProperty('AMLStatus')) {
            set(this, 'aml.amlStatus', args.AMLStatus)
        }

        if (props.dateOfBirth) {
            this.dateOfBirth = format(props.dateOfBirth, 'yyyy-MM-dd')
        }

        if (props.chancesCounter) {
            this.chancesCounter = parseInt(props.chancesCounter, 10)
        }

        if (props.winItemMaxPrice) {
            this.winItemMaxPrice = parseFloat(props.winItemMaxPrice)
        }
    }
}

export default UpdateUserDto
