/**
 * @flow
 */

/* eslint-disable */

'use strict'

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserDeliveryInfo_deliveryInfo$ref = any;
type UserLog_logs$ref = any;
type UserTransactions_data$ref = any;
export type AdminManagementAccountType = "gaming" | "sponsored" | "test" | "%future added value";
export type AdminManagementKycStatus = "accepted" | "declined" | "pending" | "%future added value";
export type AdminManagementUserRole = "admin" | "superAdmin" | "user" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserPage_user$ref: FragmentReference;
declare export opaque type UserPage_user$fragmentType: UserPage_user$ref;
export type UserPage_user = {|
  +id: string,
  +cuid: ?string,
  +balance: ?number,
  +chancesCounter: ?number,
  +name: ?string,
  +email: ?string,
  +facebookId: ?string,
  +role: ?AdminManagementUserRole,
  +isBlogger: ?boolean,
  +isBlocked: ?boolean,
  +blockedExpireAt: ?any,
  +blockedReason: ?string,
  +accountType: ?AdminManagementAccountType,
  +notes: ?string,
  +createdAt: ?any,
  +socialEmail: ?string,
  +deliveryInfo: ?{|
    +$fragmentRefs: UserDeliveryInfo_deliveryInfo$ref
  |},
  +logs: ?{|
    +$fragmentRefs: UserLog_logs$ref
  |},
  +phone: ?string,
  +dateOfBirth: ?any,
  +firstName: ?string,
  +lastName: ?string,
  +kycStatus: ?AdminManagementKycStatus,
  +isKycRequired: ?boolean,
  +isVerified: ?boolean,
  +winItemMaxPrice: ?number,
  +winEpicItemMinPrice: ?number,
  +premium: ?{|
    +type: ?string,
    +expirationDate: ?any,
  |},
  +countryCode: ?string,
  +aml: ?{|
    +amlRequired: ?boolean,
    +amlStatus: ?string,
  |},
  +isDeleted: ?boolean,
  +deletedAt: ?any,
  +deletedBy: ?string,
  +kycVerificationId: ?string,
  +kycDeclineReasonCode: ?number,
  +kycDeclineReason: ?string,
  +kycDocument: ?any,
  +kycPerson: ?any,
  +$fragmentRefs: UserTransactions_data$ref,
  +$refType: UserPage_user$ref,
|};
export type UserPage_user$data = UserPage_user;
export type UserPage_user$key = {
  +$data?: UserPage_user$data,
  +$fragmentRefs: UserPage_user$ref,
};
*/

const node /*: ReaderFragment*/ = {
    kind: 'Fragment',
    name: 'UserPage_user',
    type: 'AdminManagementUser',
    metadata: null,
    argumentDefinitions: [],
    selections: [
        {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'cuid',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'balance',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'chancesCounter',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'name',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'email',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'facebookId',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'role',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'isBlogger',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'isBlocked',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'blockedExpireAt',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'blockedReason',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'accountType',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'notes',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'createdAt',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'socialEmail',
            args: null,
            storageKey: null,
        },
        {
            kind: 'LinkedField',
            alias: null,
            name: 'deliveryInfo',
            storageKey: null,
            args: null,
            concreteType: 'AdminManagementDeliveryInfoConnection',
            plural: false,
            selections: [
                {
                    kind: 'FragmentSpread',
                    name: 'UserDeliveryInfo_deliveryInfo',
                    args: null,
                },
            ],
        },
        {
            kind: 'LinkedField',
            alias: null,
            name: 'logs',
            storageKey: null,
            args: null,
            concreteType: 'AdminManagementLogConnection',
            plural: false,
            selections: [
                {
                    kind: 'FragmentSpread',
                    name: 'UserLog_logs',
                    args: null,
                },
            ],
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'phone',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'dateOfBirth',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'firstName',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'lastName',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'kycStatus',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'isKycRequired',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'isVerified',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'winItemMaxPrice',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'winEpicItemMinPrice',
            args: null,
            storageKey: null,
        },
        {
            kind: 'LinkedField',
            alias: null,
            name: 'premium',
            storageKey: null,
            args: null,
            concreteType: 'AdminManagementpremium',
            plural: false,
            selections: [
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'type',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'expirationDate',
                    args: null,
                    storageKey: null,
                },
            ],
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'countryCode',
            args: null,
            storageKey: null,
        },
        {
            kind: 'LinkedField',
            alias: null,
            name: 'aml',
            storageKey: null,
            args: null,
            concreteType: 'AdminManagementAML',
            plural: false,
            selections: [
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'amlRequired',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'amlStatus',
                    args: null,
                    storageKey: null,
                },
            ],
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'isDeleted',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'deletedAt',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'deletedBy',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'kycVerificationId',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'kycDeclineReasonCode',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'kycDeclineReason',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'kycDocument',
            args: null,
            storageKey: null,
        },
        {
            kind: 'ScalarField',
            alias: null,
            name: 'kycPerson',
            args: null,
            storageKey: null,
        },
        {
            kind: 'FragmentSpread',
            name: 'UserTransactions_data',
            args: null,
        },
    ],
}
// prettier-ignore
;(node/*: any*/).hash = '3b5d1e54c58f9dd50c6d4a3477f0d10d';
module.exports = node
