import React from 'react'
import PropTypes from 'prop-types'
import {
    DatePicker as MaterialDatePicker,
    InlineDatePicker,
} from 'material-ui-pickers'

const DatePicker = ({
    value,
    onChange,
    disabled,
    disableFuture,
    disablePast,
    minDate,
    maxDate,
    autoOk,
    format,
    label,
    ref,
    margin,
    variant,
    fullWidth,
}) => {
    const Component =
        variant === 'inline' ? InlineDatePicker : MaterialDatePicker

    return (
        <Component
            value={value}
            onChange={onChange}
            disabled={disabled}
            disablePast={disablePast}
            disableFuture={disableFuture}
            minDate={minDate}
            maxDate={maxDate}
            autoOk={autoOk}
            format={format}
            label={label}
            ref={ref}
            fullWidth={fullWidth}
            InputLabelProps={{
                shrink: true,
            }}
            margin={margin}
        />
    )
}

DatePicker.propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    fullWidth: PropTypes.bool,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    format: PropTypes.string,
    views: PropTypes.array,
    label: PropTypes.string,
    inputRef: PropTypes.any,
    margin: PropTypes.string,
    variant: PropTypes.oneOf(['inline']),
}

DatePicker.defaultProps = {
    disabled: false,
    disableFuture: false,
    disablePast: false,
    fullWidth: false,
    minDate: new Date('1900-01-01'),
    maxDate: new Date('2100-01-01'),
    format: 'dd.MM.yyyy',
    views: ['year', 'month', 'day'],
    label: '',
    margin: 'none',
    variant: null,
}

export { DatePicker }
