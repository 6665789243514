/**
 * @flow
 * @relayHash bd532148a5c8f92387ba10a64f66f3c8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Payments_payments$ref = any;
export type BankingManagementUpdatePaymentInput = {|
  id?: ?string,
  isEnabled?: ?boolean,
  order?: ?number,
  allowedCountries?: ?$ReadOnlyArray<?string>,
  excludedCountries?: ?$ReadOnlyArray<?string>,
  suggested?: ?$ReadOnlyArray<?string>,
  suggestAll?: ?boolean,
  minAmount?: ?number,
  maxAmount?: ?number,
  amountSteps?: ?$ReadOnlyArray<?number>,
  icon?: ?string,
  clientMutationId?: ?string,
|};
export type PaymentsContainerUpdatePaymentMutationVariables = {|
  input: BankingManagementUpdatePaymentInput
|};
export type PaymentsContainerUpdatePaymentMutationResponse = {|
  +bankingManagementUpdatePayment: ?{|
    +payment: ?{|
      +$fragmentRefs: Payments_payments$ref
    |},
    +errors: ?$ReadOnlyArray<?{|
      +statusCode: ?number,
      +message: ?string,
      +meta: ?any,
      +errorCode: ?string,
      +code: ?number,
    |}>,
  |}
|};
export type PaymentsContainerUpdatePaymentMutation = {|
  variables: PaymentsContainerUpdatePaymentMutationVariables,
  response: PaymentsContainerUpdatePaymentMutationResponse,
|};
*/


/*
mutation PaymentsContainerUpdatePaymentMutation(
  $input: BankingManagementUpdatePaymentInput!
) {
  bankingManagementUpdatePayment(input: $input) {
    payment {
      ...Payments_payments
      id
    }
    errors {
      statusCode
      message
      meta
      errorCode
      code
    }
  }
}

fragment Payments_payments on BankingManagementPayment {
  id
  name
  shortName
  isEnabled
  order
  excludedCountries
  allowedCountries
  suggested
  suggestAll
  forceMethod
  paymentGroup
  minAmount
  maxAmount
  amountSteps
  icon
  currency
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "BankingManagementUpdatePaymentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "BankingManagementError",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "meta",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "errorCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PaymentsContainerUpdatePaymentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "bankingManagementUpdatePayment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "BankingManagementUpdatePaymentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "payment",
            "storageKey": null,
            "args": null,
            "concreteType": "BankingManagementPayment",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "Payments_payments",
                "args": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PaymentsContainerUpdatePaymentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "bankingManagementUpdatePayment",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "BankingManagementUpdatePaymentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "payment",
            "storageKey": null,
            "args": null,
            "concreteType": "BankingManagementPayment",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shortName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isEnabled",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "order",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "excludedCountries",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "allowedCountries",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "suggested",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "suggestAll",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "forceMethod",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "paymentGroup",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "minAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "maxAmount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "amountSteps",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "icon",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "currency",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isNew",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "PaymentsContainerUpdatePaymentMutation",
    "id": null,
    "text": "mutation PaymentsContainerUpdatePaymentMutation(\n  $input: BankingManagementUpdatePaymentInput!\n) {\n  bankingManagementUpdatePayment(input: $input) {\n    payment {\n      ...Payments_payments\n      id\n    }\n    errors {\n      statusCode\n      message\n      meta\n      errorCode\n      code\n    }\n  }\n}\n\nfragment Payments_payments on BankingManagementPayment {\n  id\n  name\n  shortName\n  isEnabled\n  order\n  excludedCountries\n  allowedCountries\n  suggested\n  suggestAll\n  forceMethod\n  paymentGroup\n  minAmount\n  maxAmount\n  amountSteps\n  icon\n  currency\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a6b20a51e65d33e69311bab50ba11dd7';
module.exports = node;
