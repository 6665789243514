import forEach from 'lodash/forEach'
import isUndefined from 'lodash/isUndefined'
import isNull from 'lodash/isNull'

export default class BaseEntity {
    id
    cuid

    constructor(props) {
        forEach(props, (value, prop) => {
            this[prop] =
                !isUndefined(value) && !isNull(value) ? value : this[prop]
        })
    }
}
