import React, { Fragment } from 'react'
import format from 'date-fns/format'
import graphql from 'babel-plugin-relay/macro'
import get from 'lodash/get'
import MobileIcon from '@material-ui/icons/PhoneIphoneOutlined'
import { withStyles } from '@material-ui/core'
import TableIcon from '@material-ui/icons/TabletMacOutlined'
import DesktopIcon from '@material-ui/icons/DesktopMacOutlined'
import OtherDeviceIcon from '@material-ui/icons/ImportantDevices'

import { createSimpleTable } from '../../../../shared/components/SimpleTable'
import { getCountryName } from '../../../../shared/countryCodes'

const getJoin = (obj, paths) =>
    paths
        .map(path => get(obj, path))
        .join(' ')
        .trim()

const deviceTypes = {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop',
}
const deviceIcons = {
    [deviceTypes.MOBILE]: <MobileIcon />,
    [deviceTypes.TABLET]: <TableIcon />,
    [deviceTypes.DESKTOP]: <DesktopIcon />,
}

const getDeviceIcon = deviceType =>
    deviceIcons[deviceType] || <OtherDeviceIcon />

const columns = classes => [
    {
        label: 'Action',
        name: 'action',
    },
    {
        label: 'Date',
        name: 'date',
        getter: ({ date }) => (
            <Fragment>
                {format(new Date(date), 'dd MMM yyyy')}
                <br />
                {format(new Date(date), 'kk:mm:ss OOOO')}
            </Fragment>
        ),
    },
    {
        label: 'Device type',
        name: 'deviceType',
        getter: ({ deviceType }) => (
            <span className={classes.deviceIcon}>
                {' '}
                {getDeviceIcon(deviceType)} {deviceType || ' undetected'}{' '}
            </span>
        ),
    },
    {
        label: 'IP',
        name: 'ip',
    },
    {
        label: 'Country',
        name: 'country',
        getter: ({ countryCode, countryName }) =>
            countryCode
                ? `${getCountryName(countryCode) || ''} (${countryCode || ''})`
                : countryName,
    },
    {
        label: 'Browser',
        name: 'browser',
        getter: ({ browser }) => getJoin(browser, ['name', 'version']),
    },
    {
        label: 'OS',
        name: 'os',
        getter: ({ os }) => getJoin(os, ['name', 'version']),
    },
    {
        label: 'Device',
        name: 'device',
        getter: ({ device }) => getJoin(device, ['vendor', 'model']),
    },
]

const fragmentSpec = {
    userAgents: graphql`
        fragment UserAgents_userAgents on AdminManagementUserAgent
            @relay(plural: true) {
            action
            date
            userAgent
            ip
            countryCode
            countryName
            deviceType
            device {
                type
                vendor
                model
            }
            browser {
                name
                version
            }
            os {
                name
                version
            }
            engine {
                name
                version
            }
        }
    `,
}

const SimpleTable = createSimpleTable(fragmentSpec)

const UserAgents = props => {
    return (
        <SimpleTable
            dataGetter="userAgents"
            columns={columns(props.classes)}
            {...props}
        />
    )
}

const styles = {
    deviceIcon: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre',
    },
}

export default withStyles(styles)(UserAgents)
