/**
 * @flow
 * @relayHash 5ccd77d1d95b69f02915c7583e2d96be
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RewardsCreateGiveawayInput = {|
  period: string,
  startAt: any,
  finishAt: any,
  participants: number,
  prizes?: ?$ReadOnlyArray<?RewardsCreateGiveawayPrize>,
  clientMutationId?: ?string,
|};
export type RewardsCreateGiveawayPrize = {|
  cuid?: ?string
|};
export type GiveawaysCreateGiveawayMutationVariables = {|
  input: RewardsCreateGiveawayInput
|};
export type GiveawaysCreateGiveawayMutationResponse = {|
  +rewardsCreateGiveaway: ?{|
    +success: ?boolean,
    +errors: ?$ReadOnlyArray<?{|
      +statusCode: ?number,
      +message: ?string,
      +meta: ?any,
      +errorCode: ?string,
      +code: ?number,
    |}>,
    +giveaway: ?{|
      +period: ?string,
      +startAt: ?any,
      +finishAt: ?any,
      +participants: ?number,
      +prizes: ?$ReadOnlyArray<?{|
        +cuid: ?string,
        +name: ?string,
        +slug: ?string,
        +imageUrl: ?string,
        +price: ?number,
      |}>,
    |},
  |}
|};
export type GiveawaysCreateGiveawayMutation = {|
  variables: GiveawaysCreateGiveawayMutationVariables,
  response: GiveawaysCreateGiveawayMutationResponse,
|};
*/

/*
mutation GiveawaysCreateGiveawayMutation(
  $input: RewardsCreateGiveawayInput!
) {
  rewardsCreateGiveaway(input: $input) {
    success
    errors {
      statusCode
      message
      meta
      errorCode
      code
    }
    giveaway {
      period
      startAt
      finishAt
      participants
      prizes {
        cuid
        name
        slug
        imageUrl
        price
        id
      }
      id
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'input',
                type: 'RewardsCreateGiveawayInput!',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'Variable',
                name: 'input',
                variableName: 'input',
            },
        ],
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'success',
            args: null,
            storageKey: null,
        },
        v3 = {
            kind: 'LinkedField',
            alias: null,
            name: 'errors',
            storageKey: null,
            args: null,
            concreteType: 'RewardsError',
            plural: true,
            selections: [
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'statusCode',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'message',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'meta',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'errorCode',
                    args: null,
                    storageKey: null,
                },
                {
                    kind: 'ScalarField',
                    alias: null,
                    name: 'code',
                    args: null,
                    storageKey: null,
                },
            ],
        },
        v4 = {
            kind: 'ScalarField',
            alias: null,
            name: 'period',
            args: null,
            storageKey: null,
        },
        v5 = {
            kind: 'ScalarField',
            alias: null,
            name: 'startAt',
            args: null,
            storageKey: null,
        },
        v6 = {
            kind: 'ScalarField',
            alias: null,
            name: 'finishAt',
            args: null,
            storageKey: null,
        },
        v7 = {
            kind: 'ScalarField',
            alias: null,
            name: 'participants',
            args: null,
            storageKey: null,
        },
        v8 = {
            kind: 'ScalarField',
            alias: null,
            name: 'cuid',
            args: null,
            storageKey: null,
        },
        v9 = {
            kind: 'ScalarField',
            alias: null,
            name: 'name',
            args: null,
            storageKey: null,
        },
        v10 = {
            kind: 'ScalarField',
            alias: null,
            name: 'slug',
            args: null,
            storageKey: null,
        },
        v11 = {
            kind: 'ScalarField',
            alias: null,
            name: 'imageUrl',
            args: null,
            storageKey: null,
        },
        v12 = {
            kind: 'ScalarField',
            alias: null,
            name: 'price',
            args: null,
            storageKey: null,
        },
        v13 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'GiveawaysCreateGiveawayMutation',
            type: 'Mutation',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'rewardsCreateGiveaway',
                    storageKey: null,
                    args: (v1 /*: any*/),
                    concreteType: 'RewardsCreateGiveawayPayload',
                    plural: false,
                    selections: [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'giveaway',
                            storageKey: null,
                            args: null,
                            concreteType: 'RewardsGiveaway',
                            plural: false,
                            selections: [
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'prizes',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'RewardsGiveawayPrizeProduct',
                                    plural: true,
                                    selections: [
                                        (v8 /*: any*/),
                                        (v9 /*: any*/),
                                        (v10 /*: any*/),
                                        (v11 /*: any*/),
                                        (v12 /*: any*/),
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'GiveawaysCreateGiveawayMutation',
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'rewardsCreateGiveaway',
                    storageKey: null,
                    args: (v1 /*: any*/),
                    concreteType: 'RewardsCreateGiveawayPayload',
                    plural: false,
                    selections: [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'giveaway',
                            storageKey: null,
                            args: null,
                            concreteType: 'RewardsGiveaway',
                            plural: false,
                            selections: [
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'prizes',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'RewardsGiveawayPrizeProduct',
                                    plural: true,
                                    selections: [
                                        (v8 /*: any*/),
                                        (v9 /*: any*/),
                                        (v10 /*: any*/),
                                        (v11 /*: any*/),
                                        (v12 /*: any*/),
                                        (v13 /*: any*/),
                                    ],
                                },
                                (v13 /*: any*/),
                            ],
                        },
                    ],
                },
            ],
        },
        params: {
            operationKind: 'mutation',
            name: 'GiveawaysCreateGiveawayMutation',
            id: null,
            text:
                'mutation GiveawaysCreateGiveawayMutation(\n  $input: RewardsCreateGiveawayInput!\n) {\n  rewardsCreateGiveaway(input: $input) {\n    success\n    errors {\n      statusCode\n      message\n      meta\n      errorCode\n      code\n    }\n    giveaway {\n      period\n      startAt\n      finishAt\n      participants\n      prizes {\n        cuid\n        name\n        slug\n        imageUrl\n        price\n        id\n      }\n      id\n    }\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '815ec4a6ba23c9c245b844777719c1f9';
module.exports = node
