/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserPage_premiumConfig$ref: FragmentReference;
declare export opaque type UserPage_premiumConfig$fragmentType: UserPage_premiumConfig$ref;
export type UserPage_premiumConfig = {|
  +types: ?$ReadOnlyArray<?{|
    +type: ?string,
    +cost: ?number,
    +duration: ?number,
  |}>,
  +$refType: UserPage_premiumConfig$ref,
|};
export type UserPage_premiumConfig$data = UserPage_premiumConfig;
export type UserPage_premiumConfig$key = {
  +$data?: UserPage_premiumConfig$data,
  +$fragmentRefs: UserPage_premiumConfig$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "UserPage_premiumConfig",
  "type": "AppManagementPremiumConfig",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "types",
      "storageKey": null,
      "args": null,
      "concreteType": "AppManagementPremiumType",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cost",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "duration",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '18aec8ea039280578d8c4b9932131356';
module.exports = node;
