import React from 'react'
import { useQuery } from 'relay-hooks'
import graphql from 'babel-plugin-relay/macro'

import Loader from '../../components/Loader'
import ContainerError from '../../components/ContainerError'
import Giveaways from './Giveaways'

const GiveawayQuery = graphql`
    query GiveawayContainerQuery {
        rewards {
            ...Giveaways_rewards
        }
        caseManagement {
            ...Giveaways_caseManagement
        }
    }
`

const __DEV__ = process.env.NODE_ENV === 'development'

const PromoContainer = () => {
    const { props, error, retry } = useQuery({
        query: GiveawayQuery,
        dataFrom: 'NETWORK_ONLY',
    })

    if (error) {
        console.error(error)

        if (__DEV__) {
            return <div>{error.message}</div>
        }

        return <ContainerError />
    }

    if (!props) {
        return <Loader />
    }

    return (
        <Giveaways
            rewards={props.rewards}
            caseManagement={props.caseManagement}
            reload={retry}
        />
    )
}

export default PromoContainer
