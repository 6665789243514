import React, { useCallback, memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import noop from 'lodash/noop'
import classNames from 'classnames'
import {
    Paper,
    Chip,
    Typography,
    TextField,
    MenuItem,
    Avatar,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { emphasize } from '@material-ui/core/styles/colorManipulator'
import { withStyles } from '@material-ui/core/styles'
import ReactCountryFlag from 'react-country-flag'

import countries from './countries'

const countriesByCode = keyBy(countries, 'code')

const getCountryByCode = code => countriesByCode[code] || { code, name: '' }

const options = countries.map(({ name, code }) => ({
    label: name,
    value: code,
}))

// https://v3.material-ui.com/demos/autocomplete/#react-select

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    input: {
        display: 'flex',
        padding: 0,
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light'
                ? theme.palette.grey[300]
                : theme.palette.grey[700],
            0.08
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing.unit * 2,
    },
})

const NoOptionsMessage = memo(props => (
    <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
    >
        {props.children}
    </Typography>
))

const inputComponent = memo(({ inputRef, ...props }) => (
    <div ref={inputRef} {...props} />
))

const Control = memo(props => {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    )
})

const Option = memo(props => (
    <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
            fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
    >
        {props.children}
    </MenuItem>
))

const Placeholder = memo(props => (
    <Typography
        color="textSecondary"
        className={props.selectProps.classes.placeholder}
        {...props.innerProps}
    >
        {props.children}
    </Typography>
))

const SingleValue = memo(props => (
    <Typography
        className={props.selectProps.classes.singleValue}
        {...props.innerProps}
    >
        {props.children}
    </Typography>
))

const ValueContainer = memo(props => (
    <div className={props.selectProps.classes.valueContainer}>
        {props.children}
    </div>
))

const MultiValue = memo(props => (
    <Chip
        tabIndex={-1}
        label={props.children}
        className={classNames(props.selectProps.classes.chip, {
            [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        avatar={
            <Avatar>
                <ReactCountryFlag code={props.data.value} svg />
            </Avatar>
        }
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon {...props.removeProps} />}
    />
))

const Menu = memo(props => (
    <Paper
        square
        className={props.selectProps.classes.paper}
        {...props.innerProps}
    >
        {props.children}
    </Paper>
))

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
}

const CountrySelect = ({
    label,
    classes,
    value,
    onChange,
    isDisabled,
    isMulti,
}) => {
    const handleChange = useCallback(
        newValues => {
            const value = isMulti ? map(newValues, 'value') : newValues.value
            onChange(value)
        },
        [isMulti, onChange]
    )

    const textFieldProps = useMemo(
        () => ({
            label,
            margin: 'dense',
            InputLabelProps: {
                htmlFor: 'country-select',
                shrink: true,
            },
        }),
        [label]
    )

    const preparedValue = useMemo(
        () =>
            isMulti
                ? value.map(countryCode => {
                      const country = getCountryByCode(countryCode)
                      return {
                          label: country.name,
                          value: country.code,
                      }
                  })
                : value
                ? {
                      label: getCountryByCode(value).name,
                      value: getCountryByCode(value).code,
                  }
                : null,
        [value, isMulti]
    )

    return (
        <div className={classes.root}>
            <Select
                classes={classes}
                options={options}
                isMulti={isMulti}
                inputId="country-select"
                textFieldProps={textFieldProps}
                value={preparedValue}
                placeholder="Search a country..."
                components={components}
                onChange={handleChange}
                isDisabled={isDisabled}
            />
        </div>
    )
}

CountrySelect.propTypes = {
    label: PropTypes.string,
    value: PropTypes.array,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    isMulti: PropTypes.bool,
}

CountrySelect.defaultProps = {
    label: 'Country',
    value: [],
    onChange: noop,
    isDisabled: false,
    isMulti: false,
}

export default memo(withStyles(styles, { withTheme: true })(CountrySelect))
