/**
 * @flow
 * @relayHash 24a5cad77e304327b0fd3bfe23ec4206
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserLog_logs$ref = any;
type UserTransactions_data$ref = any;
export type AdminManagementUpdateDeliveryInfoInput = {|
  cuid: string,
  userCuid: string,
  phone?: ?string,
  clientMutationId?: ?string,
|};
export type UpdateDeliveryInfoMutationVariables = {|
  input: AdminManagementUpdateDeliveryInfoInput,
  userCuid: string,
|};
export type UpdateDeliveryInfoMutationResponse = {|
  +adminManagementUpdateDeliveryInfo: ?{|
    +info: ?{|
      +phone: ?string
    |},
    +errors: ?$ReadOnlyArray<?{|
      +statusCode: ?number,
      +message: ?string,
      +meta: ?any,
      +errorCode: ?string,
      +code: ?number,
    |}>,
    +adminManagement: ?{|
      +user: ?{|
        +logs: ?{|
          +$fragmentRefs: UserLog_logs$ref
        |},
        +$fragmentRefs: UserTransactions_data$ref,
      |}
    |},
  |}
|};
export type UpdateDeliveryInfoMutation = {|
  variables: UpdateDeliveryInfoMutationVariables,
  response: UpdateDeliveryInfoMutationResponse,
|};
*/


/*
mutation UpdateDeliveryInfoMutation(
  $input: AdminManagementUpdateDeliveryInfoInput!
  $userCuid: String!
) {
  adminManagementUpdateDeliveryInfo(input: $input) {
    info {
      phone
      id
    }
    errors {
      statusCode
      message
      meta
      errorCode
      code
    }
    adminManagement {
      user(userId: $userCuid) {
        ...UserTransactions_data
        logs {
          ...UserLog_logs
        }
        id
      }
      id
    }
  }
}

fragment UserTransactions_data on AdminManagementUser {
  rows: transactions(orderBy: "createdAt", orderDirection: DESC, first: 100, skip: 0, types: []) {
    count
    sum
    depositsSum
    winProductsPricesSum
    edges {
      node {
        id
        cuid
        createdAt
        actionType
        details
        amount
      }
    }
  }
}

fragment UserLog_logs on AdminManagementLogConnection {
  count
  edges {
    node {
      id
      column
      value
      modifiedByUserCuid
      createdAt
      notes
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "AdminManagementUpdateDeliveryInfoInput!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "userCuid",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "errors",
  "storageKey": null,
  "args": null,
  "concreteType": "AdminManagementError",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "statusCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "message",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "meta",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "errorCode",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userCuid"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateDeliveryInfoMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "adminManagementUpdateDeliveryInfo",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AdminManagementUpdateDeliveryInfoPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "info",
            "storageKey": null,
            "args": null,
            "concreteType": "AdminManagementDeliveryInfo",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ]
          },
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "adminManagement",
            "storageKey": null,
            "args": null,
            "concreteType": "AdminManagement",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": (v4/*: any*/),
                "concreteType": "AdminManagementUser",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "logs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AdminManagementLogConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "UserLog_logs",
                        "args": null
                      }
                    ]
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "UserTransactions_data",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateDeliveryInfoMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "adminManagementUpdateDeliveryInfo",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "AdminManagementUpdateDeliveryInfoPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "info",
            "storageKey": null,
            "args": null,
            "concreteType": "AdminManagementDeliveryInfo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ]
          },
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "adminManagement",
            "storageKey": null,
            "args": null,
            "concreteType": "AdminManagement",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": (v4/*: any*/),
                "concreteType": "AdminManagementUser",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": "rows",
                    "name": "transactions",
                    "storageKey": "transactions(first:100,orderBy:\"createdAt\",orderDirection:\"DESC\",skip:0,types:[])",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 100
                      },
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": "createdAt"
                      },
                      {
                        "kind": "Literal",
                        "name": "orderDirection",
                        "value": "DESC"
                      },
                      {
                        "kind": "Literal",
                        "name": "skip",
                        "value": 0
                      },
                      {
                        "kind": "Literal",
                        "name": "types",
                        "value": []
                      }
                    ],
                    "concreteType": "AdminManagementTransactionConnection",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "sum",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "depositsSum",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "winProductsPricesSum",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AdminManagementTransactionEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AdminManagementTransaction",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "cuid",
                                "args": null,
                                "storageKey": null
                              },
                              (v7/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "actionType",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "details",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "amount",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "logs",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "AdminManagementLogConnection",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "edges",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AdminManagementLogEdge",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "node",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "AdminManagementLog",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "column",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "value",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "modifiedByUserCuid",
                                "args": null,
                                "storageKey": null
                              },
                              (v7/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "notes",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v5/*: any*/)
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "UpdateDeliveryInfoMutation",
    "id": null,
    "text": "mutation UpdateDeliveryInfoMutation(\n  $input: AdminManagementUpdateDeliveryInfoInput!\n  $userCuid: String!\n) {\n  adminManagementUpdateDeliveryInfo(input: $input) {\n    info {\n      phone\n      id\n    }\n    errors {\n      statusCode\n      message\n      meta\n      errorCode\n      code\n    }\n    adminManagement {\n      user(userId: $userCuid) {\n        ...UserTransactions_data\n        logs {\n          ...UserLog_logs\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment UserTransactions_data on AdminManagementUser {\n  rows: transactions(orderBy: \"createdAt\", orderDirection: DESC, first: 100, skip: 0, types: []) {\n    count\n    sum\n    depositsSum\n    winProductsPricesSum\n    edges {\n      node {\n        id\n        cuid\n        createdAt\n        actionType\n        details\n        amount\n      }\n    }\n  }\n}\n\nfragment UserLog_logs on AdminManagementLogConnection {\n  count\n  edges {\n    node {\n      id\n      column\n      value\n      modifiedByUserCuid\n      createdAt\n      notes\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '26037d022c9bcf1f3458fe84e8d403e6';
module.exports = node;
