/**
 * @flow
 * @relayHash 740ad5dd307ebb368eb48fdee87b09ba
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UsersList_adminManagement$ref = any;
export type AdminManagementOrderDirections = "ASC" | "DESC" | "%future added value";
export type UsersListRefetchQueryVariables = {|
  orderBy?: ?string,
  orderDirection?: ?AdminManagementOrderDirections,
  first?: ?number,
  offset?: ?number,
  searchText?: ?string,
|};
export type UsersListRefetchQueryResponse = {|
  +adminManagement: ?{|
    +$fragmentRefs: UsersList_adminManagement$ref
  |}
|};
export type UsersListRefetchQuery = {|
  variables: UsersListRefetchQueryVariables,
  response: UsersListRefetchQueryResponse,
|};
*/

/*
query UsersListRefetchQuery(
  $orderBy: String
  $orderDirection: AdminManagementOrderDirections
  $first: Int
  $offset: Int
  $searchText: String
) {
  adminManagement {
    ...UsersList_adminManagement_1uK68k
    id
  }
}

fragment UsersList_adminManagement_1uK68k on AdminManagement {
  users(orderBy: $orderBy, orderDirection: $orderDirection, first: $first, offset: $offset, searchText: $searchText) {
    count
    edges {
      node {
        id
        cuid
        facebookId
        name
        email
        socialEmail
        role
        isBlogger
        isBlocked
        accountType
        createdAt
        isVerified
        premium {
          type
          expirationDate
        }
        isDeleted
        deletedAt
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'orderBy',
                type: 'String',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'orderDirection',
                type: 'AdminManagementOrderDirections',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'first',
                type: 'Int',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'offset',
                type: 'Int',
                defaultValue: null,
            },
            {
                kind: 'LocalArgument',
                name: 'searchText',
                type: 'String',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'Variable',
                name: 'first',
                variableName: 'first',
            },
            {
                kind: 'Variable',
                name: 'offset',
                variableName: 'offset',
            },
            {
                kind: 'Variable',
                name: 'orderBy',
                variableName: 'orderBy',
            },
            {
                kind: 'Variable',
                name: 'orderDirection',
                variableName: 'orderDirection',
            },
            {
                kind: 'Variable',
                name: 'searchText',
                variableName: 'searchText',
            },
        ],
        v2 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'UsersListRefetchQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AdminManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'FragmentSpread',
                            name: 'UsersList_adminManagement',
                            args: (v1 /*: any*/),
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'UsersListRefetchQuery',
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AdminManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'users',
                            storageKey: null,
                            args: (v1 /*: any*/),
                            concreteType: 'AdminManagementUserConnection',
                            plural: false,
                            selections: [
                                {
                                    kind: 'ScalarField',
                                    alias: null,
                                    name: 'count',
                                    args: null,
                                    storageKey: null,
                                },
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'edges',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'AdminManagementUserEdge',
                                    plural: true,
                                    selections: [
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'node',
                                            storageKey: null,
                                            args: null,
                                            concreteType: 'AdminManagementUser',
                                            plural: false,
                                            selections: [
                                                (v2 /*: any*/),
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'cuid',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'facebookId',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'name',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'email',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'socialEmail',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'role',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'isBlogger',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'isBlocked',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'accountType',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'createdAt',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'isVerified',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'LinkedField',
                                                    alias: null,
                                                    name: 'premium',
                                                    storageKey: null,
                                                    args: null,
                                                    concreteType:
                                                        'AdminManagementpremium',
                                                    plural: false,
                                                    selections: [
                                                        {
                                                            kind: 'ScalarField',
                                                            alias: null,
                                                            name: 'type',
                                                            args: null,
                                                            storageKey: null,
                                                        },
                                                        {
                                                            kind: 'ScalarField',
                                                            alias: null,
                                                            name:
                                                                'expirationDate',
                                                            args: null,
                                                            storageKey: null,
                                                        },
                                                    ],
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'isDeleted',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'deletedAt',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        (v2 /*: any*/),
                    ],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'UsersListRefetchQuery',
            id: null,
            text:
                'query UsersListRefetchQuery(\n  $orderBy: String\n  $orderDirection: AdminManagementOrderDirections\n  $first: Int\n  $offset: Int\n  $searchText: String\n) {\n  adminManagement {\n    ...UsersList_adminManagement_1uK68k\n    id\n  }\n}\n\nfragment UsersList_adminManagement_1uK68k on AdminManagement {\n  users(orderBy: $orderBy, orderDirection: $orderDirection, first: $first, offset: $offset, searchText: $searchText) {\n    count\n    edges {\n      node {\n        id\n        cuid\n        facebookId\n        name\n        email\n        socialEmail\n        role\n        isBlogger\n        isBlocked\n        accountType\n        createdAt\n        isVerified\n        premium {\n          type\n          expirationDate\n        }\n        isDeleted\n        deletedAt\n      }\n    }\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '37a9eab3617394b0b5da31b58962aefc';
module.exports = node
