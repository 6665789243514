/**
 * @flow
 * @relayHash c95e00395cf6e5f8a320f9dc603c3380
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserAgents_userAgents$ref = any;
export type UserAgentsQueryVariables = {|
  userId: string
|};
export type UserAgentsQueryResponse = {|
  +adminManagement: ?{|
    +user: ?{|
      +userAgents: ?$ReadOnlyArray<?{|
        +$fragmentRefs: UserAgents_userAgents$ref
      |}>
    |}
  |}
|};
export type UserAgentsQuery = {|
  variables: UserAgentsQueryVariables,
  response: UserAgentsQueryResponse,
|};
*/

/*
query UserAgentsQuery(
  $userId: String!
) {
  adminManagement {
    user(userId: $userId) {
      userAgents {
        ...UserAgents_userAgents
      }
      id
    }
    id
  }
}

fragment UserAgents_userAgents on AdminManagementUserAgent {
  action
  date
  userAgent
  ip
  countryCode
  countryName
  deviceType
  device {
    type
    vendor
    model
  }
  browser {
    name
    version
  }
  os {
    name
    version
  }
  engine {
    name
    version
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
    var v0 = [
            {
                kind: 'LocalArgument',
                name: 'userId',
                type: 'String!',
                defaultValue: null,
            },
        ],
        v1 = [
            {
                kind: 'Variable',
                name: 'userId',
                variableName: 'userId',
            },
        ],
        v2 = [
            {
                kind: 'ScalarField',
                alias: null,
                name: 'name',
                args: null,
                storageKey: null,
            },
            {
                kind: 'ScalarField',
                alias: null,
                name: 'version',
                args: null,
                storageKey: null,
            },
        ],
        v3 = {
            kind: 'ScalarField',
            alias: null,
            name: 'id',
            args: null,
            storageKey: null,
        }
    return {
        kind: 'Request',
        fragment: {
            kind: 'Fragment',
            name: 'UserAgentsQuery',
            type: 'Query',
            metadata: null,
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AdminManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'user',
                            storageKey: null,
                            args: (v1 /*: any*/),
                            concreteType: 'AdminManagementUser',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'userAgents',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'AdminManagementUserAgent',
                                    plural: true,
                                    selections: [
                                        {
                                            kind: 'FragmentSpread',
                                            name: 'UserAgents_userAgents',
                                            args: null,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        operation: {
            kind: 'Operation',
            name: 'UserAgentsQuery',
            argumentDefinitions: (v0 /*: any*/),
            selections: [
                {
                    kind: 'LinkedField',
                    alias: null,
                    name: 'adminManagement',
                    storageKey: null,
                    args: null,
                    concreteType: 'AdminManagement',
                    plural: false,
                    selections: [
                        {
                            kind: 'LinkedField',
                            alias: null,
                            name: 'user',
                            storageKey: null,
                            args: (v1 /*: any*/),
                            concreteType: 'AdminManagementUser',
                            plural: false,
                            selections: [
                                {
                                    kind: 'LinkedField',
                                    alias: null,
                                    name: 'userAgents',
                                    storageKey: null,
                                    args: null,
                                    concreteType: 'AdminManagementUserAgent',
                                    plural: true,
                                    selections: [
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'action',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'date',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'userAgent',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'ip',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'countryCode',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'countryName',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'ScalarField',
                                            alias: null,
                                            name: 'deviceType',
                                            args: null,
                                            storageKey: null,
                                        },
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'device',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'AdminManagementUserAgentDevice',
                                            plural: false,
                                            selections: [
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'type',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'vendor',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                                {
                                                    kind: 'ScalarField',
                                                    alias: null,
                                                    name: 'model',
                                                    args: null,
                                                    storageKey: null,
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'browser',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'AdminManagementUserAgentBrowser',
                                            plural: false,
                                            selections: (v2 /*: any*/),
                                        },
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'os',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'AdminManagementUserAgentOS',
                                            plural: false,
                                            selections: (v2 /*: any*/),
                                        },
                                        {
                                            kind: 'LinkedField',
                                            alias: null,
                                            name: 'engine',
                                            storageKey: null,
                                            args: null,
                                            concreteType:
                                                'AdminManagementUserAgentEngine',
                                            plural: false,
                                            selections: (v2 /*: any*/),
                                        },
                                    ],
                                },
                                (v3 /*: any*/),
                            ],
                        },
                        (v3 /*: any*/),
                    ],
                },
            ],
        },
        params: {
            operationKind: 'query',
            name: 'UserAgentsQuery',
            id: null,
            text:
                'query UserAgentsQuery(\n  $userId: String!\n) {\n  adminManagement {\n    user(userId: $userId) {\n      userAgents {\n        ...UserAgents_userAgents\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment UserAgents_userAgents on AdminManagementUserAgent {\n  action\n  date\n  userAgent\n  ip\n  countryCode\n  countryName\n  deviceType\n  device {\n    type\n    vendor\n    model\n  }\n  browser {\n    name\n    version\n  }\n  os {\n    name\n    version\n  }\n  engine {\n    name\n    version\n  }\n}\n',
            metadata: {},
        },
    }
})()
// prettier-ignore
;(node/*: any*/).hash = '83224b10d0e385a9ce98a3a56e0d00f6';
module.exports = node
