import React, { Fragment, useCallback, useMemo } from 'react'
import graphql from 'babel-plugin-relay/macro'
import format from 'date-fns/format'
import { Button } from '@material-ui/core'
import noop from 'lodash/noop'
import compose from 'lodash/fp/compose'
import uniq from 'lodash/fp/uniq'
import map from 'lodash/fp/map'
import compact from 'lodash/fp/compact'
import styled from 'styled-components/macro'

import BaseSection from '../../../shared/components/BaseSection'
import {
    Add as AddIcon,
    ContactSupport as PhraseIcon,
    Delete as DeleteIcon,
} from '@material-ui/icons'
import { createSimpleTable } from '../../../shared/components/SimpleTable'
import { useModals } from '../../../shared/modals'
import IconButton from '@material-ui/core/IconButton'
import get from 'lodash/get'

const fragmentSpec = {
    blacklistedPhrases: graphql`
        fragment ChatConfig_blacklistedPhrases on AppManagementBlacklistedPhrase
            @relay(plural: true) {
            id
            text
            createdAt
            createdBy
        }
    `,
}

const BlackListedPhrasesTable = createSimpleTable(fragmentSpec)

const RowActionContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const columns = ({ onDelete, canDelete }) => [
    {
        label: 'Text',
        name: 'text',
    },
    {
        label: 'Created At',
        name: 'createdAt',
        getter: ({ createdAt }) => (
            <Fragment>
                {format(new Date(createdAt), 'dd MMM yyyy')}
                <br />
                {format(new Date(createdAt), 'kk:mm:ss OOOO')}
            </Fragment>
        ),
    },
    {
        label: 'Created By',
        name: 'createdBy',
    },
    {
        label: '',
        getter: props => (
            <RowActionContainer>
                <IconButton onClick={onDelete(props)} disabled={!canDelete}>
                    <DeleteIcon />
                </IconButton>
            </RowActionContainer>
        ),
    },
]

const Toolbar = ({ onCreate = noop, canEdit }) => (
    <div>
        <Button
            variant="contained"
            color="primary"
            onClick={onCreate}
            disabled={!canEdit}
        >
            Add new
            <AddIcon />
        </Button>
    </div>
)

const ChatConfig = props => {
    const modals = useModals()
    const {
        createBlacklistedPhrases = noop,
        deleteBlacklistedPhrase = noop,
        currentUser,
    } = props

    const createBlackListedPhrases = useCallback(
        ({ phrases: rawPhrases }) => {
            const phrases = compose(
                map(phrase => ({ text: phrase })),
                uniq,
                compact,
                map(phrase => phrase.trim().toLowerCase())
            )(rawPhrases.split(/;|\n/))

            return createBlacklistedPhrases({ phrases })
        },
        [createBlacklistedPhrases]
    )

    const onCreate = useCallback(() => {
        modals.openCreateBlacklistedPhrasesModal({
            onCreate: createBlackListedPhrases,
        })
    }, [modals, createBlackListedPhrases])

    const onDelete = useCallback(
        ({ text, id }) => () => {
            modals.openConfirmationModal({
                title: `You want to delete "${text}" phrase`,
                onConfirm: () => deleteBlacklistedPhrase({ id }),
            })
        },
        [modals, deleteBlacklistedPhrase]
    )

    const canEdit = useMemo(
        () =>
            get(currentUser, 'role') === 'superAdmin' ||
            get(currentUser, 'roles', []).includes('chatModerator'),
        [currentUser]
    )

    const tableColumns = useMemo(
        () => columns({ onDelete, canDelete: canEdit }),
        [canEdit, onDelete]
    )

    return (
        <BaseSection
            title="Blacklisted(spam) phrases"
            count={props.blacklistedPhrases.length}
            icon={<PhraseIcon />}
            toolbar={<Toolbar canEdit={canEdit} onCreate={onCreate} />}
        >
            <BlackListedPhrasesTable
                dataGetter="blacklistedPhrases"
                columns={tableColumns}
                {...props}
            />
        </BaseSection>
    )
}

export default ChatConfig
