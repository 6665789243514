/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ChatConfig_blacklistedPhrases$ref: FragmentReference;
declare export opaque type ChatConfig_blacklistedPhrases$fragmentType: ChatConfig_blacklistedPhrases$ref;
export type ChatConfig_blacklistedPhrases = $ReadOnlyArray<{|
  +id: string,
  +text: string,
  +createdAt: ?any,
  +createdBy: ?string,
  +$refType: ChatConfig_blacklistedPhrases$ref,
|}>;
export type ChatConfig_blacklistedPhrases$data = ChatConfig_blacklistedPhrases;
export type ChatConfig_blacklistedPhrases$key = $ReadOnlyArray<{
  +$data?: ChatConfig_blacklistedPhrases$data,
  +$fragmentRefs: ChatConfig_blacklistedPhrases$ref,
}>;
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "ChatConfig_blacklistedPhrases",
  "type": "AppManagementBlacklistedPhrase",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "text",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdBy",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '269cdd0d250a833037825a7802ad629f';
module.exports = node;
