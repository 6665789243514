import { useState } from 'react'

const useSessionStorage = (key, initialState) => {
    const [state, setState] = useState(() => {
        const item = sessionStorage.getItem(key)

        if (item) {
            try {
                return JSON.parse(item)
            } catch (e) {
                return initialState
            }
        }

        return initialState
    })

    return [
        state,
        newState => {
            setState(newState)
            sessionStorage.setItem(key, JSON.stringify(newState))
        },
    ]
}

export default useSessionStorage
