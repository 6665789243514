/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AdminManagementAccountType = "gaming" | "sponsored" | "test" | "%future added value";
export type AdminManagementUserRole = "admin" | "superAdmin" | "user" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserMiniPage_user$ref: FragmentReference;
declare export opaque type UserMiniPage_user$fragmentType: UserMiniPage_user$ref;
export type UserMiniPage_user = {|
  +id: string,
  +cuid: ?string,
  +name: ?string,
  +email: ?string,
  +role: ?AdminManagementUserRole,
  +accountType: ?AdminManagementAccountType,
  +$refType: UserMiniPage_user$ref,
|};
export type UserMiniPage_user$data = UserMiniPage_user;
export type UserMiniPage_user$key = {
  +$data?: UserMiniPage_user$data,
  +$fragmentRefs: UserMiniPage_user$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "UserMiniPage_user",
  "type": "AdminManagementUser",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "cuid",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "email",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "role",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "accountType",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '90c2ec83afa48ee163e2a7ea5c7806bd';
module.exports = node;
