import PropTypes from 'prop-types'

import { useAppContext } from '../../modules/AppContext'

const Access = ({ children, roles }) => {
    const { currentUser } = useAppContext()
    const hasAccess =
        currentUser.role === 'superAdmin' ||
        (Array.isArray(roles) ? roles : [roles]).every(role =>
            currentUser?.roles?.includes(role)
        )

    return children({ hasAccess })
}

Access.propTypes = {
    children: PropTypes.func.isRequired,
    roles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
}

export default Access
