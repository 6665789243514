import React, { Fragment } from 'react'
import {
    DialogActions,
    DialogTitle,
    Button,
    CircularProgress,
    DialogContent,
} from '@material-ui/core'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

const LoaderContainer = styled.div`
    margin-right: 5px;
    align-items: center;
    display: flex;
`

const Confirmation = ({ title = '', onClose, onConfirm, isLoading }) => {
    return (
        <Fragment>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>{title}</DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="contained">
                    No
                </Button>
                <Button
                    onClick={onConfirm}
                    color="primary"
                    disabled={isLoading}
                >
                    {isLoading && (
                        <LoaderContainer>
                            <CircularProgress size={20} />
                        </LoaderContainer>
                    )}
                    Yes
                </Button>
            </DialogActions>
        </Fragment>
    )
}

Confirmation.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    isLoading: PropTypes.bool,
}

Confirmation.defaultProps = {
    onClose: noop,
    onConfirm: noop,
    isLoading: false,
}

export default Confirmation
