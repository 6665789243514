import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core'
import React, { Fragment, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import styled from 'styled-components'
import { yellow } from '@material-ui/core/colors'

import Select from '../components/Select'
import { PremiumBadgeIcon } from '../icons'

const Title = styled.div`
    display: flex;
    align-items: center;

    & > svg {
        margin-left: 10px;
    }
`

const InputsContainer = styled.div`
    padding: 10px 0;
    & > div {
        margin-bottom: 15px;
    }
`

const LoaderContainer = styled.div`
    margin-right: 5px;
    align-items: center;
    display: flex;
`

const IssuePremiumModal = ({ onClose, onConfirm, premiumTypes, isLoading }) => {
    const [type, setType] = useState(premiumTypes[0].value)
    const [description, setDescription] = useState('')
    const handleChange = useCallback(e => setType(e.target.value), [setType])
    const handleDescriptionChange = useCallback(
        e => setDescription(e.target.value),
        [setDescription]
    )
    const handleConfirm = useCallback(() => onConfirm({ type, description }), [
        onConfirm,
        type,
        description,
    ])

    return (
        <Fragment>
            <DialogTitle>
                <Title>
                    Issue VIP subscription (without charge){' '}
                    <PremiumBadgeIcon color={yellow['A700']} fontSize="large" />
                </Title>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please, choose subscription type and optional description (
                    <b>text</b> or <b>PhraseApp key</b>) (will be visible in
                    user transactions)
                </DialogContentText>
                <InputsContainer>
                    <Select
                        autoFocus
                        options={premiumTypes.map(({ type, cost }) => ({
                            name: type,
                            label: `${type} (${cost}$)`,
                            value: type,
                        }))}
                        value={type}
                        onChange={handleChange}
                        name="premiumType"
                        label="VIP type"
                    />
                    <TextField
                        multiline
                        rows={5}
                        rowsMax={10}
                        margin="dense"
                        id="description"
                        label="Description"
                        type="text"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={description}
                        onChange={handleDescriptionChange}
                    />
                </InputsContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    {isLoading && (
                        <LoaderContainer>
                            <CircularProgress size={20} />
                        </LoaderContainer>
                    )}
                    Ok
                </Button>
            </DialogActions>
        </Fragment>
    )
}

IssuePremiumModal.propTypes = {
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    isLoading: PropTypes.bool,
}

IssuePremiumModal.defaultProps = {
    onClose: noop,
    onConfirm: noop,
    isLoading: false,
}

export default IssuePremiumModal
