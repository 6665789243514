import { Environment, RecordSource, Store } from 'relay-runtime'
import {
    RelayNetworkLayer,
    urlMiddleware,
    authMiddleware,
    retryMiddleware,
    loggerMiddleware,
} from 'react-relay-network-modern/es'
import get from 'lodash/get'

const __DEV__ = process.env.NODE_ENV === 'development'

const store = new Store(new RecordSource())

const urlMiddlewareOptions = {
    url: (__DEV__ ? process.env : window).REACT_APP_GRAPHQL_URL,
}
if ((__DEV__ ? process.env : window).REACT_APP_NODE_ENV === 'production') {
    urlMiddlewareOptions.credentials = 'same-origin'
    urlMiddlewareOptions.mode = 'same-origin'
}

const network = new RelayNetworkLayer([
    urlMiddleware({
        ...urlMiddlewareOptions,
        url: (__DEV__ ? process.env : window).REACT_APP_GRAPHQL_URL,
    }),
    authMiddleware({
        token: () =>
            localStorage.getItem('token') ||
            get(document.cookie.match(/token=(.*);/), '1'),
    }),
    retryMiddleware({
        fetchTimeout: 30000,
    }),
    __DEV__ ? loggerMiddleware() : null,
    next => async req => {
        const controller = new window.AbortController()
        const { signal } = controller

        req.fetchOpts.signal = signal

        const timeoutId = setTimeout(() => controller.abort(), 30000)

        const res = await next(req)
        clearTimeout(timeoutId)

        return res
    },
])

const environment = new Environment({ network, store })

export default environment
