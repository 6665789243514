import React, { Fragment, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import uniq from 'lodash/uniq'
import styled from 'styled-components'
import {
    Button,
    ExpansionPanelActions,
    TextField,
    ExpansionPanelDetails,
    FormControl,
    Chip,
    Fab,
    Tooltip,
    Typography,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    Input,
    FormHelperText,
    InputAdornment,
} from '@material-ui/core'
import { MonetizationOn as MoneyIcon, Add as AddIcon } from '@material-ui/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import CountrySelect from '../../../shared/components/CountrySelect'
import Loader from '../../../shared/components/Loader'
import ImageUpload from '../../../shared/components/ImageUpload'

const ImageUploadContainer = styled.div`
    padding: 10px 0;
`

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    & > * {
        flex-grow: 1;
    }

    & > *:not(:last-child) {
        margin-right: 10px !important;
    }
`

const AmountStepsContainer = styled(Row)`
    align-items: center;
    & > * {
        flex-grow: unset !important;
    }
`

const AmountStepsInputContainer = styled.div`
    align-items: center;
    display: flex;
`

const StepList = styled.div`
    display: flex;
    flex-wrap: wrap;
    & > *:not(:last-child) {
        margin-right: 10px !important;
    }
`

const StepContainer = styled.div``

const AmountStep = ({ step, index, onDelete, isDisabled }) => {
    const _onDelete = useCallback(() => {
        if (onDelete) {
            onDelete(index)
        }
    }, [index, onDelete])

    return (
        <Draggable key={step} index={index} draggableId={step}>
            {provided => (
                <StepContainer
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <Chip
                        label={step}
                        icon={<MoneyIcon />}
                        onDelete={isDisabled ? null : _onDelete}
                        clickable
                        disa
                    />
                </StepContainer>
            )}
        </Draggable>
    )
}

const AmountSteps = ({ steps, onChange, isDisabled }) => {
    const onDragEnd = useCallback(
        ({ destination, source }) => {
            if (!destination) {
                return
            }

            if (destination.index === source.index) {
                return
            }

            const newSteps = [...steps]
            newSteps.splice(source.index, 1)
            newSteps.splice(destination.index, 0, steps[source.index])

            if (onChange) {
                onChange(newSteps)
            }
        },
        [steps, onChange]
    )

    const onDelete = useCallback(
        index => {
            if (onChange) {
                const newSteps = [...steps]
                newSteps.splice(index, 1)
                onChange(newSteps)
            }
        },
        [steps, onChange]
    )

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="amountSteps" direction="horizontal">
                {provided => (
                    <StepList
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {steps.map((step, index) => (
                            <AmountStep
                                step={step}
                                index={index}
                                onDelete={onDelete}
                                isDisabled={isDisabled}
                            />
                        ))}
                        {provided.placeholder}
                    </StepList>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export const Payment = ({ payment, save, cancelAdd, canEdit }) => {
    const [allowedCountries, setAllowedCountries] = useState(
        payment.allowedCountries || []
    )
    const [excludedCountries, setExcludedCountries] = useState(
        payment.excludedCountries || []
    )
    const [suggested, setSuggested] = useState(payment.suggested || [])
    const [suggestAll, setSuggestAll] = useState(payment.suggestAll || false)
    const [order, setOrder] = useState(payment.order)
    const [minAmount, setMinAmount] = useState(payment.minAmount || 5)
    const [maxAmount, setMaxAmount] = useState(payment.maxAmount || 5000)
    const [amountSteps, setAmountSteps] = useState(payment.amountSteps)
    const [icon, setIcon] = useState(payment.icon)

    const [newAmountStep, setNewAmountStep] = useState(0)
    const [forceMethod, setForceMethod] = useState(payment.forceMethod)
    const [paymentGroup, setPaymentGroup] = useState(payment.paymentGroup)
    const [name, setName] = useState(payment.name)
    const [currency, setCurrency] = useState(payment.currency)

    const [isChanged, setIsChanged] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const [errors, setErrors] = useState({})

    const { isNew, shortName } = payment

    const wrapHandleChange = useCallback(
        func => (...params) => {
            setIsChanged(true)
            func(...params)
        },
        [setIsChanged]
    )

    const onCancel = useCallback(() => {
        setAllowedCountries(payment.allowedCountries || [])
        setExcludedCountries(payment.excludedCountries || [])
        setSuggested(payment.suggested || [])
        setSuggestAll(payment.suggestAll || false)
        setOrder(payment.order)
        setMinAmount(payment.minAmount)
        setMaxAmount(payment.maxAmount)
        setAmountSteps(payment.amountSteps)
        setIsChanged(false)
    }, [
        payment.allowedCountries,
        payment.excludedCountries,
        payment.order,
        payment.minAmount,
        payment.maxAmount,
        payment.amountSteps,
        payment.suggested,
        payment.suggestAll,
    ])

    const onSave = useCallback(async () => {
        setIsSaving(true)
        try {
            if (isNew) {
                let isValid = true
                const errors = {}
                if (!(name && name.trim())) {
                    errors.name = 'Is required'
                    isValid = false
                }

                if (!paymentGroup) {
                    errors.paymentGroup = 'Is required'
                    isValid = false
                }

                if (!isValid) {
                    setErrors(errors)
                    return
                }
            }

            await save({
                allowedCountries,
                excludedCountries,
                suggestAll,
                suggested,
                order: parseFloat(order),
                minAmount: parseFloat(minAmount.toString().replace(/,/g, '.')),
                maxAmount: parseFloat(maxAmount.toString().replace(/,/g, '.')),
                amountSteps,
                icon,
                id: payment.id,
                isNew,
                name,
                paymentGroup,
                forceMethod,
                currency,
            })
            setIsChanged(false)
        } finally {
            setIsSaving(false)
        }
    }, [
        save,
        allowedCountries,
        excludedCountries,
        suggested,
        suggestAll,
        order,
        minAmount,
        maxAmount,
        amountSteps,
        icon,
        payment.id,
        isNew,
        name,
        paymentGroup,
        forceMethod,
        currency,
    ])

    const _handleAmountStepsChange = useMemo(
        () =>
            wrapHandleChange(steps => {
                setAmountSteps(steps)
            }),
        [wrapHandleChange, setAmountSteps]
    )

    const _handleForceMethodChange = useMemo(
        () =>
            wrapHandleChange(e => {
                setForceMethod(e.target.value.trim())
            }),
        [wrapHandleChange, setForceMethod]
    )

    const _handlePaymentGroupChange = useMemo(
        () =>
            wrapHandleChange(e => {
                setPaymentGroup(e.target.value)
            }),
        [wrapHandleChange, setPaymentGroup]
    )

    const _handleNameChange = useMemo(
        () =>
            wrapHandleChange(e => {
                setName(e.target.value)
            }),
        [wrapHandleChange, setName]
    )

    const _handleCurrencyChange = useMemo(
        () =>
            wrapHandleChange(e => {
                setCurrency(e.target.value.trim())
            }),
        [wrapHandleChange, setCurrency]
    )

    const _handleOrderChange = useMemo(
        () =>
            wrapHandleChange(e => {
                setOrder(e.target.value || 0)
            }),
        [wrapHandleChange, setOrder]
    )

    const _handleMinAmountChange = useMemo(
        () =>
            wrapHandleChange(e => {
                setMinAmount(e.target.value || 0)
            }),
        [wrapHandleChange, setMinAmount]
    )

    const _handleMaxAmountChange = useMemo(
        () =>
            wrapHandleChange(e => {
                setMaxAmount(e.target.value || 0)
            }),
        [wrapHandleChange, setMaxAmount]
    )

    const _handleAllowedCountriesChange = useMemo(
        () => wrapHandleChange(setAllowedCountries),
        [wrapHandleChange, setAllowedCountries]
    )

    const _handleExcludedCountriesChange = useMemo(
        () => wrapHandleChange(setExcludedCountries),
        [wrapHandleChange, setExcludedCountries]
    )

    const _handleSuggestAllChange = useMemo(
        () => wrapHandleChange(e => setSuggestAll(e.target.checked)),
        [wrapHandleChange, setSuggestAll]
    )

    const _handleSuggestedChange = useMemo(
        () => wrapHandleChange(setSuggested),
        [wrapHandleChange, setSuggested]
    )

    const _handleNewAmountStepChange = useMemo(
        () =>
            wrapHandleChange(e => {
                setNewAmountStep(e.target.value.replace(/,|\./g, ''))
            }),
        [wrapHandleChange, setNewAmountStep]
    )

    const _handleNewAmountStepAdd = useMemo(
        () =>
            wrapHandleChange(() => {
                setAmountSteps(uniq([...amountSteps, newAmountStep]))
                setNewAmountStep(0)
            }),
        [wrapHandleChange, setAmountSteps, amountSteps, newAmountStep]
    )

    const _handleIconChange = useMemo(
        () =>
            wrapHandleChange(url => {
                setIcon(url)
                setErrors({
                    ...errors,
                    icon: null,
                })
            }),
        [wrapHandleChange, errors]
    )

    const _handleCancelAdd = useCallback(() => {
        cancelAdd(payment.id)
    }, [cancelAdd, payment.id])

    const _handleImageError = useCallback(
        e => {
            setErrors({
                ...errors,
                imageUrl: e.message,
            })
        },
        [errors]
    )

    return (
        <Fragment>
            <ExpansionPanelDetails>
                <Container>
                    {isNew && (
                        <Row>
                            <FormControl margin="dense" error={errors.name}>
                                <InputLabel
                                    htmlFor={`${shortName}-name`}
                                    shrink
                                >
                                    Name
                                </InputLabel>
                                <Input
                                    id={`${shortName}-name`}
                                    value={name}
                                    onChange={_handleNameChange}
                                />
                                {errors.name && (
                                    <FormHelperText>
                                        {errors.name}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                disabled={!payment.isNew || !canEdit}
                                margin="dense"
                                error={errors.paymentGroup}
                            >
                                <InputLabel
                                    shrink
                                    htmlFor={`${shortName}-paymentGroup`}
                                >
                                    Payment system
                                </InputLabel>
                                <Select
                                    inputProps={{
                                        id: `${shortName}-paymentGroup`,
                                    }}
                                    value={paymentGroup}
                                    onChange={_handlePaymentGroupChange}
                                >
                                    <MenuItem value="ecommpay">
                                        Ecommpay
                                    </MenuItem>
                                    <MenuItem value="g2a">G2A</MenuItem>
                                    <MenuItem value="cardpay">Cardpay</MenuItem>
                                    <MenuItem value="coins_paid">
                                        Coinspaid
                                    </MenuItem>
                                    <MenuItem value="skinpay">Skinpay</MenuItem>
                                    <MenuItem value="vouncherifyio">
                                        Voucherify
                                    </MenuItem>
                                    <MenuItem value="pay_op">PayOP</MenuItem>
                                </Select>
                                {errors.paymentGroup && (
                                    <FormHelperText>
                                        {errors.paymentGroup}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Row>
                    )}
                    <Row>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            id={`${shortName}-forceMethod`}
                            disabled={!isNew || !canEdit}
                            label="Force method"
                            value={forceMethod}
                            onChange={_handleForceMethodChange}
                            margin="dense"
                        />
                        <TextField
                            id={`${shortName}-order`}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="number"
                            inputProps={{
                                step: 0.05,
                            }}
                            label="Order"
                            value={order}
                            margin="dense"
                            onChange={_handleOrderChange}
                            disabled={!canEdit}
                        />
                        <TextField
                            id={`${shortName}-currency`}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="text"
                            label="Currency"
                            value={currency}
                            onChange={_handleCurrencyChange}
                            margin="dense"
                            disabled={!payment.isNew || !canEdit}
                        />
                    </Row>
                    <Row>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            id={`${shortName}-minAmount`}
                            inputProps={{
                                step: 0.05,
                                type: 'number',
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            }
                            onChange={_handleMinAmountChange}
                            label="Min amount"
                            value={minAmount}
                            margin="dense"
                            disabled={!canEdit}
                        />
                        <TextField
                            id={`${shortName}-maxAmount`}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 0.05,
                                type: 'number',
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    $
                                </InputAdornment>
                            }
                            onChange={_handleMaxAmountChange}
                            label="Max amount"
                            value={maxAmount}
                            margin="dense"
                            disabled={!canEdit}
                        />
                    </Row>
                    <FormControl fullWidth>
                        <CountrySelect
                            value={allowedCountries}
                            onChange={_handleAllowedCountriesChange}
                            label="Allowed countries"
                            isDisabled={!canEdit}
                            isMulti
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <CountrySelect
                            value={excludedCountries}
                            onChange={_handleExcludedCountriesChange}
                            label="Excluded countries"
                            isDisabled={!canEdit}
                            isMulti
                        />
                    </FormControl>
                    <Row>
                        <CountrySelect
                            value={suggested}
                            onChange={_handleSuggestedChange}
                            label="Suggested countries"
                            isDisabled={suggestAll || !canEdit}
                            isMulti
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={suggestAll}
                                    onChange={_handleSuggestAllChange}
                                    value={suggestAll}
                                    color="primary"
                                    disabled={!canEdit}
                                />
                            }
                            label="Suggest all"
                        />
                    </Row>
                    {amountSteps && (
                        <AmountStepsContainer>
                            <AmountStepsInputContainer>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    id={`${shortName}-amountStep`}
                                    inputProps={{
                                        step: 1,
                                        type: 'number',
                                    }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    }
                                    label="Amount step"
                                    value={newAmountStep}
                                    onChange={_handleNewAmountStepChange}
                                    margin="dense"
                                    disabled={!canEdit}
                                />
                                <Tooltip
                                    title="Add new step"
                                    aria-label="Add new step"
                                >
                                    <div>
                                        <Fab
                                            color="primary"
                                            size="small"
                                            disabled={
                                                !newAmountStep ||
                                                amountSteps.includes(
                                                    newAmountStep
                                                )
                                            }
                                            onClick={_handleNewAmountStepAdd}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </div>
                                </Tooltip>
                            </AmountStepsInputContainer>
                            <AmountSteps
                                isDisabled={!canEdit}
                                steps={amountSteps}
                                onChange={_handleAmountStepsChange}
                            />
                        </AmountStepsContainer>
                    )}
                    <ImageUploadContainer>
                        <Typography variant="caption" color="textSecondary">
                            Icon
                        </Typography>
                        <ImageUpload
                            isDisabled={!canEdit}
                            onChange={_handleIconChange}
                            onError={_handleImageError}
                            maxSize={524288}
                            defaultUrl={icon}
                        />
                        {errors.imageUrl && (
                            <FormHelperText error={Boolean(errors.imageUrl)}>
                                {errors.imageUrl}
                            </FormHelperText>
                        )}
                    </ImageUploadContainer>
                </Container>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={isNew ? _handleCancelAdd : onCancel}
                    disabled={!isNew && !isChanged}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={!isChanged || isSaving}
                    onClick={onSave}
                >
                    Save
                    {isSaving && <Loader />}
                </Button>
            </ExpansionPanelActions>
        </Fragment>
    )
}

Payment.propTypes = {
    payment: PropTypes.shape({
        name: PropTypes.string,
        shortName: PropTypes.string,
        isEnabled: PropTypes.bool,
        order: PropTypes.number,
        minAmount: PropTypes.number,
        maxAmount: PropTypes.number,
        excludedCountries: PropTypes.arrayOf(PropTypes.string),
        allowedCountries: PropTypes.arrayOf(PropTypes.string),
        suggested: PropTypes.arrayOf(PropTypes.string),
        suggestAll: PropTypes.bool,
        forceMethod: PropTypes.string,
        paymentGroup: PropTypes.string,
        amountSteps: PropTypes.arrayOf(PropTypes.number),
        canEdit: PropTypes.bool,
    }).isRequired,
    save: PropTypes.func,
}

Payment.defaultProps = {
    save: noop,
    canEdit: true,
}
