import graphql from 'babel-plugin-relay/macro'

export default graphql`
    mutation RequireAddressKycMutation(
        $input: AdminManagementRequireAddressKycInput!
    ) {
        adminManagementRequireAddressKyc(input: $input) {
            address {
                cuid
                isKycRequired
            }
            errors {
                statusCode
                message
            }
        }
    }
`
