import React, { Fragment, useState, useCallback } from 'react'
import { InlineDateTimePicker } from 'material-ui-pickers'
import {
    DialogActions,
    DialogTitle,
    Button,
    CircularProgress,
    DialogContent,
    MenuItem,
    TextField,
} from '@material-ui/core'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import Grid from '@material-ui/core/Grid'

const LoaderContainer = styled.div`
    margin-right: 5px;
    align-items: center;
    display: flex;
`

const CreateTheme = ({ onClose, onCreate, isLoading }) => {
    const [name, setName] = useState('christmas')
    const [startAt, setStartAt] = useState(null)
    const [finishAt, setFinishAt] = useState(null)

    const handleNameChange = useCallback(e => setName(e.target.value), [
        setName,
    ])
    const handleStartAtChange = useCallback(date => setStartAt(date), [])
    const handleFinishAtChange = useCallback(date => setFinishAt(date), [])

    const handleCreate = useCallback(
        () => onCreate({ name, startAt, finishAt }),
        [finishAt, name, onCreate, startAt]
    )

    return (
        <Fragment>
            <DialogTitle>Create theme schedule</DialogTitle>
            <DialogContent>
                <Grid container spacing={8}>
                    <Grid item xs={12}>
                        <TextField
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Theme"
                            fullWidth
                            value={name}
                            onChange={handleNameChange}
                            select
                        >
                            <MenuItem value="christmas">Christmas</MenuItem>
                            <MenuItem value="valentines_day">
                                Valentines Day
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <InlineDateTimePicker
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            label="Start At"
                            autoFocus
                            disablePast
                            ampm={false}
                            value={startAt}
                            onChange={handleStartAtChange}
                            maxDate={finishAt}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InlineDateTimePicker
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            label="Finish At"
                            autoFocus
                            disablePast
                            ampm={false}
                            value={finishAt}
                            onChange={handleFinishAtChange}
                            minDate={startAt}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="contained">
                    Cancel
                </Button>
                <Button
                    onClick={handleCreate}
                    color="primary"
                    disabled={isLoading}
                >
                    {isLoading && (
                        <LoaderContainer>
                            <CircularProgress size={20} />
                        </LoaderContainer>
                    )}
                    Add
                </Button>
            </DialogActions>
        </Fragment>
    )
}

CreateTheme.propTypes = {
    onClose: PropTypes.func,
    onCreate: PropTypes.func,
    isLoading: PropTypes.bool,
}

CreateTheme.defaultProps = {
    onClose: noop,
    onCreate: noop,
    isLoading: false,
}

export default CreateTheme
