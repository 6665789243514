import React, { Fragment, useState, useCallback } from 'react'
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Button,
    CircularProgress,
    Step,
    Stepper,
    StepLabel,
    Typography,
    InputAdornment,
    TextField,
    FormControl,
} from '@material-ui/core'
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import floor from 'lodash/floor'
import uniqBy from 'lodash/uniqBy'
import Papa from 'papaparse'
import FileUploader from '../components/FileUploader'
import JsonPreView from '../components/JsonPreView'
import { CopyToClipboard } from '../components/CopyToClipboard'

const ButtonIcon = styled.div`
    margin-left: 10px;
    display: flex;
    align-items: center;
`

const UploadsContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;

    > *:not(:last-child) {
        margin-right: 10px;
    }
`

const LoaderContainer = styled.div`
    margin-right: 5px;
    align-items: center;
    display: flex;
`

const AnalyzeSupport = ({ onClose, onAnalyze, isLoading }) => {
    const [uploads, setUploads] = useState(null)
    const [activeStep, setActiveStep] = useState(0)
    const [results, setResults] = useState()

    const handleUpload = useCallback(
        result => {
            const { data } = Papa.parse(result, {
                header: true,
                skipEmptyLines: true,
            })

            const users = data
                .filter(
                    ({ userCuid, date }) =>
                        userCuid && date && !isNaN(Date.parse(date))
                )
                .map(({ userCuid, date }) => ({
                    userCuid,
                    date: new Date(date),
                }))

            setUploads(uniqBy(users, 'userCuid'))
        },
        [setUploads]
    )

    const _handleAnalyze = useCallback(async () => {
        setResults(await onAnalyze(uploads))
        setActiveStep(1)
    }, [onAnalyze, uploads, setActiveStep])

    return (
        <Fragment>
            <DialogTitle>Analyze support</DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep}>
                    <Step completed={activeStep !== 0}>
                        <StepLabel>Upload file</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>View results</StepLabel>
                    </Step>
                </Stepper>
                {activeStep === 0 && (
                    <Fragment>
                        <DialogContentText>
                            Upload contact support data in .csv file with fields
                            (userCuid, date)
                        </DialogContentText>
                        <UploadsContainer>
                            <FileUploader
                                onUpload={handleUpload}
                                accept="text/csv"
                            >
                                <Button
                                    color="default"
                                    variant="contained"
                                    size="small"
                                >
                                    Upload file
                                    <ButtonIcon>
                                        <CloudUploadIcon />
                                    </ButtonIcon>
                                </Button>
                            </FileUploader>
                            {uploads && <div>count: {uploads.length}</div>}
                        </UploadsContainer>
                    </Fragment>
                )}
                {activeStep === 1 && results && (
                    <Fragment>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                label="Count"
                                disabled
                                defaultValue={results.count}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                label="Who made deposits count"
                                disabled
                                defaultValue={`${
                                    results.whoMadeDepositsCount
                                } (${floor(
                                    (results.whoMadeDepositsCount * 100) /
                                        results.count,
                                    2
                                )}%)`}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="dense">
                            <TextField
                                label="Deposits sum"
                                disabled
                                defaultValue={results.depositsSum}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                        <Typography variant="caption">
                            Users cuids who made deposits after
                        </Typography>
                        <CopyToClipboard
                            text={results.whoMadeDeposits.join(', ')}
                        >
                            <JsonPreView data={results.whoMadeDeposits} />
                        </CopyToClipboard>
                    </Fragment>
                )}
            </DialogContent>
            {activeStep === 0 && (
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color="secondary"
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={_handleAnalyze}
                        color="primary"
                        disabled={!uploads || !uploads.length || isLoading}
                    >
                        {isLoading && (
                            <LoaderContainer>
                                <CircularProgress size={20} />
                            </LoaderContainer>
                        )}
                        Analyze
                    </Button>
                </DialogActions>
            )}
            {activeStep === 1 && (
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color="secondary"
                        variant="contained"
                    >
                        Close
                    </Button>
                </DialogActions>
            )}
        </Fragment>
    )
}

AnalyzeSupport.propTypes = {
    onClose: PropTypes.func,
    onAnalyze: PropTypes.func,
    isLoading: PropTypes.bool,
}

AnalyzeSupport.defaultProps = {
    onClose: noop,
    onAnalyze: noop,
    isLoading: false,
}

export default AnalyzeSupport
